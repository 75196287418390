import { Navigate, Route, Routes, useLocation } from "react-router-dom"
import RecrutementRouting from "../modules/recrutement/RecrutementRouting"
import { useAuth } from "../AppContext"
import BusinessRoutes from "../modules/business/BusinessRoutes"
import PtfRouting from "../modules/client/ClientRouting"
import DashboardRouting from "../modules/dashboard/routing/DashboardRouting"
import ToolsRouting from "../modules/tools/ToolsRouting"
import PersonnelRouting from "../modules/personnel/PersonnelRouting"
import { CONST_PERSONNEL_APPLICATION_ACCESS_MODULE_BUSINESS_PTF } from "@common-constants/*"
import GestionRouting from "../modules/gestion/routing/GestionRouting"
import { ROUTING_ABSOLUTE_PATH_GESTION } from "../modules/gestion/routing/routing-endpoints"
import { ROUTING_ABSOLUTE_PATH_DASHBOARD } from "../modules/dashboard/routing/routing-endpoints"
import FournisseurRouting from "../modules/fournisseur/routing/FournisseurRouting"

const PrivateRoutes = () => {
     const { currentUser } = useAuth()
     const location = useLocation()

     let prevUrl: any
     if (location.pathname.includes("auth") && localStorage.getItem("prevUrl")) {
          prevUrl = localStorage.getItem("prevUrl")
          localStorage.removeItem("prevUrl")
     }

     return (
          <Routes>
               {/* MODULES */}
               <Route path="/" element={<DashboardRouting />} />
               <Route path={`${ROUTING_ABSOLUTE_PATH_DASHBOARD}/*`} element={<DashboardRouting />} />

               <Route path="personnel/*" element={<PersonnelRouting />} />

               <Route path="recrutement/*" element={<RecrutementRouting />} />

               <Route path="fournisseurs/*" element={<FournisseurRouting />} />

               <Route path="business/*" element={<BusinessRoutes />} />

               {currentUser?.authorizedAppModules?.find(item => item === CONST_PERSONNEL_APPLICATION_ACCESS_MODULE_BUSINESS_PTF) !== undefined && (
                    <Route path="client/*" element={<PtfRouting />} />
               )}

               <Route path={`${ROUTING_ABSOLUTE_PATH_GESTION}/*`} element={<GestionRouting />} />

               <Route path="tools/*" element={<ToolsRouting />} />

               {/* Redirect to the requested route after login. Otherwise redirect to dashboard*/}
               <Route path="auth/*" element={<Navigate replace to={prevUrl || "/dashboard"} />} />

               {/*Page not found*/}
               <Route path="*" element={<Navigate to="/error/404" />} />
          </Routes>
     )
}

export { PrivateRoutes }
