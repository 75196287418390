import React, { useState } from "react"
import { toAbsoluteUrl } from "@common-metronic/helpers"
import MyLoadingOverlay from "@common-utils/MyLoadingOverlay"
import { IGetPropositionsDataItemResponseModel, IGetPropositionsResponseModel } from "./core/_models"
import { usePtfListingContext } from "./PtfListing"
import { getSelectedItemPlusPreviousAndNextItem } from "./core/_helpers"
import { usePtfListingListContext } from "./_PtfListingList"
import MyTooltip from "@common-utils/MyTooltip"
import useSwal from "@common-hooks/useSwal"
import { setEtatClientPropalRequest } from "../core/_requests"
import { useQueryClient } from "react-query"
import { toast } from "react-toastify"
import { Link } from "react-router-dom"
import FileAndDriveHelper from "@common-helpers/FileAndDriveHelper"

interface IListItemPropsModel {
     item: IGetPropositionsDataItemResponseModel
     handleClickItemForPreview: (e: React.MouseEvent<any>) => void
     isItemSelected: boolean
     isItemLoading: boolean
}

const ListItem = ({ item, handleClickItemForPreview, isItemSelected, isItemLoading }: IListItemPropsModel) => {
     const { swal } = useSwal()
     const queryClient = useQueryClient()
     const globalContext = usePtfListingContext()
     const listContext = usePtfListingListContext()
     const [isAvatarLoading, setIsAvatarLoading] = useState<boolean>(!!item.proposition.client?.googleDriveIdAvatar)

     function handleOnCheckItem() {
          if (!globalContext.isBulkSelectionEnabled.checkedElementsInPage.includes(item.proposition.id as number)) {
               globalContext.setIsBulkSelectionEnabled(prev => ({
                    ...prev,
                    checkedElementsInPage: [...prev.checkedElementsInPage, item.proposition.id as number],
                    areAllElementsInAllPagesChecked: false,
               }))
          } else {
               globalContext.setIsBulkSelectionEnabled(prev => ({
                    ...prev,
                    checkedElementsInPage: prev.checkedElementsInPage.filter(id => id !== item.proposition.id),
                    areAllElementsInAllPagesChecked: false,
               }))
          }
     }

     function handleSetPropalState(etat: string) {
          swal.fire({
               icon: "warning",
               title: `Veuillez confirmer votre action`,
               showCancelButton: true,
               confirmButtonText: "Confirmer",
               cancelButtonText: "Annuler",
               showLoaderOnConfirm: true,
               preConfirm: async () => {
                    return setEtatClientPropalRequest(item.proposition.id as number, etat)
                         .then(r => {
                              // Update item
                              queryClient.setQueryData<IGetPropositionsResponseModel | undefined>(
                                   globalContext.REACT_QUERY_KEY_LISTING,
                                   (prev: IGetPropositionsResponseModel | undefined) => {
                                        if (prev?.data && prev.data.length > 0) {
                                             const index = prev.data.findIndex(item_ => item_.proposition?.id === item.proposition.id)
                                             if (index >= 0) {
                                                  prev.data[index] = {
                                                       ...prev.data[index],
                                                       proposition: {
                                                            ...prev.data[index].proposition,
                                                            ...r.data,
                                                       },
                                                  }
                                                  return prev
                                             }
                                        }

                                        return prev
                                   }
                              )
                              toast.success("Votre action a bien été prise en compte.")
                         })
                         .catch(e => {
                              toast.error(e?.response?.data?.detail, { autoClose: false })
                         })
               },
               allowOutsideClick: () => !swal.isLoading(),
          }).then()
     }

     const BadgeEtat = () => {
          return (
               <>
                    {item.proposition.etat === "ETAT_EN_COURS" && <div className={`badge badge-warning`}>{item.proposition.etatLabel}</div>}
                    {item.proposition.etat === "ETAT_VALIDEE" && <div className={`badge badge-success`}>{item.proposition.etatLabel}</div>}
                    {item.proposition.etat === "ETAT_REFUSEE" && <div className={`badge badge-danger`}>{item.proposition.etatLabel}</div>}
                    {item.proposition.etat === "ETAT_DISABLED" && <div className={`badge badge-secondary`}>{item.proposition.etatLabel}</div>}
                    {item.proposition.etat === "ETAT_EN_PROD" && <div className={`badge badge-primary`}>{item.proposition.etatLabel}</div>}
                    {item.proposition.etat === "ETAT_PARTIELLEMENT_LIVRE" && <div className={`badge badge-info`}>{item.proposition.etatLabel}</div>}
                    {item.proposition.etat === "ETAT_LIVRE" && <div className={`badge badge-success`}>{item.proposition.etatLabel}</div>}
               </>
          )
     }

     const BadgeMere = () => {
          const tooltipTitle =
               item.propositionsEnfant.length > 0 ? (
                    <div>
                         <p className={"fw-bold"}>Enfants</p>
                         {item.propositionsEnfant.map((item_enfant, enfant_key) => (
                              <div className={"d-flex"}>
                                   <Link to={`/client/ptf/${item_enfant.id}/details`} target={"_blank"} className={"me-1"} key={enfant_key}>
                                        {item_enfant.reference}
                                   </Link>
                                   à {item_enfant.tjm}€ / jour
                              </div>
                         ))}
                    </div>
               ) : (
                    "Aucun enfant"
               )
          return (
               <MyTooltip title={<div className={"d-flex justify-content-center flex-column"}>{tooltipTitle}</div>} trigger={"click"}>
                    <span className={"badge badge-primary fs-9 ms-1 cursor-pointer not-concerned-by-preview"}>
                         {item.propositionsEnfant.length > 0 ? (
                              <>
                                   Contient
                                   <span className={"text-decoration-underline ms-1 not-concerned-by-preview"}>
                                        {item.propositionsEnfant.length} {item.propositionsEnfant.length > 1 ? "enfants" : "enfant"}
                                   </span>
                              </>
                         ) : (
                              "Sans enfants"
                         )}
                    </span>
               </MyTooltip>
          )
     }

     const BadgeEnfant = () => {
          return (
               <span className={"badge badge-primary fs-9 ms-1 cursor-pointer not-concerned-by-preview"}>
                    {item.propositionMere ? (
                         <>
                              <span className={"fw-bold me-1"}>Hérite de la</span>{" "}
                              <Link to={`/client/ptf/${item.propositionMere.id}/details`} className={"text-white text-decoration-underline"}>
                                   {item.propositionMere.reference}
                              </Link>
                         </>
                    ) : (
                         <>Sans PTF mère</>
                    )}
               </span>
          )
     }

     const ActionsIcons = () => {
          return (
               <>
                    {item.proposition.etat === "ETAT_EN_COURS" && (
                         <>
                              <div>
                                   <MyTooltip title={"J'ai remporté cette affaire"}>
                                        <button
                                             type={"button"}
                                             className={`btn btn-outline btn-outline-success btn-sm btn-icon rounded-circle mb-2`}
                                             onClick={() => handleSetPropalState("ETAT_VALIDEE")}
                                        >
                                             <i className="fas fa-handshake fs-4" />
                                        </button>
                                   </MyTooltip>
                              </div>
                              <div>
                                   <MyTooltip title={"J'ai perdu cette affaire"}>
                                        <button
                                             type={"button"}
                                             className={`btn btn-outline btn-outline-danger btn-sm btn-icon rounded-circle mb-2`}
                                             onClick={() => handleSetPropalState("ETAT_REFUSEE")}
                                        >
                                             <i className="fas fa-sad-tear fs-4" />
                                        </button>
                                   </MyTooltip>
                              </div>
                         </>
                    )}

                    {item.proposition.type === "TYPE_FORFAIT" && item.proposition.etat === "ETAT_VALIDEE" && (
                         <div>
                              <MyTooltip title={"Démarrage du projet et passage en production"}>
                                   <button
                                        type={"button"}
                                        className={`btn btn-outline btn-outline-info btn-sm btn-icon rounded-circle mb-2`}
                                        onClick={() => handleSetPropalState("ETAT_EN_PROD")}
                                   >
                                        <i className="fas fa-hard-hat fs-4" />
                                   </button>
                              </MyTooltip>
                         </div>
                    )}

                    {item.proposition.type === "TYPE_FORFAIT" && ["ETAT_VALIDEE", "ETAT_EN_PROD", "ETAT_PARTIELLEMENT_LIVRE"].includes(item.proposition.etat as string) && (
                         <div>
                              <MyTooltip title={"Le projet a été livré dans son intégralité"}>
                                   <button
                                        type={"button"}
                                        className={`btn btn-outline btn-outline-success btn-sm btn-icon rounded-circle mb-2`}
                                        onClick={() => handleSetPropalState("ETAT_LIVRE")}
                                   >
                                        <i className="fas fa-check fs-4" />
                                   </button>
                              </MyTooltip>
                         </div>
                    )}

                    {item.proposition.etat !== "ETAT_DISABLED" && (
                         <>
                              <div>
                                   <MyTooltip title={"Changer de responsable"}>
                                        <button
                                             type={"button"}
                                             className={`btn btn-outline btn-outline-primary btn-sm btn-icon rounded-circle mb-2`}
                                             onClick={() => globalContext.setChangementResponsableOfOneOrMultiplePropal([item.proposition])}
                                        >
                                             <i className="fas fa-user-pen fs-4" />
                                        </button>
                                   </MyTooltip>
                              </div>
                              <div>
                                   <MyTooltip title={"Archiver"}>
                                        <button
                                             type={"button"}
                                             className={`btn btn-outline btn-outline-dark btn-sm btn-icon rounded-circle mb-2`}
                                             onClick={() => handleSetPropalState("ETAT_DISABLED")}
                                        >
                                             <i className="fas fa-archive fs-4" />
                                        </button>
                                   </MyTooltip>
                              </div>
                         </>
                    )}
               </>
          )
     }

     return (
          <MyLoadingOverlay isActive={isItemLoading}>
               <>
                    <div
                         className={`${isItemSelected ? "border-start border-2 border-primary" : ""}`}
                         onClick={e => handleClickItemForPreview(e)}
                         ref={isItemSelected ? listContext.selectedItemRef : null}
                    >
                         <div className={`p-4 d-flex align-items-center ${isItemSelected ? "mb-4 bg-light" : "cursor-pointer"}`}>
                              {/* checkbox */}
                              {globalContext.isBulkSelectionEnabled.enabled && (
                                   <div className={"form-check form-check-custom form-check-solid form-check-sm me-4"}>
                                        <input
                                             type="checkbox"
                                             className={"form-check-input cursor-pointer border border-secondary not-concerned-by-preview"}
                                             checked={globalContext.isBulkSelectionEnabled.checkedElementsInPage.includes(item.proposition.id as number)}
                                             onChange={() => handleOnCheckItem()}
                                        />
                                   </div>
                              )}
                              {/* Client avatar */}
                              <div>
                                   <div className={"d-flex justify-content-center"}>
                                        <MyLoadingOverlay isActive={isAvatarLoading} styles={{ height: "50px", width: "50px", borderRadius: "50%" }} size={"sm"}>
                                             {/* Avatar */}
                                             <img
                                                  src={
                                                       item.proposition.client?.googleDriveIdAvatar
                                                            ? FileAndDriveHelper.getThumbnailDriveUrl(item.proposition.client?.googleDriveIdAvatar)
                                                            : toAbsoluteUrl("/media/svg/files/blank-image.svg")
                                                  }
                                                  onLoad={() => setIsAvatarLoading(false)}
                                                  style={{ height: "50px", width: "50px", borderRadius: "50%" }}
                                                  alt="Avatar"
                                             />
                                        </MyLoadingOverlay>
                                   </div>
                              </div>
                              {/* Référence */}
                              <div className={"flex-grow-1 ms-4"}>
                                   <div className={"d-flex align-items-center"}>
                                        <div>
                                             <span className={"fs-5 fw-bold me-2 d-flex align-items-center"}>
                                                  <Link to={`/client/ptf/${item.proposition.id}/details`}>{item.proposition.reference}</Link>
                                                  {item.proposition.categorie === "CATEGORIE_MERE" && <BadgeMere />}
                                                  {item.proposition.categorie === "CATEGORIE_FILLE" && <BadgeEnfant />}
                                             </span>
                                        </div>
                                        <div className={"ms-auto"}>
                                             <div className={`badge badge-dark`}>{item.proposition.typeLabelShort}</div>
                                        </div>
                                   </div>
                                   <div className={"d-flex align-items-center mt-1"}>
                                        <div>{item.proposition.titre && item.proposition.titre}</div>
                                        <div className={"ms-auto"}>
                                             <BadgeEtat />
                                        </div>
                                   </div>
                              </div>
                         </div>

                         {isItemSelected && (
                              <div className={"d-flex align-items-center px-4 pb-4"}>
                                   <div className={"col-8"}>
                                        <div className={`d-flex align-items-center mb-2`}>
                                             <span className="fas fa-calendar-day fs-5 w-25px" />
                                             Créée le {item.proposition.createdAt?.format("dddd D MMMM YYYY")}
                                        </div>
                                        <div className={`d-flex align-items-center mb-2`}>
                                             <span className="fas fa-user fs-5 w-25px" />
                                             {item.proposition.personnel?.prenomNom}
                                        </div>
                                        <div className={`d-flex align-items-center mb-2`}>
                                             <span className="fas fa-building fs-5 w-25px" />
                                             {item.proposition.client?.nom} ({" "}
                                             <MyTooltip
                                                  title={
                                                       <div className={"d-flex justify-content-center flex-column"}>
                                                            <div className={"fw-bold me-2"}>Email:</div>
                                                            <a href={`mailto:${item.proposition.clientResponsable?.email}`}>{item.proposition.clientResponsable?.email}</a>
                                                            <div className={"fw-bold me-2"}>Tél.:</div>
                                                            <a href={`tel:${item.proposition.clientResponsable?.tel}`}>{item.proposition.clientResponsable?.tel}</a>
                                                       </div>
                                                  }
                                                  trigger={"click"}
                                             >
                                                  <span style={{ textDecoration: "underline dotted", cursor: "pointer" }}>{item.proposition.clientResponsable?.prenomNom}</span>
                                             </MyTooltip>
                                             ){item.proposition.categorie !== "CATEGORIE_MERE" && <span className={"ms-1"}>à {item.proposition.tjm}€ / jour</span>}
                                        </div>
                                        <div className={`d-flex align-items-center mb-2`}>
                                             <span className="fas fa-map-location fs-5 w-25px" />
                                             <a href={`https://maps.google.com/?q=${item.proposition.clientAdresse?.adresseComplete}`} target={"_blank"}>
                                                  {item.proposition.clientAdresse?.adresseComplete}
                                             </a>
                                        </div>
                                        <div className={`d-flex align-items-center`}>
                                             <span className="fas fa-users fs-5 w-25px" />
                                             {item.consultantsAttachesToPropositionToday.length > 0 ? (
                                                  item.consultantsAttachesToPropositionToday.map((item_, item_key) => {
                                                       return (
                                                            <Link to={`/business/consultants/${item_.id}/details`} target={"_blank"} className={"me-2"} key={item_key}>
                                                                 {item_.prenomNom}
                                                            </Link>
                                                       )
                                                  })
                                             ) : (
                                                  <span className={"text-muted"}>Aucun consultant</span>
                                             )}
                                        </div>
                                   </div>
                                   <div className="col text-end">{item.proposition.categorie !== "CATEGORIE_FILLE" && <ActionsIcons />}</div>
                              </div>
                         )}
                    </div>
               </>
          </MyLoadingOverlay>
     )
}

const PtfListingListItem = ({ item, index }: { item: IGetPropositionsDataItemResponseModel; index: number }) => {
     const context = usePtfListingContext()

     const isItemSelected: boolean = context.itemInPreview !== null && context.itemInPreview.selectedItem.proposition.id === item.proposition.id
     const isItemLoading: boolean =
          context.itemInPreview !== null && context.itemInPreview.selectedItem.proposition.id === item.proposition.id && context.itemInPreview.isSelectedItemLoading

     // Shows item in preview
     function handleClickItemForPreview(e: React.MouseEvent<any>) {
          const clickedElement = e.target as HTMLElement
          // .not-concerned-by-preview elements are not concerned by the click
          if (!clickedElement.classList.contains("not-concerned-by-preview") && context.itemInPreview?.selectedItem.proposition.id !== item.proposition.id) {
               context.setAreFiltersVisible(false)

               context.setItemInPreview(
                    getSelectedItemPlusPreviousAndNextItem(item, index, context.listingQuery.data!.data, context.listingQuery.data!.page, context.listingQuery.data!.totalPages)
               )
          }
     }

     return <ListItem item={item} handleClickItemForPreview={handleClickItemForPreview} isItemSelected={isItemSelected} isItemLoading={isItemLoading} />
}

export default PtfListingListItem
