import React from "react"
import MyCard from "@common-utils/MyCard"
import MyTooltip from "@common-utils/MyTooltip"
import { Form, Formik } from "formik"
import MyInputField from "@common-utils/fields/MyInputField"
import MySelectGetAllDataFromServer from "@common-utils/fields/MySelectGetAllDataFromServer"
import { CONST_API_UTILS_GET_ACTIVE_PERSONNELS_ENDPOINT, CONST_API_UTILS_GET_ENTITES_ENDPOINT } from "../../../../endpoints"
import { IGetAlertesMissionsExpirationDashboardRequestModel } from "./core/_models"
import { useAuth } from "../../../../AppContext"
import moment from "moment/moment"
import { useDashboardAlertesMissionsExpirationListingContext } from "./DashboardAlertesMissionsExpirationListing"
import MySelectField from "@common-utils/fields/MySelectField"
import { CONST_CONSULTANT_CONTRAT_CDD, CONST_CONSULTANT_CONTRAT_CDI, CONST_CONSULTANT_CONTRAT_CDIC, CONST_CONSULTANT_CONTRAT_ST } from "@common-constants/*"

// Filters
const DashboardAlertesMissionsExpirationListingFilters = () => {
     const context = useDashboardAlertesMissionsExpirationListingContext()
     const { currentUser } = useAuth()

     const initialValues: IGetAlertesMissionsExpirationDashboardRequestModel = {
          ...context.filters,
     }

     function handleSubmit(values: IGetAlertesMissionsExpirationDashboardRequestModel) {
          context.setFilters(prev => ({
               ...prev,
               ...values,
          }))
          context.setAreFiltersVisible(false)
          context.setItemInPreview(null)
     }

     function handleReset() {
          context.filtersFormikRef.current.resetForm() // Reset local form
          context.simpleSearchBarFormikRef.current.resetForm() // Reset search bar form
          context.setItemInPreview(null)
          context.setAreFiltersVisible(false)

          // Reset payload filters
          context.setFilters({
               page: 1,
               pageLength: 20,
               dateAlerte: moment().add(45, "days").format("YYYY-MM-DD"),
          })

          context.filtersFormikRef.current.setSubmitting(true)
     }

     function handleClickHideFilters() {
          // Hide filters
          context.setAreFiltersVisible(prev => !prev)
          // Show preview only if it was opened before showing filters
          context.setItemInPreview(prev => {
               if (prev) {
                    return { ...prev, isPreviewVisible: true }
               }
               return null
          })
     }

     return (
          <MyCard>
               <MyCard.Header
                    isCollapseIconShown={false}
                    rightSideContent={
                         <MyTooltip title={"Cacher les filters"}>
                              <button className={"btn btn-sm btn-outline btn-outline-secondary "} onClick={handleClickHideFilters}>
                                   <i className={"fas fa-eye-slash fs-4 text-dark"} />
                              </button>
                         </MyTooltip>
                    }
               >
                    Filtres
               </MyCard.Header>

               <MyCard.Body>
                    <Formik initialValues={initialValues} onSubmit={handleSubmit} innerRef={context.filtersFormikRef}>
                         {helpers => {
                              return (
                                   <Form noValidate autoComplete="off">
                                        <div className="row mb-5">
                                             <label className="col-lg-4 col-form-label fw-bold fs-6">Mot clé</label>

                                             {/* Simple search */}
                                             <div className="col-lg-8">
                                                  <MyInputField
                                                       name={"keyword"}
                                                       value={helpers.values.keyword}
                                                       onChange={val => {
                                                            helpers.setFieldValue("keyword", val).then()
                                                            context.simpleSearchBarFormikRef.current.setFieldValue("keyword", val).then()
                                                       }}
                                                       isInvalid={!!helpers.errors.keyword}
                                                       placeholder={"Rechercher ..."}
                                                  />
                                                  {helpers.errors.keyword && <div className={"text-danger"}>{helpers.errors.keyword}</div>}
                                             </div>
                                        </div>

                                        <div className="separator separator-dotted mt-4 mb-8"></div>

                                        {/* Responsables */}
                                        <div className="row mb-5">
                                             <label className="col-lg-4 col-form-label fw-bold fs-6">Responsables</label>

                                             <div className="col-lg-8">
                                                  <MySelectGetAllDataFromServer
                                                       name={"responsables"}
                                                       url={CONST_API_UTILS_GET_ACTIVE_PERSONNELS_ENDPOINT}
                                                       value={helpers.values.responsables}
                                                       defaultOptions={
                                                            currentUser?.teamRecrutement && currentUser.teamRecrutement.length > 0
                                                                 ? currentUser.teamRecrutement.map(item => ({
                                                                        value: item.id as number,
                                                                        label: item.prenomNom as string,
                                                                   }))
                                                                 : []
                                                       }
                                                       onChange={val => helpers.setFieldValue("responsables", val)}
                                                       method={"GET"}
                                                       isInvalid={!!helpers.errors.responsables}
                                                       isMulti
                                                  />
                                                  {helpers.errors.responsables && <div className={"text-danger"}>{helpers.errors.responsables}</div>}
                                             </div>
                                        </div>

                                        {/* Entité */}
                                        <div className="row mb-4">
                                             <label className={`col-lg-4 col-form-label fw-bold fs-6`}>Entités</label>

                                             <div className="col-lg-8">
                                                  <MySelectGetAllDataFromServer
                                                       name={"entites"}
                                                       url={CONST_API_UTILS_GET_ENTITES_ENDPOINT}
                                                       value={helpers.values.entites}
                                                       onChange={val => helpers.setFieldValue("entites", val)}
                                                       isInvalid={!!helpers.errors.entites}
                                                       isMulti
                                                  />
                                                  {helpers.errors.entites && <div className={"text-danger"}>{helpers.errors.entites}</div>}
                                             </div>
                                        </div>

                                        {/* Contrat */}
                                        <div className="row mb-4">
                                             <label className={`col-lg-4 col-form-label fw-bold fs-6`}>Contrat</label>

                                             <div className="col-lg-8">
                                                  <MySelectField
                                                       name={"contrat"}
                                                       value={helpers.values.contrat}
                                                       onChange={val => helpers.setFieldValue("contrat", val)}
                                                       isInvalid={!!helpers.errors.contrat}
                                                       options={[
                                                            { label: "CDI C", value: CONST_CONSULTANT_CONTRAT_CDD },
                                                            { label: "CDD", value: CONST_CONSULTANT_CONTRAT_CDI },
                                                            { label: "CDI", value: CONST_CONSULTANT_CONTRAT_CDIC },
                                                            { label: "Sous-traitant", value: CONST_CONSULTANT_CONTRAT_ST },
                                                       ]}
                                                  />
                                                  {helpers.errors.contrat && <div className={"text-danger"}>{helpers.errors.contrat}</div>}
                                             </div>
                                        </div>

                                        <div className={"text-end"}>
                                             <button
                                                  className={"btn btn-sm btn-outline btn-outline-primary d-inline-flex align-items-center me-2"}
                                                  type={"button"}
                                                  disabled={helpers.isSubmitting}
                                                  onClick={() => handleReset()}
                                             >
                                                  <span className={"d-none d-lg-block"}>Réinitialiser</span> <i className={"fas fa-sync ms-lg-1"} />
                                             </button>
                                             <button className={"btn btn-sm btn-primary d-inline-flex align-items-center"} type={"submit"} disabled={helpers.isSubmitting}>
                                                  <span className={"d-none d-lg-block"}>Filtrer </span>
                                                  {!helpers.isSubmitting ? (
                                                       <i className={"fas fa-filter ms-md-1"} />
                                                  ) : (
                                                       <span className="spinner-border spinner-border-sm align-middle ms-lg-1" />
                                                  )}
                                             </button>
                                        </div>
                                   </Form>
                              )
                         }}
                    </Formik>
               </MyCard.Body>
          </MyCard>
     )
}

export default DashboardAlertesMissionsExpirationListingFilters
