import { useQuery } from "react-query"
import { IConsultantDeclarationFactureModel } from "@common-models/*"
import { AxiosError } from "axios"
import { getDeclarationActiveFactures } from "../../core/_requests"
import { useConsultantDeclarationDetailsContext } from "../_ConsultantDeclarationDetails"
import MySimpleSpinner from "@common-utils/MySimpleSpinner"
import MyAlert from "@common-utils/MyAlert"
import Footer from "./Footer"
import React, { useState } from "react"
import MyGoogleDriveFileViewerInModal from "@common-utils/myDocumentViewer/MyGoogleDriveFileViewerInModal"

const ConsultantDeclarationDetails_Factures = () => {
     const context = useConsultantDeclarationDetailsContext()

     const [documentInModal, setActiveDriveDocumentInModal] = useState<{
          driveID: string
          driveMime: string
          modalTitle: string
          isLoading: boolean
     }>()

     const facturesQuery = useQuery<IConsultantDeclarationFactureModel[], AxiosError>("GET_FACTURES", () => getDeclarationActiveFactures(context.declaration.id!).then(r => r.data))

     // Is fetching
     if (facturesQuery.isFetching) {
          return (
               <div className={"text-center p-5"}>
                    <MySimpleSpinner size={"xl"} />{" "}
               </div>
          )
     }

     // Error
     if (!facturesQuery.isFetching && facturesQuery.isError) {
          return (
               <MyAlert type={"danger"} classNames={"mb-4"}>
                    <>
                         <span className={"me-2"}>{facturesQuery.error?.response?.data?.detail}</span>
                         <button className={"btn btn-sm btn-danger"} onClick={() => facturesQuery.refetch()}>
                              Recharger
                         </button>
                    </>
               </MyAlert>
          )
     }

     // Empty data
     if (!facturesQuery.isFetching && !facturesQuery.isError && facturesQuery.data && facturesQuery.data.length === 0) {
          return (
               <div className={"text-center p-4"}>
                    <div className={"text-dark fw-bold fs-4"}>
                         <div>Nombre de jours travaillés: {context.nbJoursTravailles}</div>
                    </div>

                    <div className="separator my-5" />
                    <p style={{ fontWeight: 500 }} className={"mb-4"}>
                         Aucune facture n'a été ajoutée ...
                    </p>
                    <Footer />
               </div>
          )
     }

     return (
          <>
               <div className={"row"}>
                    {facturesQuery.data!.map((item, index) => (
                         <div
                              className={"col-6 col-md-4 d-flex flex-column text-center cursor-pointer"}
                              onClick={() =>
                                   setActiveDriveDocumentInModal({
                                        modalTitle: `Factures n°${index + 1}`,
                                        driveID: item.googleDriveId!,
                                        driveMime: item.googleDriveMimeType!,
                                        isLoading: true,
                                   })
                              }
                         >
                              <div className={"fas fa-file-alt mb-2"} style={{ fontSize: "5rem" }} />
                              Facture n°{index + 1}
                         </div>
                    ))}
               </div>
               <div className="separator separator-dashed my-5"></div>
               <Footer />
               {/* Document in modal */}
               {documentInModal && (
                    <MyGoogleDriveFileViewerInModal
                         googleDriveID={documentInModal.driveID as string}
                         googleDriveMimetype={documentInModal.driveMime as string}
                         modalTitle={<span>{documentInModal.modalTitle}</span>}
                         handleCloseModal={() => setActiveDriveDocumentInModal(undefined)}
                         modalSize={"xl"}
                    />
               )}
          </>
     )
}

export default ConsultantDeclarationDetails_Factures
