import { useFournisseurListingContext } from "../../FournisseurListing"
import { IItemResponseModel } from "../../core/_models"
import GlobalHelper from "@common-helpers/GlobalHelper"
import React from "react"

const FormViewMode = ({ toggleToEditMode }: { toggleToEditMode: () => void }) => {
     const globalContext = useFournisseurListingContext()
     const selectedItemInPreview = globalContext.itemInPreview!.selectedItem
     const itemInPreview = globalContext.listingQuery.data?.data.find(item => item.fournisseur?.id === selectedItemInPreview.fournisseur?.id) as IItemResponseModel

     return (
          <>
               <div className="row mb-7">
                    <label className="col-lg-4 fw-bold text-muted">Nom commercial</label>

                    <div className="col-lg-8">
                         <span className="fw-bolder fs-6 text-dark">
                              {itemInPreview.fournisseur?.raisonSociale ? (
                                   <>{itemInPreview.fournisseur?.raisonSociale}</>
                              ) : (
                                   <span className={"text-warning d-flex align-items-center"}>
                                        À renseigner <i className={"fas fa-exclamation-circle text-warning ms-1"} />
                                   </span>
                              )}
                         </span>
                    </div>
               </div>

               <div className="row mb-7">
                    <label className="col-lg-4 fw-bold text-muted">Forme juridique</label>

                    <div className="col-lg-8">
                         <span className="fw-bolder fs-6 text-dark">
                              {itemInPreview.fournisseur?.formeJuridique ? (
                                   <>{itemInPreview.fournisseur?.formeJuridiqueLabel}</>
                              ) : (
                                   <span className={"text-warning d-flex align-items-center"}>
                                        À renseigner <i className={"fas fa-exclamation-circle text-warning ms-1"} />
                                   </span>
                              )}
                         </span>
                    </div>
               </div>

               <div className="row mb-7">
                    <label className="col-lg-4 fw-bold text-muted">SIRET</label>

                    <div className="col-lg-8">
                         <span className="fw-bolder fs-6 text-dark">
                              {itemInPreview.fournisseur?.siret ? (
                                   itemInPreview.fournisseur?.siret
                              ) : (
                                   <span className={"text-warning d-flex align-items-center"}>
                                        À renseigner <i className={"fas fa-exclamation-circle text-warning ms-1"} />
                                   </span>
                              )}
                         </span>
                    </div>
               </div>

               <div className="row mb-7">
                    <label className="col-lg-4 fw-bold text-muted">Capital Social</label>

                    <div className="col-lg-8">
                         <span className="fw-bolder fs-6 text-dark">
                              {itemInPreview.fournisseur?.capitalSociale ? (
                                   <>{GlobalHelper.separateNumber(itemInPreview.fournisseur?.capitalSociale)} €</>
                              ) : (
                                   <span className={"text-warning d-flex align-items-center"}>
                                        À renseigner <i className={"fas fa-exclamation-circle text-warning ms-1"} />
                                   </span>
                              )}
                         </span>
                    </div>
               </div>

               <div className="row mb-7">
                    <label className="col-lg-4 fw-bold text-muted">Adresse</label>

                    <div className="col-lg-8">
                         <span className="fw-bolder fs-6 text-dark">
                              {itemInPreview.fournisseur?.adresse ? (
                                   itemInPreview.fournisseur?.adresse
                              ) : (
                                   <span className={"text-warning d-flex align-items-center"}>
                                        À renseigner <i className={"fas fa-exclamation-circle text-warning ms-1"} />
                                   </span>
                              )}
                         </span>
                    </div>
               </div>

               <div className="row mb-7">
                    <label className="col-lg-4 fw-bold text-muted">Ville</label>

                    <div className="col-lg-8">
                         <span className="fw-bolder fs-6 text-dark">
                              {itemInPreview.fournisseur?.ville ? (
                                   itemInPreview.fournisseur?.ville
                              ) : (
                                   <span className={"text-warning d-flex align-items-center"}>
                                        À renseigner <i className={"fas fa-exclamation-circle text-warning ms-1"} />
                                   </span>
                              )}
                         </span>
                    </div>
               </div>

               <div className="row mb-7">
                    <label className="col-lg-4 fw-bold text-muted">Code postal</label>

                    <div className="col-lg-8">
                         <span className="fw-bolder fs-6 text-dark">
                              {itemInPreview.fournisseur?.codePostal ? (
                                   itemInPreview.fournisseur?.codePostal
                              ) : (
                                   <span className={"text-warning d-flex align-items-center"}>
                                        À renseigner <i className={"fas fa-exclamation-circle text-warning ms-1"} />
                                   </span>
                              )}
                         </span>
                    </div>
               </div>

               <div className="row mb-7">
                    <label className="col-lg-4 fw-bold text-muted">Pays</label>

                    <div className="col-lg-8">
                         <span className="fw-bolder fs-6 text-dark">
                              {itemInPreview.fournisseur?.pays?.nomFr ? (
                                   itemInPreview.fournisseur?.pays?.nomFr
                              ) : (
                                   <span className={"text-warning d-flex align-items-center"}>
                                        À renseigner <i className={"fas fa-exclamation-circle text-warning ms-1"} />
                                   </span>
                              )}
                         </span>
                    </div>
               </div>

               <div className="row">
                    <label className="col-lg-4 fw-bold text-muted"></label>

                    <div className="col-lg-8">
                         <button type={"button"} className={"btn btn-sm btn-outline btn-outline-warning d-flex align-items-center"} onClick={toggleToEditMode}>
                              EDITER <i className={"fas fa-edit ms-2"} />
                         </button>
                    </div>
               </div>
          </>
     )
}

export default FormViewMode
