import axios from "axios"
import { IGetConsultantsDeclarationsVoyantsResponseModel } from "./_models"
import { CONST_API_BUSINESS_CONSULTANT_DECLARATION_GET_VOYANTS_ENDPOINT } from "../../../../endpoints"

export function getConsultantsDeclarationsVoyants(contrat: "salarie" | "st") {
     return axios.get<IGetConsultantsDeclarationsVoyantsResponseModel[]>(CONST_API_BUSINESS_CONSULTANT_DECLARATION_GET_VOYANTS_ENDPOINT, {
          params: {
               contrat,
          },
     })
}
