import { IFournisseurResponsableModel, IHttpErrorResponseModel } from "@common-models/*"
import React, { useState } from "react"
import { useThemeMode } from "@common-metronic/partials"
import FileAndDriveHelper from "@common-helpers/FileAndDriveHelper"
import { toAbsoluteUrl } from "@common-metronic/helpers"
import * as Yup from "yup"
import {
     CONST_CIVILITE_NON_RENSEIGNEE,
     CONST_CIVILITES_OPTIONS_FOR_SELECT2,
     CONST_HTTP_CUSTOM_CODE_FORM_VALIDATION_ERROR,
     CONST_MIME_IMAGE_JPEG,
     CONST_MIME_IMAGE_JPG,
     CONST_MIME_IMAGE_PNG,
} from "@common-constants/*"
import { Form, Formik, FormikHelpers } from "formik"
import MyAlert from "@common-utils/MyAlert"
import MyLoadingOverlay from "@common-utils/MyLoadingOverlay"
import MyInputField from "@common-utils/fields/MyInputField"
import MyInptuMaskField from "@common-utils/fields/MyInputFieldMask"
import MySelectGetAllDataFromServer from "@common-utils/fields/MySelectGetAllDataFromServer"
import { CONST_API_FOURNISSEUR_RESPONSABLE_SET_DETAILS_ENDPOINT, CONST_API_UTILS_GET_FOURNISSEURS_ENDPOINT, CONST_API_UTILS_GET_INDICATIF_TEL_ENDPOINT } from "../../../endpoints"
import MySimpleSpinner from "@common-utils/MySimpleSpinner"
import MySelectField from "@common-utils/fields/MySelectField"
import axios, { AxiosError } from "axios"
import { toast } from "react-toastify"
import MyCheckBoxField from "@common-utils/fields/MyCheckBoxField"

const FournisseurResponsableEditDetails = ({ fournisseurResponsable, cancelCallback, onSubmitCallback, isFournisseurFieldVisible = true }: IPropsModel) => {
     const { mode } = useThemeMode()

     const [isAvatarLoading, setIsAvatarLoading] = useState<boolean>(true)
     const [avatarPreview, setAvatarPreview] = useState<any>(
          fournisseurResponsable.user?.avatarGoogleDriveId
               ? FileAndDriveHelper.getThumbnailDriveUrl(fournisseurResponsable.user.avatarGoogleDriveId)
               : toAbsoluteUrl(mode == "dark" ? "/media/svg/files/blank-image-dark.svg" : "/media/svg/files/blank-image.svg")
     )

     // Schema
     const schema = Yup.object().shape({
          fournisseur: Yup.number().required().label(`"Fournisseur"`),
          civilite: Yup.string().label(`"Civilité"`).required(),
          nom: Yup.string().required().label(`"Nom"`),
          prenom: Yup.string().required().label(`"Prénom"`),
          mail: Yup.string().email().required().label(`E-mail`),
          tel: Yup.string().required().label(`"Tél."`),
          poste: Yup.string().required().label(`"Poste"`),
          avatar: Yup.mixed()
               .test("fileFormat", "Seuls les formats JPEG ou PNG sont acceptés", (value: any) => {
                    if (!value) return true // If no file is provided, skip the test
                    return [CONST_MIME_IMAGE_PNG, CONST_MIME_IMAGE_JPEG, CONST_MIME_IMAGE_JPG].includes(value.type)
               })
               .test("fileSize", "Le document ne doit pas dépasser le 10MB", (value: any) => {
                    if (!value) return true // If no file is provided, skip the test
                    return value.size <= 10 * 1024 * 1024
               }),
     })

     // Init values
     const initialValues: IFournisseurRespEditInfoByStaffModel = {
          civilite: fournisseurResponsable.user?.civilite,
          nom: fournisseurResponsable.user?.nom,
          prenom: fournisseurResponsable.user?.prenom,
          mail: fournisseurResponsable.user?.username,
          telIndicatif: fournisseurResponsable.user?.telIndicatif?.id,
          tel: fournisseurResponsable.user?.tel,
          poste: fournisseurResponsable.poste,
          accountEnabled: fournisseurResponsable.user?.enabled === true,
          accountAdmin: !!fournisseurResponsable.user?.roles?.includes("ROLE_RESPONSABLE_FOURNISSEUR_ADMIN"),
          fournisseur: fournisseurResponsable.fournisseur?.id,
     }

     // Handle submit
     function handleSubmit(values: IFournisseurRespEditInfoByStaffModel, helpers: FormikHelpers<IFournisseurRespEditInfoByStaffModel>) {
          helpers.setStatus(null)

          const formData = new FormData()

          for (const [key, value] of Object.entries(values)) {
               if (key === "avatar" && value) {
                    formData.append("avatar", value)
               } else {
                    formData.append(`data[${key}]`, value)
               }
          }

          // Request
          axios.post<IFournisseurResponsableModel>(CONST_API_FOURNISSEUR_RESPONSABLE_SET_DETAILS_ENDPOINT(fournisseurResponsable.id), formData)
               .then(r => {
                    toast.success("Vos modifications ont bien été prises en compte.")
                    onSubmitCallback(r.data)
               })
               .catch((e: AxiosError) => {
                    const error: IHttpErrorResponseModel = e.response?.data

                    // Set form errors
                    if (error?.code === CONST_HTTP_CUSTOM_CODE_FORM_VALIDATION_ERROR && error?.errors) {
                         for (const key in error.errors) helpers.setFieldError(key, error.errors[key])
                    }

                    // Set form global status and notify user using a toast
                    helpers.setStatus(error?.detail)
                    toast.error(error?.detail, { autoClose: false })

                    // Stop submit loader
                    helpers.setSubmitting(false)
               })
               .finally(() => helpers.setSubmitting(false))
     }

     return (
          <>
               <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={schema}>
                    {helpers => {
                         return (
                              <Form noValidate autoComplete="off">
                                   {helpers.status && (
                                        <MyAlert type={"danger"} classNames={"mb-4"}>
                                             {helpers.status}
                                        </MyAlert>
                                   )}

                                   {/* Field Avatar */}
                                   <div className="row mb-4">
                                        <label className="col-lg-4 col-form-label fw-bold fs-6">Avatar</label>
                                        <div className="col-lg-8">
                                             <div className="image-input image-input-outline" data-kt-image-input="true" style={{ backgroundImage: `url(${avatarPreview})` }}>
                                                  <MyLoadingOverlay isActive={isAvatarLoading}>
                                                       <img
                                                            className="image-input-wrapper w-125px h-125px"
                                                            src={avatarPreview}
                                                            onLoad={() => setIsAvatarLoading(false)}
                                                            alt={"avatar"}
                                                       ></img>
                                                  </MyLoadingOverlay>

                                                  <label
                                                       className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                                                       data-kt-image-input-action="change"
                                                       data-bs-original-title="Change Avatar"
                                                  >
                                                       <i className="bi bi-pencil-fill fs-7"></i>
                                                       <input
                                                            type="file"
                                                            name="avatar"
                                                            accept=".png, .jpg, .jpeg"
                                                            onChange={e => {
                                                                 const file = e.target.files![0]
                                                                 helpers.setFieldValue("avatar", file)
                                                                 const reader = new FileReader()
                                                                 reader.onloadend = () => {
                                                                      // Set the image preview URL
                                                                      setAvatarPreview(reader.result as string)
                                                                 }
                                                                 reader.readAsDataURL(file)
                                                            }}
                                                       />
                                                  </label>
                                             </div>
                                        </div>
                                   </div>
                                   {helpers.errors.avatar && <div className={"text-danger"}>{helpers.errors.avatar}</div>}

                                   {/* Account enabled */}
                                   <div className="row mb-5">
                                        <label className="col-lg-4 col-form-label fw-bold fs-6 required">Compte activé</label>

                                        <div className="col-lg-8 d-flex align-items-center">
                                             <MyCheckBoxField
                                                  name={"accountEnabled"}
                                                  value={helpers.values.accountEnabled}
                                                  isInvalid={!!helpers.errors.accountEnabled}
                                                  onChange={val => helpers.setFieldValue("accountEnabled", val)}
                                             />
                                             {helpers.errors.accountEnabled && <div className={"text-danger"}>{helpers.errors.accountEnabled}</div>}
                                        </div>
                                   </div>

                                   {/* Account admin */}
                                   <div className="row mb-5">
                                        <label className="col-lg-4 col-form-label fw-bold fs-6 required">Compte admin.</label>

                                        <div className="col-lg-8 d-flex align-items-center">
                                             <MyCheckBoxField
                                                  name={"accountAdmin"}
                                                  value={helpers.values.accountAdmin}
                                                  isInvalid={!!helpers.errors.accountAdmin}
                                                  onChange={val => helpers.setFieldValue("accountAdmin", val)}
                                             />
                                             {helpers.errors.accountAdmin && <div className={"text-danger"}>{helpers.errors.accountAdmin}</div>}
                                        </div>
                                   </div>

                                   {/* Field Civilité */}
                                   <div className="row mb-5">
                                        <label className="col-lg-4 col-form-label fw-bold fs-6 required">Civilité</label>

                                        <div className="col-lg-8">
                                             <MySelectField
                                                  name={"civilite"}
                                                  options={CONST_CIVILITES_OPTIONS_FOR_SELECT2.filter(c => c.value !== CONST_CIVILITE_NON_RENSEIGNEE)}
                                                  value={helpers.values.civilite}
                                                  isInvalid={!!helpers.errors.civilite}
                                                  onChange={val => helpers.setFieldValue("civilite", val)}
                                             />
                                             {helpers.errors.civilite && <div className={"text-danger"}>{helpers.errors.civilite}</div>}
                                        </div>
                                   </div>

                                   {/* Nom */}
                                   <div className="row mb-5">
                                        <label className="col-lg-4 col-form-label fw-bold fs-6 required">Nom</label>

                                        <div className="col-lg-8">
                                             <MyInputField
                                                  name={"nom"}
                                                  value={helpers.values.nom}
                                                  onChange={val => helpers.setFieldValue("nom", val)}
                                                  placeholder={"Nom"}
                                                  isInvalid={!!helpers.errors.nom}
                                             />
                                             <div>{helpers.errors.nom && <div className={"text-danger"}>{helpers.errors.nom}</div>}</div>
                                        </div>
                                   </div>

                                   {/* Prénom */}
                                   <div className="row mb-5">
                                        <label className="col-lg-4 col-form-label fw-bold fs-6 required">Prénom</label>

                                        <div className="col-lg-8">
                                             <MyInputField
                                                  name={"prenom"}
                                                  value={helpers.values.prenom}
                                                  onChange={val => helpers.setFieldValue("prenom", val)}
                                                  placeholder={"Prénom"}
                                                  isInvalid={!!helpers.errors.prenom}
                                             />
                                             <div>{helpers.errors.prenom && <div className={"text-danger"}>{helpers.errors.prenom}</div>}</div>
                                        </div>
                                   </div>

                                   {/* Mail */}
                                   <div className="row mb-5">
                                        <label className="col-lg-4 col-form-label fw-bold fs-6 required">E-mail</label>

                                        <div className="col-lg-8">
                                             <MyInputField
                                                  name={"mail"}
                                                  value={helpers.values.mail}
                                                  onChange={val => helpers.setFieldValue("mail", val)}
                                                  placeholder={"E-mail"}
                                                  isInvalid={!!helpers.errors.mail}
                                             />
                                             <div>{helpers.errors.mail && <div className={"text-danger"}>{helpers.errors.mail}</div>}</div>
                                        </div>
                                   </div>

                                   {/* Indicatif */}
                                   <div className="row mb-5">
                                        <label className="col-lg-4 col-form-label fw-bold fs-6 required">Indicatif Tél.</label>

                                        <div className="col-lg-8">
                                             <MySelectGetAllDataFromServer
                                                  name={"pays"}
                                                  value={helpers.values.telIndicatif}
                                                  url={CONST_API_UTILS_GET_INDICATIF_TEL_ENDPOINT}
                                                  onChange={val => helpers.setFieldValue("telIndicatif", val)}
                                                  defaultOptions={
                                                       fournisseurResponsable?.user?.telIndicatif
                                                            ? [
                                                                   {
                                                                        value: fournisseurResponsable?.user?.telIndicatif.id as number,
                                                                        label: fournisseurResponsable?.user?.telIndicatif.phoneCodeWithPlusPrefixAndCountry as string,
                                                                   },
                                                              ]
                                                            : undefined
                                                  }
                                                  placeholder={"Indicatif tél."}
                                                  isInvalid={!!helpers.errors.telIndicatif}
                                             />
                                             <div className={"mb-2"}>{helpers.errors.telIndicatif && <div className={"text-danger"}>{helpers.errors.telIndicatif}</div>}</div>
                                        </div>
                                   </div>

                                   {/* Tél */}
                                   <div className="row mb-5">
                                        <label className="col-lg-4 col-form-label fw-bold fs-6 required">Tél.</label>

                                        <div className="col-lg-8">
                                             <MyInptuMaskField
                                                  name={"tel"}
                                                  value={helpers.values.tel}
                                                  isInvalid={!!helpers.errors.tel}
                                                  mask={"99 9999999999999999999999999"}
                                                  placeholder={"Téléphone"}
                                                  inputMode={"numeric"}
                                                  onChange={e => helpers.setFieldValue("tel", e.target.value)}
                                             />
                                             <div>{helpers.errors.tel && <div className={"text-danger"}>{helpers.errors.tel}</div>}</div>
                                        </div>
                                   </div>

                                   {/* Poste */}
                                   <div className="row mb-5">
                                        <label className="col-lg-4 col-form-label fw-bold fs-6 required">Poste</label>

                                        <div className="col-lg-8">
                                             <MyInputField
                                                  name={"poste"}
                                                  value={helpers.values.poste}
                                                  onChange={val => helpers.setFieldValue("poste", val)}
                                                  placeholder={"Poste"}
                                                  isInvalid={!!helpers.errors.poste}
                                             />
                                             <div>{helpers.errors.poste && <div className={"text-danger"}>{helpers.errors.poste}</div>}</div>
                                        </div>
                                   </div>

                                   {/* Fournisseur */}
                                   {isFournisseurFieldVisible && (
                                        <div className="row mb-5">
                                             <label className="col-lg-4 col-form-label fw-bold fs-6 required">Fournisseur</label>

                                             <div className="col-lg-8">
                                                  <MySelectGetAllDataFromServer
                                                       name={"fournisseur"}
                                                       value={helpers.values.fournisseur}
                                                       url={CONST_API_UTILS_GET_FOURNISSEURS_ENDPOINT}
                                                       onChange={val => helpers.setFieldValue("fournisseur", val)}
                                                       defaultOptions={
                                                            fournisseurResponsable?.fournisseur
                                                                 ? [
                                                                        {
                                                                             value: fournisseurResponsable?.fournisseur.id as number,
                                                                             label: fournisseurResponsable?.fournisseur.raisonSociale as string,
                                                                        },
                                                                   ]
                                                                 : undefined
                                                       }
                                                       placeholder={"Fournisseur"}
                                                       isInvalid={!!helpers.errors.fournisseur}
                                                  />
                                                  <div className={"mb-2"}>{helpers.errors.fournisseur && <div className={"text-danger"}>{helpers.errors.fournisseur}</div>}</div>
                                             </div>
                                        </div>
                                   )}

                                   <div className="separator bg-primary my-5" />

                                   {/* Submit button */}
                                   <div className={"d-flex justify-content-end gap-5"}>
                                        {cancelCallback && (
                                             <button
                                                  className={"btn btn-sm btn-outline btn-active-secondary d-inline-flex align-items-center"}
                                                  type={"button"}
                                                  onClick={cancelCallback}
                                                  disabled={helpers.isSubmitting}
                                             >
                                                  Annuler
                                             </button>
                                        )}
                                        <button className={"btn btn-sm btn-primary d-inline-flex align-items-center"} type={"submit"} disabled={helpers.isSubmitting}>
                                             {!helpers.isSubmitting ? <>Appliquer les modifications</> : <MySimpleSpinner size={"sm"} />}
                                        </button>
                                   </div>
                              </Form>
                         )
                    }}
               </Formik>
          </>
     )
}

interface IPropsModel {
     fournisseurResponsable: IFournisseurResponsableModel
     cancelCallback?: () => void
     onSubmitCallback: (resp: IFournisseurResponsableModel) => void
     isFournisseurFieldVisible?: boolean
}

interface IFournisseurRespEditInfoByStaffModel {
     fournisseur?: number
     civilite?: string
     nom?: string
     prenom?: string
     mail?: string
     telIndicatif?: number
     tel?: string
     poste?: string
     accountEnabled: boolean
     accountAdmin: boolean
     avatar?: File
}

export default FournisseurResponsableEditDetails
