import React, { createContext, useContext, useEffect, useRef, useState } from "react"
import { IIsBulkSelectionEnabledPropsModel } from "../../../../models"
import { useQuery } from "react-query"
import { AxiosError } from "axios"
import { IHttpErrorResponseModel } from "@common-models/*"
import { CONST_HTTP_CUSTOM_CODE_FORM_VALIDATION_ERROR } from "@common-constants/*"
import { toast } from "react-toastify"
import { IItemInPreviewModel, IListingContextPropsModel, IRequestModel, IResponseModel } from "./core/_models"
import SearchBar from "./SearchBar"
import Preview from "./Preview"
import Filters from "./Filters"
import DashboardAlertesPeriodesEssaiExpirationListingList from "./List"
import { getAlertesConsultantsAvecDocsEmbaucheNonEnvoyesRequest } from "./core/requests"

export const REACT_QUERY_KEY_GET_LISTING = "REACT_QUERY_KEY_GET_LISTING"
const DashboardAlertesPeriodesEssaiExpirationListingContext = createContext<IListingContextPropsModel>({} as IListingContextPropsModel)
export const useDashboardAlertesPeriodesEssaiExpirationListingContext = () => useContext(DashboardAlertesPeriodesEssaiExpirationListingContext)
const DashboardAlertesConsultantAvecDocsEmbaucheNonEnvoyesListing = () => {
     // Referencing filters form, simple search bar form & preview container
     const filtersFormikRef = useRef<any>(null)
     const simpleSearchBarFormikRef = useRef<any>(null)
     const previewContainerRef = useRef<any>(null)

     // Filters, item in preview & bulk selection
     const [filters, setFilters] = useState<IRequestModel>({
          page: 1,
          pageLength: 20,
     })
     const [areFiltersVisible, setAreFiltersVisible] = useState<boolean>(false)
     const [itemInPreview, setItemInPreview] = useState<IItemInPreviewModel | null>(null)
     const [isBulkSelectionEnabled, setIsBulkSelectionEnabled] = useState<IIsBulkSelectionEnabledPropsModel>({
          enabled: false,
          checkedElementsInPage: [],
          areAllElementsInAllPagesChecked: false,
     })

     // Main listing query
     const listingQuery = useQuery<IResponseModel, AxiosError>(REACT_QUERY_KEY_GET_LISTING, async () => {
          return getAlertesConsultantsAvecDocsEmbaucheNonEnvoyesRequest(filters)
               .then(r => {
                    // Stop loaders (filters + simple search bar)
                    filtersFormikRef.current.setSubmitting(false)
                    simpleSearchBarFormikRef.current.setSubmitting(false)

                    return r.data
               })
               .catch((e: AxiosError) => {
                    const error: IHttpErrorResponseModel = e.response?.data

                    // Set form errors in the filters form
                    if (error?.code === CONST_HTTP_CUSTOM_CODE_FORM_VALIDATION_ERROR && error?.errors) {
                         for (const key in error.errors) filtersFormikRef.current.setFieldError(key, error.errors[key])
                    }

                    // Stop loaders (filters + simple search bar)
                    filtersFormikRef.current.setSubmitting(false)
                    simpleSearchBarFormikRef.current.setSubmitting(false)

                    // Toast error
                    toast.error(error?.detail, { autoClose: false })

                    throw e
               })
     })

     useEffect(() => {
          listingQuery.refetch().then()
     }, [filters])

     return (
          <>
               <DashboardAlertesPeriodesEssaiExpirationListingContext.Provider
                    value={{
                         areFiltersVisible,
                         setAreFiltersVisible,
                         filters,
                         setFilters,
                         itemInPreview,
                         setItemInPreview,
                         REACT_QUERY_KEY_GET_LISTING,
                         listingQuery,
                         filtersFormikRef,
                         previewContainerRef,
                         simpleSearchBarFormikRef,
                         isBulkSelectionEnabled,
                         setIsBulkSelectionEnabled,
                    }}
               >
                    {/* Adapt view to display */}
                    {window.innerWidth >= 1200 ? <ViewXlOrHigher /> : <ViewLgOrLess />}
               </DashboardAlertesPeriodesEssaiExpirationListingContext.Provider>
          </>
     )
}

// View display Lg or less
const ViewLgOrLess = () => {
     const context = useDashboardAlertesPeriodesEssaiExpirationListingContext()

     return (
          <>
               <div className={`${context.areFiltersVisible ? "d-none" : "mb-2"}`}>
                    <SearchBar />
               </div>

               {context.itemInPreview && context.itemInPreview.isPreviewVisible && (
                    <div className={"mb-2"}>
                         <Preview />
                    </div>
               )}

               <div className={`${!context.areFiltersVisible ? "d-none" : "mb-2"}`}>
                    <Filters />
               </div>

               <DashboardAlertesPeriodesEssaiExpirationListingList />
          </>
     )
}

// View display Xl or higher
const ViewXlOrHigher = () => {
     const context = useDashboardAlertesPeriodesEssaiExpirationListingContext()
     return (
          <>
               <div className={"row"}>
                    {/* Filters become visible when the filter icon is clicked */}
                    <div className={`col-5 ${!context.areFiltersVisible ? "d-none" : ""}`}>
                         <Filters />
                    </div>
                    {/*
                     * List & preview will share the remaining width
                     * Preview will not be visible at the beginning
                     */}
                    <div className={`col ${context.itemInPreview && context.itemInPreview.isPreviewVisible ? "" : "col-7"}`}>
                         <div className={"row"}>
                              <div className={context.itemInPreview && context.itemInPreview.isPreviewVisible ? "col-5" : ""}>
                                   {/* Simple bar search will be shown only if filters are not displayed */}
                                   <div className={`mb-2 ${context.areFiltersVisible ? "d-none" : ""}`}>
                                        <SearchBar />
                                   </div>

                                   <DashboardAlertesPeriodesEssaiExpirationListingList />
                              </div>
                              {context.itemInPreview && context.itemInPreview.isPreviewVisible && (
                                   <div className="col-7">
                                        <Preview />
                                   </div>
                              )}
                         </div>
                    </div>
               </div>
          </>
     )
}

export default DashboardAlertesConsultantAvecDocsEmbaucheNonEnvoyesListing
