import { Form, Formik, FormikHelpers } from "formik"
import { toast } from "react-toastify"
import { AxiosError } from "axios"
import { IFournisseurResponsableModel, IHttpErrorResponseModel } from "@common-models/*"
import { CONST_CIVILITE_NON_RENSEIGNEE, CONST_CIVILITES_OPTIONS_FOR_SELECT2, CONST_HTTP_CUSTOM_CODE_FORM_VALIDATION_ERROR } from "@common-constants/*"
import MyAlert from "@common-utils/MyAlert"
import MySimpleSpinner from "@common-utils/MySimpleSpinner"
import React, { useState } from "react"
import MyInputField from "@common-utils/fields/MyInputField"
import * as Yup from "yup"
import { IAddResponsableFournisseurRequestModel } from "./core/_models"
import { addResponsableFournisseurRequest } from "./core/_requests"
import MySelectGetAllDataFromServer from "@common-utils/fields/MySelectGetAllDataFromServer"
import { CONST_API_UTILS_GET_FOURNISSEURS_ENDPOINT } from "../../endpoints"
import MyInptuMaskField from "@common-utils/fields/MyInputFieldMask"
import MySelectField from "@common-utils/fields/MySelectField"
import MyCheckBoxField from "@common-utils/fields/MyCheckBoxField"
import { useAuth } from "../../AppContext"

const AddResponsableFournisseurForm = ({
     onSubmitCallback = () => {},
     onCancelCallback,
     defaultFournisseurFieldValue,
     defaultFournisseurFieldLabel,
     isFournisseurFieldReadOnly = false,
}: IPropsModel) => {
     const { currentUser } = useAuth()

     const [selectedFournisseur, setSelectedFournisseur] = useState<{
          label: string
          value: any
     } | null>(
          defaultFournisseurFieldValue && defaultFournisseurFieldLabel
               ? {
                      label: defaultFournisseurFieldLabel,
                      value: defaultFournisseurFieldValue,
                 }
               : null
     )

     // Schema
     const schema = Yup.object().shape({
          fournisseur: Yup.number().required().label(`"Fournisseur"`),
          civilite: Yup.string().label(`"Civilité"`).required(),
          nom: Yup.string().required().label(`"Nom"`),
          prenom: Yup.string().required().label(`"Prénom"`),
          mail: Yup.string().email().required().label(`E-mail`),
          tel: Yup.string().required().label(`"Tél."`),
          poste: Yup.string().required().label(`"Poste"`),
     })

     // Init values
     const initialValues: IAddResponsableFournisseurRequestModel = {
          fournisseur: defaultFournisseurFieldValue,
          isAccountEnable: true,
          isAccountAdmin: false,
     }

     // Handle submit
     function handleSubmit(values: IAddResponsableFournisseurRequestModel, helpers: FormikHelpers<IAddResponsableFournisseurRequestModel>) {
          helpers.setStatus(null)

          addResponsableFournisseurRequest(values)
               .then(r => {
                    onSubmitCallback(r.data)
               })
               .catch((e: AxiosError) => {
                    const error: IHttpErrorResponseModel = e.response?.data

                    // Set form errors
                    if (error?.code === CONST_HTTP_CUSTOM_CODE_FORM_VALIDATION_ERROR && error?.errors) {
                         for (const key in error.errors) helpers.setFieldError(key, error.errors[key])
                    }

                    // Set form global status and notify user using a toast
                    helpers.setStatus(error?.detail)
                    toast.error(error?.detail, { autoClose: false })

                    // Stop submit loader
                    helpers.setSubmitting(false)
               })
     }

     return (
          <>
               <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={schema}>
                    {helpers => {
                         return (
                              <Form noValidate autoComplete="off">
                                   {helpers.status && (
                                        <MyAlert type={"danger"} classNames={"mb-4"}>
                                             {helpers.status}
                                        </MyAlert>
                                   )}

                                   {/* fournisseur */}
                                   <div className="row">
                                        <label className="col-lg-4 col-form-label fw-bold fs-6 required">Fournisseur</label>

                                        <div className="col-lg-8">
                                             {!isFournisseurFieldReadOnly ? (
                                                  <>
                                                       <MySelectGetAllDataFromServer
                                                            name={"fournisseur"}
                                                            url={CONST_API_UTILS_GET_FOURNISSEURS_ENDPOINT}
                                                            value={helpers.values.fournisseur}
                                                            onChange={(val, option) => {
                                                                 helpers.setFieldValue("fournisseur", val)
                                                                 setSelectedFournisseur({
                                                                      label: option.label,
                                                                      value: option.value,
                                                                 })
                                                            }}
                                                            defaultOptions={
                                                                 defaultFournisseurFieldLabel && defaultFournisseurFieldValue
                                                                      ? [
                                                                             {
                                                                                  value: defaultFournisseurFieldValue,
                                                                                  label: defaultFournisseurFieldLabel,
                                                                             },
                                                                        ]
                                                                      : []
                                                            }
                                                            isInvalid={!!helpers.errors.fournisseur}
                                                       />
                                                       <div>{helpers.errors.fournisseur && <div className={"text-danger"}>{helpers.errors.fournisseur}</div>}</div>
                                                  </>
                                             ) : (
                                                  <input className={"form-control form-control-solid cursor-default border"} value={selectedFournisseur?.label} readOnly />
                                             )}
                                        </div>
                                   </div>

                                   <div className="separator my-5" />

                                   {/* Field Civilité */}
                                   <div className="row mb-5">
                                        <label className="col-lg-4 col-form-label fw-bold fs-6 required">Civilité</label>

                                        <div className="col-lg-8">
                                             <MySelectField
                                                  name={"civilite"}
                                                  options={CONST_CIVILITES_OPTIONS_FOR_SELECT2.filter(c => c.value !== CONST_CIVILITE_NON_RENSEIGNEE)}
                                                  value={helpers.values.civilite}
                                                  isInvalid={!!helpers.errors.civilite}
                                                  onChange={val => helpers.setFieldValue("civilite", val)}
                                             />
                                             {helpers.errors.civilite && <div className={"text-danger"}>{helpers.errors.civilite}</div>}
                                        </div>
                                   </div>

                                   {/* Nom */}
                                   <div className="row mb-5">
                                        <label className="col-lg-4 col-form-label fw-bold fs-6 required">Nom</label>

                                        <div className="col-lg-8">
                                             <MyInputField
                                                  name={"nom"}
                                                  value={helpers.values.nom}
                                                  onChange={val => helpers.setFieldValue("nom", val)}
                                                  placeholder={"Nom"}
                                                  isInvalid={!!helpers.errors.nom}
                                             />
                                             <div>{helpers.errors.nom && <div className={"text-danger"}>{helpers.errors.nom}</div>}</div>
                                        </div>
                                   </div>

                                   {/* Prénom */}
                                   <div className="row mb-5">
                                        <label className="col-lg-4 col-form-label fw-bold fs-6 required">Prénom</label>

                                        <div className="col-lg-8">
                                             <MyInputField
                                                  name={"prenom"}
                                                  value={helpers.values.prenom}
                                                  onChange={val => helpers.setFieldValue("prenom", val)}
                                                  placeholder={"Prénom"}
                                                  isInvalid={!!helpers.errors.prenom}
                                             />
                                             <div>{helpers.errors.prenom && <div className={"text-danger"}>{helpers.errors.prenom}</div>}</div>
                                        </div>
                                   </div>

                                   {/* Mail */}
                                   <div className="row mb-5">
                                        <label className="col-lg-4 col-form-label fw-bold fs-6 required">E-mail</label>

                                        <div className="col-lg-8">
                                             <MyInputField
                                                  name={"mail"}
                                                  value={helpers.values.mail}
                                                  onChange={val => helpers.setFieldValue("mail", val)}
                                                  placeholder={"E-mail"}
                                                  isInvalid={!!helpers.errors.mail}
                                             />
                                             <div>{helpers.errors.mail && <div className={"text-danger"}>{helpers.errors.mail}</div>}</div>
                                        </div>
                                   </div>

                                   {/* Tél */}
                                   <div className="row mb-5">
                                        <label className="col-lg-4 col-form-label fw-bold fs-6 required">Tél.</label>

                                        <div className="col-lg-8">
                                             <MyInptuMaskField
                                                  name={"tel"}
                                                  value={helpers.values.tel}
                                                  isInvalid={!!helpers.errors.tel}
                                                  mask={"99 9999999999999999999999999"}
                                                  placeholder={"Téléphone"}
                                                  inputMode={"numeric"}
                                                  onChange={e => helpers.setFieldValue("tel", e.target.value)}
                                             />
                                             <div>{helpers.errors.tel && <div className={"text-danger"}>{helpers.errors.tel}</div>}</div>
                                        </div>
                                   </div>

                                   {/* Poste */}
                                   <div className="row mb-5">
                                        <label className="col-lg-4 col-form-label fw-bold fs-6 required">Poste</label>

                                        <div className="col-lg-8">
                                             <MyInputField
                                                  name={"poste"}
                                                  value={helpers.values.poste}
                                                  onChange={val => helpers.setFieldValue("poste", val)}
                                                  placeholder={"Poste"}
                                                  isInvalid={!!helpers.errors.poste}
                                             />
                                             <div>{helpers.errors.poste && <div className={"text-danger"}>{helpers.errors.poste}</div>}</div>
                                        </div>
                                   </div>

                                   {["ROLE_GROUP_APP_ADMIN", "ROLE_GROUP_DIRECTION"].includes(currentUser?.roleGroup as string) && (
                                        <>
                                             {/* Account enabled */}
                                             <div className="row mb-5">
                                                  <label className="col-lg-4 col-form-label fw-bold fs-6 required">Compte activé</label>

                                                  <div className="col-lg-8 d-flex align-items-center">
                                                       <MyCheckBoxField
                                                            name={"isAccountEnable"}
                                                            value={helpers.values.isAccountEnable}
                                                            isInvalid={!!helpers.errors.isAccountEnable}
                                                            onChange={val => helpers.setFieldValue("isAccountEnable", val)}
                                                       />
                                                       {helpers.errors.isAccountEnable && <div className={"text-danger"}>{helpers.errors.isAccountEnable}</div>}
                                                  </div>
                                             </div>

                                             {/* Is admin */}
                                             <div className="row mb-5">
                                                  <label className="col-lg-4 col-form-label fw-bold fs-6 required">Compte admin.</label>

                                                  <div className="col-lg-8 d-flex align-items-center">
                                                       <MyCheckBoxField
                                                            name={"isAccountAdmin"}
                                                            value={helpers.values.isAccountAdmin}
                                                            isInvalid={!!helpers.errors.isAccountAdmin}
                                                            onChange={val => helpers.setFieldValue("isAccountAdmin", val)}
                                                       />
                                                       {helpers.errors.isAccountAdmin && <div className={"text-danger"}>{helpers.errors.isAccountAdmin}</div>}
                                                  </div>
                                             </div>
                                        </>
                                   )}

                                   {/* Submit button */}
                                   <div className={"d-flex justify-content-end gap-2"}>
                                        {onCancelCallback && (
                                             <button
                                                  className={"btn btn-sm btn-outline btn-active-secondary d-inline-flex align-items-center"}
                                                  type={"button"}
                                                  onClick={onCancelCallback}
                                                  disabled={helpers.isSubmitting}
                                             >
                                                  Annuler
                                             </button>
                                        )}
                                        <button className={"btn btn-sm btn-primary d-inline-flex align-items-center"} type={"submit"} disabled={helpers.isSubmitting}>
                                             {!helpers.isSubmitting ? <>Ajouter</> : <MySimpleSpinner size={"sm"} />}
                                        </button>
                                   </div>
                              </Form>
                         )
                    }}
               </Formik>
          </>
     )
}

interface IPropsModel {
     onSubmitCallback?: (val: IFournisseurResponsableModel) => void
     onCancelCallback?: () => void
     defaultFournisseurFieldValue?: number
     defaultFournisseurFieldLabel?: string
     isFournisseurFieldReadOnly?: boolean
}

export default AddResponsableFournisseurForm
