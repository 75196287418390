import { PageLink, PageTitle } from "@common-metronic/layout/core"
import { Navigate, Outlet, Route, Routes } from "react-router-dom"
import CandidatsRecherche from "./candidats/recherche/CandidatsRecherche"
import AnnonceCreate from "./annonces/create/AnnonceCreate"
import LogsSearchCandidate from "./candidats/recherche/logsAndSavedFiltersSearchCandidate/logs/LogsSearchCandidate"
import SavedFiltersSearchCandidate from "./candidats/recherche/logsAndSavedFiltersSearchCandidate/savedFilters/SavedFiltersSearchCandidate"
import CandidatsRechercheResults from "./candidats/recherche/results/CandidatsRechercheResults"
import DemandeEmbaucheDetails from "./demandesEmbauche/details/DemandeEmbaucheDetails"
import DemandesEmbaucheListing from "./demandesEmbauche/listing/DemandesEmbaucheListing"
import AnnoncesListing from "./annonces/listing/AnnoncesListing"
import AnnonceActivation from "./annonces/create/AnnonceActivation"
import ViviersListing from "./viviers/listing/ViviersListing"
import VivierCreate from "./viviers/create/VivierCreate"
import CandidatCreate from "./candidats/create/CandidatCreate"
import DemandeEmbaucheStart from "./demandesEmbauche/start/DemandeEmbaucheStart"

const RecrutementRouting = () => {
     return (
          <Routes>
               <Route
                    element={
                         <>
                              {/*<div>header</div>*/}
                              <Outlet />
                         </>
                    }
               >
                    <Route
                         path="/annonces"
                         element={
                              <>
                                   {/*<div>header</div>*/}
                                   <Outlet />
                              </>
                         }
                    >
                         <Route
                              path="listing"
                              element={
                                   <>
                                        <PageTitle breadcrumbs={annoncesBreadCrumbs}>Listing</PageTitle>
                                        <AnnoncesListing />
                                   </>
                              }
                         />
                         <Route
                              path="create"
                              element={
                                   <>
                                        <PageTitle breadcrumbs={annoncesBreadCrumbs}>Création</PageTitle>
                                        <AnnonceCreate />
                                   </>
                              }
                         />
                         <Route
                              path="activation"
                              element={
                                   <>
                                        <PageTitle breadcrumbs={annoncesBreadCrumbs}>Activation</PageTitle>
                                        <AnnonceActivation />
                                   </>
                              }
                         />
                    </Route>

                    <Route
                         path="/candidats"
                         element={
                              <>
                                   {/*<div>header</div>*/}
                                   <Outlet />
                              </>
                         }
                    >
                         <Route
                              path="create"
                              element={
                                   <>
                                        <PageTitle breadcrumbs={candidatsBreadCrumbs}>Création</PageTitle>
                                        <CandidatCreate />
                                   </>
                              }
                         />
                         <Route
                              path="search"
                              element={
                                   <>
                                        {/*<div>header</div>*/}
                                        <Outlet />
                                   </>
                              }
                         >
                              <Route
                                   path=""
                                   element={
                                        <>
                                             <PageTitle breadcrumbs={candidatsBreadCrumbs}>Recherche</PageTitle>
                                             <CandidatsRecherche />
                                        </>
                                   }
                              />
                              <Route
                                   path="logs"
                                   element={
                                        <>
                                             <PageTitle breadcrumbs={candidatsRechercheBreadCrumbs}>Historique</PageTitle>
                                             <LogsSearchCandidate />
                                        </>
                                   }
                              />
                              <Route
                                   path="saved-filters"
                                   element={
                                        <>
                                             <PageTitle breadcrumbs={candidatsRechercheBreadCrumbs}>Filtres sauvegardés</PageTitle>
                                             <SavedFiltersSearchCandidate />
                                        </>
                                   }
                              />
                              <Route
                                   path="results"
                                   element={
                                        <>
                                             <PageTitle breadcrumbs={candidatsRechercheBreadCrumbs}>Résultats</PageTitle>
                                             <CandidatsRechercheResults />
                                        </>
                                   }
                              />
                         </Route>
                    </Route>

                    <Route
                         path="/demandes-embauche"
                         element={
                              <>
                                   {/*<div>header</div>*/}
                                   <Outlet />
                              </>
                         }
                    >
                         <Route
                              path="listing"
                              element={
                                   <>
                                        <PageTitle breadcrumbs={demandesEmbaucheBreadCrumbs}>Listing</PageTitle>
                                        <DemandesEmbaucheListing />
                                   </>
                              }
                         />
                         <Route
                              path="start"
                              element={
                                   <>
                                        <PageTitle breadcrumbs={demandesEmbaucheBreadCrumbs}>Listing</PageTitle>
                                        <DemandeEmbaucheStart />
                                   </>
                              }
                         />
                         <Route
                              path=":consultant_id/details"
                              element={
                                   <>
                                        <PageTitle breadcrumbs={demandesEmbaucheBreadCrumbs}>Détails</PageTitle>
                                        <DemandeEmbaucheDetails />
                                   </>
                              }
                         />
                    </Route>

                    <Route
                         path="/viviers"
                         element={
                              <>
                                   {/*<div>header</div>*/}
                                   <Outlet />
                              </>
                         }
                    >
                         <Route
                              path="listing"
                              element={
                                   <>
                                        <PageTitle breadcrumbs={viviersBreadCrumbs}>Listing</PageTitle>
                                        <ViviersListing />
                                   </>
                              }
                         />
                         <Route
                              path="create"
                              element={
                                   <>
                                        <PageTitle breadcrumbs={viviersBreadCrumbs}>Création</PageTitle>
                                        <VivierCreate />
                                   </>
                              }
                         />
                    </Route>

                    {/*Default route*/}
                    <Route index element={<Navigate to="search" />} />
               </Route>
          </Routes>
     )
}

const candidatsBreadCrumbs: Array<PageLink> = [
     {
          title: "Candidats",
          path: "/recrutement/candidats/search",
     },
]

const candidatsRechercheBreadCrumbs: Array<PageLink> = [
     ...candidatsBreadCrumbs,
     {
          title: "Recherche",
          path: "/recrutement/candidats/search",
     },
]

const annoncesBreadCrumbs: Array<PageLink> = [
     {
          title: "Annonces",
          path: "/recrutement/annonces/listing",
     },
]

const viviersBreadCrumbs: Array<PageLink> = [
     {
          title: "Viviers",
          path: "/recrutement/viviers/listing",
     },
]

const demandesEmbaucheBreadCrumbs: Array<PageLink> = [
     {
          title: "Demandes d'embauche",
          path: "/recrutement/demandes-embauche/listing",
     },
]

export default RecrutementRouting
