import React, { createContext, useContext, useMemo, useState } from "react"
import { toAbsoluteUrl } from "@common-metronic/helpers"
import { useSearchResultsContext } from "../CandidatsRechercheResults"
import { IBrowserListItemPropsModel, ISearchResultsDataItemResponseModel, ISearchResultsListItemContextPropsModel } from "../core/_models"
import { getSelectedCandidatePlusPreviousAndNextCandidate } from "../core/helpers"
import MyLoadingOverlay from "@common-utils/MyLoadingOverlay"
import { useSearchResultsListContext } from "./_CandidatsRechercheResultsList"
import {
     AddOrRemoveToFromListeTraitementButton,
     AddToViviersButton,
     BottomCardDivInfoDms,
     DateLastCandidatureOrDateSaisi,
     Disponibilite,
     Mail,
     Metiers,
     Mobilite,
     PartagerProfilParMailButton,
     Phone,
     SalaireNetMensuel,
     SeenSuiviVivierListeTraitementStatutsIcons,
     TagCandidatureSpon,
     TagEmbauche,
} from "./_CandidatsRechercheResultsListItemHelpers"
import { CONST_CANDIDAT_ETAT_EMBAUCHE, CONST_CANDIDAT_TYPE_CS } from "@common-constants/*"
import FileAndDriveHelper from "@common-helpers/FileAndDriveHelper"
import MyModal from "@common-utils/MyModal"
import { CandidatSuiviListing } from "../../../suivi/_CandidatSuiviListing"

// Listing for >= MD breakpoint
const ListItem = ({ item, handleClickItemForPreview, isItemSelected, isItemLoading }: IBrowserListItemPropsModel) => {
     const globalContext = useSearchResultsContext()
     const listContext = useSearchResultsListContext()
     const [isAvatarLoading, setIsAvatarLoading] = useState<boolean>(!!item.candidat.user?.avatarGoogleDriveId)

     function handleOnCheckItem() {
          if (!globalContext.isBulkSelectionEnabled.checkedElementsInPage.includes(item.candidat.id as number)) {
               globalContext.setIsBulkSelectionEnabled(prev => ({
                    ...prev,
                    checkedElementsInPage: [...prev.checkedElementsInPage, item.candidat.id as number],
                    areAllElementsInAllPagesChecked: false,
               }))
          } else {
               globalContext.setIsBulkSelectionEnabled(prev => ({
                    ...prev,
                    checkedElementsInPage: prev.checkedElementsInPage.filter(id => id !== item.candidat.id),
                    areAllElementsInAllPagesChecked: false,
               }))
          }
     }

     return (
          <MyLoadingOverlay isActive={isItemLoading}>
               <>
                    <div
                         className={`${isItemSelected ? "border-start border-2 border-primary" : ""}`}
                         onClick={e => handleClickItemForPreview(e)}
                         ref={isItemSelected ? listContext.selectedItemRef : null}
                    >
                         {/* Avatar + name + date candidature / date saisie + métier */}
                         <div className={`p-4 d-flex align-items-center ${isItemSelected ? "mb-4 bg-light" : "cursor-pointer"}`}>
                              {/* checkbox */}
                              {globalContext.isBulkSelectionEnabled.enabled && (
                                   <div className={"form-check form-check-custom form-check-solid form-check-sm me-4"}>
                                        <input
                                             type="checkbox"
                                             className={"form-check-input cursor-pointer border border-secondary not-concerned-by-preview"}
                                             checked={globalContext.isBulkSelectionEnabled.checkedElementsInPage.includes(item.candidat.id as number)}
                                             onChange={() => handleOnCheckItem()}
                                        />
                                   </div>
                              )}
                              <div>
                                   <div className={"d-flex justify-content-center"}>
                                        <MyLoadingOverlay isActive={isAvatarLoading} styles={{ height: "50px", width: "50px", borderRadius: "50%" }} size={"sm"}>
                                             {/* Avatar */}
                                             <img
                                                  src={
                                                       item.candidat.user?.avatarGoogleDriveId
                                                            ? FileAndDriveHelper.getThumbnailDriveUrl(item.candidat.user.avatarGoogleDriveId)
                                                            : toAbsoluteUrl("/media/svg/avatars/blank.svg")
                                                  }
                                                  onLoad={() => setIsAvatarLoading(false)}
                                                  style={{ height: "50px", width: "50px", borderRadius: "50%" }}
                                                  alt="Avatar"
                                             />
                                        </MyLoadingOverlay>
                                   </div>
                                   {isItemSelected && item.candidat.type === CONST_CANDIDAT_TYPE_CS && <TagCandidatureSpon />}
                                   {isItemSelected && item.candidat.etat === CONST_CANDIDAT_ETAT_EMBAUCHE && <TagEmbauche />}
                              </div>
                              {/* Name + date candidature / date saisie + métier */}
                              <div className={"flex-grow-1 ms-4"}>
                                   <div className={"d-flex align-items-center"}>
                                        <div>
                                             <span className={"fs-5 fw-bold me-2"}>{item.candidat.prenomNom}</span>
                                        </div>
                                        <div className={"ms-auto"}>
                                             <DateLastCandidatureOrDateSaisi classNames={"not-concerned-by-preview"} />
                                        </div>
                                   </div>
                                   <div className={"d-flex align-items-center"}>
                                        {item.candidat.niveauEtudes && <span className={"text-primary me-1"}>{item.candidat.niveauEtudesLabel} - </span>}
                                        <Metiers classNames={"not-concerned-by-preview"} />
                                        <div className={"ms-auto"}>
                                             <SeenSuiviVivierListeTraitementStatutsIcons />
                                        </div>
                                   </div>
                              </div>
                         </div>

                         {/* Tél, mail, DMS, actions: ajout liste traitement + ajout dans vivier */}
                         {isItemSelected && (
                              <div className={"d-flex align-items-center px-4 pb-4"}>
                                   <div className={"col-8"}>
                                        <Mail classNames={"mb-2"} />
                                        <Phone classNames={"mb-2"} />
                                        <Disponibilite classNames={"mb-2"} />
                                        <Mobilite classNames={"mb-2"} />
                                        <SalaireNetMensuel classNames={"mb-2"} />
                                   </div>
                                   <div className="col text-end">
                                        <AddOrRemoveToFromListeTraitementButton classNames={"mb-2"} />
                                        <AddToViviersButton classNames={"mb-2"} />
                                        <PartagerProfilParMailButton />
                                   </div>
                              </div>
                         )}
                    </div>
                    {isItemSelected && <BottomCardDivInfoDms />}
               </>
          </MyLoadingOverlay>
     )
}

const SearchResultsListItemContext = createContext<ISearchResultsListItemContextPropsModel>({} as ISearchResultsListItemContextPropsModel)
export const useSearchResultsListItemContext = () => useContext(SearchResultsListItemContext)
const CandidatsRechercheResultsListItem = ({ item, index }: { item: ISearchResultsDataItemResponseModel; index: number }) => {
     const globalContext = useSearchResultsContext()

     const isItemSelected: boolean = globalContext.itemInPreview !== null && globalContext.itemInPreview.selectedItem.candidat.id === item.candidat.id
     const isItemLoading: boolean =
          globalContext.itemInPreview !== null && globalContext.itemInPreview.selectedItem.candidat.id === item.candidat.id && globalContext.itemInPreview.isSelectedItemLoading

     const [isSuivisModalOpen, setIsSuivisModalOpen] = useState<boolean>(false)

     // Shows candidate curriculum in preview + prepares quick navigation from one curriculum to another
     function handleClickItemForPreview(e: React.MouseEvent<any>) {
          const clickedElement = e.target as HTMLElement
          // .not-concerned-by-preview elements are not concerned by the click
          if (!clickedElement.classList.contains("not-concerned-by-preview") && globalContext.itemInPreview?.selectedItem.candidat.id !== item.candidat.id) {
               globalContext.setAreFiltersVisible(false)

               globalContext.setItemInPreview(
                    getSelectedCandidatePlusPreviousAndNextCandidate(
                         item,
                         index,
                         globalContext.useListingQuery.data!.data,
                         globalContext.useListingQuery.data!.page,
                         globalContext.useListingQuery.data!.totalPages
                    )
               )

               if (!item.connectedUserHasSeenCandidat) globalContext.checkItemAsSeen(item.candidat.id as number)
          }
     }

     const SuivisListingInModal = useMemo(() => {
          return (
               <MyModal title={<span>Suivis de {item.candidat.prenomNom}</span>} show={true} handleClose={() => setIsSuivisModalOpen(false)} fullscreen noPadding>
                    <CandidatSuiviListing candidat_id={item.candidat.id as number} />
               </MyModal>
          )
     }, [isSuivisModalOpen])

     return (
          <SearchResultsListItemContext.Provider
               value={{
                    item,
                    isSuivisModalOpen,
                    setIsSuivisModalOpen,
               }}
          >
               <ListItem item={item} handleClickItemForPreview={handleClickItemForPreview} isItemSelected={isItemSelected} isItemLoading={isItemLoading} />

               {isSuivisModalOpen && <>{SuivisListingInModal}</>}
          </SearchResultsListItemContext.Provider>
     )
}

export default CandidatsRechercheResultsListItem
