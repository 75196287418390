import moment from "moment/moment"
import MyTooltip from "@common-utils/MyTooltip"
import { Link } from "react-router-dom"
import { ROUTING_ABSOLUTE_PATH_GESTION_STRUCTURE_DECLARATIONS_LISTING } from "../../../routing/routing-endpoints"
import React from "react"
import { IGetPersonnelsDeclarationsVoyantsResponsableBmResponseModel } from "./core/_models"

const VoyantsMonthVueResp = ({ item, mois, includeOnlyThisPersonnels, excludedPersonnels }: IVoyantsMonthVueRespPropsModel) => {
     const enAttenteDeReception = item.enAttenteDeReception
     const enCoursRectification = item.enCoursDeRectification
     const aEnvoyer = item.aValider
     const aControler = item.aControler
     const envoyeesAuSiege = item.validees
     const valideesPourPaie = item.valideesPourPaie

     return (
          <>
               <div className="d-flex justify-content-center mb-2">
                    <MyTooltip title={enAttenteDeReception === 1 ? `Une déclaration en attente de réception` : `${enAttenteDeReception} déclarations en attente de réception`}>
                         <Link
                              to={ROUTING_ABSOLUTE_PATH_GESTION_STRUCTURE_DECLARATIONS_LISTING}
                              state={{
                                   mois: mois.format("YYYY-MM-DD"),
                                   etat: "ETAT_NON_ADMIN__EN_ATTENTE_RECEPTION",
                                   includeOnlyThisPersonnels,
                                   excludedPersonnels,
                              }}
                              className={`fs-5 btn btn-sm ${enAttenteDeReception > 0 ? "btn-danger" : "btn-outline btn-outline-danger"} btn-icon me-3 ${
                                   enAttenteDeReception === 0 ? "cursor-not-allowed text-muted" : ""
                              }`}
                              onClick={e => enAttenteDeReception === 0 && e.preventDefault()}
                         >
                              {enAttenteDeReception}
                         </Link>
                    </MyTooltip>
                    <MyTooltip title={enCoursRectification === 1 ? `Une déclaration en cours de rectification` : `${enCoursRectification} déclarations en cours de rectification`}>
                         <Link
                              to={ROUTING_ABSOLUTE_PATH_GESTION_STRUCTURE_DECLARATIONS_LISTING}
                              state={{
                                   mois: mois.format("YYYY-MM-DD"),
                                   etat: "ETAT_NON_ADMIN__EN_COURS_RECTIFICATION",
                                   includeOnlyThisPersonnels,
                                   excludedPersonnels,
                              }}
                              className={`fs-5 btn btn-sm ${enCoursRectification > 0 ? "btn-info" : "btn-outline btn-outline-info"} btn-icon ${
                                   enCoursRectification === 0 ? "cursor-not-allowed text-muted" : ""
                              }`}
                              onClick={e => enCoursRectification === 0 && e.preventDefault()}
                         >
                              {enCoursRectification}
                         </Link>
                    </MyTooltip>
               </div>
               <div className="d-flex justify-content-center mb-2">
                    <MyTooltip title={aControler === 1 ? `Une déclaration à contrôler` : `${aControler} déclarations à contrôler`}>
                         <Link
                              to={ROUTING_ABSOLUTE_PATH_GESTION_STRUCTURE_DECLARATIONS_LISTING}
                              state={{
                                   mois: mois.format("YYYY-MM-DD"),
                                   etat: "ETAT_NON_ADMIN__A_CONTROLER",
                                   includeOnlyThisPersonnels,
                                   excludedPersonnels,
                              }}
                              className={`fs-5 btn btn-sm ${aControler > 0 ? "btn-warning" : "btn-outline btn-outline-warning"} btn-icon me-3 ${
                                   aControler === 0 ? "cursor-not-allowed text-muted" : ""
                              }`}
                              onClick={e => aControler === 0 && e.preventDefault()}
                         >
                              {aControler}
                         </Link>
                    </MyTooltip>
                    <MyTooltip title={aEnvoyer === 1 ? `Une déclaration à valider` : `${aEnvoyer} déclarations à valider`}>
                         <Link
                              to={ROUTING_ABSOLUTE_PATH_GESTION_STRUCTURE_DECLARATIONS_LISTING}
                              state={{
                                   mois: mois.format("YYYY-MM-DD"),
                                   etat: "ETAT_NON_ADMIN__A_ENVOYER_AU_SIEGE",
                                   includeOnlyThisPersonnels,
                                   excludedPersonnels,
                              }}
                              className={`fs-5 btn btn-sm ${aEnvoyer > 0 ? "btn-warning" : "btn-outline btn-outline-warning"} btn-icon ${
                                   aEnvoyer === 0 ? "cursor-not-allowed text-muted" : ""
                              }`}
                              onClick={e => aEnvoyer === 0 && e.preventDefault()}
                         >
                              {aEnvoyer}
                         </Link>
                    </MyTooltip>
               </div>
               <div className="d-flex justify-content-center">
                    <MyTooltip title={envoyeesAuSiege === 1 ? `Une déclaration a été envoyée au siège` : `${envoyeesAuSiege} déclarations ont été envoyées au siège`}>
                         <Link
                              to={ROUTING_ABSOLUTE_PATH_GESTION_STRUCTURE_DECLARATIONS_LISTING}
                              state={{
                                   mois: mois.format("YYYY-MM-DD"),
                                   etat: "ETAT_NON_ADMIN__ENVOYE_AU_SIEGE",
                                   includeOnlyThisPersonnels,
                                   excludedPersonnels,
                              }}
                              className={`fs-5 btn btn-sm ${envoyeesAuSiege > 0 ? "btn-success" : "btn-outline btn-outline-success"} btn-icon me-3 ${
                                   envoyeesAuSiege === 0 ? "cursor-not-allowed text-muted" : ""
                              }`}
                              onClick={e => envoyeesAuSiege === 0 && e.preventDefault()}
                         >
                              {envoyeesAuSiege}
                         </Link>
                    </MyTooltip>
                    <MyTooltip title={valideesPourPaie === 1 ? `Une déclaration a été validée par le siège` : `${valideesPourPaie} déclarations ont été validées par le siège`}>
                         <Link
                              to={ROUTING_ABSOLUTE_PATH_GESTION_STRUCTURE_DECLARATIONS_LISTING}
                              state={{
                                   mois: mois.format("YYYY-MM-DD"),
                                   etat: "ETAT_NON_ADMIN__VALIDE_POUR_PAIE",
                                   includeOnlyThisPersonnels,
                                   excludedPersonnels,
                              }}
                              className={`fs-5 btn btn-sm ${valideesPourPaie > 0 ? "btn-success" : "btn-outline btn-outline-success"} btn-icon ${
                                   valideesPourPaie === 0 ? "cursor-not-allowed text-muted" : ""
                              }`}
                              onClick={e => valideesPourPaie === 0 && e.preventDefault()}
                         >
                              {valideesPourPaie}
                         </Link>
                    </MyTooltip>
               </div>
          </>
     )
}

interface IVoyantsMonthVueRespPropsModel {
     item: IGetPersonnelsDeclarationsVoyantsResponsableBmResponseModel
     mois: moment.Moment
     includeOnlyThisPersonnels?: number[]
     excludedPersonnels?: number[]
}

export default VoyantsMonthVueResp
