import React, { useState } from "react"
import { Link } from "react-router-dom"
import { useConnectedUserDeclarationDetails } from "./ConnectedPersonnelDeclarationDetails"
import { IPersonnelArretMaladieModel } from "@common-models/*"
import { toast } from "react-toastify"
import { useQueryClient } from "react-query"
import MyModal from "@common-utils/MyModal"
import PersonnelMaladieCreate from "../../../declaration/maladie/create/_PersonnelMaladieCreate"
import { PersonnelMaladieListingForOne, REACT_QUERY_KEY_GET_PERSONNEL_MALADIES } from "../../../declaration/maladie/listing/listing-only-one/_PersonnelMaladieListingForOne"
import { useAuth } from "../../../../../AppContext"

export const ConnectedUserDeclarationDetails_arrets = () => {
     const { currentUser } = useAuth()
     const context = useConnectedUserDeclarationDetails()
     const queryClient = useQueryClient()
     const [addMaladieInModal, setAddMaladieInModal] = useState<boolean>(false)

     function handleCreateMaladie(newItem: IPersonnelArretMaladieModel) {
          queryClient.setQueryData<IPersonnelArretMaladieModel[] | undefined>(REACT_QUERY_KEY_GET_PERSONNEL_MALADIES, (prev: IPersonnelArretMaladieModel[] | undefined) => {
               if (prev) {
                    return [newItem, ...prev]
               }

               return prev
          })
          setAddMaladieInModal(false)
          toast.success("Votre arrêt maladie a bien été enregistré.")
          toast.info(
               "Merci de vérifier, lors de l'étape de Compte Rendu d'Activité (CRA), si les heures liées à l'absence ainsi que celles de travail pour la période concernée par votre demande sont corrects.",
               {
                    autoClose: false,
               }
          )
     }

     const Footer = () => {
          return (
               <>
                    <div className="d-none d-sm-flex justify-content-between">
                         <div>
                              <Link to="/personnel/mes-activites/declarations " className={`btn btn-sm btn-light-primary me-2`}>
                                   <i className={"fas fa-list-dots me-2 align-middle"} /> Retour aux déclarations
                              </Link>
                         </div>

                         <button
                              type="button"
                              className="btn btn-sm btn-primary"
                              onClick={() => {
                                   context.setCurrentStepHeader(context.enCoursDeRectificationEnabledDepuisRecapitulatif ? "recap" : "ddc")
                                   context.setEnCoursDeRectificationEnabledDepuisRecapitulatif(false)
                              }}
                         >
                              {context.enCoursDeRectificationEnabledDepuisRecapitulatif ? "Récapitulatif" : "Congés"}
                              <span className={"fas fa-arrow-right fs-3 ms-1 align-middle"} />
                         </button>
                    </div>

                    <div className="d-flex d-sm-none flex-column">
                         <div className={"mb-3"}>
                              <button
                                   type="button"
                                   className="btn btn-sm btn-primary float-end"
                                   onClick={() => {
                                        context.setCurrentStepHeader(context.enCoursDeRectificationEnabledDepuisRecapitulatif ? "recap" : "ddc")
                                        context.setEnCoursDeRectificationEnabledDepuisRecapitulatif(false)
                                   }}
                              >
                                   {context.enCoursDeRectificationEnabledDepuisRecapitulatif ? "Récapitulatif" : "Congés"}
                                   <span className={"fas fa-arrow-right fs-3 ms-1 align-middle"} />
                              </button>
                         </div>

                         <Link to="/personnel/mes-activites/declarations " className={`btn btn-sm btn-light-primary`}>
                              <i className={"fas fa-list-dots me-1 align-middle"} /> Retour aux déclarations
                         </Link>
                    </div>
               </>
          )
     }

     return (
          <>
               {context.data.declaration?.modifiable && (
                    <div className="d-flex justify-content-end mb-4">
                         <button type={"button"} className={`btn btn-primary btn-sm d-flex align-items-center`} onClick={() => setAddMaladieInModal(true)}>
                              <span className={`d-md-inline d-none`}>Nouvel arrêt</span>
                              <i className={"fas fa-plus-circle ms-md-2 pe-0 fs-3"} />
                         </button>
                    </div>
               )}
               <div>
                    <PersonnelMaladieListingForOne
                         personnel_ID={currentUser?.id as number}
                         filtres={{ duOrAuWithThisMonthAndYear: context.data.declaration?.mois?.parseZone().format("YYYY-MM-DD") as string }}
                         editionMode={context.editionModeEnabled}
                    />
               </div>

               <div className="separator separator-dashed my-6" />
               <Footer />

               {addMaladieInModal && (
                    <MyModal show={true} handleClose={() => setAddMaladieInModal(false)} title={<>Nouvel arrêt</>} size={"lg"}>
                         <PersonnelMaladieCreate
                              duOrAuWithThisMonthAndYear={context.data.declaration?.mois?.parseZone().startOf("month").format("YYYY-MM-DD")}
                              callback={handleCreateMaladie}
                         />
                    </MyModal>
               )}
          </>
     )
}
