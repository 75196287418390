import moment from "moment/moment"
import MyTooltip from "@common-utils/MyTooltip"
import { Link } from "react-router-dom"
import React from "react"
import { IGetPersonnelsDeclarationsVoyantsAdminResponseModel } from "./core/_models"
import { ROUTING_ABSOLUTE_PATH_GESTION_STRUCTURE_DECLARATIONS_LISTING } from "../../../routing/routing-endpoints"

const VoyantsMonthVueSiege = ({ item, mois, includeOnlyThisPersonnels, excludedPersonnels }: IVoyantsMonthVueSiegePropsModel) => {
     const enAttenteDeReception = item.enAttenteDeReception
     const enCoursRectification = item.enCoursDeRectification
     const aValiderPourLaPaie = item.aValider
     const valideesPourPaie = item.valideesPourPaie

     return (
          <>
               <div className="d-flex justify-content-center mb-2">
                    <MyTooltip title={enAttenteDeReception === 1 ? `Une déclaration en attente de réception` : `${enAttenteDeReception} déclarations en attente de réception`}>
                         <Link
                              to={ROUTING_ABSOLUTE_PATH_GESTION_STRUCTURE_DECLARATIONS_LISTING}
                              state={{
                                   mois: mois.format("YYYY-MM-DD"),
                                   etat: "ETAT_ADMIN__EN_ATTENTE_RECEPTION",
                                   includeOnlyThisPersonnels,
                                   excludedPersonnels,
                                   vueSiegeOrResp: "resp",
                              }}
                              className={`fs-5 btn btn-sm ${enAttenteDeReception > 0 ? "btn-danger" : "btn-outline btn-outline-danger"} btn-icon me-3 ${
                                   enAttenteDeReception === 0 ? "cursor-not-allowed text-muted" : ""
                              }`}
                              onClick={e => enAttenteDeReception === 0 && e.preventDefault()}
                         >
                              {enAttenteDeReception}
                         </Link>
                    </MyTooltip>
                    <MyTooltip title={enCoursRectification === 1 ? `Une déclaration en cours de rectification` : `${enCoursRectification} déclarations en cours de rectification`}>
                         <Link
                              to={ROUTING_ABSOLUTE_PATH_GESTION_STRUCTURE_DECLARATIONS_LISTING}
                              state={{
                                   mois: mois.format("YYYY-MM-DD"),
                                   etat: "ETAT_ADMIN__EN_COURS_RECTIFICATION",
                                   includeOnlyThisPersonnels,
                                   excludedPersonnels,
                                   vueSiegeOrResp: "resp",
                              }}
                              className={`fs-5 btn btn-sm ${enCoursRectification > 0 ? "btn-info" : "btn-outline btn-outline-info"} btn-icon ${
                                   enCoursRectification === 0 ? "cursor-not-allowed text-muted" : ""
                              }`}
                              onClick={e => enCoursRectification === 0 && e.preventDefault()}
                         >
                              {enCoursRectification}
                         </Link>
                    </MyTooltip>
               </div>
               <div className="d-flex justify-content-center">
                    <MyTooltip title={aValiderPourLaPaie === 1 ? `Une déclaration à valider` : `${aValiderPourLaPaie} déclarations à valider`}>
                         <Link
                              to={ROUTING_ABSOLUTE_PATH_GESTION_STRUCTURE_DECLARATIONS_LISTING}
                              state={{ mois: mois.format("YYYY-MM-DD"), etat: "ETAT_ADMIN__A_VALIDER", includeOnlyThisPersonnels, excludedPersonnels, vueSiegeOrResp: "resp" }}
                              className={`fs-5 btn btn-sm ${aValiderPourLaPaie > 0 ? "btn-warning" : "btn-outline btn-outline-warning"} btn-icon me-3 ${
                                   aValiderPourLaPaie === 0 ? "cursor-not-allowed text-muted" : ""
                              }`}
                              onClick={e => aValiderPourLaPaie === 0 && e.preventDefault()}
                         >
                              {aValiderPourLaPaie}
                         </Link>
                    </MyTooltip>
                    <MyTooltip title={valideesPourPaie === 1 ? `Une déclaration a été validée par le siège` : `${valideesPourPaie} déclarations ont été validées par le siège`}>
                         <Link
                              to={ROUTING_ABSOLUTE_PATH_GESTION_STRUCTURE_DECLARATIONS_LISTING}
                              state={{
                                   mois: mois.format("YYYY-MM-DD"),
                                   etat: "ETAT_ADMIN__VALIDE_POUR_PAIE",
                                   includeOnlyThisPersonnels,
                                   excludedPersonnels,
                                   vueSiegeOrResp: "resp",
                              }}
                              className={`fs-5 btn btn-sm ${valideesPourPaie > 0 ? "btn-success" : "btn-outline btn-outline-success"} btn-icon ${
                                   valideesPourPaie === 0 ? "cursor-not-allowed text-muted" : ""
                              }`}
                              onClick={e => valideesPourPaie === 0 && e.preventDefault()}
                         >
                              {valideesPourPaie}
                         </Link>
                    </MyTooltip>
               </div>
          </>
     )
}

interface IVoyantsMonthVueSiegePropsModel {
     item: IGetPersonnelsDeclarationsVoyantsAdminResponseModel
     mois: moment.Moment
     includeOnlyThisPersonnels?: number[]
     excludedPersonnels?: number[]
}

export default VoyantsMonthVueSiege
