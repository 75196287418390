import React, { useEffect, useState } from "react"
import MyCard from "@common-utils/MyCard"
import { useFournisseurListingContext } from "../FournisseurListing"
import "../styles.scss"
import { IItemResponseModel } from "../core/_models"
import InfoBase from "./infoBase/InfoBase"
import Responsables from "./responsables/Responsables"
import Consultants from "./Consultants"

/* Wrapper */
const Preview = () => {
     const globalContext = useFournisseurListingContext()
     const selectedItemInPreview = globalContext.itemInPreview!.selectedItem
     const itemInPreview = globalContext.listingQuery.data?.data.find(item => item.fournisseur?.id === selectedItemInPreview.fournisseur?.id) as IItemResponseModel

     const [tab, setTab] = useState<"info_base" | "contacts" | "consultants">("info_base")

     useEffect(() => {
          globalContext.setItemInPreview(prev => (prev ? { ...prev, isSelectedItemLoading: false } : prev))
     }, [itemInPreview])
     return (
          <MyCard>
               <MyCard.Header
                    isCollapseIconShown={false}
                    rightSideContent={
                         <div className={"d-flex gap-5"}>
                              <button
                                   className={`btn ${tab === "info_base" ? "btn-dark" : "btn-outline btn-outline-dark"} btn-sm px-2 py-2 fw-bold`}
                                   onClick={() => setTab("info_base")}
                              >
                                   INFO. DE BASE
                              </button>
                              <button
                                   className={`btn ${tab === "contacts" ? "btn-dark" : "btn-outline btn-outline-dark"} btn-sm px-2 py-2 fw-bold`}
                                   onClick={() => setTab("contacts")}
                              >
                                   CONTACTS
                              </button>
                              <button
                                   className={`btn ${tab === "consultants" ? "btn-dark" : "btn-outline btn-outline-dark"} btn-sm px-2 py-2 fw-bold`}
                                   onClick={() => setTab("consultants")}
                              >
                                   CONSULTANTS
                              </button>
                         </div>
                    }
               >
                    {itemInPreview.fournisseur.raisonSociale}
               </MyCard.Header>
               <MyCard.Body styles={{ maxHeight: "75.1vh", overflowY: "scroll" }}>
                    <>{tab === "info_base" && <InfoBase />}</>
                    <>{tab === "contacts" && <Responsables />}</>
                    <>{tab === "consultants" && <Consultants />}</>
               </MyCard.Body>
          </MyCard>
     )
}

export default Preview
