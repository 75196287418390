import React, { FC } from "react"
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom"
import { PrivateRoutes } from "./PrivateRoutes"
import { ErrorsPage } from "../modules/errors/ErrorsPage"
import { App } from "../App"
import useWindowDimensions from "../../common/hooks/useWindowDimensions"
import { IS_DEV_ENV_ENABLED } from "@common-constants/*"
import { useAuth } from "../AppContext"
import { MasterLayout } from "../layout/MasterLayout"
import MyAlert from "@common-utils/MyAlert"
import { CompteHeader } from "../modules/personnel/mon-compte/CompteHeader"
import CompteInfoPerso from "../modules/personnel/mon-compte/infoPerso/CompteInfoPerso"
import PublicRoutes from "./PublicRoutes"
import SyncGoogleCalendar from "../modules/personnel/syncGoogleCalendar"

const { PUBLIC_URL } = process.env

const AppRoutes: FC = () => {
     const { height, width } = useWindowDimensions()
     const { currentUser } = useAuth()

     return (
          <BrowserRouter basename={PUBLIC_URL}>
               {/* Shows the container bootstrap breakpoints */}
               {IS_DEV_ENV_ENABLED && (
                    <div
                         className={"float-start"}
                         style={{
                              zIndex: 9999999999,
                              position: "fixed",
                         }}
                    >
                         {width} x {height} {width < 576 && "( XS )"}
                         {width >= 576 && width < 768 && "( SM )"}
                         {width >= 768 && width < 992 && "( MD )"}
                         {width >= 992 && width < 1200 && "( LG )"}
                         {width >= 1200 && width < 1400 && "( XL )"}
                         {width >= 1400 && "( XXL )"}
                    </div>
               )}

               <Routes>
                    <Route element={<App />}>
                         {currentUser ? (
                              <Route element={<MasterLayout />}>
                                   {currentUser.isGoogleCalendarTokenExistent ? (
                                        <>
                                             {currentUser.isProfilCompleted ? (
                                                  <Route
                                                       path="/*"
                                                       element={
                                                            <>
                                                                 <PrivateRoutes />
                                                            </>
                                                       }
                                                  />
                                             ) : (
                                                  <>
                                                       <Route
                                                            path="personnel/mon-compte/profil"
                                                            element={
                                                                 <>
                                                                      <div className={"d-flex justify-content-center"}>
                                                                           <div className={"w-100 w-lg-75 w-xxl-65"}>
                                                                                <MyAlert title={<>Talent a besoin de quelques informations</>} type={"primary"} classNames={"mb-2"}>
                                                                                     Avant d'utiliser Talent, veuillez fournir ou mettre à jour vos informations personnelles. Une
                                                                                     fois terminé, veuillez cliquer sur le bouton "Appliquer les modifications".
                                                                                </MyAlert>
                                                                                <CompteHeader />
                                                                                <CompteInfoPerso />
                                                                           </div>
                                                                      </div>
                                                                 </>
                                                            }
                                                       />

                                                       <Route path="*" element={<Navigate to="/personnel/mon-compte/profil" />} />
                                                  </>
                                             )}
                                        </>
                                   ) : (
                                        <>
                                             <Route path="sync-google-calendar" element={<SyncGoogleCalendar />} />
                                             <Route path="*" element={<Navigate to="sync-google-calendar" />} />
                                        </>
                                   )}
                              </Route>
                         ) : (
                              <Route path="/*" element={<PublicRoutes />} />
                         )}

                         <Route path="error/*" element={<ErrorsPage />} />
                    </Route>
               </Routes>
          </BrowserRouter>
     )
}

export { AppRoutes }
