import MyCard from "@common-utils/MyCard"
import React, { useState } from "react"
import * as Yup from "yup"
import { Form, Formik, FormikHelpers } from "formik"
import axios, { AxiosError } from "axios"
import { IConsultantModel, IHttpErrorResponseModel } from "@common-models/*"
import {
     CONST_CIVILITE_MME,
     CONST_CIVILITE_MR,
     CONST_CIVILITE_NON_RENSEIGNEE,
     CONST_CIVILITES_OPTIONS_FOR_SELECT2,
     CONST_CONSULTANT_STATUT_ST_FREELANCE,
     CONST_CONSULTANT_STATUT_ST_PORTAGE,
     CONST_CONSULTANT_STATUT_ST_SOCIETE_SERVICE,
     CONST_FOURNISSEUR_TYPE_FREELANCE,
     CONST_FOURNISSEUR_TYPE_SOCIETE_PORTAGE,
     CONST_FOURNISSEUR_TYPE_SOCIETE_SERVICE,
     CONST_HTTP_CUSTOM_CODE_FORM_VALIDATION_ERROR,
} from "@common-constants/*"
import { toast } from "react-toastify"
import MyAlert from "@common-utils/MyAlert"
import MyRadioWithLabelAndDescriptionField from "@common-utils/fields/MyRadioWithLabelAndDescriptionField"
import MySelectGetAllDataFromServer from "@common-utils/fields/MySelectGetAllDataFromServer"
import {
     CONST_API_RECRUITMENT_CANDIDATES_POST_DEMANDER_INFO_PRE_EMBAUCHE_SANS_PASSER_PAR_CANDIDAT_EXISTANT_PAR_MAIL_ENDPOINT,
     CONST_API_RECRUITMENT_CANDIDATES_POST_ENTAMER_DEMANDE_EMBAUCHE_SANS_PASSER_PAR_CANDIDAT_EXISTANT_PAR_MAIL_ENDPOINT,
     CONST_API_UTILS_GET_ENTITES_ENDPOINT,
     CONST_API_UTILS_GET_FOURNISSEURS_ENDPOINT,
     CONST_API_UTILS_GET_FOURNISSEURS_RESP_ENDPOINT,
} from "../../../../endpoints"
import MyModal from "@common-utils/MyModal"
import AddFournisseurForm from "../../../utils/core/_AddFournisseurForm"
import AddResponsableFournisseurForm from "../../../utils/_AddResponsableFournisseurForm"
import MyInputField from "@common-utils/fields/MyInputField"
import MySelectField from "@common-utils/fields/MySelectField"
import MySimpleSpinner from "@common-utils/MySimpleSpinner"
import { useNavigate } from "react-router-dom"
import { TYPE_CONTRAT_FREELANCE, TYPE_CONTRAT_SALARIE } from "../../candidats/actions/_CandidatDemanderInfoPreEmbaucheParMail"
import MyTooltip from "@common-utils/MyTooltip"
import swal from "sweetalert2"

const DemandeEmbaucheStart = () => {
     const navigate = useNavigate()

     // States
     // URL parameters on redirect vers la page de candidats
     const [demanderInfoPreembaucheContratFieldValue, setDemanderInfoPreembaucheContratFieldValue] = useState<string | null>(null)
     const [demanderInfoPreembaucheFournisseurField, setDemanderInfoPreembaucheFournisseurField] = useState<{ value: any; label: any } | null>(null)
     const [demanderInfoPreembaucheFournisseurRespField, setDemanderInfoPreembaucheFournisseurRespField] = useState<{ value: any; label: any } | null>(null)

     const [isAddingFournisseur, setIsAddingFournisseur] = useState<boolean>(false)
     const [isAddingFournisseurResp, setIsAddingFournisseurResp] = useState<boolean>(false)

     const schema = Yup.object().shape({
          sousTraitantOuSalarie: Yup.string().label(`"Catégorie"`).required(),
          typeSousTraitant: Yup.string()
               .label(`"Type sous-traitant"`)
               .when("sousTraitantOuSalarie", {
                    is: "ST",
                    then: schema => schema.required(),
               }),
          fournisseur: Yup.number()
               .label(`"Fournisseur"`)
               .when("sousTraitantOuSalarie", {
                    is: "ST",
                    then: schema => schema.required(),
               }),
          fournisseurResp: Yup.number()
               .label(`"Responsable fournisseur"`)
               .when("sousTraitantOuSalarie", {
                    is: "ST",
                    then: schema => schema.required(),
               }),
          saisieManuelleOuAPartirCandidatExistant: Yup.string()
               .label(`"Champ"`)
               .when(["sousTraitantOuSalarie", "typeSousTraitant"], {
                    is: (sousTraitantOuSalarie: string, typeSousTraitant: string) =>
                         (sousTraitantOuSalarie === "ST" && typeSousTraitant === CONST_CONSULTANT_STATUT_ST_FREELANCE) || sousTraitantOuSalarie === "SALARIE",
                    then: schema => schema.required(),
               }),
          saisieManuelleCivilite: Yup.string()
               .label(`"Civilité"`)
               .when(["sousTraitantOuSalarie", "typeSousTraitant", "saisieManuelleOuAPartirCandidatExistant"], {
                    is: (sousTraitantOuSalarie: string, typeSousTraitant: string, saisieManuelleOuAPartirCandidatExistant: string) =>
                         ((sousTraitantOuSalarie === "ST" && typeSousTraitant === CONST_CONSULTANT_STATUT_ST_FREELANCE) || sousTraitantOuSalarie === "SALARIE") &&
                         saisieManuelleOuAPartirCandidatExistant,
                    then: schema => schema.required(),
               }),
          saisieManuelleNom: Yup.string()
               .label(`"Nom"`)
               .when(["sousTraitantOuSalarie", "typeSousTraitant", "saisieManuelleOuAPartirCandidatExistant"], {
                    is: (sousTraitantOuSalarie: string, typeSousTraitant: string, saisieManuelleOuAPartirCandidatExistant: string) =>
                         ((sousTraitantOuSalarie === "ST" && typeSousTraitant === CONST_CONSULTANT_STATUT_ST_FREELANCE) || sousTraitantOuSalarie === "SALARIE") &&
                         saisieManuelleOuAPartirCandidatExistant,
                    then: schema => schema.required(),
               }),
          saisieManuellePrenom: Yup.string()
               .label(`"Prénom"`)
               .when(["sousTraitantOuSalarie", "typeSousTraitant", "saisieManuelleOuAPartirCandidatExistant"], {
                    is: (sousTraitantOuSalarie: string, typeSousTraitant: string, saisieManuelleOuAPartirCandidatExistant: string) =>
                         ((sousTraitantOuSalarie === "ST" && typeSousTraitant === CONST_CONSULTANT_STATUT_ST_FREELANCE) || sousTraitantOuSalarie === "SALARIE") &&
                         saisieManuelleOuAPartirCandidatExistant,
                    then: schema => schema.required(),
               }),
          saisieManuelleEmail: Yup.string()
               .label(`"Email"`)
               .when(["sousTraitantOuSalarie", "saisieManuelleOuAPartirCandidatExistant"], {
                    is: (sousTraitantOuSalarie: string, saisieManuelleOuAPartirCandidatExistant: string) =>
                         sousTraitantOuSalarie === "SALARIE" && saisieManuelleOuAPartirCandidatExistant,
                    then: schema => schema.required(),
               }),
          saisieManuelleEntite: Yup.number()
               .label(`"Entité"`)
               .when(["sousTraitantOuSalarie", "typeSousTraitant", "saisieManuelleOuAPartirCandidatExistant"], {
                    is: (sousTraitantOuSalarie: string, typeSousTraitant: string, saisieManuelleOuAPartirCandidatExistant: string) =>
                         ((sousTraitantOuSalarie === "ST" && typeSousTraitant === CONST_CONSULTANT_STATUT_ST_FREELANCE) || sousTraitantOuSalarie === "SALARIE") &&
                         saisieManuelleOuAPartirCandidatExistant,
                    then: schema => schema.required(),
               }),
          saisieManuelleNationalite: Yup.string()
               .label(`"Nationalité"`)
               .when(["sousTraitantOuSalarie", "saisieManuelleOuAPartirCandidatExistant"], {
                    is: (sousTraitantOuSalarie: string, saisieManuelleOuAPartirCandidatExistant: string) =>
                         sousTraitantOuSalarie === "SALARIE" && saisieManuelleOuAPartirCandidatExistant,
                    then: schema => schema.required(),
               }),
     })

     const initialValues: IDemandeEmbaucheStartRequestModel = {
          saisieManuelleCivilite: CONST_CIVILITE_MR,
     }

     function handleSubmit(values: IDemandeEmbaucheStartRequestModel, helpers: FormikHelpers<IDemandeEmbaucheStartRequestModel>) {
          helpers.setStatus(null)
          const payload = {
               typeContrat:
                    values.sousTraitantOuSalarie === "SALARIE"
                         ? "TYPE_CONTRAT_SALARIE"
                         : (() => {
                                switch (values.typeSousTraitant) {
                                     case "STATUT_ST_FREELANCE":
                                          return "TYPE_CONTRAT_FREELANCE"
                                     case "STATUT_ST_SOCIETE_SERVICE":
                                          return "TYPE_CONTRAT_SOCIETE_SERVICE"
                                     case "STATUT_ST_PORTAGE":
                                          return "TYPE_CONTRAT_PORTAGE"
                                     default:
                                          throw new Error("typeSousTraitant unkown")
                                }
                           })(),
               civilite: values.saisieManuelleCivilite,
               nom: values.saisieManuelleNom,
               prenom: values.saisieManuellePrenom,
               entiteRattachement: values.saisieManuelleEntite,
               email: values.saisieManuelleEmail,
               nationalite: values.saisieManuelleNationalite,
               fournisseur: values.fournisseur,
               fournisseurResp: values.fournisseurResp,
          }

          axios.post<IConsultantModel>(CONST_API_RECRUITMENT_CANDIDATES_POST_ENTAMER_DEMANDE_EMBAUCHE_SANS_PASSER_PAR_CANDIDAT_EXISTANT_PAR_MAIL_ENDPOINT, payload)
               .then(r => {
                    navigate(`/recrutement/demandes-embauche/${r.data.id}/details`)
               })
               .catch((e: AxiosError) => {
                    const error: IHttpErrorResponseModel = e.response?.data

                    // Set form errors
                    if (error?.code === CONST_HTTP_CUSTOM_CODE_FORM_VALIDATION_ERROR && error?.errors) {
                         for (const key in error.errors) helpers.setFieldError(key, error.errors[key])
                    }

                    // Set form global status and notify user using a toast
                    helpers.setStatus(error?.detail)
                    toast.error(error?.detail, { autoClose: false })

                    // Stop submit loader
                    helpers.setSubmitting(false)
               })
     }

     function handleFaireRemplirPar(values: IDemandeEmbaucheStartRequestModel) {
          const payload = {
               typeContrat:
                    values.sousTraitantOuSalarie === "SALARIE"
                         ? "TYPE_CONTRAT_SALARIE"
                         : (() => {
                                switch (values.typeSousTraitant) {
                                     case "STATUT_ST_FREELANCE":
                                          return "TYPE_CONTRAT_FREELANCE"
                                     case "STATUT_ST_SOCIETE_SERVICE":
                                          return "TYPE_CONTRAT_SOCIETE_SERVICE"
                                     case "STATUT_ST_PORTAGE":
                                          return "TYPE_CONTRAT_PORTAGE"
                                     default:
                                          throw new Error("typeSousTraitant unkown")
                                }
                           })(),
               civilite: values.saisieManuelleCivilite,
               nom: values.saisieManuelleNom,
               prenom: values.saisieManuellePrenom,
               entiteRattachement: values.saisieManuelleEntite,
               email: values.saisieManuelleEmail,
               nationalite: values.saisieManuelleNationalite,
               fournisseur: values.fournisseur,
               fournisseurResp: values.fournisseurResp,
          }

          swal.fire({
               icon: "warning",
               title: `Vous êtes sur le point d'envoyer un e-mail pour demander le remplissage des informations relatives à l'embauche`,
               text: "Voulez-vous procéder ?",
               showCancelButton: true,
               confirmButtonText: "Confirmer",
               cancelButtonText: "Annuler",
               focusConfirm: true,
               showLoaderOnConfirm: true,
               preConfirm: async () => {
                    return axios
                         .post<IConsultantModel>(CONST_API_RECRUITMENT_CANDIDATES_POST_DEMANDER_INFO_PRE_EMBAUCHE_SANS_PASSER_PAR_CANDIDAT_EXISTANT_PAR_MAIL_ENDPOINT, payload)
                         .then(() => {
                              toast.success(
                                   `Le dossier d'embauche de ${values.saisieManuellePrenom} a été créé avec succès. Un mail a été envoyé au responsable pour lui demander de remplir quelques informations.`
                              )
                              navigate(`/recrutement/demandes-embauche/listing`)
                         })
                         .catch(e => {
                              toast.error(e?.response?.data?.detail, { autoClose: false })
                         })
               },
               allowOutsideClick: () => !swal.isLoading(),
          }).then()
     }

     return (
          <>
               <div className={"d-flex justify-content-center"}>
                    <MyCard classNames={"w-100 w-lg-75 w-xl-60"}>
                         <MyCard.Body>
                              <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={schema}>
                                   {helpers => {
                                        return (
                                             <Form noValidate autoComplete="off">
                                                  {helpers.status && (
                                                       <MyAlert type={"danger"} classNames={"mb-4"}>
                                                            {helpers.status}
                                                       </MyAlert>
                                                  )}

                                                  {/* Field salarié / ST */}
                                                  <>
                                                       <div className={"fs-3 fw-bold required"}>Choisissez entre salarié et sous traitant</div>
                                                       <div className="separator separator-dashed mt-2 mb-4" />
                                                       <div className="d-flex flex-column gap-4">
                                                            <MyRadioWithLabelAndDescriptionField
                                                                 name={"sousTraitantOuSalarie"}
                                                                 value={"SALARIE"}
                                                                 description={"CDI C, CDI, CDD"}
                                                                 isChecked={helpers.values.sousTraitantOuSalarie === "SALARIE"}
                                                                 label={"Salarié"}
                                                                 iconFontAwesomeClassName={"user-tie"}
                                                                 color={"dark"}
                                                                 isInvalid={!!helpers.errors.sousTraitantOuSalarie}
                                                                 onChange={val => {
                                                                      helpers.setFieldValue("sousTraitantOuSalarie", val).then(() => {
                                                                           setDemanderInfoPreembaucheContratFieldValue(TYPE_CONTRAT_SALARIE)
                                                                      })
                                                                 }}
                                                            />
                                                            <MyRadioWithLabelAndDescriptionField
                                                                 name={"sousTraitantOuSalarie"}
                                                                 value={"ST"}
                                                                 description={"Freelance, portage salariale ou société de service"}
                                                                 isChecked={helpers.values.sousTraitantOuSalarie === "ST"}
                                                                 label={"Sous-traitant"}
                                                                 iconFontAwesomeClassName={"user-astronaut"}
                                                                 color={"dark"}
                                                                 isInvalid={!!helpers.errors.sousTraitantOuSalarie}
                                                                 onChange={val => {
                                                                      helpers.setFieldValue("sousTraitantOuSalarie", val).then(() => {
                                                                           helpers.setFieldValue("typeSousTraitant", undefined).then()
                                                                           helpers.setFieldValue("saisieManuelleOuAPartirCandidatExistant", undefined).then()
                                                                      })
                                                                 }}
                                                            />
                                                       </div>
                                                       {helpers.errors.sousTraitantOuSalarie && <div className={"text-danger mt-2"}>{helpers.errors.sousTraitantOuSalarie}</div>}
                                                  </>

                                                  {/* Cas Salarié*/}
                                                  {/* From candidat or from scratch */}
                                                  {helpers.values.sousTraitantOuSalarie === "SALARIE" && <></>}

                                                  {/* Cas Sous-traitant*/}
                                                  {helpers.values.sousTraitantOuSalarie === "ST" && (
                                                       <>
                                                            {/* Type de sous traitance */}
                                                            <div className={"mt-10"}>
                                                                 <div className={"fs-3 fw-bold required"}>Choisissez le type de sous-traitance</div>
                                                                 <div className="separator separator-dashed mt-2 mb-4" />
                                                                 <div className="d-flex flex-column gap-4 mb-4">
                                                                      <MyRadioWithLabelAndDescriptionField
                                                                           name={"typeSousTraitant"}
                                                                           value={CONST_CONSULTANT_STATUT_ST_FREELANCE}
                                                                           label={"Freelance"}
                                                                           isChecked={helpers.values.typeSousTraitant === CONST_CONSULTANT_STATUT_ST_FREELANCE}
                                                                           iconFontAwesomeClassName={"building-user"}
                                                                           color={"dark"}
                                                                           isInvalid={!!helpers.errors.typeSousTraitant}
                                                                           onChange={val => {
                                                                                helpers.setFieldValue("typeSousTraitant", val).then(() => {
                                                                                     helpers.setFieldValue("fournisseur", undefined).then()
                                                                                     helpers.setFieldValue("fournisseurResp", undefined).then()
                                                                                     helpers.setFieldValue("saisieManuelleOuAPartirCandidatExistant", undefined).then()

                                                                                     if (val === CONST_CONSULTANT_STATUT_ST_FREELANCE) {
                                                                                          setDemanderInfoPreembaucheContratFieldValue(TYPE_CONTRAT_FREELANCE)
                                                                                          setDemanderInfoPreembaucheFournisseurField(null)
                                                                                          setDemanderInfoPreembaucheFournisseurRespField(null)
                                                                                     }
                                                                                })
                                                                           }}
                                                                      />
                                                                      <MyRadioWithLabelAndDescriptionField
                                                                           name={"typeSousTraitant"}
                                                                           value={CONST_CONSULTANT_STATUT_ST_PORTAGE}
                                                                           label={"Société de portage"}
                                                                           isChecked={helpers.values.typeSousTraitant === CONST_CONSULTANT_STATUT_ST_PORTAGE}
                                                                           iconFontAwesomeClassName={"building"}
                                                                           color={"dark"}
                                                                           isInvalid={!!helpers.errors.typeSousTraitant}
                                                                           onChange={val => {
                                                                                helpers.setFieldValue("typeSousTraitant", val).then(() => {
                                                                                     setDemanderInfoPreembaucheFournisseurField(null)
                                                                                     setDemanderInfoPreembaucheFournisseurRespField(null)
                                                                                })
                                                                           }}
                                                                      />
                                                                      <MyRadioWithLabelAndDescriptionField
                                                                           name={"typeSousTraitant"}
                                                                           value={CONST_CONSULTANT_STATUT_ST_SOCIETE_SERVICE}
                                                                           label={"Société de service"}
                                                                           isChecked={helpers.values.typeSousTraitant === CONST_CONSULTANT_STATUT_ST_SOCIETE_SERVICE}
                                                                           iconFontAwesomeClassName={"building"}
                                                                           color={"dark"}
                                                                           isInvalid={!!helpers.errors.typeSousTraitant}
                                                                           onChange={val => {
                                                                                helpers.setFieldValue("typeSousTraitant", val).then(() => {
                                                                                     setDemanderInfoPreembaucheFournisseurField(null)
                                                                                     setDemanderInfoPreembaucheFournisseurRespField(null)
                                                                                })
                                                                           }}
                                                                      />
                                                                 </div>
                                                                 {helpers.errors.typeSousTraitant && <div className={"text-danger mt-2"}>{helpers.errors.typeSousTraitant}</div>}
                                                            </div>

                                                            {/* Fournisseur & contact fournisseur */}
                                                            {helpers.values.typeSousTraitant && (
                                                                 <div className={"mt-10"}>
                                                                      <div className={"fs-3 fw-bold required"}>Choisissez le fournisseur</div>
                                                                      <div className="separator separator-dashed mt-2 mb-4" />
                                                                      <div className="row mb-5">
                                                                           <label className="col-lg-4 col-form-label fw-bold fs-6 required">Fournisseur</label>

                                                                           <div className="col-lg-8">
                                                                                <MySelectGetAllDataFromServer
                                                                                     name={"fournisseur"}
                                                                                     value={helpers.values.fournisseur}
                                                                                     url={CONST_API_UTILS_GET_FOURNISSEURS_ENDPOINT}
                                                                                     method={"GET"}
                                                                                     payload={(() => {
                                                                                          if (helpers.values.typeSousTraitant === CONST_CONSULTANT_STATUT_ST_FREELANCE) {
                                                                                               return { types: [CONST_FOURNISSEUR_TYPE_FREELANCE] }
                                                                                          } else if (helpers.values.typeSousTraitant === CONST_CONSULTANT_STATUT_ST_PORTAGE) {
                                                                                               return { types: [CONST_FOURNISSEUR_TYPE_SOCIETE_PORTAGE] }
                                                                                          } else {
                                                                                               // Société de service
                                                                                               return { types: [CONST_FOURNISSEUR_TYPE_SOCIETE_SERVICE] }
                                                                                          }
                                                                                     })()}
                                                                                     isInvalid={!!helpers.errors.fournisseur}
                                                                                     onChange={(val, item) => {
                                                                                          helpers.setFieldValue("fournisseur", val)
                                                                                          setDemanderInfoPreembaucheFournisseurField({
                                                                                               value: val,
                                                                                               label: item.label,
                                                                                          })
                                                                                     }}
                                                                                />
                                                                                <div className="d-flex justify-content-between flex-row-reverse mt-1">
                                                                                     {helpers.errors.fournisseur && (
                                                                                          <div className={"text-danger"}>{helpers.errors.fournisseur}</div>
                                                                                     )}
                                                                                     <div
                                                                                          className="text-primary fw-bold cursor-pointer"
                                                                                          onClick={() => setIsAddingFournisseur(true)}
                                                                                     >
                                                                                          Ajouter <i className={"fas fa-plus-circle text-primary"} />
                                                                                     </div>
                                                                                </div>
                                                                           </div>
                                                                      </div>

                                                                      {helpers.values.fournisseur && (
                                                                           <div className="row mb-5">
                                                                                <label className="col-lg-4 col-form-label fw-bold fs-6 required">Contact fournisseur</label>

                                                                                <div className="col-lg-8">
                                                                                     <MySelectGetAllDataFromServer
                                                                                          name={"fournisseurResp"}
                                                                                          value={helpers.values.fournisseurResp}
                                                                                          url={CONST_API_UTILS_GET_FOURNISSEURS_RESP_ENDPOINT}
                                                                                          method={"GET"}
                                                                                          payload={{ fournisseur: helpers.values.fournisseur }}
                                                                                          isInvalid={!!helpers.errors.fournisseurResp}
                                                                                          onChange={(val, item) => {
                                                                                               helpers.setFieldValue("fournisseurResp", val).then(() => {
                                                                                                    setDemanderInfoPreembaucheFournisseurRespField({
                                                                                                         value: val,
                                                                                                         label: item?.label,
                                                                                                    })
                                                                                               })
                                                                                          }}
                                                                                     />
                                                                                     <div className="d-flex justify-content-between flex-row-reverse mt-1">
                                                                                          {helpers.errors.fournisseurResp && (
                                                                                               <div className={"text-danger"}>{helpers.errors.fournisseurResp}</div>
                                                                                          )}
                                                                                          <div
                                                                                               className="text-primary fw-bold cursor-pointer"
                                                                                               onClick={() => setIsAddingFournisseurResp(true)}
                                                                                          >
                                                                                               Ajouter <i className={"fas fa-plus-circle text-primary"} />
                                                                                          </div>
                                                                                     </div>
                                                                                </div>
                                                                           </div>
                                                                      )}
                                                                 </div>
                                                            )}
                                                       </>
                                                  )}

                                                  {/* Cas salarié or sous traitant freelance */}
                                                  {(helpers.values.sousTraitantOuSalarie === "SALARIE" ||
                                                       (helpers.values.sousTraitantOuSalarie === "ST" &&
                                                            helpers.values.typeSousTraitant === CONST_CONSULTANT_STATUT_ST_FREELANCE &&
                                                            helpers.values.fournisseur &&
                                                            helpers.values.fournisseurResp)) && (
                                                       <>
                                                            <div className={"mt-10"}>
                                                                 <div className={"fs-3 fw-bold required"}>Saisie manuelle ou partir d’un candidat existant</div>
                                                                 <div className="separator separator-dashed mt-2 mb-4" />
                                                                 <div className="d-flex flex-column gap-4">
                                                                      <MyRadioWithLabelAndDescriptionField
                                                                           name={"saisieManuelleOuAPartirCandidatExistant"}
                                                                           value={"EXISTANT"}
                                                                           label={"Candidat existant"}
                                                                           description={"Remplir la demande d'embauche à partir des informations d'un profil candidat existant"}
                                                                           isChecked={helpers.values.saisieManuelleOuAPartirCandidatExistant === "EXISTANT"}
                                                                           iconFontAwesomeClassName={"user-check"}
                                                                           color={"dark"}
                                                                           isInvalid={!!helpers.errors.saisieManuelleOuAPartirCandidatExistant}
                                                                           onChange={val => helpers.setFieldValue("saisieManuelleOuAPartirCandidatExistant", val)}
                                                                      />
                                                                      <MyRadioWithLabelAndDescriptionField
                                                                           name={"saisieManuelleOuAPartirCandidatExistant"}
                                                                           value={"MANUELLE"}
                                                                           label={"Saisie manuelle"}
                                                                           description={"Saisissez les informations à partir de zéro"}
                                                                           isChecked={helpers.values.saisieManuelleOuAPartirCandidatExistant === "MANUELLE"}
                                                                           iconFontAwesomeClassName={"file-alt"}
                                                                           color={"dark"}
                                                                           isInvalid={!!helpers.errors.saisieManuelleOuAPartirCandidatExistant}
                                                                           onChange={val => helpers.setFieldValue("saisieManuelleOuAPartirCandidatExistant", val)}
                                                                      />
                                                                 </div>
                                                                 {helpers.errors.saisieManuelleOuAPartirCandidatExistant && (
                                                                      <div className={"text-danger mt-2"}>{helpers.errors.saisieManuelleOuAPartirCandidatExistant}</div>
                                                                 )}
                                                            </div>
                                                       </>
                                                  )}

                                                  {/* Saisir les info du consultant (Salarié avec saisie manuelle / ST freelance + saisie manuelle + fournisseur / ST non freelance + fournisseur + fournisseeur resp )*/}
                                                  {
                                                       /* Salarié avec saisie manuelle */
                                                       ((helpers.values.sousTraitantOuSalarie === "SALARIE" &&
                                                            helpers.values.saisieManuelleOuAPartirCandidatExistant === "MANUELLE") ||
                                                            (((helpers.values.sousTraitantOuSalarie === "ST" &&
                                                                 helpers.values.typeSousTraitant === CONST_CONSULTANT_STATUT_ST_FREELANCE &&
                                                                 helpers.values.saisieManuelleOuAPartirCandidatExistant === "MANUELLE") ||
                                                                 (helpers.values.sousTraitantOuSalarie === "ST" &&
                                                                      helpers.values.typeSousTraitant !== CONST_CONSULTANT_STATUT_ST_FREELANCE)) &&
                                                                 helpers.values.fournisseur &&
                                                                 helpers.values.fournisseurResp)) && (
                                                            <div className={"mt-10"}>
                                                                 <div className={"fs-3 fw-bold required"}>
                                                                      Informations
                                                                      {helpers.values.saisieManuelleCivilite === CONST_CIVILITE_MME
                                                                           ? " de la future collaboratrice"
                                                                           : " du futur collaborateur"}
                                                                 </div>
                                                                 <div className="separator separator-dashed mt-2 mb-4" />

                                                                 {/* Civilité */}
                                                                 <div className="row mb-5">
                                                                      <label className="col-lg-4 col-form-label fw-bold fs-6 required">Civilité</label>
                                                                      <div className="col-lg-8">
                                                                           <MySelectField
                                                                                name={"saisieManuelleCivilite"}
                                                                                options={CONST_CIVILITES_OPTIONS_FOR_SELECT2.filter(c => c.value !== CONST_CIVILITE_NON_RENSEIGNEE)}
                                                                                value={helpers.values.saisieManuelleCivilite}
                                                                                isInvalid={!!helpers.errors.saisieManuelleCivilite}
                                                                                onChange={val => helpers.setFieldValue("saisieManuelleCivilite", val)}
                                                                           />
                                                                           {helpers.errors.saisieManuelleCivilite && (
                                                                                <div className={"text-danger"}>{helpers.errors.saisieManuelleCivilite}</div>
                                                                           )}
                                                                      </div>
                                                                 </div>

                                                                 {/* Nom & Prénom*/}
                                                                 <div className={"row mb-5"}>
                                                                      {/* Nom & prénom */}
                                                                      <label className="col-lg-4 col-form-label fw-bold fs-6 required">Prénom NOM</label>

                                                                      <div className="col-lg-8">
                                                                           <div className="row">
                                                                                <div className="col-lg-6 mb-2 mb-lg-0">
                                                                                     <MyInputField
                                                                                          name={"saisieManuellePrenom"}
                                                                                          value={helpers.values.saisieManuellePrenom}
                                                                                          isInvalid={!!helpers.errors.saisieManuellePrenom}
                                                                                          placeholder={"Prénom"}
                                                                                          onChange={val => helpers.setFieldValue("saisieManuellePrenom", val)}
                                                                                     />
                                                                                     {helpers.errors.saisieManuellePrenom && (
                                                                                          <div className={"text-danger"}>{helpers.errors.saisieManuellePrenom}</div>
                                                                                     )}
                                                                                </div>
                                                                                <div className="col-lg-6">
                                                                                     <MyInputField
                                                                                          name={"saisieManuelleNom"}
                                                                                          value={helpers.values.saisieManuelleNom}
                                                                                          isInvalid={!!helpers.errors.saisieManuelleNom}
                                                                                          placeholder={"Nom"}
                                                                                          onChange={val => helpers.setFieldValue("saisieManuelleNom", val)}
                                                                                     />
                                                                                     {helpers.errors.saisieManuelleNom && (
                                                                                          <div className={"text-danger"}>{helpers.errors.saisieManuelleNom}</div>
                                                                                     )}
                                                                                </div>
                                                                           </div>
                                                                      </div>
                                                                 </div>

                                                                 {/* Email (uniquement salarié et ST freelance) */}
                                                                 {(helpers.values.sousTraitantOuSalarie === "SALARIE" ||
                                                                      helpers.values.typeSousTraitant === CONST_CONSULTANT_STATUT_ST_FREELANCE) && (
                                                                      <div className="row mb-5">
                                                                           <label className="col-lg-4 col-form-label fw-bold fs-6 required">Email</label>
                                                                           <div className="col-lg-8">
                                                                                <MyInputField
                                                                                     name={"saisieManuelleEmail"}
                                                                                     value={helpers.values.saisieManuelleEmail}
                                                                                     isInvalid={!!helpers.errors.saisieManuelleEmail}
                                                                                     placeholder={"Email"}
                                                                                     onChange={value => helpers.setFieldValue("saisieManuelleEmail", value)}
                                                                                />
                                                                                {helpers.errors.saisieManuelleEmail && (
                                                                                     <div className="text-danger">{helpers.errors.saisieManuelleEmail}</div>
                                                                                )}
                                                                           </div>
                                                                      </div>
                                                                 )}

                                                                 {/* Field entite */}
                                                                 <div className="row mb-5">
                                                                      <label className="col-lg-4 col-form-label fw-bold fs-6 required">Entité de rattachement</label>

                                                                      <div className="col-lg-8">
                                                                           <MySelectGetAllDataFromServer
                                                                                name={"saisieManuelleEntite"}
                                                                                url={CONST_API_UTILS_GET_ENTITES_ENDPOINT}
                                                                                value={helpers.values.saisieManuelleEntite}
                                                                                onChange={val => helpers.setFieldValue("saisieManuelleEntite", val)}
                                                                                isInvalid={!!helpers.errors.saisieManuelleEntite}
                                                                           />
                                                                           {helpers.errors.saisieManuelleEntite && (
                                                                                <div className="text-danger">{helpers.errors.saisieManuelleEntite}</div>
                                                                           )}
                                                                      </div>
                                                                 </div>

                                                                 {/* Field nationalité */}
                                                                 {helpers.values.sousTraitantOuSalarie === "SALARIE" && (
                                                                      <>
                                                                           <MyAlert type={"primary"} classNames={"mb-5"} isDismissible={false}>
                                                                                <>
                                                                                     Si ce futur collaborateur possède plusieurs nationalités dont une française, veuillez
                                                                                     sélectionner la nationalité française.
                                                                                </>
                                                                           </MyAlert>
                                                                           <div className="row mb-5">
                                                                                <label className="col-lg-4 col-form-label fw-bold fs-6 required">Nationalité</label>

                                                                                <div className="col-lg-8">
                                                                                     <MySelectField
                                                                                          name={"saisieManuelleNationalite"}
                                                                                          options={[
                                                                                               { label: "Française", value: "NATIONALITE_FR" },
                                                                                               { label: "Etrangère", value: "NATIONALITE_ETRANGERE" },
                                                                                          ]}
                                                                                          value={helpers.values.saisieManuelleNationalite}
                                                                                          onChange={val => helpers.setFieldValue("saisieManuelleNationalite", val)}
                                                                                          isInvalid={!!helpers.errors.saisieManuelleNationalite}
                                                                                     />
                                                                                     {helpers.errors.saisieManuelleNationalite && (
                                                                                          <div className="text-danger">{helpers.errors.saisieManuelleNationalite}</div>
                                                                                     )}
                                                                                </div>
                                                                           </div>
                                                                      </>
                                                                 )}
                                                            </div>
                                                       )
                                                  }

                                                  {((helpers.values.sousTraitantOuSalarie === "SALARIE" && helpers.values.saisieManuelleOuAPartirCandidatExistant) ||
                                                       (helpers.values.sousTraitantOuSalarie === "ST" &&
                                                            helpers.values.typeSousTraitant &&
                                                            helpers.values.fournisseur &&
                                                            helpers.values.fournisseurResp &&
                                                            ((helpers.values.typeSousTraitant === CONST_CONSULTANT_STATUT_ST_FREELANCE &&
                                                                 helpers.values.saisieManuelleOuAPartirCandidatExistant) ||
                                                                 helpers.values.typeSousTraitant !== CONST_CONSULTANT_STATUT_ST_FREELANCE))) && (
                                                       <>
                                                            <div className="separator my-5" />
                                                            <div className={"d-flex justify-content-end gap-5"}>
                                                                 {((helpers.values.sousTraitantOuSalarie === "SALARIE" &&
                                                                      helpers.values.saisieManuelleOuAPartirCandidatExistant === "MANUELLE") ||
                                                                      (helpers.values.sousTraitantOuSalarie === "ST" &&
                                                                           ((helpers.values.typeSousTraitant === CONST_CONSULTANT_STATUT_ST_FREELANCE &&
                                                                                helpers.values.saisieManuelleOuAPartirCandidatExistant === "MANUELLE") ||
                                                                                helpers.values.typeSousTraitant !== CONST_CONSULTANT_STATUT_ST_FREELANCE))) && (
                                                                      <MyTooltip
                                                                           title={
                                                                                "Envoyer un mail afin de demander le remplissage des informations personnelles et des documents d'embauche dans son espace Talent."
                                                                           }
                                                                      >
                                                                           <button
                                                                                className={"btn btn-sm btn-outline btn-outline-primary d-inline-flex align-items-center"}
                                                                                type={"button"}
                                                                                onClick={() => handleFaireRemplirPar(helpers.values)}
                                                                                disabled={helpers.isSubmitting || !helpers.isValid}
                                                                           >
                                                                                Faire remplir par{" "}
                                                                                {helpers.values.sousTraitantOuSalarie === "ST" && helpers.values.fournisseurResp ? (
                                                                                     <>{demanderInfoPreembaucheFournisseurRespField?.label}</>
                                                                                ) : (
                                                                                     <>
                                                                                          {helpers.values.saisieManuelleCivilite === CONST_CIVILITE_MME
                                                                                               ? "la candidate"
                                                                                               : "le candidat"}{" "}
                                                                                     </>
                                                                                )}
                                                                                <i className={"fas fa-envelope ms-2"} />
                                                                           </button>
                                                                      </MyTooltip>
                                                                 )}
                                                                 <MyTooltip
                                                                      title={
                                                                           helpers.values.saisieManuelleOuAPartirCandidatExistant === "EXISTANT"
                                                                                ? "Choisissez un profil candidat existant dans la base"
                                                                                : "Tout remplir par soi-même"
                                                                      }
                                                                 >
                                                                      <button
                                                                           className={"btn btn-sm btn-primary d-inline-flex align-items-center"}
                                                                           type={"submit"}
                                                                           disabled={helpers.isSubmitting}
                                                                           onClick={() => {
                                                                                if (helpers.values.saisieManuelleOuAPartirCandidatExistant === "EXISTANT") {
                                                                                     toast.info(
                                                                                          "Veuillez rechercher votre candidat. Cliquez dessus puis utiliser le bouton «Recruter» pour démarrer l'embauche.",
                                                                                          { autoClose: false, position: "top-right" }
                                                                                     )
                                                                                     let params = `?contratFieldValue=${demanderInfoPreembaucheContratFieldValue}&redirectionUrlAfterSubmit=/recrutement/demandes-embauche/listing`
                                                                                     if (
                                                                                          helpers.values.sousTraitantOuSalarie === "ST" &&
                                                                                          helpers.values.typeSousTraitant === CONST_CONSULTANT_STATUT_ST_FREELANCE
                                                                                     ) {
                                                                                          params =
                                                                                               params +
                                                                                               `&fournisseurFieldValue=${demanderInfoPreembaucheFournisseurField?.value}&fournisseurFieldLabel=${demanderInfoPreembaucheFournisseurField?.label}&fournisseurRespFieldValue=${demanderInfoPreembaucheFournisseurRespField?.value}&fournisseurRespFieldLabel=${demanderInfoPreembaucheFournisseurRespField?.label}`
                                                                                     }

                                                                                     navigate(`/recrutement/candidats/search/results${params}`)
                                                                                }
                                                                           }}
                                                                      >
                                                                           {!helpers.isSubmitting ? (
                                                                                helpers.values.saisieManuelleOuAPartirCandidatExistant === "EXISTANT" ? (
                                                                                     <div>
                                                                                          Recherche le candidat <i className={"fas fa-search ms-1"} />
                                                                                     </div>
                                                                                ) : (
                                                                                     <div>
                                                                                          Démarrer l'embauche <i className={"fas fa-play ms-1"} />
                                                                                     </div>
                                                                                )
                                                                           ) : (
                                                                                <MySimpleSpinner size={"sm"} />
                                                                           )}
                                                                      </button>
                                                                 </MyTooltip>
                                                            </div>
                                                       </>
                                                  )}

                                                  {/* Modal add fournisseur */}
                                                  {isAddingFournisseur && (
                                                       <MyModal title={<>Nouveau fournisseur</>} show={true} handleClose={() => setIsAddingFournisseur(false)}>
                                                            <AddFournisseurForm
                                                                 callback={() => setIsAddingFournisseur(false)}
                                                                 defaultTypeFieldValue={
                                                                      helpers.values.typeSousTraitant === "STATUT_ST_SOCIETE_SERVICE"
                                                                           ? CONST_FOURNISSEUR_TYPE_SOCIETE_SERVICE
                                                                           : helpers.values.typeSousTraitant === "STATUT_ST_PORTAGE"
                                                                           ? CONST_FOURNISSEUR_TYPE_SOCIETE_PORTAGE
                                                                           : CONST_FOURNISSEUR_TYPE_FREELANCE
                                                                 }
                                                            />
                                                       </MyModal>
                                                  )}

                                                  {/* Modal add resp client */}
                                                  {isAddingFournisseurResp && (
                                                       <MyModal title={<>Nouveau responsable fournisseur</>} show={true} handleClose={() => setIsAddingFournisseurResp(false)}>
                                                            <AddResponsableFournisseurForm
                                                                 onSubmitCallback={() => {
                                                                      toast.success("Le responsable a bien été ajouté")
                                                                      setIsAddingFournisseurResp(false)
                                                                 }}
                                                                 defaultFournisseurFieldLabel={demanderInfoPreembaucheFournisseurField?.label}
                                                                 defaultFournisseurFieldValue={demanderInfoPreembaucheFournisseurField?.value}
                                                                 isFournisseurFieldReadOnly={true}
                                                            />
                                                       </MyModal>
                                                  )}
                                             </Form>
                                        )
                                   }}
                              </Formik>
                         </MyCard.Body>
                    </MyCard>
               </div>
          </>
     )
}

interface IDemandeEmbaucheStartRequestModel {
     sousTraitantOuSalarie?: "SALARIE" | "ST"
     typeSousTraitant?: typeof CONST_CONSULTANT_STATUT_ST_PORTAGE | typeof CONST_CONSULTANT_STATUT_ST_SOCIETE_SERVICE | typeof CONST_CONSULTANT_STATUT_ST_FREELANCE
     fournisseur?: number
     fournisseurResp?: number
     saisieManuelleOuAPartirCandidatExistant?: "EXISTANT" | "MANUELLE"
     saisieManuelleCivilite?: string
     saisieManuellePrenom?: string
     saisieManuelleNom?: string
     saisieManuelleEmail?: string
     saisieManuelleEntite?: number
     saisieManuelleNationalite?: string
}

export default DemandeEmbaucheStart
