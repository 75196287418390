import { useDemandeEmbaucheDetailsContext } from "./DemandeEmbaucheDetails"
import { CONST_CONSULTANT_CONTRAT_ST, CONST_CONSULTANT_ETAT_EN_COURS_EMBAUCHE_POUR_MISSION, CONST_CONSULTANT_ETAT_EN_COURS_EMBAUCHE_SANS_MISSION } from "@common-constants/*"

export const DemandeEmbaucheDetailsNavigationMenu = () => {
     const context = useDemandeEmbaucheDetailsContext()

     // "perso" | "contract" | "client" | "doc" | "recap"
     return (
          <div className="stepper stepper-links mb-5">
               <div className="stepper-nav">
                    {/* begin::Step Info perso */}
                    <div
                         className={`stepper-item m-sm-5 m-4 ${context.navigationStep === "perso" && "current"} ${
                              ["contract", "client", "odm", "doc", "recap"].includes(context.navigationStep) && "completed"
                         }  ${!["perso"].includes(context.navigationStep) && "cursor-pointer"}`}
                         onClick={() => !["perso"].includes(context.navigationStep) && context.setNavigationStep("perso")}
                    >
                         <h3 className="stepper-title">
                              <span className={"d-none d-sm-block"}>
                                   <span className={`fas ${context.consultant.contrat === "CONTRAT_ST" ? "fa-user-tie" : "fa-user"} me-1`}></span>{" "}
                                   {context.consultant.contrat === "CONTRAT_ST" ? "Fournisseur" : "Info. perso."}
                              </span>
                              <span className={"d-block d-sm-none fas fa-user"} />
                         </h3>
                    </div>
                    {/* end::Step Info perso */}

                    {/* begin::Step Info contract. */}
                    <div
                         className={`stepper-item m-sm-5 m-4 ${context.navigationStep === "contract" && "current"} ${
                              ["client", "odm", "doc", "recap"].includes(context.navigationStep) && "completed"
                         }  ${!["contract"].includes(context.navigationStep) && "cursor-pointer"}`}
                         onClick={() => !["contract"].includes(context.navigationStep) && context.setNavigationStep("contract")}
                    >
                         <h3 className="stepper-title">
                              <span className={"d-none d-sm-block"}>
                                   <span className={"fas fa-file-contract me-1"}></span> Info. contract.
                              </span>
                              <span className={"d-block d-sm-none fas fa-file-contrat"} />
                         </h3>
                    </div>
                    {/* end::Step Info contract. */}

                    {(context.consultant.etat === null ||
                         (context.consultant.contrat !== CONST_CONSULTANT_CONTRAT_ST && context.consultant.etat === CONST_CONSULTANT_ETAT_EN_COURS_EMBAUCHE_POUR_MISSION) ||
                         context.consultant.contrat === CONST_CONSULTANT_CONTRAT_ST) && (
                         <>
                              {/* begin::Step Info client. */}
                              <div
                                   className={`stepper-item m-sm-5 m-4 ${context.navigationStep === "client" && "current"} ${
                                        ["odm", "doc", "recap"].includes(context.navigationStep) && "completed"
                                   }  ${!["client"].includes(context.navigationStep) && "cursor-pointer"}`}
                                   onClick={() => {
                                        if (context.consultant.dateEmbauche) {
                                             !["client"].includes(context.navigationStep) && context.setNavigationStep("client")
                                        }
                                   }}
                              >
                                   <h3 className="stepper-title">
                                        <span className={"d-none d-sm-block"}>
                                             <span className={"fas fa-building me-1"}></span> Info. client
                                        </span>
                                        <span className={"d-block d-sm-none fas fa-building"}></span>
                                   </h3>
                              </div>
                              {/* end::Step Info client. */}
                         </>
                    )}

                    {context.consultant.contrat !== CONST_CONSULTANT_CONTRAT_ST &&
                         (context.consultant.etat === null || context.consultant.etat === CONST_CONSULTANT_ETAT_EN_COURS_EMBAUCHE_POUR_MISSION) && (
                              <>
                                   {/* begin::Step ODM. */}
                                   <div
                                        className={`stepper-item m-sm-5 m-4 ${context.navigationStep === "odm" && "current"} ${
                                             ["doc", "recap"].includes(context.navigationStep) && "completed"
                                        }  ${!["odm"].includes(context.navigationStep) && "cursor-pointer"}`}
                                        onClick={() => {
                                             if (context.consultant.clientPropal?.id) {
                                                  !["odm"].includes(context.navigationStep) && context.setNavigationStep("odm")
                                             }
                                        }}
                                   >
                                        <h3 className="stepper-title">
                                             <span className={"d-none d-sm-block"}>
                                                  <span className={"fas fa-file-alt me-1"}></span> ODM
                                             </span>
                                             <span className={"d-block d-sm-none fas fa-file-alt"} />
                                        </h3>
                                   </div>
                                   {/* end::Step ODM. */}
                              </>
                         )}

                    {/* begin::Step Documents */}
                    <div
                         className={`stepper-item m-sm-5 m-4 ${context.navigationStep === "doc" && "current"} ${["recap"].includes(context.navigationStep) && "completed"}  ${
                              !["doc"].includes(context.navigationStep) && "cursor-pointer"
                         }`}
                         onClick={() => {
                              if (
                                   (context.consultant.clientPropal?.id && context.consultant.etat === CONST_CONSULTANT_ETAT_EN_COURS_EMBAUCHE_POUR_MISSION) ||
                                   (context.consultant.etat === CONST_CONSULTANT_ETAT_EN_COURS_EMBAUCHE_SANS_MISSION && context.consultant.coutBrutNonCharge)
                              ) {
                                   !["doc"].includes(context.navigationStep) && context.setNavigationStep("doc")
                              }
                         }}
                    >
                         <h3 className="stepper-title">
                              <span className={"d-none d-sm-block"}>
                                   <span className={"fas fa-folder-open me-1"}></span> Documents
                              </span>
                              <span className={"d-block d-sm-none fas fa-folder-open"}></span>
                         </h3>
                    </div>
                    {/* end::Step Documents */}
               </div>
          </div>
     )
}

export default DemandeEmbaucheDetailsNavigationMenu
