import { PageTitle } from "@common-metronic/layout/core"
import { Route, Routes } from "react-router-dom"
import FournisseurListing from "../listing/FournisseurListing"
import { ROUTING_RELATIVE_PATH_FOURNISSEUR_CREATE, ROUTING_RELATIVE_PATH_FOURNISSEUR_LISTING } from "./routing-endpoints"
import { ROUTING_BREADCRUMBS_FOURNISSEURS } from "./routing-breadcrumbs"
import FournisseurCreate from "../create/FournisseurCreate"

const FournisseurRouting = () => {
     return (
          <Routes>
               <Route
                    index
                    path={ROUTING_RELATIVE_PATH_FOURNISSEUR_LISTING}
                    element={
                         <>
                              <PageTitle breadcrumbs={ROUTING_BREADCRUMBS_FOURNISSEURS}>Listing</PageTitle>
                              <FournisseurListing />
                         </>
                    }
               />

               <Route
                    path={ROUTING_RELATIVE_PATH_FOURNISSEUR_CREATE}
                    element={
                         <>
                              <PageTitle breadcrumbs={ROUTING_BREADCRUMBS_FOURNISSEURS}>Create</PageTitle>
                              <FournisseurCreate />
                         </>
                    }
               />
          </Routes>
     )
}

export default FournisseurRouting
