import axios from "axios"
import {
     CONST_API_BUSINESS_CONSULTANT_POST_SUIVI_AFFECTER_MISSION_TO_SALARIE_EMBAUCHE_SUR_PROFIL_ENDPOINT,
     CONST_API_BUSINESS_CONSULTANT_POST_SUIVI_CHANGEMENT_AJOUT_MACHINE_ENDPOINT,
     CONST_API_BUSINESS_CONSULTANT_POST_SUIVI_CHANGEMENT_COEFF_ENDPOINT,
     CONST_API_BUSINESS_CONSULTANT_POST_SUIVI_CHANGEMENT_ENTITE_FACTURATION_ENDPOINT,
     CONST_API_BUSINESS_CONSULTANT_POST_SUIVI_CHANGEMENT_ENTITE_RATTACHEMENT_ENDPOINT,
     CONST_API_BUSINESS_CONSULTANT_POST_SUIVI_CHANGEMENT_HEURES_SUPP_ENDPOINT,
     CONST_API_BUSINESS_CONSULTANT_POST_SUIVI_CHANGEMENT_MANAGER_ENDPOINT,
     CONST_API_BUSINESS_CONSULTANT_POST_SUIVI_CHANGEMENT_MISSION_NON_SALARIE_ENDPOINT,
     CONST_API_BUSINESS_CONSULTANT_POST_SUIVI_CHANGEMENT_MISSION_SALARIE_ENDPOINT,
     CONST_API_BUSINESS_CONSULTANT_POST_SUIVI_CHANGEMENT_POSITION_ENDPOINT,
     CONST_API_BUSINESS_CONSULTANT_POST_SUIVI_CHANGEMENT_SALAIRE_ENDPOINT,
     CONST_API_BUSINESS_CONSULTANT_POST_SUIVI_CHANGEMENT_STATUT_ENDPOINT,
     CONST_API_BUSINESS_CONSULTANT_POST_SUIVI_CHANGEMENT_TAUX_ACHAT_ENDPOINT,
     CONST_API_BUSINESS_CONSULTANT_POST_SUIVI_COMMENTAIRE_ENDPOINT,
     CONST_API_BUSINESS_CONSULTANT_POST_SUIVI_DELEGATION_ADMINISTRATIVE_ENDPOINT,
     CONST_API_BUSINESS_CONSULTANT_POST_SUIVI_EVOLUTION_TAUX_VENTE_ENDPOINT,
     CONST_API_BUSINESS_CONSULTANT_POST_SUIVI_INTERCONTRAT_ENDPOINT,
     CONST_API_BUSINESS_CONSULTANT_POST_SUIVI_PRIME_ENDPOINT,
     CONST_API_BUSINESS_CONSULTANT_POST_SUIVI_PROLONGEMENT_MISSION_NON_SALARIE_ENDPOINT,
     CONST_API_BUSINESS_CONSULTANT_POST_SUIVI_PROLONGEMENT_MISSION_SALARIE_ENDPOINT,
     CONST_API_BUSINESS_CONSULTANT_POST_SUIVI_REPORTING_MISSION_ENDPOINT,
     CONST_API_BUSINESS_CONSULTANT_POST_SUIVI_REPRISE_MISSION_ENDPOINT,
     CONST_API_BUSINESS_CONSULTANT_POST_SUIVI_SORTIE_EFFECTIFS_ENDPOINT,
     CONST_API_BUSINESS_CONSULTANT_POST_SUIVI_SUPPRESSION_MACHINE_ENDPOINT,
     CONST_API_BUSINESS_CONSULTANTS_SUIVIS_ENDPOINT,
     CONST_API_UTILS_GET_PRE_FILL_FORM_DATA_POST_PROLONGEMENT_MISSION_ENDPOINT,
} from "../../../../../endpoints"
import {
     IConsultantSuiviChangementCoeffRequestModel,
     IConsultantSuiviChangementEntiteFacturationRequestModel,
     IConsultantSuiviChangementEntiteRattachementRequestModel,
     IConsultantSuiviChangementHeuresSuppRequestModel,
     IConsultantSuiviChangementManagerRequestModel,
     IConsultantSuiviChangementMissionSalarieRequestModel,
     IConsultantSuiviChangementMissionSousTraitantRequestModel,
     IConsultantSuiviChangementPositionRequestModel,
     IConsultantSuiviChangementSalaireRequestModel,
     IConsultantSuiviChangementStatutRequestModel,
     IConsultantSuiviChangementTauxAchatRequestModel,
     IConsultantSuiviCommentaireRequestModel,
     IConsultantSuiviDelegationAdministrativeItemRequestModel,
     IConsultantSuiviEvolutionTjmRequestModel,
     IConsultantSuiviPrimeRequestModel,
     IConsultantSuiviProlongementMissionNonSalarieRequestModel,
     IConsultantSuiviProlongementMissionSalarieRequestModel,
     IConsultantSuiviReportingRequestModel,
     IConsultantSuiviSalarieChangementEtatToIntercontratRequestModel,
     IConsultantSuiviSalarieRepriseMissionRequestModel,
     IConsultantSuiviSortieDesEffectifsRequestModel,
     IConsultantSuiviSuppressionMachineRequestModel,
     IGetPrefillFormDataProlongementMissionRequestModel,
} from "./_models"
import { IEntiteModel } from "@common-models/*"
import { IConsultantsSuivisListingRequestModel, IConsultantsSuivisListingResponseModel } from "../listing/core/_models"

export function consultantsSuivisListingRequest(filters: IConsultantsSuivisListingRequestModel) {
     return axios.post<IConsultantsSuivisListingResponseModel>(CONST_API_BUSINESS_CONSULTANTS_SUIVIS_ENDPOINT, filters)
}

export function consultantSuiviSalarieEmbaucheSurProfilAffectationMissionRequest(consultant_ID: number, payload: IConsultantSuiviSalarieRepriseMissionRequestModel) {
     const formData = new FormData()

     for (const [key, value] of Object.entries(payload)) {
          if (key === "ordreMission" && value) {
               formData.append("ordreMission", value)
          } else {
               formData.append(`data[${key}]`, value)
          }
     }

     return axios.post<{ ordreDeMissionGoogleDriveID: string }>(
          CONST_API_BUSINESS_CONSULTANT_POST_SUIVI_AFFECTER_MISSION_TO_SALARIE_EMBAUCHE_SUR_PROFIL_ENDPOINT(consultant_ID),
          formData
     )
}

export function consultantSuiviChangementMissionSousTraitantRequest(consultant_ID: number, payload: IConsultantSuiviChangementMissionSousTraitantRequestModel) {
     return axios.post<{ ordreDeMissionGoogleDriveID: string }>(CONST_API_BUSINESS_CONSULTANT_POST_SUIVI_CHANGEMENT_MISSION_NON_SALARIE_ENDPOINT(consultant_ID), payload)
}

export function consultantSuiviChangementMissionSalarieRequest(consultant_ID: number, payload: IConsultantSuiviChangementMissionSalarieRequestModel) {
     const formData = new FormData()

     for (const [key, value] of Object.entries(payload)) {
          if (key === "ordreMission" && value) {
               formData.append("ordreMission", value)
          } else {
               formData.append(`data[${key}]`, value)
          }
     }

     return axios.post<{ ordreDeMissionGoogleDriveID: string }>(CONST_API_BUSINESS_CONSULTANT_POST_SUIVI_CHANGEMENT_MISSION_SALARIE_ENDPOINT(consultant_ID), formData)
}

export function consultantSuiviEvolutionTjmRequest(consultant_ID: number, payload: IConsultantSuiviEvolutionTjmRequestModel) {
     const formData = new FormData()

     for (const [key, value] of Object.entries(payload)) {
          if (key === "ptf" && value) {
               formData.append("ptf", value)
          } else {
               formData.append(`data[${key}]`, value)
          }
     }

     return axios.post<{ ptfGoogleDriveID: string }>(CONST_API_BUSINESS_CONSULTANT_POST_SUIVI_EVOLUTION_TAUX_VENTE_ENDPOINT(consultant_ID), formData)
}

export function consultantSuiviReportingRequest(consultant_ID: number, payload: IConsultantSuiviReportingRequestModel) {
     const formData = new FormData()
     if (payload.type) formData.append("data[type]", payload.type)
     if (payload.documentUploadedOrGenerated) formData.append("data[documentUploadedOrGenerated]", payload.documentUploadedOrGenerated)
     if (payload.document) formData.append("document", payload.document)
     return axios.post<{ reportingGoogleDriveID: string }>(CONST_API_BUSINESS_CONSULTANT_POST_SUIVI_REPORTING_MISSION_ENDPOINT(consultant_ID), formData)
}

export function consultantSuiviSortieDesEffectifsRequest(consultant_ID: number, payload: IConsultantSuiviSortieDesEffectifsRequestModel) {
     return axios.post(CONST_API_BUSINESS_CONSULTANT_POST_SUIVI_SORTIE_EFFECTIFS_ENDPOINT(consultant_ID), payload)
}

export function consultantSuiviSalarieRepriseMissionRequest(consultant_ID: number, payload: IConsultantSuiviSalarieRepriseMissionRequestModel) {
     const formData = new FormData()

     for (const [key, value] of Object.entries(payload)) {
          if (key === "ordreMission" && value) {
               formData.append("ordreMission", value)
          } else {
               formData.append(`data[${key}]`, value)
          }
     }

     return axios.post<{ ordreDeMissionGoogleDriveID: string }>(CONST_API_BUSINESS_CONSULTANT_POST_SUIVI_REPRISE_MISSION_ENDPOINT(consultant_ID), formData)
}

export function consultantSuiviSalarieChangementEtatToIntercontratRequest(consultant_ID: number, payload: IConsultantSuiviSalarieChangementEtatToIntercontratRequestModel) {
     return axios.post(CONST_API_BUSINESS_CONSULTANT_POST_SUIVI_INTERCONTRAT_ENDPOINT(consultant_ID), payload)
}

export function consultantSuiviChangementEntiteRattachementRequest(payload: IConsultantSuiviChangementEntiteRattachementRequestModel) {
     return axios.post<IEntiteModel>(CONST_API_BUSINESS_CONSULTANT_POST_SUIVI_CHANGEMENT_ENTITE_RATTACHEMENT_ENDPOINT, payload)
}

export function consultantSuiviChangementEntiteFacturationRequest(payload: IConsultantSuiviChangementEntiteFacturationRequestModel) {
     return axios.post<IEntiteModel>(CONST_API_BUSINESS_CONSULTANT_POST_SUIVI_CHANGEMENT_ENTITE_FACTURATION_ENDPOINT, payload)
}

export function consultantSuiviSuppressionMachineRequest(consultant_ID: number, payload: IConsultantSuiviSuppressionMachineRequestModel) {
     return axios.post<IEntiteModel>(CONST_API_BUSINESS_CONSULTANT_POST_SUIVI_SUPPRESSION_MACHINE_ENDPOINT(consultant_ID), payload)
}

export function consultantSuiviAjoutOuChangementMachineRequest(consultant_ID: number, payload: IConsultantSuiviChangementEntiteFacturationRequestModel) {
     return axios.post<IEntiteModel>(CONST_API_BUSINESS_CONSULTANT_POST_SUIVI_CHANGEMENT_AJOUT_MACHINE_ENDPOINT(consultant_ID), payload)
}

export function consultantSuiviChangementManagerRequest(payload: IConsultantSuiviChangementManagerRequestModel) {
     return axios.post<IEntiteModel>(CONST_API_BUSINESS_CONSULTANT_POST_SUIVI_CHANGEMENT_MANAGER_ENDPOINT, payload)
}

export function consultantSuiviDelegationAdministrativeRequest(payload: IConsultantSuiviDelegationAdministrativeItemRequestModel[]) {
     return axios.post<IEntiteModel>(CONST_API_BUSINESS_CONSULTANT_POST_SUIVI_DELEGATION_ADMINISTRATIVE_ENDPOINT, payload)
}

export function consultantSuiviChangementSalaireRequest(consultant_ID: number, payload: IConsultantSuiviChangementSalaireRequestModel) {
     return axios.post(CONST_API_BUSINESS_CONSULTANT_POST_SUIVI_CHANGEMENT_SALAIRE_ENDPOINT(consultant_ID), payload)
}

export function consultantSuiviChangementCoeffRequest(payload: IConsultantSuiviChangementCoeffRequestModel) {
     return axios.post(CONST_API_BUSINESS_CONSULTANT_POST_SUIVI_CHANGEMENT_COEFF_ENDPOINT, payload)
}

export function consultantSuiviChangementSatutRequest(payload: IConsultantSuiviChangementStatutRequestModel) {
     return axios.post(CONST_API_BUSINESS_CONSULTANT_POST_SUIVI_CHANGEMENT_STATUT_ENDPOINT, payload)
}

export function consultantSuiviCommentaireRequest(consultant_ID: number, payload: IConsultantSuiviCommentaireRequestModel) {
     return axios.post(CONST_API_BUSINESS_CONSULTANT_POST_SUIVI_COMMENTAIRE_ENDPOINT(consultant_ID), payload)
}

export function consultantSuiviChangementPositionRequest(payload: IConsultantSuiviChangementPositionRequestModel) {
     return axios.post(CONST_API_BUSINESS_CONSULTANT_POST_SUIVI_CHANGEMENT_POSITION_ENDPOINT, payload)
}

export function consultantSuiviChangementHeuresSuppRequest(payload: IConsultantSuiviChangementHeuresSuppRequestModel) {
     return axios.post(CONST_API_BUSINESS_CONSULTANT_POST_SUIVI_CHANGEMENT_HEURES_SUPP_ENDPOINT, payload)
}

export function consultantSuiviPrime(consultant_ID: number, payload: IConsultantSuiviPrimeRequestModel) {
     return axios.post(CONST_API_BUSINESS_CONSULTANT_POST_SUIVI_PRIME_ENDPOINT(consultant_ID), payload)
}

export function consultantSuiviChangementTauxAchatRequest(consultant_ID: number, payload: IConsultantSuiviChangementTauxAchatRequestModel) {
     return axios.post(CONST_API_BUSINESS_CONSULTANT_POST_SUIVI_CHANGEMENT_TAUX_ACHAT_ENDPOINT(consultant_ID), payload)
}

export function consultantSuiviProlongementMissionSalarieRequestModel(consultant_ID: number, payload: IConsultantSuiviProlongementMissionSalarieRequestModel) {
     const formData = new FormData()
     if (payload.dateDebut) formData.append("data[dateDebut]", payload.dateDebut)
     if (payload.dateFin) formData.append("data[dateFin]", payload.dateFin)
     if (payload.ordreMissionUploadedOrGenerated) formData.append("data[ordreMissionUploadedOrGenerated]", payload.ordreMissionUploadedOrGenerated)
     if (payload.document) formData.append("document", payload.document)
     if (payload.adresse) formData.append("data[adresse]", payload.adresse)
     if (payload.pays) formData.append("data[pays]", payload.pays.toString())
     if (payload.ville) formData.append("data[ville]", payload.ville)
     if (payload.villeFrance) formData.append("data[villeFrance]", payload.villeFrance.toString())
     if (payload.codePostal) formData.append("data[codePostal]", payload.codePostal)
     return axios.post<{ ordreDeMissionGoogleDriveID: string }>(CONST_API_BUSINESS_CONSULTANT_POST_SUIVI_PROLONGEMENT_MISSION_SALARIE_ENDPOINT(consultant_ID), formData)
}

export function consultantSuiviProlongementMissionNonSalarieRequestModel(consultant_ID: number, payload: IConsultantSuiviProlongementMissionNonSalarieRequestModel) {
     return axios.post(CONST_API_BUSINESS_CONSULTANT_POST_SUIVI_PROLONGEMENT_MISSION_NON_SALARIE_ENDPOINT(consultant_ID), payload)
}

export function getPrefillFormDataProlongementMissionRequest(consultant_ID: number) {
     return axios.post<IGetPrefillFormDataProlongementMissionRequestModel>(CONST_API_UTILS_GET_PRE_FILL_FORM_DATA_POST_PROLONGEMENT_MISSION_ENDPOINT, {
          consultant_ID,
     })
}
