import AddFournisseurForm from "../../utils/core/_AddFournisseurForm"
import MyCard from "@common-utils/MyCard"
import { useNavigate } from "react-router-dom"
import { ROUTING_ABSOLUTE_PATH_FOURNISSEUR_LISTING } from "../routing/routing-endpoints"

const FournisseurCreate = () => {
     const navigate = useNavigate()

     return (
          <>
               <div className={"d-flex justify-content-center"}>
                    <MyCard classNames={"w-100 w-lg-75 w-xl-60"}>
                         <MyCard.Header>Nouveau fournisseur</MyCard.Header>
                         <MyCard.Body>
                              <AddFournisseurForm callback={() => navigate(ROUTING_ABSOLUTE_PATH_FOURNISSEUR_LISTING)} />
                         </MyCard.Body>
                    </MyCard>
               </div>
          </>
     )
}

export default FournisseurCreate
