import { Tree, TreeNode } from "react-organizational-chart"
import { useThemeMode } from "@common-metronic/partials"
import React, { createContext, useState } from "react"
import { useQuery, UseQueryResult } from "react-query"
import { AxiosError } from "axios"
import { IHttpErrorResponseModel, IPersonnelModel } from "@common-models/*"
import { getPersonnelsOrgranigrammeRequest } from "../../core/_requests"
import { toast } from "react-toastify"
import MySimpleSpinner from "@common-utils/MySimpleSpinner"
import MyAlert from "@common-utils/MyAlert"
import PersonnelTreeNodeLabel from "./PersonnelTreeNodeLabel"
import "./styles.scss"
import { MapInteractionCSS } from "react-map-interaction"
import FileAndDriveHelper from "@common-helpers/FileAndDriveHelper"
import { toAbsoluteUrl } from "@common-metronic/helpers"
import { Link } from "react-router-dom"
import { ROUTING_ABSOLUTE_PATH_GESTION_STRUCTURE_STAFF_DETAILS } from "../../../gestion/routing/routing-endpoints"

const PersonnelsOrganigramme = ({ isNameLinkable = true }: { isNameLinkable?: boolean }) => {
     const { mode } = useThemeMode()
     const [isAvatarLoading, setIsAvatarLoading] = useState<boolean>(true)

     const organigrammeQuery = useQuery<IPersonnelOrganigrammeResponse, AxiosError>(REACT_QUERY_KEY_GET_ORGANIGRAMME, async () => {
          return getPersonnelsOrgranigrammeRequest()
               .then(r => {
                    return r.data
               })
               .catch((e: AxiosError) => {
                    const error: IHttpErrorResponseModel = e.response?.data

                    toast.error(error?.detail, { autoClose: false })

                    throw e
               })
     })

     // Recursive function to render the tree nodes
     const renderTreeNodes = (node: IPersonnelOrganigrammeResponse) => (
          <TreeNode label={<PersonnelTreeNodeLabel node={node} key={node.personnel.id} />} key={node.personnel.id}>
               {node.children.map(child => renderTreeNodes(child))}
          </TreeNode>
     )

     return (
          <>
               <div className="d-flex justify-content-center">
                    <div className={"w-100 bg-body p-5"} style={{ maxWidth: "100%", maxHeight: "73vh", overflowX: "hidden" }}>
                         <PersonnelsOrganigrammeContext.Provider
                              value={{
                                   organigrammeQuery,
                                   isNameLinkable,
                                   REACT_QUERY_KEY_GET_ORGANIGRAMME,
                              }}
                         >
                              <>
                                   {organigrammeQuery.isFetching && (
                                        <div className={"text-center p-5 bg-body"}>
                                             <MySimpleSpinner size={"xl"} />
                                        </div>
                                   )}

                                   {!organigrammeQuery.isFetching && organigrammeQuery.isError && (
                                        <div className={"text-center p-5 bg-body"}>
                                             <MyAlert type={"danger"}>
                                                  <>
                                                       <span className={"me-2"}>{organigrammeQuery.error?.response?.data?.detail}</span>
                                                       <button className={"btn btn-sm btn-danger"} onClick={() => organigrammeQuery.refetch()}>
                                                            Recharger
                                                       </button>
                                                  </>
                                             </MyAlert>
                                        </div>
                                   )}

                                   {!organigrammeQuery.isFetching && !organigrammeQuery.isError && organigrammeQuery.data && (
                                        <>
                                             <MapInteractionCSS
                                                  defaultValue={{
                                                       scale: 0.6,
                                                       translation: { x: -500, y: 0 },
                                                  }}
                                                  minScale={0.4}
                                                  maxScale={1}
                                                  translationBounds={{
                                                       xMax: 400,
                                                       yMax: 200,
                                                  }}
                                             >
                                                  <Tree
                                                       lineColor={mode === "dark" ? "white" : "black"}
                                                       lineHeight={"3rem"}
                                                       nodePadding={"2rem"}
                                                       label={
                                                            <>
                                                                 {/* Avatar */}
                                                                 <div>
                                                                      {isAvatarLoading && <MySimpleSpinner classNames={"bg-black w-50px h-50px mb-2"} />}
                                                                      <img
                                                                           src={
                                                                                organigrammeQuery.data.personnel?.avatarGoogleDriveId
                                                                                     ? FileAndDriveHelper.getThumbnailDriveUrl(
                                                                                            organigrammeQuery.data.personnel?.avatarGoogleDriveId
                                                                                       )
                                                                                     : toAbsoluteUrl(`/media/svg/avatars/${mode === "light" ? "blank" : "blank-dark"}.svg`)
                                                                           }
                                                                           onLoad={() => setIsAvatarLoading(false)}
                                                                           style={{ borderRadius: "50%" }}
                                                                           className={"w-50px h-50px mb-2"}
                                                                           alt="Avatar"
                                                                      />
                                                                 </div>

                                                                 {isNameLinkable ? (
                                                                      <Link
                                                                           to={ROUTING_ABSOLUTE_PATH_GESTION_STRUCTURE_STAFF_DETAILS(organigrammeQuery.data.personnel.id as number)}
                                                                           target={"_blank"}
                                                                           className={"text-primary text-hover-dark"}
                                                                      >
                                                                           {organigrammeQuery.data.personnel.prenomNom}
                                                                      </Link>
                                                                 ) : (
                                                                      <span className={"text-primary"}>{organigrammeQuery.data.personnel.prenomNom}</span>
                                                                 )}
                                                                 <div className={"text-gray-700"}>
                                                                      {(organigrammeQuery.data.personnel.personnelPostes || [])[0].poste?.nom || "Non renseigné"}
                                                                 </div>
                                                            </>
                                                       }
                                                  >
                                                       {organigrammeQuery.data.children.map(child => renderTreeNodes(child))}
                                                  </Tree>
                                             </MapInteractionCSS>
                                        </>
                                   )}
                              </>
                         </PersonnelsOrganigrammeContext.Provider>
                    </div>
               </div>
          </>
     )
}

// Consts & interfaces
const REACT_QUERY_KEY_GET_ORGANIGRAMME = "REACT_QUERY_KEY_GET_ORGANIGRAMME"
const PersonnelsOrganigrammeContext = createContext<IPersonnelsOrganigrammeContextModel>({} as IPersonnelsOrganigrammeContextModel)

interface IPersonnelsOrganigrammeContextModel {
     REACT_QUERY_KEY_GET_ORGANIGRAMME: string
     organigrammeQuery: UseQueryResult<IPersonnelOrganigrammeResponse, AxiosError>
     isNameLinkable: boolean
}

export interface IPersonnelOrganigrammeResponse {
     personnel: IPersonnelModel
     children: Array<IPersonnelOrganigrammeResponse>
}

export { PersonnelsOrganigramme, PersonnelsOrganigrammeContext }
