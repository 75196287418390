import { IPersonnelOrganigrammeResponse, PersonnelsOrganigrammeContext } from "./PersonnelsOrganigramme"
import React, { useContext, useState } from "react"
import FileAndDriveHelper from "@common-helpers/FileAndDriveHelper"
import { toAbsoluteUrl } from "@common-metronic/helpers"
import { useThemeMode } from "@common-metronic/partials"
import MySimpleSpinner from "@common-utils/MySimpleSpinner"
import { Link } from "react-router-dom"
import { ROUTING_ABSOLUTE_PATH_GESTION_STRUCTURE_STAFF_DETAILS } from "../../../gestion/routing/routing-endpoints"
import MyTooltip from "@common-utils/MyTooltip"

const PersonnelTreeNodeLabel = ({ node }: { node: IPersonnelOrganigrammeResponse }) => {
     const { isNameLinkable } = useContext(PersonnelsOrganigrammeContext)
     const [isAvatarLoading, setIsAvatarLoading] = useState<boolean>(true)
     const { mode } = useThemeMode()
     const poste = (node.personnel.personnelPostes || [])[0].poste?.nom || "Poste non renseigné"

     return (
          <>
               {/* Avatar */}
               <div>
                    {isAvatarLoading && <MySimpleSpinner classNames={"bg-black w-50px h-50px mb-2"} />}
                    <img
                         src={
                              node.personnel?.avatarGoogleDriveId
                                   ? FileAndDriveHelper.getThumbnailDriveUrl(node.personnel?.avatarGoogleDriveId)
                                   : toAbsoluteUrl(`/media/svg/avatars/${mode === "light" ? "blank" : "blank-dark"}.svg`)
                         }
                         onLoad={() => setIsAvatarLoading(false)}
                         style={{ borderRadius: "50%" }}
                         className={"w-50px h-50px mb-2"}
                         alt="Avatar"
                    />
               </div>
               {/* Nom */}
               {isNameLinkable ? (
                    <Link to={ROUTING_ABSOLUTE_PATH_GESTION_STRUCTURE_STAFF_DETAILS(node.personnel.id as number)} target={"_blank"} className={"text-primary text-hover-dark fs-5"}>
                         {node.personnel.prenomNom}
                    </Link>
               ) : (
                    <span className={"text-primary"}>{node.personnel.prenomNom}</span>
               )}
               {/* Poste*/}
               <div className={"text-gray-700"}>{poste}</div>
               {/* Agence */}
               <div className={"text-info mb-2"}>{node.personnel.agence?.nom}</div>
               <div className="d-flex justify-content-center mb-2">
                    <div className="separator separator-dashed" style={{ width: "7rem" }} />
               </div>
               <div className={"d-flex justify-content-center gap-4 mb-2"}>
                    <MyTooltip
                         title={
                              <>
                                   Composez le {node.personnel.telPersonnelIndicatif?.phoneCodeWithPlusPrefix} {node.personnel.telProfessionnel}
                              </>
                         }
                    >
                         <a
                              href={`tel:${node?.personnel.telPersonnelIndicatif ? node?.personnel.telPersonnelIndicatif.phoneCodeWithPlusPrefix : ""} ${node?.personnel
                                   .telProfessionnel}`}
                         >
                              <i className={"fas fa-phone text-success cursor-pointer fs-2"} />
                         </a>
                    </MyTooltip>
                    <a href={`mailto:${node.personnel.mailProfessionnel}`} target={"_blank"} rel={"noreferrer"}>
                         <i className={"fas fa-envelope text-dark cursor-pointer fs-2"} />
                    </a>
               </div>
          </>
     )
}

export default PersonnelTreeNodeLabel
