import axios from "axios"
import {
     CONST_API_BUSINESS_CONSULTANT_DELETE_DE_ENDPOINT,
     CONST_API_BUSINESS_CONSULTANT_ENVOYER_DOCS_EMBAUCHE_ENDPOINT,
     CONST_API_BUSINESS_CONSULTANT_GET_DE_DOCS_TO_SEND_ENDPOINT,
     CONST_API_BUSINESS_CONSULTANT_GET_DE_DOCUMENTS_ENDPOINT,
     CONST_API_BUSINESS_CONSULTANT_IGNORER_ENVOI_DOCS_EMBAUCHE_ENDPOINT,
     CONST_API_BUSINESS_CONSULTANT_POST_DE_DEMANDE_RECTIF_DIRECTION_ENDPOINT,
     CONST_API_BUSINESS_CONSULTANT_POST_DE_DEMANDE_RECTIF_RESPONSABLE_ENDPOINT,
     CONST_API_BUSINESS_CONSULTANT_POST_DE_DEMANDE_RECTIF_SIEGE_ENDPOINT,
     CONST_API_BUSINESS_CONSULTANT_POST_DE_REJET_DEFINITIF_PAR_DIRECTION_ENDPOINT,
     CONST_API_BUSINESS_CONSULTANT_POST_DE_REJET_DEFINITIF_PAR_RESPONSABLE_ENDPOINT,
     CONST_API_BUSINESS_CONSULTANT_POST_DE_SOUMISSION_ENDPOINT,
     CONST_API_BUSINESS_CONSULTANT_POST_DE_VALIDATION_DIRECTION_ENDPOINT,
     CONST_API_BUSINESS_CONSULTANT_POST_DE_VALIDATION_RESPONSABLE_ENDPOINT,
     CONST_API_BUSINESS_CONSULTANT_POST_DE_VALIDATION_SIEGE_ENDPOINT,
} from "../../../../../../endpoints"
import { IGetDemandeEmbaucheDocumentsRequestModel } from "./_models"
import { IConsultantDEsuiviModel } from "@common-models/*"

export function getDocsToSendEmbaucheDocuments(consultant_id: number) {
     return axios.get<{ titre: string; googleDriveId: string }[]>(CONST_API_BUSINESS_CONSULTANT_GET_DE_DOCS_TO_SEND_ENDPOINT(consultant_id))
}

export function ignorerEnvoiDocsEmbaucheDocuments(consultant_id: number) {
     return axios.post(CONST_API_BUSINESS_CONSULTANT_IGNORER_ENVOI_DOCS_EMBAUCHE_ENDPOINT(consultant_id), {})
}

export function envoyerDocsEmbauche(consultant_id: number) {
     return axios.post(CONST_API_BUSINESS_CONSULTANT_ENVOYER_DOCS_EMBAUCHE_ENDPOINT(consultant_id), {})
}

export function getDemandeEmbaucheDocuments(consultant_id: number) {
     return axios.get<IGetDemandeEmbaucheDocumentsRequestModel>(CONST_API_BUSINESS_CONSULTANT_GET_DE_DOCUMENTS_ENDPOINT(consultant_id))
}

export function soumissionDemandeEmbauche(consultant_id: number, commentaire?: string | null) {
     return axios.post<IConsultantDEsuiviModel>(CONST_API_BUSINESS_CONSULTANT_POST_DE_SOUMISSION_ENDPOINT(consultant_id), { commentaire })
}

export function demandeRectifParResponsableDemandeEmbauche(consultant_id: number, commentaire: string) {
     return axios.post<IConsultantDEsuiviModel>(CONST_API_BUSINESS_CONSULTANT_POST_DE_DEMANDE_RECTIF_RESPONSABLE_ENDPOINT(consultant_id), { commentaire })
}

export function validationRespDemandeEmbauche(consultant_id: number, commentaire?: string | null) {
     return axios.post<IConsultantDEsuiviModel>(CONST_API_BUSINESS_CONSULTANT_POST_DE_VALIDATION_RESPONSABLE_ENDPOINT(consultant_id), { commentaire })
}

export function demandeRectifParDirectionDemandeEmbauche(consultant_id: number, commentaire: string) {
     return axios.post<IConsultantDEsuiviModel>(CONST_API_BUSINESS_CONSULTANT_POST_DE_DEMANDE_RECTIF_DIRECTION_ENDPOINT(consultant_id), { commentaire })
}

export function validationDirectionDemandeEmbauche(consultant_id: number, commentaire?: string | null) {
     return axios.post<IConsultantDEsuiviModel>(CONST_API_BUSINESS_CONSULTANT_POST_DE_VALIDATION_DIRECTION_ENDPOINT(consultant_id), { commentaire })
}

export function validationSiegeDemandeEmbauche(consultant_id: number, commentaire?: string | null) {
     return axios.post<IConsultantDEsuiviModel>(CONST_API_BUSINESS_CONSULTANT_POST_DE_VALIDATION_SIEGE_ENDPOINT(consultant_id), { commentaire })
}

export function demandeRectifParSiegeDemandeEmbauche(consultant_id: number, commentaire: string) {
     return axios.post<IConsultantDEsuiviModel>(CONST_API_BUSINESS_CONSULTANT_POST_DE_DEMANDE_RECTIF_SIEGE_ENDPOINT(consultant_id), { commentaire })
}

export function rejetDefinitifParDirectionDemandeEmbauche(consultant_id: number, commentaire: string) {
     return axios.post<IConsultantDEsuiviModel>(CONST_API_BUSINESS_CONSULTANT_POST_DE_REJET_DEFINITIF_PAR_DIRECTION_ENDPOINT(consultant_id), { commentaire })
}

export function rejetDefinitifParResponsableDemandeEmbauche(consultant_id: number, commentaire: string) {
     return axios.post<IConsultantDEsuiviModel>(CONST_API_BUSINESS_CONSULTANT_POST_DE_REJET_DEFINITIF_PAR_RESPONSABLE_ENDPOINT(consultant_id), { commentaire })
}

export function deleteDemandeEmbauche(consultant_id: number, commentaire: string) {
     return axios.delete(CONST_API_BUSINESS_CONSULTANT_DELETE_DE_ENDPOINT(consultant_id), {
          data: { commentaire },
     })
}
