import React from "react"
import { useConsultantDeclarationDetailsContext } from "../_ConsultantDeclarationDetails"
import { CONST_CONSULTANT_CONTRAT_ST, CONST_CONSULTANT_STATUT_ST_PORTAGE, CONST_CONSULTANT_STATUT_ST_SOCIETE_SERVICE } from "@common-constants/*"

const Footer = () => {
     const context = useConsultantDeclarationDetailsContext()

     return (
          <>
               <div className="d-flex justify-content-between">
                    <div>
                         {![CONST_CONSULTANT_STATUT_ST_PORTAGE, CONST_CONSULTANT_STATUT_ST_SOCIETE_SERVICE].includes(context.declaration.consultant?.statut!) && (
                              <button type="button" className="btn btn-sm btn-light-primary me-3" onClick={() => context.setNavigationStep("cra")}>
                                   <span className={"fas fa-arrow-left fs-3 me-1 align-middle"} /> CRA
                              </button>
                         )}
                    </div>

                    <div className={"d-flex"}>
                         {context.isActionDemandeRectifAvailable && (
                              <button type="button" className="btn btn-sm btn-warning d-flex align-items-center me-2" onClick={() => context.handleDemandeRectification()}>
                                   <span className={"d-none d-sm-flex"}>Demander une rectification</span>
                                   <span className={"fas fa-thumbs-down fs-3 ms-1 align-middle"} />
                              </button>
                         )}
                         {context.isActionValiderOuRejeterDemandeRegulAvailable && (
                              <>
                                   <button type="button" className="btn btn-sm btn-success d-flex align-items-center me-2" onClick={() => context.handleValidationDemandeRegul()}>
                                        <span className={"d-none d-sm-flex"}>Valider la demande de régul.</span>
                                        <span className={"fas fa-thumbs-up fs-3 ms-1 align-middle"} />
                                   </button>
                                   <button type="button" className="btn btn-sm btn-danger d-flex align-items-center me-2" onClick={() => context.handleRejetDemandeRegul()}>
                                        <span className={"fas fa-thumbs-down fs-3 align-middle"} />
                                   </button>
                              </>
                         )}
                         <button type="button" className="btn btn-sm btn-primary d-flex align-items-center me-2" onClick={() => context.setNavigationStep("ndf")}>
                              {context.declaration.consultant?.contrat === CONST_CONSULTANT_CONTRAT_ST ? "Factures de frais" : "NDF"}
                              <span className={"fas fa-arrow-right fs-3 ms-1 align-middle"} />
                         </button>
                    </div>
               </div>
          </>
     )
}

export default Footer
