import { useConnectedUserDeclarationDetails } from "../../mes-activites/declarations/details/ConnectedPersonnelDeclarationDetails"
import MyTooltip from "@common-utils/MyTooltip"
import moment from "moment"
import useSwal from "@common-hooks/useSwal"
import { IPersonnelCraDetailsModel, IPersonnelCraModel } from "@common-models/*"
import { toast } from "react-toastify"
import React, { useState } from "react"
import { useQueryClient } from "react-query"
import { usePersonnelTrContext } from "./_PersonnelTr"
import { toggleAllPersonnelDeclarationTrMonthRequest, togglePersonnelDeclarationTrRequest } from "../core/_requests"

const PersonnelTrTableTbodyRow = () => {
     const contextTr = usePersonnelTrContext()
     const contextDeclaration = useConnectedUserDeclarationDetails()

     const queryClient = useQueryClient()
     const { swal } = useSwal()

     const [cellsToggling, setCellsToggling] = useState<IPersonnelCraDetailsModel[]>([])

     function toggleCellTicketRestaurant(cra_details_id: number) {
          togglePersonnelDeclarationTrRequest(cra_details_id)
               .then(r => {
                    queryClient.setQueryData<IPersonnelCraModel | undefined>(contextTr.REACT_QUERY_KEY_GET_ACTIVE_CRA, (prev: IPersonnelCraModel | undefined) => {
                         if (prev && prev.personnelCraDetails) {
                              const index = prev.personnelCraDetails.findIndex(item => item.id === cra_details_id)
                              prev.personnelCraDetails[index] = { ...prev.personnelCraDetails[index], ticketResto: r.data.ticketNewValue }
                         }
                         return prev
                    })
               })
               .catch(e => {
                    toast.error(e.response.data?.detail, { autoClose: false })
               })
               .finally(() => setCellsToggling(prev => prev.filter(item => item.id !== cra_details_id)))
     }

     function checkAllCellsPossible(date: moment.Moment) {
          swal.fire({
               icon: "info",
               text: `Vous êtes sur le point d'auto-compléter la ligne. Voulez-vous continuer?`,
               showCancelButton: true,
               confirmButtonText: "Oui",
               cancelButtonText: "Annuler",
               showLoaderOnConfirm: true,
               preConfirm: async () => {
                    return toggleAllPersonnelDeclarationTrMonthRequest(date.format("MM"), date.format("YYYY"))
                         .then(r => {
                              queryClient.setQueryData<IPersonnelCraModel | undefined>(contextTr.REACT_QUERY_KEY_GET_ACTIVE_CRA, (prev: IPersonnelCraModel | undefined) => {
                                   if (prev && prev.personnelCraDetails) {
                                        prev.personnelCraDetails.map(item => {
                                             if (r.data.personnelCraDetailsAffectedIds.includes(item.id as number)) item.ticketResto = true
                                             return item
                                        })
                                   }
                                   return prev
                              })
                              toast.success("Votre action a bien été prise en compte.")
                         })
                         .catch(e => {
                              toast.error(e?.response?.data?.detail, { autoClose: false })
                         })
               },
               allowOutsideClick: () => !swal.isLoading(),
          }).then()
     }

     return (
          <>
               <tr>
                    <td scope="row" className={"border-end sticky-column"}>
                         <span className={"d-flex align-items-center text-primary fw-bold ps-sm-2"}>
                              TICKETS
                              {contextTr.editionModeEnabled && (
                                   <MyTooltip title={"Saisie automatique"}>
                                        <i
                                             className={"fas fa-wand-magic-sparkles text-dark ms-4 fs-2 p-0"}
                                             onClick={() => checkAllCellsPossible(contextDeclaration.data.declaration?.mois?.parseZone() as moment.Moment)}
                                        />
                                   </MyTooltip>
                              )}
                         </span>
                    </td>

                    {contextTr.data.personnelCraDetails?.map((item, key) => (
                         <td
                              key={key}
                              className={`text-center heuresCell ${[5, 6].includes(item.date?.weekday() as number) && "isWeekendCell"} ${
                                   moment().parseZone().format("YYYYMMDD") === item.date?.parseZone().format("YYYYMMDD") && "isTodayCell"
                              } ${contextTr.editionModeEnabled && "cursor-pointer"}`}
                              onClick={() => {
                                   if (contextTr.editionModeEnabled && !cellsToggling.find(item_ => item_.id === item.id)) {
                                        toggleCellTicketRestaurant(item.id as number)
                                        setCellsToggling(prev => [...prev, item])
                                   }
                              }}
                         >
                              <span>
                                   {cellsToggling.find(item_ => item_.id === item.id) ? (
                                        <span className="spinner-border spinner-border-sm align-middle"></span>
                                   ) : (
                                        item.ticketResto && <i className={"fas fa-check-circle text-dark"} />
                                   )}
                              </span>
                         </td>
                    ))}
               </tr>
          </>
     )
}

export default PersonnelTrTableTbodyRow
