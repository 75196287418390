import React, { useEffect, useState } from "react"
import { useConsultantDeclarationDetailsContext } from "../_ConsultantDeclarationDetails"
import { useQuery } from "react-query"
import { IConsultantNdfDetailModel, IPersonnelNdfDetailModel } from "@common-models/*"
import { AxiosError } from "axios"
import MySimpleSpinner from "@common-utils/MySimpleSpinner"
import MyAlert from "@common-utils/MyAlert"
import MyTooltip from "@common-utils/MyTooltip"
import { getDeclarationActiveNdfDetails } from "../../core/_requests"
import MyModal from "@common-utils/MyModal"
import MyLoadingOverlay from "@common-utils/MyLoadingOverlay"
import FileAndDriveHelper from "@common-helpers/FileAndDriveHelper"
import Footer from "./_Footer"
import { CONST_CONSULTANT_CONTRAT_ST } from "@common-constants/*"

const REACT_QUERY_KEY_GET_CONSULTANT_NDF = "REACT_QUERY_KEY_GET_CONSULTANT_NDF"
const ConsultantDeclarationDetailsNDF = () => {
     const context = useConsultantDeclarationDetailsContext()
     const declaration_id = context.declaration.id!

     const [totalDepenses, setTotalDepenses] = useState<number>(0)

     const [documentInModal, setDocumentInModal] = useState<{
          driveID: string
          modalTitle: string
          isLoading: boolean
     }>()

     const declarationActiveNdfDetailsQuery = useQuery<IConsultantNdfDetailModel[], AxiosError>(REACT_QUERY_KEY_GET_CONSULTANT_NDF, () =>
          getDeclarationActiveNdfDetails(declaration_id).then(r => r.data)
     )

     useEffect(() => {
          computeTotalExpenses(declarationActiveNdfDetailsQuery.data || [])
     }, [declarationActiveNdfDetailsQuery.data])

     function computeTotalExpenses(items: IPersonnelNdfDetailModel[]) {
          let total = 0
          items.map(item => {
               if (item.isParent && item.enfants) {
                    item.enfants.map(enfant => {
                         total += enfant.montantTtc as number
                    })
               } else {
                    total += item.montantTtc as number
               }
          })
          setTotalDepenses(total)
     }

     // Is fetching
     if (declarationActiveNdfDetailsQuery.isFetching) {
          return (
               <div className={"text-center p-5"}>
                    <MySimpleSpinner size={"xl"} />{" "}
               </div>
          )
     }

     // Error
     if (!declarationActiveNdfDetailsQuery.isFetching && declarationActiveNdfDetailsQuery.isError) {
          return (
               <MyAlert type={"danger"} classNames={"mb-4"}>
                    <>
                         <span className={"me-2"}>{declarationActiveNdfDetailsQuery.error?.response?.data?.detail}</span>
                         <button className={"btn btn-sm btn-danger"} onClick={() => declarationActiveNdfDetailsQuery.refetch()}>
                              Recharger
                         </button>
                    </>
               </MyAlert>
          )
     }

     // Empty data
     if (
          !declarationActiveNdfDetailsQuery.isFetching &&
          !declarationActiveNdfDetailsQuery.isError &&
          declarationActiveNdfDetailsQuery.data &&
          declarationActiveNdfDetailsQuery.data.length === 0
     ) {
          return (
               <div className={"text-center p-4"}>
                    <p style={{ fontWeight: 500 }} className={"mb-4"}>
                         Aucune justificatif n'a été ajouté ...
                    </p>
                    <Footer />
               </div>
          )
     }

     const Salarie = () => {
          return (
               <div className={"row"}>
                    {declarationActiveNdfDetailsQuery.data?.map((item, key) => (
                         <div className="card shadow-sm col-sm-6 col-lg-4 mb-4 p-0 pe-sm-2" key={key}>
                              <div className="card-header bg-info border border-info" style={{ minHeight: "2rem" }}>
                                   <h3 className="card-title text-white align-items-center">
                                        <span className={"fas fa-file-invoice me-2"}></span>
                                        {item.isParent ? `Justificatifs groupés ( ${item.enfants?.length} )` : item.categorieLabel}
                                   </h3>
                              </div>
                              <div className="card-body border-info border">
                                   <div className="d-flex justify-content-center">
                                        {item.consultantNdfDocuments?.map((item_, key_) => (
                                             <MyTooltip title={"Voir la pièce jointe"} key={key_}>
                                                  <div
                                                       className={`mb-4 d-flex justify-content-center fas fa-file-invoice cursor-pointer ${key_ > 0 ? "ms-4" : ""}`}
                                                       style={{ fontSize: "2rem" }}
                                                       onClick={() =>
                                                            setDocumentInModal({
                                                                 isLoading: true,
                                                                 modalTitle: "Justificatif",
                                                                 driveID: item_.googleDriveID as string,
                                                            })
                                                       }
                                                  />
                                             </MyTooltip>
                                        ))}
                                   </div>

                                   {/* Justificatif simple */}
                                   {!item.isParent && (
                                        <div className={"mb-4"}>
                                             <div className={"block"}>
                                                  <span className={"fw-bold"}>Date : </span> {item.date?.parseZone().format("DD/MM/YYYY")}
                                             </div>
                                             {item.description && (
                                                  <MyTooltip title={item.description}>
                                                       <div className={"block d-flex align-items-center cursor-default text-hover-dark"}>
                                                            <span className={"fw-bold"}>Description : </span> <i className={"fas fa-eye fs-5 ms-2"} />
                                                       </div>
                                                  </MyTooltip>
                                             )}
                                        </div>
                                   )}

                                   {/* Justificatif groupée */}
                                   {item.isParent && (
                                        <>
                                             {item.enfants && item.enfants.length > 0 && (
                                                  <>
                                                       {item.enfants.map(child => (
                                                            <div className={"mb-4"}>
                                                                 <div className={"block fs-5 border-bottom border-dark mb-1 fw-bold align-items-center"}>
                                                                      Ticket n°{child.numeroTicket}
                                                                 </div>
                                                                 <div className={"block"}>
                                                                      <span className={"fw-bold"}>Date : </span> {child.date!.parseZone().format("dddd DD MMMM YYYY")}
                                                                 </div>
                                                                 <div className={"block"}>
                                                                      <span className={"fw-bold"}>Catégorie : </span> {child.categorieLabel}
                                                                 </div>
                                                                 <div className={"block"}>
                                                                      <span className={"fw-bold"}>Montant TTC : </span> {child.montantTtc} €
                                                                 </div>
                                                                 {child.description && (
                                                                      <MyTooltip title={child.description}>
                                                                           <div className={"block d-flex align-items-center cursor-default text-hover-dark"}>
                                                                                <span className={"fw-bold"}>Description : </span> <i className={"fas fa-eye fs-5 ms-2"} />
                                                                           </div>
                                                                      </MyTooltip>
                                                                 )}
                                                            </div>
                                                       ))}
                                                  </>
                                             )}
                                        </>
                                   )}

                                   <div className="row border-top-dashed border-info pt-3">
                                        <div className="col-6 p-0"></div>
                                        <div className="col-6 fw-bold fs-5 d-flex align-items-center p-0 justify-content-end">
                                             Total :{" "}
                                             {item.isParent
                                                  ? item.enfants
                                                       ? Object.values(item.enfants.map(child => child.montantTtc as number))
                                                              .reduce((acc, val) => acc + val, 0)
                                                              .toFixed(2)
                                                       : 0
                                                  : item.montantTtc}{" "}
                                             €
                                        </div>
                                   </div>
                              </div>
                         </div>
                    ))}
                    {declarationActiveNdfDetailsQuery?.data && declarationActiveNdfDetailsQuery?.data.length > 1 && (
                         <div className="d-flex justify-content-end px-2">
                              {/* round two decimals totaldepenses*/}
                              <div className={"alert alert-info px-2 py-1 text-dark fw-bold m-0"}>TOTAL: {totalDepenses.toFixed(2)} €</div>
                         </div>
                    )}
               </div>
          )
     }

     const SousTraitant = () => {
          return (
               <div className={"row"}>
                    {declarationActiveNdfDetailsQuery.data!.map(
                         (item_, index) =>
                              item_.consultantNdfDocuments?.map(item => (
                                   <div
                                        className={"col-6 col-md-4 d-flex flex-column text-center cursor-pointer"}
                                        onClick={() =>
                                             setDocumentInModal({
                                                  modalTitle: `Factures n°${index + 1}`,
                                                  driveID: item.googleDriveID!,
                                                  isLoading: true,
                                             })
                                        }
                                   >
                                        <div className={"fas fa-file-alt mb-2"} style={{ fontSize: "5rem" }} />
                                        Doc n°{index + 1}
                                   </div>
                              ))
                    )}
               </div>
          )
     }

     return (
          <>
               {context.declaration.consultant?.contrat === CONST_CONSULTANT_CONTRAT_ST ? <SousTraitant /> : <Salarie />}

               <div className="separator separator-dashed my-5"></div>

               <Footer />

               {/* Document in modal */}
               {documentInModal && (
                    <MyModal title={<span>{documentInModal.modalTitle}</span>} show={true} handleClose={() => setDocumentInModal(undefined)} noPadding size={"lg"}>
                         <MyLoadingOverlay isActive={documentInModal.isLoading}>
                              <iframe
                                   src={FileAndDriveHelper.getUrlOfDriveFilePreview(documentInModal.driveID)}
                                   onLoad={() => setDocumentInModal(prev => (prev ? { ...prev, isLoading: false } : prev))}
                                   style={{ width: "100%", minHeight: "82vh", overflowY: "scroll" }}
                              />
                         </MyLoadingOverlay>
                    </MyModal>
               )}
          </>
     )
}

export default ConsultantDeclarationDetailsNDF
