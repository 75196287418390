import React, { useState } from "react"
import { toAbsoluteUrl } from "@common-metronic/helpers"
import MyLoadingOverlay from "@common-utils/MyLoadingOverlay"
import { useListContext } from "./_List"
import { useListingContext } from "./PersonnelMaladieListingMultiple"
import FileAndDriveHelper from "@common-helpers/FileAndDriveHelper"
import { getSelectedItemPlusPreviousAndNextItem } from "./core/_helpers"
import { Link } from "react-router-dom"
import { IGetPersonnelMaladieItemResponseModel } from "./core/_models"
import MyTooltip from "@common-utils/MyTooltip"

const ListItem = ({ item, handleClickItemForPreview, isItemSelected, isItemLoading }: IListItemPropsModel) => {
     const globalContext = useListingContext()
     const listContext = useListContext()

     const [isAvatarLoading, setIsAvatarLoading] = useState<boolean>(true)

     function handleOnCheckItem() {
          if (!globalContext.isBulkSelectionEnabled.checkedElementsInPage.includes(item.arret.id as number)) {
               globalContext.setIsBulkSelectionEnabled(prev => ({
                    ...prev,
                    checkedElementsInPage: [...prev.checkedElementsInPage, item.arret.id as number],
                    areAllElementsInAllPagesChecked: false,
               }))
          } else {
               globalContext.setIsBulkSelectionEnabled(prev => ({
                    ...prev,
                    checkedElementsInPage: prev.checkedElementsInPage.filter(id => id !== item.arret.id),
                    areAllElementsInAllPagesChecked: false,
               }))
          }
     }

     return (
          <MyLoadingOverlay isActive={isItemLoading}>
               <>
                    <div
                         className={`${isItemSelected ? "border-start border-2 border-primary" : ""}`}
                         onClick={e => handleClickItemForPreview(e)}
                         ref={isItemSelected ? listContext.selectedItemRef : null}
                    >
                         <div className={`p-4 ${isItemSelected ? "mb-4 bg-light" : "cursor-pointer"}`}>
                              {/* checkbox, Avatar, nom & métier*/}
                              <div className={"d-flex justify-content-between align-items-center"}>
                                   {/* checkbox */}
                                   {globalContext.isBulkSelectionEnabled.enabled && (
                                        <div className={"form-check form-check-custom form-check-solid form-check-sm me-4"}>
                                             <input
                                                  type="checkbox"
                                                  className={"form-check-input cursor-pointer border border-secondary not-concerned-by-preview"}
                                                  checked={globalContext.isBulkSelectionEnabled.checkedElementsInPage.includes(item.arret?.id as number)}
                                                  onChange={() => handleOnCheckItem()}
                                             />
                                        </div>
                                   )}

                                   <div className={"d-flex align-items-center"}>
                                        {/* Left */}
                                        <MyLoadingOverlay isActive={isAvatarLoading} styles={{ height: "50px", width: "50px", borderRadius: "50%" }} size={"sm"}>
                                             <div className={"d-flex"}>
                                                  <img
                                                       src={
                                                            item.arret.personnel?.avatarGoogleDriveId
                                                                 ? FileAndDriveHelper.getThumbnailDriveUrl(item.arret?.personnel.avatarGoogleDriveId as string)
                                                                 : toAbsoluteUrl("/media/svg/avatars/blank.svg")
                                                       }
                                                       onLoad={() => setIsAvatarLoading(false)}
                                                       style={{ height: "50px", width: "50px", borderRadius: "50%" }}
                                                       alt="Avatar"
                                                  />
                                             </div>
                                        </MyLoadingOverlay>

                                        {/* Middle */}
                                        <div className={"ms-4"}>
                                             <div className={"d-flex"}>
                                                  <Link to={`#`} target={"_blank"} className={"text-primary fw-bold fs-5"}>
                                                       {item.arret?.personnel?.prenomNom}
                                                  </Link>
                                             </div>
                                             <div>
                                                  <span className={"text-muted"}>Envoyé le {item.arret.createdAt?.format("dddd D MMMM YYYY")}</span>
                                             </div>
                                        </div>
                                   </div>

                                   {/* Right side */}
                                   <div className="d-flex flex-column align-items-end">
                                        <div className={"badge badge-dark"}>{item.arret.personnel?.mainEntite?.nom}</div>
                                        {item.arret.deletedAt && (
                                             <MyTooltip title={`Annulation le ${item.arret.deletedAt?.format("dddd D MMMM YYYY")}`}>
                                                  <div className={"badge badge-danger mt-1 not-concerned-by-preview cursor-default"}>Annulé</div>
                                             </MyTooltip>
                                        )}
                                   </div>
                              </div>
                         </div>

                         {isItemSelected && (
                              <>
                                   <div className={"d-flex align-items-center px-4 pb-4"}>
                                        <div className={"col-10"}>
                                             {/* Date de la demande */}
                                             <div className={`d-flex align-items-center mb-2`}>
                                                  <span className="fas fa-calendar-days fs-5 w-25px" />
                                                  Du {item.arret.du?.format("dddd D MMMM YYYY")} au {item.arret.au?.format("dddd D MMMM YYYY")}
                                             </div>

                                             {/* Responsable */}
                                             <div className={`d-flex align-items-center mb-2`}>
                                                  <span className="fas fa-user fs-5 w-25px" /> {item.responsable?.prenomNom}
                                             </div>

                                             {item.arret.deletedAt && (
                                                  <div className="alert alert-danger m-0 fw-bold">Annulation le {item.arret.deletedAt?.format("dddd D MMMM YYYY")}</div>
                                             )}
                                        </div>
                                   </div>
                              </>
                         )}
                    </div>
               </>
          </MyLoadingOverlay>
     )
}

const DdcListingListItem = ({ item, index }: { item: IGetPersonnelMaladieItemResponseModel; index: number }) => {
     const context = useListingContext()

     const isItemSelected: boolean = context.itemInPreview !== null && context.itemInPreview.selectedItem.arret?.id === item.arret?.id
     const isItemLoading: boolean = context.itemInPreview !== null && context.itemInPreview.selectedItem?.arret.id === item.arret?.id && context.itemInPreview.isSelectedItemLoading

     // Shows item in preview
     function handleClickItemForPreview(e: React.MouseEvent<any>) {
          const clickedElement = e.target as HTMLElement
          // .not-concerned-by-preview elements are not concerned by the click
          if (!clickedElement.classList.contains("not-concerned-by-preview") && context.itemInPreview?.selectedItem?.arret.id !== item.arret?.id) {
               context.setAreFiltersVisible(false)

               context.setItemInPreview({
                    ...getSelectedItemPlusPreviousAndNextItem(item, index, context.listingQuery.data!.data, context.listingQuery.data!.page, context.listingQuery.data!.totalPages),
                    isSelectedItemLoading: false,
               })
          }
     }

     return <ListItem item={item} handleClickItemForPreview={handleClickItemForPreview} isItemSelected={isItemSelected} isItemLoading={isItemLoading} />
}

interface IListItemPropsModel {
     item: IGetPersonnelMaladieItemResponseModel
     handleClickItemForPreview: (e: React.MouseEvent<any>) => void
     isItemSelected: boolean
     isItemLoading: boolean
}

export default DdcListingListItem
