import React, { useState } from "react"
import FormViewMode from "./FormViewMode"
import FormEditMode from "./FormEditMode"

const InfoBase = () => {
     const [mode, setMode] = useState<"view" | "edit">("view")

     return (
          <>
               <h4>Informations de base</h4>

               <div className="separator separator-dashed mt-3 mb-5" />

               {mode === "view" ? <FormViewMode toggleToEditMode={() => setMode("edit")} /> : <FormEditMode toggleToViewMode={() => setMode("view")} />}
          </>
     )
}

export default InfoBase
