import { CONST_CONSULTANT_DECLARATION_SUIVI_TYPE_SIEGE_DEMANDE_RECTIFICATION } from "@common-constants/*"
import React from "react"
import { useConsultantDeclarationDetailsContext } from "../_ConsultantDeclarationDetails"

const Footer = () => {
     const context = useConsultantDeclarationDetailsContext()

     return (
          <>
               <div className="d-flex justify-content-between">
                    <div>
                         <button type="button" className="btn btn-sm btn-light-primary me-3" onClick={() => context.setNavigationStep("ndf")}>
                              <span className={"fas fa-arrow-left fs-3 me-1 align-middle"} /> NDF
                         </button>
                    </div>

                    <div className={"d-flex"}>
                         {context.isActionDemandeRectifAvailable && (
                              <button type="button" className="btn btn-sm btn-warning d-flex align-items-center" onClick={context.handleDemandeRectification}>
                                   <span className={"d-none d-sm-flex"}>Demander une rectification</span>
                                   <span className={"fas fa-thumbs-down fs-3 ms-1 align-middle"} />
                              </button>
                         )}

                         {context.isActionValidationAvailable && (
                              <button type="button" className="btn btn-sm btn-success d-flex align-items-center ms-2" onClick={context.handleValidation}>
                                   <span className={"d-none d-sm-flex"}>
                                        {context.declaration.etat?.type === CONST_CONSULTANT_DECLARATION_SUIVI_TYPE_SIEGE_DEMANDE_RECTIFICATION
                                             ? "Retourner la déclaration au siège"
                                             : "Valider et suivant"}
                                   </span>
                                   <span
                                        className={`fas fa-${
                                             context.declaration.etat?.type === CONST_CONSULTANT_DECLARATION_SUIVI_TYPE_SIEGE_DEMANDE_RECTIFICATION
                                                  ? "hand-point-right"
                                                  : "thumbs-up"
                                        } fs-3 ms-1`}
                                   />
                              </button>
                         )}

                         {context.isActionValiderOuRejeterDemandeRegulAvailable && (
                              <>
                                   <button type="button" className="btn btn-sm btn-success d-flex align-items-center me-2" onClick={() => context.handleValidationDemandeRegul()}>
                                        <span className={"d-none d-sm-flex"}>Valider la demande de régul.</span>
                                        <span className={"fas fa-thumbs-up fs-3 ms-1 align-middle"} />
                                   </button>
                                   <button type="button" className="btn btn-sm btn-danger d-flex align-items-center me-2" onClick={() => context.handleRejetDemandeRegul()}>
                                        <span className={"fas fa-thumbs-down fs-3 align-middle"} />
                                   </button>
                              </>
                         )}
                    </div>
               </div>
          </>
     )
}

export default Footer
