import React, { useEffect } from "react"
import MyCard from "@common-utils/MyCard"
import { useDashboardAlertesPeriodesEssaiExpirationListingContext } from "./DashboardAlertesConsultantAvecDocsEmbaucheNonEnvoyesListing"
import "./styles.scss"
import { IItemResponseModel } from "./core/_models"
import { envoyerDocsEmbauche, getDocsToSendEmbaucheDocuments, ignorerEnvoiDocsEmbaucheDocuments } from "../../../recrutement/demandesEmbauche/details/_documents/core/_requests"
import { toast } from "react-toastify"
import { Link } from "react-router-dom"
import { useQuery, useQueryClient } from "react-query"
import { AxiosError } from "axios"
import { IHttpErrorResponseModel } from "@common-models/*"
import { REACT_QUERY_KEY_GET_CONSULTANT_DETAILS } from "../../../business/consultants/details/ConsultantDetails"
import MySimpleSpinner from "@common-utils/MySimpleSpinner"
import MyAlert from "@common-utils/MyAlert"
import FileAndDriveHelper from "@common-helpers/FileAndDriveHelper"
import swal from "sweetalert2"

/* Wrapper */
const Preview = () => {
     const queryClient = useQueryClient()
     const globalContext = useDashboardAlertesPeriodesEssaiExpirationListingContext()
     const selectedItemInPreview = globalContext.itemInPreview!.selectedItem
     const itemInPreview = globalContext.listingQuery.data?.data.find(item => item.consultant?.id === selectedItemInPreview.consultant?.id) as IItemResponseModel

     const detailsQuery = useQuery<{ titre: string; googleDriveId: string }[], AxiosError>(REACT_QUERY_KEY_GET_CONSULTANT_DETAILS, async () => {
          return getDocsToSendEmbaucheDocuments(itemInPreview.consultant.id!)
               .then(r => {
                    globalContext.setItemInPreview(prev => (prev ? { ...prev, isSelectedItemLoading: false } : prev))
                    return r.data
               })
               .catch((e: AxiosError) => {
                    const error: IHttpErrorResponseModel = e.response?.data

                    toast.error(error?.detail, { autoClose: false })

                    throw e
               })
     })

     useEffect(() => {
          detailsQuery.refetch()
     }, [itemInPreview.consultant.id])

     function handleIgnorerEnvoi() {
          swal.fire({
               icon: "warning",
               title: `Veuillez confirmer votre action`,
               showCancelButton: true,
               confirmButtonText: "Confirmer",
               cancelButtonText: "Annuler",
               showLoaderOnConfirm: true,
               preConfirm: async () => {
                    return ignorerEnvoiDocsEmbaucheDocuments(itemInPreview.consultant.id!)
                         .then(() => {
                              globalContext.setItemInPreview(null)
                              globalContext.listingQuery.refetch()
                              toast.success(`Votre action a bien été prise en compte. L'Alerte d'envoi concernant ${itemInPreview.consultant.prenomNom} a été supprimée.`)
                         })
                         .catch(e => {
                              toast.error(e?.response?.data?.detail, { autoClose: false })
                         })
               },
               allowOutsideClick: () => !swal.isLoading(),
          })
     }

     function handleEnvoyerDocs() {
          swal.fire({
               icon: "warning",
               title: `Veuillez confirmer l'envoi`,
               showCancelButton: true,
               confirmButtonText: "Confirmer",
               cancelButtonText: "Annuler",
               showLoaderOnConfirm: true,
               preConfirm: async () => {
                    return envoyerDocsEmbauche(itemInPreview.consultant.id!)
                         .then(() => {
                              globalContext.setItemInPreview(null)
                              globalContext.listingQuery.refetch()
                              toast.success(`Les documents d'embauche ont bien été envoyés à ${itemInPreview.consultant.prenomNom}. Vous êtes en copie du mail.`)
                         })
                         .catch(e => {
                              toast.error(e?.response?.data?.detail, { autoClose: false })
                         })
               },
               allowOutsideClick: () => !swal.isLoading(),
          })
     }

     return (
          <MyCard>
               <MyCard.Header isCollapseIconShown={false}>
                    Documents d'embauche de <Link to={`/business/consultants/${itemInPreview.consultant.id}/details`}>{itemInPreview.consultant.prenomNom}</Link>
               </MyCard.Header>
               <MyCard.Body noPadding styles={{ maxHeight: "75.1vh", overflowY: "scroll" }}>
                    {detailsQuery.isFetching && (
                         <div className={"text-center p-5"}>
                              <MySimpleSpinner size={"xl"} />
                         </div>
                    )}

                    {!detailsQuery.isFetching && detailsQuery.isError && (
                         <MyAlert type={"danger"} classNames={"mb-4"}>
                              <>
                                   <span className={"me-2"}>{detailsQuery.error?.response?.data?.detail}</span>
                                   <button className={"btn btn-sm btn-danger"} onClick={() => detailsQuery.refetch()}>
                                        Recharger
                                   </button>
                              </>
                         </MyAlert>
                    )}

                    {!detailsQuery.isFetching && !detailsQuery.isError && detailsQuery.data && (
                         <>
                              <>{detailsQuery.data.length === 0 && <div className={"p-5"}>Aucun documents à envoyer</div>}</>
                              <>
                                   {detailsQuery.data.length > 0 && (
                                        <>
                                             <ul className="list-group">
                                                  {detailsQuery.data.map(item => (
                                                       <li
                                                            className="list-group-item bg-hover-light"
                                                            onClick={() => {
                                                                 window.open(FileAndDriveHelper.getUrlOfDriveFilePreview(item.googleDriveId), "_blank")
                                                            }}
                                                            key={item.googleDriveId}
                                                       >
                                                            <i className="fas fa-file-image me-2 text-dark" />
                                                            <a href={FileAndDriveHelper.getUrlOfDriveFilePreview(item.googleDriveId)} target={"_blank"}>
                                                                 {item.titre}
                                                            </a>
                                                       </li>
                                                  ))}
                                             </ul>

                                             <div className={"separator separator-dashed my-5"} />

                                             <div className={"d-flex gap-5 text-center justify-content-center mb-5"}>
                                                  <button className={"btn btn-danger btn-sm"} onClick={handleIgnorerEnvoi}>
                                                       Ignorer <i className={"fas fa-times"} />
                                                  </button>
                                                  <button className={"btn btn-primary btn-sm"} onClick={handleEnvoyerDocs}>
                                                       Envoyer <i className={"fas fa-paper-plane"} />
                                                  </button>
                                             </div>
                                        </>
                                   )}
                              </>
                         </>
                    )}
               </MyCard.Body>
          </MyCard>
     )
}

export default Preview
