import { Form, Formik, FormikHelpers } from "formik"
import { toast } from "react-toastify"
import { AxiosError } from "axios"
import { IHttpErrorResponseModel, IMachineModel } from "@common-models/*"
import {
     CONST_FOURNISSEUR_FORME_JURIDIQUE_AUTOENTREPREURE,
     CONST_FOURNISSEUR_FORME_JURIDIQUE_BICBNC,
     CONST_FOURNISSEUR_FORMES_JURIDIQUE_OPTIONS_FOR_SELECT2,
     CONST_FOURNISSEUR_TYPE_FREELANCE,
     CONST_FOURNISSEUR_TYPE_SOCIETE_PORTAGE,
     CONST_FOURNISSEUR_TYPE_SOCIETE_SERVICE,
     CONST_FOURNISSEUR_TYPES_OPTIONS_FOR_SELECT2,
     CONST_HTTP_CUSTOM_CODE_FORM_VALIDATION_ERROR,
} from "@common-constants/*"
import MyAlert from "@common-utils/MyAlert"
import MySimpleSpinner from "@common-utils/MySimpleSpinner"
import React from "react"
import MyInputField from "@common-utils/fields/MyInputField"
import * as Yup from "yup"
import { IAddFournisseurRequestModel } from "./_models"
import MySelectField from "@common-utils/fields/MySelectField"
import { addFournisseurRequest } from "./_requests"

const AddFournisseurForm = ({ callback = () => {}, defaultTypeFieldValue, isTypeFieldReadOnly = false }: IPropsModel) => {
     // Schema
     const schema = Yup.object().shape({
          raisonSociale: Yup.string().required().nullable().label(`"Raison sociale"`),
          formeJuridique: Yup.string().required().nullable().label(`"Forme juridique"`),
          type: Yup.string().required().nullable().label(`"Type"`),
     })

     // Init values
     const initialValues: IAddFournisseurRequestModel = {
          type: defaultTypeFieldValue,
     }

     // Handle submit
     function handleSubmit(values: IAddFournisseurRequestModel, helpers: FormikHelpers<IAddFournisseurRequestModel>) {
          helpers.setStatus(null)

          addFournisseurRequest(values)
               .then(r => {
                    toast.success("Le fournisseur a bien été ajouté")

                    callback(r.data)
               })
               .catch((e: AxiosError) => {
                    const error: IHttpErrorResponseModel = e.response?.data

                    // Set form errors
                    if (error?.code === CONST_HTTP_CUSTOM_CODE_FORM_VALIDATION_ERROR && error?.errors) {
                         for (const key in error.errors) helpers.setFieldError(key, error.errors[key])
                    }

                    // Set form global status and notify user using a toast
                    helpers.setStatus(error?.detail)
                    toast.error(error?.detail, { autoClose: false })

                    // Stop submit loader
                    helpers.setSubmitting(false)
               })
     }

     return (
          <>
               <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={schema}>
                    {helpers => {
                         console.log(helpers.errors)
                         return (
                              <Form noValidate autoComplete="off">
                                   {helpers.status && (
                                        <MyAlert type={"danger"} classNames={"mb-4"}>
                                             {helpers.status}
                                        </MyAlert>
                                   )}

                                   <div className="row mb-5">
                                        <label className="col-lg-4 col-form-label fw-bold fs-6 required">Catégorie</label>

                                        <div className="col-lg-8">
                                             {!isTypeFieldReadOnly ? (
                                                  <>
                                                       <MySelectField
                                                            options={CONST_FOURNISSEUR_TYPES_OPTIONS_FOR_SELECT2}
                                                            name={`type`}
                                                            isSearchable={false}
                                                            value={helpers.values.type}
                                                            onChange={value => helpers.setFieldValue(`type`, value)}
                                                            isInvalid={!!helpers.errors.type}
                                                       />
                                                       {helpers.errors.type && <div className={"text-danger"}>{helpers.errors.type}</div>}
                                                  </>
                                             ) : (
                                                  <input
                                                       className={"form-control form-control-solid cursor-default border"}
                                                       value={CONST_FOURNISSEUR_TYPES_OPTIONS_FOR_SELECT2.find(item => item.value === helpers.values.type)?.label}
                                                       readOnly
                                                  />
                                             )}
                                        </div>
                                   </div>

                                   <div className="row mb-5">
                                        <label className="col-lg-4 col-form-label fw-bold fs-6 required">Forme juridique</label>

                                        <div className="col-lg-8">
                                             <MySelectField
                                                  options={CONST_FOURNISSEUR_FORMES_JURIDIQUE_OPTIONS_FOR_SELECT2.filter(item => {
                                                       if (
                                                            helpers.values.type === CONST_FOURNISSEUR_TYPE_SOCIETE_PORTAGE ||
                                                            helpers.values.type === CONST_FOURNISSEUR_TYPE_SOCIETE_SERVICE
                                                       ) {
                                                            if (
                                                                 item.value === CONST_FOURNISSEUR_FORME_JURIDIQUE_AUTOENTREPREURE ||
                                                                 item.value === CONST_FOURNISSEUR_FORME_JURIDIQUE_BICBNC
                                                            ) {
                                                                 return false
                                                            }
                                                       }
                                                       return true
                                                  })}
                                                  name={`formeJuridique`}
                                                  isSearchable={false}
                                                  value={helpers.values.formeJuridique}
                                                  onChange={value => helpers.setFieldValue(`formeJuridique`, value)}
                                                  isInvalid={!!helpers.errors.formeJuridique}
                                             />
                                             {helpers.errors.formeJuridique && <div className={"text-danger"}>{helpers.errors.formeJuridique}</div>}
                                        </div>
                                   </div>

                                   {/* Raison sociale */}
                                   <div className="row">
                                        <label className="col-lg-4 col-form-label fw-bold fs-6 required">Raison sociale</label>

                                        <div className="col-lg-8">
                                             <MyInputField
                                                  name={"raisonSociale"}
                                                  value={helpers.values.raisonSociale}
                                                  onChange={val => helpers.setFieldValue("raisonSociale", val)}
                                                  placeholder={"Raison sociale"}
                                                  isInvalid={!!helpers.errors.raisonSociale}
                                             />
                                             <div className={"mb-2"}>{helpers.errors.raisonSociale && <div className={"text-danger"}>{helpers.errors.raisonSociale}</div>}</div>
                                        </div>
                                   </div>

                                   <div className="separator my-5" />

                                   {/* Submit button */}
                                   <div className={"d-flex justify-content-end"}>
                                        <button className={"btn btn-sm btn-primary d-inline-flex align-items-center"} type={"submit"} disabled={helpers.isSubmitting}>
                                             {!helpers.isSubmitting ? <>Ajouter</> : <MySimpleSpinner size={"sm"} />}
                                        </button>
                                   </div>
                              </Form>
                         )
                    }}
               </Formik>
          </>
     )
}

interface IPropsModel {
     callback?: (val: IMachineModel) => void
     defaultTypeFieldValue?: typeof CONST_FOURNISSEUR_TYPE_SOCIETE_PORTAGE | typeof CONST_FOURNISSEUR_TYPE_FREELANCE | typeof CONST_FOURNISSEUR_TYPE_SOCIETE_SERVICE
     isTypeFieldReadOnly?: boolean
}

export default AddFournisseurForm
