import { useQuery, useQueryClient } from "react-query"
import axios, { AxiosError } from "axios"
import { IFournisseurResponsableModel, IHttpErrorResponseModel } from "@common-models/*"
import { toast } from "react-toastify"
import { CONST_API_FOURNISSEUR_RESPONSABLE_ENVOYER_INSTRUCTIONS_CONNEXION_ESPACE_TALENT_ENDPOINT, CONST_API_FOURNISSEUR_RESPONSABLE_GET_DETAILS_ENDPOINT } from "../../../endpoints"
import MySimpleSpinner from "@common-utils/MySimpleSpinner"
import MyAlert from "@common-utils/MyAlert"
import React, { useState } from "react"
import FournisseurResponsableEditDetails from "./FournisseurResponsableEditDetails"
import swal from "sweetalert2"
import Swal from "sweetalert2"

const FournisseurResponsableDetails = ({ fournisseurId, onSubmitEditionCallback, isFournisseurFieldVisible = true }: IPropsModel) => {
     const queryClient = useQueryClient()
     const [isEditing, setIsEditing] = useState(false)

     // Query
     const detailsQuery = useQuery<IFournisseurResponsableModel, AxiosError>("GET-FOURNISSEUR-RESP-DETAILS", async () => {
          return axios
               .get<IFournisseurResponsableModel>(CONST_API_FOURNISSEUR_RESPONSABLE_GET_DETAILS_ENDPOINT(fournisseurId))
               .then(r => {
                    return r.data
               })
               .catch((e: AxiosError) => {
                    const error: IHttpErrorResponseModel = e.response?.data

                    toast.error(error?.detail, { autoClose: false })

                    throw e
               })
     })

     function handleEdit(resp: IFournisseurResponsableModel) {
          queryClient.setQueryData<IFournisseurResponsableModel | undefined>("GET-FOURNISSEUR-RESP-DETAILS", () => {
               return resp
          })

          if (onSubmitEditionCallback) onSubmitEditionCallback(resp)

          setIsEditing(false)
     }

     function handleEnvoyerInstructionsDeConnexion() {
          swal.fire({
               icon: "warning",
               title: "Voulez-vous vraiment lui envoyer les instructions de connexion ?",
               showCancelButton: true,
               confirmButtonText: "Confirmer",
               cancelButtonText: "Annuler",
               showLoaderOnConfirm: true,
               preConfirm: async () => {
                    return axios
                         .post(CONST_API_FOURNISSEUR_RESPONSABLE_ENVOYER_INSTRUCTIONS_CONNEXION_ESPACE_TALENT_ENDPOINT(detailsQuery.data!.id), {})
                         .then(() => {
                              toast.success("Les instructions de connexion ont bien été envoyés.")
                         })
                         .catch(e => {
                              toast.error(e?.response?.data?.detail, { autoClose: false })
                         })
               },
               allowOutsideClick: () => !Swal.isLoading(),
          })
     }

     return (
          <>
               {detailsQuery.isFetching && (
                    <div className={"text-center p-5"}>
                         <MySimpleSpinner size={"xl"} />
                    </div>
               )}

               {!detailsQuery.isFetching && detailsQuery.isError && (
                    <MyAlert type={"danger"} classNames={"mb-4"}>
                         <>
                              <span className={"me-2"}>{detailsQuery.error?.response?.data?.detail}</span>
                              <button className={"btn btn-sm btn-danger"} onClick={() => detailsQuery.refetch()}>
                                   Recharger
                              </button>
                         </>
                    </MyAlert>
               )}

               {!detailsQuery.isFetching &&
                    !detailsQuery.isError &&
                    detailsQuery.data &&
                    (isEditing ? (
                         <FournisseurResponsableEditDetails
                              fournisseurResponsable={detailsQuery.data}
                              cancelCallback={() => setIsEditing(false)}
                              onSubmitCallback={handleEdit}
                              isFournisseurFieldVisible={isFournisseurFieldVisible}
                         />
                    ) : (
                         <>
                              <div className="row mb-7">
                                   <label className="col-lg-4 fw-bold text-muted">Compte actif</label>

                                   <div className="col-lg-8">
                                        <span className="fw-bolder fs-6 text-dark">
                                             {detailsQuery.data.user?.enabled ? (
                                                  <i className={"fas fa-check-circle text-success"} />
                                             ) : (
                                                  <i className={"fas fa-times-circle text-danger"}></i>
                                             )}
                                        </span>
                                   </div>
                              </div>

                              <div className="row mb-7">
                                   <label className="col-lg-4 fw-bold text-muted">Compte admin.</label>

                                   <div className="col-lg-8">
                                        <span className="fw-bolder fs-6 text-dark">
                                             {detailsQuery.data.user?.roles?.includes("ROLE_RESPONSABLE_FOURNISSEUR_ADMIN") ? (
                                                  <i className={"fas fa-check-circle text-success"} />
                                             ) : (
                                                  <i className={"fas fa-times-circle text-danger"}></i>
                                             )}
                                        </span>
                                   </div>
                              </div>

                              <div className="row mb-7">
                                   <label className="col-lg-4 fw-bold text-muted">Civilité</label>

                                   <div className="col-lg-8">
                                        <span className="fw-bolder fs-6 text-dark">
                                             {detailsQuery.data.user?.civiliteLabel ? (
                                                  detailsQuery.data.user?.civiliteLabel
                                             ) : (
                                                  <span className={"text-muted d-flex align-items-center"}>Non renseigné</span>
                                             )}
                                        </span>
                                   </div>
                              </div>

                              <div className="row mb-7">
                                   <label className="col-lg-4 fw-bold text-muted">Nom</label>

                                   <div className="col-lg-8">
                                        <span className="fw-bolder fs-6 text-dark">
                                             {detailsQuery.data.user?.nom ? (
                                                  detailsQuery.data.user?.nom
                                             ) : (
                                                  <span className={"text-muted d-flex align-items-center"}>Non renseigné</span>
                                             )}
                                        </span>
                                   </div>
                              </div>

                              <div className="row mb-7">
                                   <label className="col-lg-4 fw-bold text-muted">Prénom</label>

                                   <div className="col-lg-8">
                                        <span className="fw-bolder fs-6 text-dark">
                                             {detailsQuery.data.user?.prenom ? (
                                                  detailsQuery.data.user?.prenom
                                             ) : (
                                                  <span className={"text-muted d-flex align-items-center"}>Non renseigné</span>
                                             )}
                                        </span>
                                   </div>
                              </div>

                              <div className="row mb-7">
                                   <label className="col-lg-4 fw-bold text-muted">Email</label>

                                   <div className="col-lg-8">
                                        <span className="fw-bolder fs-6 text-dark">
                                             {detailsQuery.data.user?.username ? (
                                                  <a href={`mailto:${detailsQuery.data.user.username}`} rel={"noreferrer"} target={"_blank"}>
                                                       {detailsQuery.data.user?.username}
                                                  </a>
                                             ) : (
                                                  <span className={"text-muted d-flex align-items-center"}>Non renseigné</span>
                                             )}
                                        </span>
                                   </div>
                              </div>

                              <div className="row mb-7">
                                   <label className="col-lg-4 fw-bold text-muted">Tél.</label>

                                   <div className="col-lg-8">
                                        <span className="fw-bolder fs-6 text-dark">
                                             {detailsQuery.data.user?.tel ? (
                                                  <a
                                                       href={`tel:${detailsQuery.data.user.telIndicatif?.phoneCodeWithPlusPrefix} ${detailsQuery.data.user.tel}`}
                                                       rel={"noreferrer"}
                                                       target={"_blank"}
                                                  >
                                                       {detailsQuery.data.user.telIndicatif?.phoneCodeWithPlusPrefix} {detailsQuery.data.user.tel}
                                                  </a>
                                             ) : (
                                                  <span className={"text-muted d-flex align-items-center"}>Non renseigné</span>
                                             )}
                                        </span>
                                   </div>
                              </div>

                              <div className="row mb-7">
                                   <label className="col-lg-4 fw-bold text-muted">Poste</label>

                                   <div className="col-lg-8">
                                        <span className="fw-bolder fs-6 text-dark">
                                             {detailsQuery.data.poste ? detailsQuery.data.poste : <span className={"text-muted d-flex align-items-center"}>Non renseigné</span>}
                                        </span>
                                   </div>
                              </div>

                              {isFournisseurFieldVisible && (
                                   <div className="row mb-7">
                                        <label className="col-lg-4 fw-bold text-muted">Fournisseur</label>

                                        <div className="col-lg-8">
                                             <span className="fw-bolder fs-6 text-dark">{detailsQuery.data.fournisseur?.raisonSociale}</span>
                                        </div>
                                   </div>
                              )}

                              <div className={`d-flex ${detailsQuery.data.user?.dateDerniereConnexion ? "justify-content-end" : "justify-content-between"} gap-5`}>
                                   {!detailsQuery.data.user?.dateDerniereConnexion && (
                                        <button
                                             type={"button"}
                                             className={"btn btn-sm btn-outline btn-outline-primary d-flex align-items-center"}
                                             onClick={handleEnvoyerInstructionsDeConnexion}
                                        >
                                             Envoyer instructions de connexion
                                             <i className={"fas fa-envelope ms-2"} />
                                        </button>
                                   )}
                                   <button type={"button"} className={"btn btn-sm btn-outline btn-outline-warning d-flex align-items-center"} onClick={() => setIsEditing(true)}>
                                        EDITER <i className={"fas fa-edit ms-2"} />
                                   </button>
                              </div>
                         </>
                    ))}
          </>
     )
}

interface IPropsModel {
     fournisseurId: number
     onSubmitEditionCallback?: (resp: IFournisseurResponsableModel) => void
     isFournisseurFieldVisible?: boolean
}

export default FournisseurResponsableDetails
