import * as Yup from "yup"
import {
     CONST_CIVILITE_MR,
     CONST_CONSULTANT_CONTRAT_CDD,
     CONST_CONSULTANT_CONTRAT_ST,
     CONST_CONSULTANT_DE_DISPOSITIF_POLE_EMPLOI_AFPR,
     CONST_CONSULTANT_DE_DISPOSITIF_POLE_EMPLOI_PMSMP,
     CONST_CONSULTANT_ETAT_EN_COURS_EMBAUCHE_POUR_MISSION,
     CONST_CONSULTANT_ETAT_EN_COURS_EMBAUCHE_SANS_MISSION,
     CONST_CONSULTANT_STATUT_ST_FREELANCE,
     CONST_CONSULTANT_STATUT_ST_PORTAGE,
     CONST_CONSULTANT_STATUT_ST_SOCIETE_SERVICE,
     CONST_CONSULTANT_STATUTS_OPTIONS_FOR_SELECT2,
     CONST_CONTRATS_FOR_SELECT2,
     CONST_HTTP_CUSTOM_CODE_FORM_VALIDATION_ERROR,
} from "@common-constants/*"
import { Form, Formik, FormikHelpers } from "formik"
import MyAlert from "@common-utils/MyAlert"
import React, { useState } from "react"
import MySimpleSpinner from "@common-utils/MySimpleSpinner"
import MyCard from "@common-utils/MyCard"
import { useDemandeEmbaucheDetailsContext } from "../DemandeEmbaucheDetails"
import {
     IGetDemandeEmbaucheInfoContractResponseModel,
     ISetDemandeEmbaucheInfoContractSalarieFormikValuesModel,
     ISetDemandeEmbaucheInfoProSalarieRequestModel,
} from "./core/_models"
import MyCheckBoxField from "@common-utils/fields/MyCheckBoxField"
import MyDateDayMonthYearField from "@common-utils/fields/MyDateDayMonthYearField"
import MyRadioWithLabelAndDescriptionField from "@common-utils/fields/MyRadioWithLabelAndDescriptionField"
import MyInputField from "@common-utils/fields/MyInputField"
import MySelectField from "@common-utils/fields/MySelectField"
import MySelectGetAllDataFromServer from "@common-utils/fields/MySelectGetAllDataFromServer"
import { CONST_API_UTILS_GET_ACTIVE_PERSONNELS_ENDPOINT, CONST_API_UTILS_GET_ENTITES_ENDPOINT, CONST_API_UTILS_GET_METIERS_URL_ENDPOINT } from "../../../../../endpoints"
import { calculateFinPeriodeEssai } from "@common-utils/core/_requests"
import { ICalculateFinPeriodeEssaiRequestModel } from "@common-utils/core/_models"
import { AxiosError, AxiosResponse } from "axios"
import moment from "moment/moment"
import { toast } from "react-toastify"
import MyLoadingOverlay from "@common-utils/MyLoadingOverlay"
import { setDemandeEmbaucheInfoContractSalarie } from "./core/_requests"
import { IConsultantModel, IHttpErrorResponseModel } from "@common-models/*"
import { useQueryClient } from "react-query"
import MyModal from "@common-utils/MyModal"
import AddMetierForm from "../../../../utils/_AddMetierForm"

const DemandeEmbaucheDetailsInfoContractSalarie = ({ data }: { data: IGetDemandeEmbaucheInfoContractResponseModel }) => {
     const context = useDemandeEmbaucheDetailsContext()
     const [isCalculatingDateFinPe, setIsCalculatingDateFinPe] = useState<boolean>(false)
     const [isAddingMetier, setIsAddingMetier] = useState<boolean>(false)
     const queryClient = useQueryClient()

     const schema = Yup.object().shape({
          doesConsultantBeneficierDispositifPoleEmploi: Yup.boolean().label(`"Pôle emploi"`).nullable().required(),
          dispositifPoleEmploi: Yup.string()
               .label(`"Dispositif"`)
               .nullable()
               .when("doesConsultantBeneficierDispositifPoleEmploi", {
                    is: true,
                    then: schema => schema.required(),
               }),
          dispositifPoleEmploiDateDebut: Yup.string()
               .label(`"Date de début"`)
               .nullable()
               .when("doesConsultantBeneficierDispositifPoleEmploi", {
                    is: true,
                    then: schema => schema.required(),
               }),
          dispositifPoleEmploiDateFin: Yup.string()
               .label(`"Date de fin"`)
               .nullable()
               .when("doesConsultantBeneficierDispositifPoleEmploi", {
                    is: true,
                    then: schema => schema.required(),
               }),
          isFacturationClientPendantPeriodeDispositifPoleEmploi: Yup.boolean()
               .label(`"Facturation client"`)
               .nullable()
               .when("doesConsultantBeneficierDispositifPoleEmploi", {
                    is: true,
                    then: schema => schema.required(),
               }),
          facturationClientPendantPeriodeDispositifPoleEmploiDateDebut: Yup.string()
               .label(`"Date de début"`)
               .nullable()
               .when("isFacturationClientPendantPeriodeDispositifPoleEmploi", {
                    is: true,
                    then: schema => schema.required(),
               }),
          facturationClientPendantPeriodeDispositifPoleEmploiDateFin: Yup.string()
               .label(`"Date de fin"`)
               .nullable()
               .when("isFacturationClientPendantPeriodeDispositifPoleEmploi", {
                    is: true,
                    then: schema => schema.required(),
               }),
          facturationClientPendantPeriodeDispositifPoleEmploiTauxJournalier: Yup.string()
               .label(`"TJM"`)
               .nullable()
               .when("isFacturationClientPendantPeriodeDispositifPoleEmploi", {
                    is: true,
                    then: schema => schema.required(),
               }),
          contrat: Yup.string().label(`"Contrat"`).nullable().required(),
          contratDateFin: Yup.string()
               .label(`"Date fin de contrat"`)
               .nullable()
               .when("contrat", {
                    is: CONST_CONSULTANT_CONTRAT_CDD,
                    then: schema => schema.required(),
               }),
          statut: Yup.string().label(`"Statut"`).nullable().required(),
          entiteRattachement: Yup.number().label(`"Entité de rattachement"`).nullable().required(),
          entiteFacturation: Yup.number().label(`"Entité de facturation"`).nullable().required(),
          metier: Yup.number().label(`"Métier"`).nullable().required(),
          dateEmbauche: Yup.string().label(`"Date d'embauche"`).nullable().required(),
          dateSouhaiteRemiseContrat: Yup.string().label(`"Remise de contrat"`).nullable().required(),
          position: Yup.string().label(`"Position"`).nullable(),
          coeff: Yup.string().label(`"Coeff."`).nullable(),
          typeEmbauche: Yup.string().label(`"Type d'embauche"`).nullable().required(),
          doesConsultantHaveDelegationAdministrative: Yup.boolean().label("Délégation").nullable().required(),
          personnelsWithDelegationAdminDuConsultant: Yup.array()
               .label("Responsables")
               .nullable()
               .when("doesConsultantHaveDelegationAdministrative", {
                    is: true,
                    then: schema => schema.required(),
               }),
          salaireBrutMensuelBase35h: Yup.number()
               .label(`"Salaire"`)
               .nullable()
               .when("typeEmbauche", {
                    is: CONST_CONSULTANT_ETAT_EN_COURS_EMBAUCHE_POUR_MISSION,
                    then: schema => schema.required(),
               }),
          heuresTravailParJour: Yup.number()
               .label(`"Heures de travail"`)
               .nullable()
               .when("typeEmbauche", {
                    is: CONST_CONSULTANT_ETAT_EN_COURS_EMBAUCHE_POUR_MISSION,
                    then: schema => schema.required(),
               }),
          heuresTravailSuppParJour: Yup.number()
               .label(`"Heures supp."`)
               .nullable()
               .when("typeEmbauche", {
                    is: CONST_CONSULTANT_ETAT_EN_COURS_EMBAUCHE_POUR_MISSION,
                    then: schema => schema.required(),
               }),
          doesConsultantHavePeriodeEssai: Yup.boolean()
               .label(`"Période d'essai"`)
               .nullable()
               .when("typeEmbauche", {
                    is: CONST_CONSULTANT_ETAT_EN_COURS_EMBAUCHE_POUR_MISSION,
                    then: schema => schema.required(),
               }),
          dateFinPeriodeEssai: Yup.string()
               .label(`"Date fin PE"`)
               .nullable()
               .when("doesConsultantHavePeriodeEssai", {
                    is: true,
                    then: schema => schema.required(),
               }),
     })

     const initialValues: ISetDemandeEmbaucheInfoContractSalarieFormikValuesModel = {
          doesConsultantBeneficierDispositifPoleEmploi: !!data.demandeEmbauche?.dispositifPoleEmploi,
          dispositifPoleEmploi: data.demandeEmbauche?.dispositifPoleEmploi,
          dispositifPoleEmploiDateDebut: data.demandeEmbauche?.dispositifPoleEmploiDateDebut?.format("YYYY-MM-DD"),
          dispositifPoleEmploiDateFin: data.demandeEmbauche?.dispositifPoleEmploiDateFin?.format("YYYY-MM-DD"),
          isFacturationClientPendantPeriodeDispositifPoleEmploi: !!data.demandeEmbauche?.dispositifPoleEmploiFacturationClient,
          facturationClientPendantPeriodeDispositifPoleEmploiDateDebut: data.demandeEmbauche?.dispositifPoleEmploiFacturationClientDateDebut?.format("YYYY-MM-DD"),
          facturationClientPendantPeriodeDispositifPoleEmploiDateFin: data.demandeEmbauche?.dispositifPoleEmploiFacturationClientDateFin?.format("YYYY-MM-DD"),
          facturationClientPendantPeriodeDispositifPoleEmploiTauxJournalier: data.demandeEmbauche?.dispositifPoleEmploiFacturationClientTJM,
          contrat: data.consultant.contrat as "CONTRAT_CDI" | "CONTRAT_CDD" | "CONTRAT_CDIC" | null,
          contratDateFin: data.consultant.dateSortie?.format("YYYY-MM-DD"),
          statut: data.consultant.statut as "STATUT_ETAM" | "STATUT_AGENT_MAITRISE" | "STATUT_CADRE" | null,
          entiteRattachement: data.consultant.entite ? (data.consultant.entite.id as number) : null,
          entiteFacturation: data.consultant.entiteFacturation ? (data.consultant.entiteFacturation.id as number) : null,
          metier: data.consultant.metier ? (data.consultant.metier.id as number) : null,
          dateEmbauche: data.consultant.dateEmbauche ? data.consultant.dateEmbauche.format("YYYY-MM-DD") : null,
          dateSouhaiteRemiseContrat: data.demandeEmbauche?.dateRemiseContratSouhaite ? data.demandeEmbauche.dateRemiseContratSouhaite.format("YYYY-MM-DD") : null,
          position: data.salarie?.position,
          coeff: data.salarie?.coefficient,
          typeEmbauche: data.consultant?.etat as "ETAT_EN_COURS_EMBAUCHE_SANS_MISSION" | "ETAT_EN_COURS_EMBAUCHE_POUR_MISSION" | null,
          salaireBrutMensuelBase35h: data?.consultant.coutBrutNonCharge as number | null,
          doesConsultantHaveDelegationAdministrative: !!(
               data.consultant.personnelAvecDelegationDroitsConsultants && data.consultant.personnelAvecDelegationDroitsConsultants.length > 0
          ),
          personnelsWithDelegationAdminDuConsultant:
               data.consultant.personnelAvecDelegationDroitsConsultants && data.consultant.personnelAvecDelegationDroitsConsultants.length > 0
                    ? data.consultant.personnelAvecDelegationDroitsConsultants.map(item => item.personnel?.id as number)
                    : null,
          heuresTravailParJour: data.consultant.heuresTravail,
          heuresTravailSuppParJour: data.consultant.heuresTravailSupp,
          doesConsultantHavePeriodeEssai: !!(data.consultant.consultantPEs && data.consultant.consultantPEs.length > 0),
          dateFinPeriodeEssai: data.consultant.consultantPEs && data.consultant.consultantPEs.length > 0 ? data.consultant.consultantPEs[0].dateFin1?.format("YYYY-MM-DD") : null,
     }

     function handleSubmit(values: ISetDemandeEmbaucheInfoContractSalarieFormikValuesModel, helpers: FormikHelpers<ISetDemandeEmbaucheInfoContractSalarieFormikValuesModel>) {
          helpers.setStatus(null)

          const payload: ISetDemandeEmbaucheInfoProSalarieRequestModel = {
               doesConsultantBeneficierDispositifPoleEmploi: values.doesConsultantBeneficierDispositifPoleEmploi,
               dispositifPoleEmploi: values.dispositifPoleEmploi,
               dispositifPoleEmploiDateDebut: values.dispositifPoleEmploiDateDebut,
               dispositifPoleEmploiDateFin: values.dispositifPoleEmploiDateFin,
               isFacturationClientPendantPeriodeDispositifPoleEmploi: values.isFacturationClientPendantPeriodeDispositifPoleEmploi,
               facturationClientPendantPeriodeDispositifPoleEmploiDateDebut: values.facturationClientPendantPeriodeDispositifPoleEmploiDateDebut,
               facturationClientPendantPeriodeDispositifPoleEmploiDateFin: values.facturationClientPendantPeriodeDispositifPoleEmploiDateFin,
               facturationClientPendantPeriodeDispositifPoleEmploiTauxJournalier: values.facturationClientPendantPeriodeDispositifPoleEmploiTauxJournalier,
               contrat: values.contrat as "CONTRAT_CDI" | "CONTRAT_CDD" | "CONTRAT_CDIC",
               contratDateFin: values.contratDateFin,
               statut: values.statut as "STATUT_ETAM" | "STATUT_AGENT_MAITRISE" | "STATUT_CADRE",
               entiteRattachement: values.entiteRattachement as number,
               entiteFacturation: values.entiteFacturation as number,
               metier: values.metier as number,
               dateEmbauche: values.dateEmbauche as string,
               dateSouhaiteRemiseContrat: values.dateSouhaiteRemiseContrat as string,
               position: values.position,
               coeff: values.coeff,
               typeEmbauche: values.typeEmbauche as "ETAT_EN_COURS_EMBAUCHE_POUR_MISSION" | "ETAT_EMBAUCHE_AVEC_MISSION",
               salaireBrutMensuelBase35h: values.salaireBrutMensuelBase35h as number,
               doesConsultantHaveDelegationAdministrative: values.doesConsultantHaveDelegationAdministrative,
               personnelsWithDelegationAdminDuConsultant: values.personnelsWithDelegationAdminDuConsultant,
               heuresTravailParJour: values.heuresTravailParJour,
               heuresTravailSuppParJour: values.heuresTravailSuppParJour,
               doesConsultantHavePeriodeEssai: values.doesConsultantHavePeriodeEssai,
               dateFinPeriodeEssai: values.dateFinPeriodeEssai,
          }

          setDemandeEmbaucheInfoContractSalarie(data.consultant.id as number, payload)
               .then(() => {
                    toast.success("Vos modifications ont bien été prises en compte.")
                    queryClient.setQueryData<IConsultantModel | undefined>(context.REACT_QUERY_KEY_GET_DE_CONTRAT_ETAT_STATUT, (prev: IConsultantModel | undefined) => {
                         if (prev) {
                              prev = {
                                   ...prev,
                                   etat: values.typeEmbauche as "ETAT_EN_COURS_EMBAUCHE_SANS_MISSION" | "ETAT_EN_COURS_EMBAUCHE_POUR_MISSION",
                              }
                         }
                         return prev
                    })
                    context.setNavigationStep(values.typeEmbauche === "ETAT_EN_COURS_EMBAUCHE_POUR_MISSION" ? "client" : "doc")
               })
               .catch((e: AxiosError) => {
                    const error: IHttpErrorResponseModel = e.response?.data

                    // Set form errors
                    if (error?.code === CONST_HTTP_CUSTOM_CODE_FORM_VALIDATION_ERROR && error?.errors) {
                         for (const key in error.errors) helpers.setFieldError(key, error.errors[key])
                    }

                    // Set form global status and notify user using a toast
                    helpers.setStatus(error?.detail)
                    toast.error(error?.detail, { autoClose: false })

                    // Stop submit loader
                    helpers.setSubmitting(false)
               })
               .finally(() => helpers.setSubmitting(false))
     }

     return (
          <>
               <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={schema}>
                    {helpers => {
                         function handleCalculateDateFinPeriodeEssai(contrat: any, statut: any, dateEmbauche: any, dateFinContrat: any, doesConsultantHavePeriodeEssai: any) {
                              let request: null | Promise<AxiosResponse<{ dateFin: moment.Moment }>> = null
                              if (contrat && statut && dateEmbauche && doesConsultantHavePeriodeEssai === true) {
                                   if (contrat === "CONTRAT_CDD") {
                                        if (dateFinContrat) {
                                             const payload: ICalculateFinPeriodeEssaiRequestModel = { contrat, statut, dateDebutPE: dateEmbauche, dateFinCDD: dateFinContrat }
                                             request = calculateFinPeriodeEssai(payload)
                                        }
                                   } else {
                                        const payload: ICalculateFinPeriodeEssaiRequestModel = { contrat, statut, dateDebutPE: dateEmbauche }
                                        request = calculateFinPeriodeEssai(payload)
                                   }

                                   if (request !== null) {
                                        setIsCalculatingDateFinPe(true)
                                        request
                                             .then(r => {
                                                  helpers.setFieldValue("dateFinPeriodeEssai", r.data.dateFin.format("YYYY-MM-DD")).then(() => {
                                                       setIsCalculatingDateFinPe(false)
                                                       toast.info("La date de fin de PE a été calculée automatiquement. Veuillez la modifier si besoin.")
                                                  })
                                             })
                                             .catch(() => toast.error("Une erreur est survenue lors du calcul de la date de fin de PE"))
                                   }
                              }
                         }

                         return (
                              <MyCard classNames={"w-100 w-lg-75 w-xl-60"}>
                                   <MyCard.Body>
                                        <Form noValidate autoComplete="off">
                                             {helpers.status && (
                                                  <MyAlert type={"danger"} classNames={"mb-4"}>
                                                       {helpers.status}
                                                  </MyAlert>
                                             )}

                                             <div className="d-flex justify-content-between align-items-center mb-2">
                                                  <h3>Informations contractuelles</h3>
                                             </div>
                                             <div className="separator separator-dashed mb-6" />

                                             {/* Dispositif Pôle emploi */}
                                             <div className={"mb-4"}>
                                                  {/* Bénéficie-elle d'un dispositf Pôle emploi? */}
                                                  <div className="row mb-4">
                                                       <label className="col-lg-4 col-form-label fw-bold fs-6">
                                                            Bénéficie-{data.consultant.civilite === CONST_CIVILITE_MR ? "il" : "elle"} de Pôle emploi?
                                                       </label>

                                                       <div className="col-lg-8 d-flex align-items-center">
                                                            <MyCheckBoxField
                                                                 name={"doesConsultantBeneficierDispositifPoleEmploi"}
                                                                 value={helpers.values.doesConsultantBeneficierDispositifPoleEmploi}
                                                                 isInvalid={!!helpers.errors.doesConsultantBeneficierDispositifPoleEmploi}
                                                                 onChange={val => helpers.setFieldValue("doesConsultantBeneficierDispositifPoleEmploi", val)}
                                                            />
                                                            {helpers.errors.doesConsultantBeneficierDispositifPoleEmploi && (
                                                                 <div className={"text-danger"}>{helpers.errors.doesConsultantBeneficierDispositifPoleEmploi}</div>
                                                            )}
                                                       </div>
                                                  </div>

                                                  {helpers.values.doesConsultantBeneficierDispositifPoleEmploi && (
                                                       <div className={"p-4 border border-dashed"}>
                                                            <h4 className={"mb-4"}>Pôle emploi</h4>
                                                            <div className="separator separator-dashed mb-6" />

                                                            {/* Dispositif Pôle emploi*/}
                                                            <div className="row mb-4">
                                                                 <label className="col-lg-4 col-form-label fw-bold fs-6 required">Choisissez le dispositif</label>

                                                                 <div className="col-lg-8">
                                                                      <div className="row g-2">
                                                                           <div className="col-md-6 col-lg-12">
                                                                                <MyRadioWithLabelAndDescriptionField
                                                                                     name={"dispositifPoleEmploi"}
                                                                                     value={CONST_CONSULTANT_DE_DISPOSITIF_POLE_EMPLOI_AFPR}
                                                                                     isChecked={
                                                                                          helpers.values.dispositifPoleEmploi === CONST_CONSULTANT_DE_DISPOSITIF_POLE_EMPLOI_AFPR
                                                                                     }
                                                                                     label={"AFPR"}
                                                                                     description={"Action de Formation Préalable au Recrutement"}
                                                                                     iconFontAwesomeClassName={"a"}
                                                                                     color={"primary"}
                                                                                     isInvalid={!!helpers.errors.dispositifPoleEmploi}
                                                                                     onChange={val => helpers.setFieldValue("dispositifPoleEmploi", val)}
                                                                                />
                                                                           </div>
                                                                           <div className="col-md-6 col-lg-12">
                                                                                <MyRadioWithLabelAndDescriptionField
                                                                                     name={"dispositifPoleEmploi"}
                                                                                     value={CONST_CONSULTANT_DE_DISPOSITIF_POLE_EMPLOI_PMSMP}
                                                                                     isChecked={
                                                                                          helpers.values.dispositifPoleEmploi === CONST_CONSULTANT_DE_DISPOSITIF_POLE_EMPLOI_PMSMP
                                                                                     }
                                                                                     label={"PMSMP"}
                                                                                     description={"Périodes de Mise en Situation en Milieu Professionnel"}
                                                                                     iconFontAwesomeClassName={"p"}
                                                                                     color={"success"}
                                                                                     isInvalid={!!helpers.errors.dispositifPoleEmploi}
                                                                                     onChange={val => helpers.setFieldValue("dispositifPoleEmploi", val)}
                                                                                />
                                                                           </div>
                                                                      </div>
                                                                      {helpers.errors.dispositifPoleEmploi && (
                                                                           <div className={"text-danger"}>{helpers.errors.dispositifPoleEmploi}</div>
                                                                      )}
                                                                 </div>
                                                            </div>

                                                            {/* Période PL */}
                                                            <div className="row mb-4">
                                                                 <label className="col-lg-4 col-form-label fw-bold fs-6 required">Période</label>

                                                                 <div className="col-lg-8">
                                                                      <div className="d-flex">
                                                                           <MyDateDayMonthYearField
                                                                                name={"dispositifPoleEmploiDateDebut"}
                                                                                value={helpers.values.dispositifPoleEmploiDateDebut}
                                                                                isInvalid={!!helpers.errors.dispositifPoleEmploiDateFin}
                                                                                minDate={helpers.values.dateEmbauche}
                                                                                maxDate={helpers.values.dispositifPoleEmploiDateFin}
                                                                                onChange={val => helpers.setFieldValue("dispositifPoleEmploiDateDebut", val)}
                                                                                placeholder={"Date de début"}
                                                                                classNames={"rounded-0 rounded-start"}
                                                                           />
                                                                           <span className="input-group-text fas fa-ellipsis d-flex align-items-center rounded-0" />
                                                                           <MyDateDayMonthYearField
                                                                                name={"dispositifPoleEmploiDateFin"}
                                                                                value={helpers.values.dispositifPoleEmploiDateFin}
                                                                                minDate={helpers.values.dispositifPoleEmploiDateDebut}
                                                                                isInvalid={!!helpers.errors.dispositifPoleEmploiDateFin}
                                                                                onChange={val => helpers.setFieldValue("dispositifPoleEmploiDateFin", val)}
                                                                                placeholder={"Date de fin"}
                                                                                classNames={"rounded-0 rounded-end"}
                                                                           />
                                                                      </div>
                                                                      {(helpers.errors.dispositifPoleEmploiDateDebut || helpers.errors.dispositifPoleEmploiDateFin) && (
                                                                           <div className={"text-danger"}>Période requise ou incorrecte</div>
                                                                      )}
                                                                 </div>
                                                            </div>

                                                            {/* Période facturation client? */}
                                                            <div className="row">
                                                                 <label className="col-lg-4 col-form-label fw-bold fs-6">
                                                                      Il y a-il une facturation client pendant cette période?
                                                                 </label>

                                                                 <div className="col-lg-8 d-flex align-items-center">
                                                                      <MyCheckBoxField
                                                                           name={"doesConsultantBeneficierDispositifPoleEmploi"}
                                                                           value={helpers.values.isFacturationClientPendantPeriodeDispositifPoleEmploi}
                                                                           isInvalid={!!helpers.errors.isFacturationClientPendantPeriodeDispositifPoleEmploi}
                                                                           onChange={val => helpers.setFieldValue("isFacturationClientPendantPeriodeDispositifPoleEmploi", val)}
                                                                      />
                                                                      {helpers.errors.isFacturationClientPendantPeriodeDispositifPoleEmploi && (
                                                                           <div className={"text-danger"}>
                                                                                {helpers.errors.isFacturationClientPendantPeriodeDispositifPoleEmploi}
                                                                           </div>
                                                                      )}
                                                                 </div>
                                                            </div>

                                                            {helpers.values.isFacturationClientPendantPeriodeDispositifPoleEmploi && (
                                                                 <div className={"p-4 border border-dashed mt-4"}>
                                                                      <h5 className={"mb-4"}>Facturation client pendant la période Pôle emploi</h5>
                                                                      <div className="separator separator-dashed mb-6" />

                                                                      {/* Période Facturation période PL */}
                                                                      <div className="row mb-4">
                                                                           <label className="col-lg-4 col-form-label fw-bold fs-6 required">Période</label>

                                                                           <div className="col-lg-8">
                                                                                <div className="d-flex">
                                                                                     <MyDateDayMonthYearField
                                                                                          name={"facturationClientPendantPeriodeDispositifPoleEmploiDateDebut"}
                                                                                          value={helpers.values.facturationClientPendantPeriodeDispositifPoleEmploiDateDebut}
                                                                                          isInvalid={!!helpers.errors.facturationClientPendantPeriodeDispositifPoleEmploiDateDebut}
                                                                                          minDate={helpers.values.dateEmbauche}
                                                                                          maxDate={helpers.values.facturationClientPendantPeriodeDispositifPoleEmploiDateFin}
                                                                                          onChange={val =>
                                                                                               helpers.setFieldValue(
                                                                                                    "facturationClientPendantPeriodeDispositifPoleEmploiDateDebut",
                                                                                                    val
                                                                                               )
                                                                                          }
                                                                                          placeholder={"Date de début"}
                                                                                          classNames={"rounded-0 rounded-start"}
                                                                                     />
                                                                                     <span className="input-group-text fas fa-ellipsis d-flex align-items-center rounded-0" />
                                                                                     <MyDateDayMonthYearField
                                                                                          name={"facturationClientPendantPeriodeDispositifPoleEmploiDateFin"}
                                                                                          value={helpers.values.facturationClientPendantPeriodeDispositifPoleEmploiDateFin}
                                                                                          minDate={helpers.values.facturationClientPendantPeriodeDispositifPoleEmploiDateDebut}
                                                                                          maxDate={helpers.values.dispositifPoleEmploiDateFin}
                                                                                          isInvalid={!!helpers.errors.facturationClientPendantPeriodeDispositifPoleEmploiDateFin}
                                                                                          onChange={val =>
                                                                                               helpers.setFieldValue(
                                                                                                    "facturationClientPendantPeriodeDispositifPoleEmploiDateFin",
                                                                                                    val
                                                                                               )
                                                                                          }
                                                                                          placeholder={"Date de fin"}
                                                                                          classNames={"rounded-0 rounded-end"}
                                                                                     />
                                                                                </div>
                                                                                {(helpers.errors.facturationClientPendantPeriodeDispositifPoleEmploiDateDebut ||
                                                                                     helpers.errors.facturationClientPendantPeriodeDispositifPoleEmploiDateFin) && (
                                                                                     <div className={"text-danger"}>Période requise ou incorrecte</div>
                                                                                )}
                                                                           </div>
                                                                      </div>

                                                                      {/* TJM période PL */}
                                                                      <div className="row">
                                                                           <label className="col-lg-4 col-form-label fw-bold fs-6 required">Précisez le TJM</label>

                                                                           <div className="col-lg-8">
                                                                                <MyInputField
                                                                                     type={"number"}
                                                                                     inputMode={"numeric"}
                                                                                     name={"facturationClientPendantPeriodeDispositifPoleEmploiTauxJournalier"}
                                                                                     value={helpers.values.facturationClientPendantPeriodeDispositifPoleEmploiTauxJournalier}
                                                                                     isInvalid={!!helpers.errors.facturationClientPendantPeriodeDispositifPoleEmploiTauxJournalier}
                                                                                     onChange={val =>
                                                                                          helpers.setFieldValue(
                                                                                               "facturationClientPendantPeriodeDispositifPoleEmploiTauxJournalier",
                                                                                               val
                                                                                          )
                                                                                     }
                                                                                     placeholder={"TJM"}
                                                                                />
                                                                                {helpers.errors.facturationClientPendantPeriodeDispositifPoleEmploiTauxJournalier && (
                                                                                     <div className={"text-danger"}>
                                                                                          {helpers.errors.facturationClientPendantPeriodeDispositifPoleEmploiTauxJournalier}
                                                                                     </div>
                                                                                )}
                                                                           </div>
                                                                      </div>
                                                                 </div>
                                                            )}
                                                       </div>
                                                  )}
                                             </div>

                                             {/* Métier */}
                                             <div className="row mb-4">
                                                  <label className="col-lg-4 col-form-label fw-bold fs-6 required">Métier</label>

                                                  <div className="col-lg-8">
                                                       <MySelectGetAllDataFromServer
                                                            name={"metier"}
                                                            value={helpers.values.metier}
                                                            defaultOptions={
                                                                 data.consultant.metier
                                                                      ? [{ value: data.consultant.metier.id as number, label: data.consultant.metier.nom as string }]
                                                                      : []
                                                            }
                                                            isInvalid={!!helpers.errors.metier}
                                                            onChange={val => helpers.setFieldValue("metier", val)}
                                                            url={CONST_API_UTILS_GET_METIERS_URL_ENDPOINT}
                                                       />
                                                       <div className="d-flex justify-content-between flex-row-reverse mt-1">
                                                            {helpers.errors.metier && <div className={"text-danger"}>{helpers.errors.metier}</div>}
                                                            <div className="text-primary fw-bold cursor-pointer" onClick={() => setIsAddingMetier(true)}>
                                                                 Ajouter <i className={"fas fa-plus-circle text-primary"} />
                                                            </div>
                                                       </div>
                                                  </div>
                                             </div>

                                             {/* Statut */}
                                             <div className="row mb-4">
                                                  <label className="col-lg-4 col-form-label fw-bold fs-6 required">Statut</label>

                                                  <div className="col-lg-8">
                                                       <MySelectField
                                                            name={"statut"}
                                                            value={helpers.values.statut}
                                                            options={CONST_CONSULTANT_STATUTS_OPTIONS_FOR_SELECT2.filter(
                                                                 ({ value }) =>
                                                                      ![
                                                                           CONST_CONSULTANT_STATUT_ST_SOCIETE_SERVICE,
                                                                           CONST_CONSULTANT_STATUT_ST_FREELANCE,
                                                                           CONST_CONSULTANT_STATUT_ST_PORTAGE,
                                                                      ].includes(value)
                                                            )}
                                                            isInvalid={!!helpers.errors.statut}
                                                            onChange={val => {
                                                                 helpers.setFieldValue("statut", val).then(() => {
                                                                      handleCalculateDateFinPeriodeEssai(
                                                                           helpers.values.contrat,
                                                                           val,
                                                                           helpers.values.dateEmbauche,
                                                                           helpers.values.contratDateFin,
                                                                           helpers.values.doesConsultantHavePeriodeEssai
                                                                      )
                                                                 })
                                                            }}
                                                       />
                                                       {helpers.errors.statut && <div className={"text-danger"}>{helpers.errors.statut}</div>}
                                                  </div>
                                             </div>

                                             {/* Date d'embauche */}
                                             <div className="row mb-4">
                                                  <label className="col-lg-4 col-form-label fw-bold fs-6 required">Date d'embauche</label>

                                                  <div className="col-lg-8">
                                                       <MyDateDayMonthYearField
                                                            name={"dateEmbauche"}
                                                            value={helpers.values.dateEmbauche}
                                                            isInvalid={!!helpers.errors.dateEmbauche}
                                                            onChange={val => {
                                                                 helpers.setFieldValue("dateEmbauche", val).then(() => {
                                                                      handleCalculateDateFinPeriodeEssai(
                                                                           helpers.values.contrat,
                                                                           helpers.values.statut,
                                                                           val,
                                                                           helpers.values.contratDateFin,
                                                                           helpers.values.doesConsultantHavePeriodeEssai
                                                                      )
                                                                 })
                                                            }}
                                                            placeholder={"Date d'embauche"}
                                                       />
                                                       {helpers.errors.dateEmbauche && <div className={"text-danger"}>{helpers.errors.dateEmbauche}</div>}
                                                  </div>
                                             </div>

                                             {/* Contrat */}
                                             <div className="row mb-4">
                                                  <label className="col-lg-4 col-form-label fw-bold fs-6 required">Contrat</label>

                                                  <div className="col-lg-8">
                                                       <MySelectField
                                                            name={"contrat"}
                                                            options={CONST_CONTRATS_FOR_SELECT2.filter(item => item.value !== CONST_CONSULTANT_CONTRAT_ST)}
                                                            value={helpers.values.contrat}
                                                            isInvalid={!!helpers.errors.contrat}
                                                            onChange={val => {
                                                                 helpers.setFieldValue("contrat", val).then(() => {
                                                                      handleCalculateDateFinPeriodeEssai(
                                                                           val,
                                                                           helpers.values.statut,
                                                                           helpers.values.dateEmbauche,
                                                                           helpers.values.contratDateFin,
                                                                           helpers.values.doesConsultantHavePeriodeEssai
                                                                      )
                                                                 })
                                                            }}
                                                       />
                                                       {helpers.errors.contrat && <div className={"text-danger"}>{helpers.errors.contrat}</div>}
                                                  </div>
                                             </div>

                                             {/* Date fin de contrat */}
                                             {helpers.values.contrat === CONST_CONSULTANT_CONTRAT_CDD && (
                                                  <div className="row mb-4">
                                                       <label className="col-lg-4 col-form-label fw-bold fs-6 required">Date de fin de contrat</label>

                                                       <div className="col-lg-8">
                                                            <MyDateDayMonthYearField
                                                                 name={"contrat"}
                                                                 value={helpers.values.contratDateFin}
                                                                 isInvalid={!!helpers.errors.contratDateFin}
                                                                 onChange={val => {
                                                                      helpers.setFieldValue("contratDateFin", val).then(() => {
                                                                           handleCalculateDateFinPeriodeEssai(
                                                                                helpers.values.contrat,
                                                                                helpers.values.statut,
                                                                                helpers.values.dateEmbauche,
                                                                                val,
                                                                                helpers.values.doesConsultantHavePeriodeEssai
                                                                           )
                                                                      })
                                                                 }}
                                                                 placeholder={"Date de fin de contrat"}
                                                            />
                                                            {helpers.errors.contratDateFin && <div className={"text-danger"}>{helpers.errors.contratDateFin}</div>}
                                                       </div>
                                                  </div>
                                             )}

                                             {/* Date souhaitée remise contrat */}
                                             <div className="row mb-4">
                                                  <label className="col-lg-4 col-form-label fw-bold fs-6 required">Date souhaitée de remise de contrat par le siège</label>

                                                  <div className="col-lg-8">
                                                       <MyDateDayMonthYearField
                                                            name={"dateSouhaiteRemiseContrat"}
                                                            value={helpers.values.dateSouhaiteRemiseContrat}
                                                            isInvalid={!!helpers.errors.dateSouhaiteRemiseContrat}
                                                            maxDate={helpers.values.dateEmbauche}
                                                            onChange={val => helpers.setFieldValue("dateSouhaiteRemiseContrat", val)}
                                                            placeholder={"Date souhaitée de remise de contrat"}
                                                       />
                                                       {helpers.errors.dateSouhaiteRemiseContrat && <div className={"text-danger"}>{helpers.errors.dateSouhaiteRemiseContrat}</div>}
                                                  </div>
                                             </div>

                                             {/* Entité de rattachement */}
                                             <div className="row mb-4">
                                                  <label className="col-lg-4 col-form-label fw-bold fs-6 required">Entité de rattachement</label>

                                                  <div className="col-lg-8">
                                                       <MySelectGetAllDataFromServer
                                                            name={"entiteRattachement"}
                                                            value={helpers.values.entiteRattachement}
                                                            defaultOptions={
                                                                 data.consultant.entite
                                                                      ? [{ value: data.consultant.entite.id as number, label: data.consultant.entite.nom as string }]
                                                                      : []
                                                            }
                                                            isInvalid={!!helpers.errors.entiteRattachement}
                                                            onChange={val => helpers.setFieldValue("entiteRattachement", val)}
                                                            url={CONST_API_UTILS_GET_ENTITES_ENDPOINT}
                                                       />
                                                       {helpers.errors.entiteRattachement && <div className={"text-danger"}>{helpers.errors.entiteRattachement}</div>}
                                                  </div>
                                             </div>

                                             {/* Entité de facturation */}
                                             <div className="row mb-4">
                                                  <label className="col-lg-4 col-form-label fw-bold fs-6 required">Entité de facturation</label>

                                                  <div className="col-lg-8">
                                                       <MySelectGetAllDataFromServer
                                                            name={"entiteFacturation"}
                                                            value={helpers.values.entiteFacturation}
                                                            defaultOptions={
                                                                 data.consultant.entiteFacturation
                                                                      ? [
                                                                             {
                                                                                  value: data.consultant.entiteFacturation.id as number,
                                                                                  label: data.consultant.entiteFacturation.nom as string,
                                                                             },
                                                                        ]
                                                                      : []
                                                            }
                                                            isInvalid={!!helpers.errors.entiteFacturation}
                                                            onChange={val => helpers.setFieldValue("entiteFacturation", val)}
                                                            url={CONST_API_UTILS_GET_ENTITES_ENDPOINT}
                                                       />
                                                       {helpers.errors.entiteFacturation && <div className={"text-danger"}>{helpers.errors.entiteFacturation}</div>}
                                                  </div>
                                             </div>

                                             {/* Type embauche */}
                                             <div className="row mb-4">
                                                  <label className="col-lg-4 col-form-label fw-bold fs-6 required">Choisissez le type d'embauche</label>

                                                  <div className="col-lg-8">
                                                       <div className="row">
                                                            <div className="col-md-6 col-lg-12 mb-2">
                                                                 <MyRadioWithLabelAndDescriptionField
                                                                      name={"typeEmbauche"}
                                                                      value={CONST_CONSULTANT_ETAT_EN_COURS_EMBAUCHE_POUR_MISSION}
                                                                      isChecked={helpers.values.typeEmbauche === CONST_CONSULTANT_ETAT_EN_COURS_EMBAUCHE_POUR_MISSION}
                                                                      label={"Démarrage de mission"}
                                                                      description={"Le candidat démarrera chez le client dès la signature du contrat"}
                                                                      iconFontAwesomeClassName={"check-circle"}
                                                                      color={"success"}
                                                                      isInvalid={!!helpers.errors.typeEmbauche}
                                                                      onChange={val => helpers.setFieldValue("typeEmbauche", val)}
                                                                 />
                                                            </div>
                                                            <div className="col-md-6 col-lg-12">
                                                                 <MyRadioWithLabelAndDescriptionField
                                                                      name={"typeEmbauche"}
                                                                      value={CONST_CONSULTANT_ETAT_EN_COURS_EMBAUCHE_SANS_MISSION}
                                                                      isChecked={helpers.values.typeEmbauche === CONST_CONSULTANT_ETAT_EN_COURS_EMBAUCHE_SANS_MISSION}
                                                                      label={"Embauche sur profil"}
                                                                      description={"Le candidat n'aura pas de mission à l'embauche"}
                                                                      iconFontAwesomeClassName={"clock"}
                                                                      color={"primary"}
                                                                      isInvalid={!!helpers.errors.typeEmbauche}
                                                                      onChange={val => helpers.setFieldValue("typeEmbauche", val)}
                                                                 />
                                                            </div>
                                                       </div>
                                                       {helpers.errors.typeEmbauche && <div className={"text-danger"}>{helpers.errors.typeEmbauche}</div>}
                                                  </div>
                                             </div>

                                             {/* Salaire & délégation */}
                                             {helpers.values.typeEmbauche && (
                                                  <div className={"p-4 border border-dashed mt-4"}>
                                                       <h5 className={"mb-4"}>
                                                            {helpers.values.typeEmbauche === CONST_CONSULTANT_ETAT_EN_COURS_EMBAUCHE_POUR_MISSION
                                                                 ? "Démarrage de mission"
                                                                 : "Embauche sur profil"}
                                                       </h5>
                                                       <div className="separator separator-dashed mb-6" />

                                                       {/* Démarrage mission */}
                                                       {helpers.values.typeEmbauche === CONST_CONSULTANT_ETAT_EN_COURS_EMBAUCHE_POUR_MISSION && (
                                                            <>
                                                                 {/* Heures travail */}
                                                                 <div className="row mb-4">
                                                                      <label className="col-lg-4 col-form-label fw-bold fs-6 required">Heures de travail / jour</label>

                                                                      <div className="col-lg-8">
                                                                           <MyInputField
                                                                                type={"number"}
                                                                                name={"heuresTravailParJour"}
                                                                                value={helpers.values.heuresTravailParJour}
                                                                                isInvalid={!!helpers.errors.heuresTravailParJour}
                                                                                inputMode={"numeric"}
                                                                                onChange={val => helpers.setFieldValue("heuresTravailParJour", val)}
                                                                                placeholder={"Heures de travail / jour"}
                                                                           />
                                                                           {helpers.errors.heuresTravailParJour && (
                                                                                <div className={"text-danger"}>{helpers.errors.heuresTravailParJour}</div>
                                                                           )}
                                                                      </div>
                                                                 </div>

                                                                 {/* Heures supp */}
                                                                 <div className="row mb-4">
                                                                      <label className="col-lg-4 col-form-label fw-bold fs-6 required">Heures supp. / jour</label>

                                                                      <div className="col-lg-8">
                                                                           <MyInputField
                                                                                type={"number"}
                                                                                name={"heuresTravailSuppParJour"}
                                                                                inputMode={"numeric"}
                                                                                value={helpers.values.heuresTravailSuppParJour}
                                                                                isInvalid={!!helpers.errors.heuresTravailSuppParJour}
                                                                                onChange={val => helpers.setFieldValue("heuresTravailSuppParJour", val)}
                                                                                placeholder={"Heures supp. / jour"}
                                                                           />
                                                                           {helpers.errors.heuresTravailSuppParJour && (
                                                                                <div className={"text-danger"}>{helpers.errors.heuresTravailSuppParJour}</div>
                                                                           )}
                                                                      </div>
                                                                 </div>

                                                                 {/* Période essai */}
                                                                 <div className="row mb-4">
                                                                      <label className="col-lg-4 col-form-label fw-bold fs-6">Période d'essai</label>

                                                                      <div className="col-lg-8 d-flex align-items-center">
                                                                           <MyCheckBoxField
                                                                                name={"doesConsultantHavePeriodeEssai"}
                                                                                value={helpers.values.doesConsultantHavePeriodeEssai}
                                                                                isInvalid={!!helpers.errors.doesConsultantHavePeriodeEssai}
                                                                                onChange={val => {
                                                                                     helpers.setFieldValue("doesConsultantHavePeriodeEssai", val).then(() => {
                                                                                          handleCalculateDateFinPeriodeEssai(
                                                                                               helpers.values.contrat,
                                                                                               helpers.values.statut,
                                                                                               helpers.values.dateEmbauche,
                                                                                               helpers.values.dateFinPeriodeEssai,
                                                                                               val
                                                                                          )
                                                                                     })
                                                                                }}
                                                                           />
                                                                           {helpers.errors.doesConsultantHavePeriodeEssai && (
                                                                                <div className={"text-danger"}>{helpers.errors.doesConsultantHavePeriodeEssai}</div>
                                                                           )}
                                                                      </div>
                                                                 </div>

                                                                 {/* Date fin PE */}
                                                                 {helpers.values.doesConsultantHavePeriodeEssai && (
                                                                      <div className="row mb-4">
                                                                           <label className="col-lg-4 col-form-label fw-bold fs-6 required">Date de fin de PE</label>

                                                                           <div className="col-lg-8">
                                                                                <MyLoadingOverlay isActive={isCalculatingDateFinPe}>
                                                                                     <>
                                                                                          <MyDateDayMonthYearField
                                                                                               name={"dateFinPeriodeEssai"}
                                                                                               value={helpers.values.dateFinPeriodeEssai}
                                                                                               isInvalid={!!helpers.errors.dateFinPeriodeEssai}
                                                                                               onChange={val => helpers.setFieldValue("dateFinPeriodeEssai", val)}
                                                                                               placeholder={"Date de fin de PE"}
                                                                                          />
                                                                                          {helpers.errors.dateFinPeriodeEssai && (
                                                                                               <div className={"text-danger"}>{helpers.errors.dateFinPeriodeEssai}</div>
                                                                                          )}
                                                                                     </>
                                                                                </MyLoadingOverlay>
                                                                           </div>
                                                                      </div>
                                                                 )}
                                                            </>
                                                       )}

                                                       {/* Salaire brut mensuel base 35h */}
                                                       <div className="row mb-4">
                                                            <label className="col-lg-4 col-form-label fw-bold fs-6 required">Salaire brut mensuel base 35h</label>

                                                            <div className="col-lg-8">
                                                                 <MyInputField
                                                                      type={"number"}
                                                                      name={"salaireBrutMensuelBase35h"}
                                                                      value={helpers.values.salaireBrutMensuelBase35h}
                                                                      isInvalid={!!helpers.errors.salaireBrutMensuelBase35h}
                                                                      inputMode={"numeric"}
                                                                      onChange={val => helpers.setFieldValue("salaireBrutMensuelBase35h", val)}
                                                                      placeholder={"Salaire brut mensuel base 35h"}
                                                                 />
                                                                 {helpers.errors.salaireBrutMensuelBase35h && (
                                                                      <div className={"text-danger"}>{helpers.errors.salaireBrutMensuelBase35h}</div>
                                                                 )}
                                                            </div>
                                                       </div>

                                                       {/* Délégation admin */}
                                                       <div className="row mb-4">
                                                            <label className="col-lg-4 col-form-label fw-bold fs-6">
                                                                 Voulez-vous déléguer la gestion administrative du futur collaborateur?
                                                            </label>

                                                            <div className="col-lg-8 d-flex align-items-center">
                                                                 <MyCheckBoxField
                                                                      name={"doesConsultantHaveDelegationAdministrative"}
                                                                      value={helpers.values.doesConsultantHaveDelegationAdministrative}
                                                                      isInvalid={!!helpers.errors.doesConsultantHaveDelegationAdministrative}
                                                                      onChange={val => helpers.setFieldValue("doesConsultantHaveDelegationAdministrative", val)}
                                                                 />
                                                                 {helpers.errors.doesConsultantHaveDelegationAdministrative && (
                                                                      <div className={"text-danger"}>{helpers.errors.doesConsultantHaveDelegationAdministrative}</div>
                                                                 )}
                                                            </div>
                                                       </div>

                                                       {helpers.values.doesConsultantHaveDelegationAdministrative && (
                                                            <div className="row">
                                                                 <label className="col-lg-4 col-form-label fw-bold fs-6 required">Choisissez les délégués</label>

                                                                 <div className="col-lg-8">
                                                                      <MySelectGetAllDataFromServer
                                                                           name={"personnelsWithDelegationAdminDuConsultant"}
                                                                           value={helpers.values.personnelsWithDelegationAdminDuConsultant}
                                                                           defaultOptions={
                                                                                data.consultant.personnelAvecDelegationDroitsConsultants &&
                                                                                data.consultant.personnelAvecDelegationDroitsConsultants.length > 0
                                                                                     ? data.consultant.personnelAvecDelegationDroitsConsultants.map(item => ({
                                                                                            value: item.personnel?.id as number,
                                                                                            label: item.personnel?.prenomNom as string,
                                                                                       }))
                                                                                     : []
                                                                           }
                                                                           isInvalid={!!helpers.errors.personnelsWithDelegationAdminDuConsultant}
                                                                           onChange={val => helpers.setFieldValue("personnelsWithDelegationAdminDuConsultant", val)}
                                                                           url={CONST_API_UTILS_GET_ACTIVE_PERSONNELS_ENDPOINT}
                                                                           isMulti
                                                                      />
                                                                      {helpers.errors.personnelsWithDelegationAdminDuConsultant && (
                                                                           <div className={"text-danger"}>{helpers.errors.personnelsWithDelegationAdminDuConsultant}</div>
                                                                      )}
                                                                 </div>
                                                            </div>
                                                       )}
                                                  </div>
                                             )}

                                             <div className="separator bg-primary my-5"></div>

                                             {/* Submit button */}
                                             <div className={"d-flex justify-content-between"}>
                                                  <button
                                                       className={"btn btn-sm btn-warning d-inline-flex align-items-center"}
                                                       type={"button"}
                                                       disabled={helpers.isSubmitting}
                                                       onClick={() => context.setNavigationStep("perso")}
                                                  >
                                                       <span className={"fas fa-arrow-left fs-4 me-1"}></span> Précédant
                                                  </button>
                                                  <button className={"btn btn-sm btn-primary d-inline-flex align-items-center"} type={"submit"} disabled={helpers.isSubmitting}>
                                                       {!helpers.isSubmitting ? (
                                                            <>
                                                                 Sauvegarder et suivant <span className={"fas fa-arrow-right fs-4 ms-1"}></span>
                                                            </>
                                                       ) : (
                                                            <MySimpleSpinner size={"sm"} />
                                                       )}
                                                  </button>
                                             </div>
                                        </Form>
                                   </MyCard.Body>
                              </MyCard>
                         )
                    }}
               </Formik>

               {/* Modal add métier */}
               {isAddingMetier && (
                    <MyModal title={<>Nouveau métier</>} show={true} handleClose={() => setIsAddingMetier(false)}>
                         <AddMetierForm callback={() => setIsAddingMetier(false)} />
                    </MyModal>
               )}
          </>
     )
}

export default DemandeEmbaucheDetailsInfoContractSalarie
