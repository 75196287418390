import moment from "moment"

const useDateHelper = () => {
     function isWeekend(date: moment.Moment): boolean {
          date = moment(date.format("YYYY-MM-DD")) // Ignores timezone

          const day = date.day()
          return day === 0 || day === 6 // 0 is Sunday, 6 is Saturday
     }

     function countWeekdaysBetweenDates(startDate: moment.Moment, endDate: moment.Moment): number {
          let totalDays = 0
          let currentDate = startDate.clone()

          while (currentDate.isSameOrBefore(endDate, "day")) {
               if (!isWeekend(currentDate) && !isHoliday(currentDate)) {
                    totalDays++
               }
               currentDate.add(1, "day")
          }

          return totalDays
     }

     function isHoliday(date: moment.Moment): boolean {
          date = moment(date.format("YYYY-MM-DD")) // Ignores timezone
          const year = date.year()

          // Calculate Easter date
          const easterDate = calculateEasterDate(year)
          const easterDay = easterDate.date()
          const easterMonth = easterDate.month() + 1 // month() is zero-based

          // Define holidays
          const holidays = [
               // Fixed dates
               moment(`${year}-01-01`), // 1er janvier
               moment(`${year}-05-01`), // Fête du travail
               moment(`${year}-05-08`), // Victoire des alliés
               moment(`${year}-07-14`), // Fête nationale
               moment(`${year}-08-15`), // Assomption
               moment(`${year}-11-01`), // Toussaint
               moment(`${year}-11-11`), // Armistice
               moment(`${year}-12-25`), // Noel

               // Variable dates
               moment(`${year}-${easterMonth}-${easterDay + 1}`), // Lundi de paques
               moment(`${year}-${easterMonth}-${easterDay + 39}`), // Ascension
          ]

          return holidays.some(holiday => holiday.isSame(date, "day"))
     }

     function calculateEasterDate(year: number): moment.Moment {
          const f = Math.floor,
               G = year % 19,
               C = f(year / 100),
               H = (C - f(C / 4) - f((8 * C + 13) / 25) + 19 * G + 15) % 30,
               I = H - f(H / 28) * (1 - f(29 / (H + 1)) * f((21 - G) / 11)),
               J = (year + f(year / 4) + I + 2 - C + f(C / 4)) % 7,
               L = I - J,
               month = 3 + f((L + 40) / 44),
               day = L + 28 - 31 * f(month / 4)

          return moment(`${year}-${month}-${day}`)
     }

     return { isWeekend, countWeekdaysBetweenDates }
}

export default useDateHelper
