import React, { useState } from "react"
import { toAbsoluteUrl } from "@common-metronic/helpers"
import MyLoadingOverlay from "@common-utils/MyLoadingOverlay"
import MyTooltip from "@common-utils/MyTooltip"
import { useAnnuaireListContext } from "./_AnnuaireList"
import { useAnnuaireContext } from "./Annuaire"
import { getSelectedItemPlusPreviousAndNextItem } from "./core/_helpers"
import FileAndDriveHelper from "@common-helpers/FileAndDriveHelper"
import { IPersonnelModel } from "@common-models/*"

const ListItem = ({ item, handleClickItemForPreview, isItemSelected, isItemLoading }: IListItemPropsModel) => {
     const globalContext = useAnnuaireContext()
     const listContext = useAnnuaireListContext()
     const [isAvatarLoading, setIsAvatarLoading] = useState<boolean>(true)

     function handleOnCheckItem() {
          if (!globalContext.isBulkSelectionEnabled.checkedElementsInPage.includes(item.id as number)) {
               globalContext.setIsBulkSelectionEnabled(prev => ({
                    ...prev,
                    checkedElementsInPage: [...prev.checkedElementsInPage, item.id as number],
                    areAllElementsInAllPagesChecked: false,
               }))
          } else {
               globalContext.setIsBulkSelectionEnabled(prev => ({
                    ...prev,
                    checkedElementsInPage: prev.checkedElementsInPage.filter(id => id !== item.id),
                    areAllElementsInAllPagesChecked: false,
               }))
          }
     }

     return (
          <MyLoadingOverlay isActive={isItemLoading}>
               <>
                    <div
                         className={`${isItemSelected ? "border-start border-2 border-primary" : ""}`}
                         onClick={e => handleClickItemForPreview(e)}
                         ref={isItemSelected ? listContext.selectedItemRef : null}
                    >
                         <div className={`p-4 ${isItemSelected ? "mb-4 bg-light" : "cursor-pointer"}`}>
                              {/* checkbox, Avatar, nom & métier*/}
                              <div className={"d-flex justify-content-between"}>
                                   {/* checkbox */}
                                   {globalContext.isBulkSelectionEnabled.enabled && (
                                        <div className={"form-check form-check-custom form-check-solid form-check-sm me-4"}>
                                             <input
                                                  type="checkbox"
                                                  className={"form-check-input cursor-pointer border border-secondary not-concerned-by-preview"}
                                                  checked={globalContext.isBulkSelectionEnabled.checkedElementsInPage.includes(item.id as number)}
                                                  onChange={() => handleOnCheckItem()}
                                             />
                                        </div>
                                   )}

                                   <div className={"d-flex align-items-center"}>
                                        {/* Left */}
                                        <MyLoadingOverlay isActive={isAvatarLoading} styles={{ height: "50px", width: "50px", borderRadius: "50%" }} size={"sm"}>
                                             <div className={"d-flex"}>
                                                  <img
                                                       src={
                                                            item?.avatarGoogleDriveId
                                                                 ? FileAndDriveHelper.getThumbnailDriveUrl(item?.avatarGoogleDriveId as string)
                                                                 : toAbsoluteUrl("/media/svg/avatars/blank.svg")
                                                       }
                                                       onLoad={() => setIsAvatarLoading(false)}
                                                       style={{ height: "50px", width: "50px", borderRadius: "50%" }}
                                                       alt="Avatar"
                                                  />
                                             </div>
                                        </MyLoadingOverlay>

                                        {/* Middle */}
                                        <div className={"ms-4"}>
                                             <div className={"d-flex"}>
                                                  <span className={"fw-bold fs-5"}>{item.prenomNom}</span>
                                             </div>
                                             <div className={"text-muted"}>
                                                  {item.personnelPostes && item.personnelPostes.length > 0 && item.personnelPostes[0]?.poste?.nom} à {item.agence?.ville}
                                             </div>
                                        </div>
                                   </div>

                                   {/* Right side */}
                                   <div className="d-flex align-items-center">
                                        <span className={"badge badge-dark"}>{item.mainEntite?.nom}</span>
                                   </div>
                              </div>
                         </div>

                         {isItemSelected && (
                              <>
                                   <div className={"d-flex align-items-center px-4 pb-4"}>
                                        <div className={"col-10"}>
                                             {/* Mail */}
                                             <div className={`d-flex align-items-center mb-2`}>
                                                  <span className="fas fa-envelope fs-5 w-25px" />
                                                  <a href={`mailto::${item.mailProfessionnel}`} target={"_blank"}>
                                                       {item.mailProfessionnel}
                                                  </a>
                                             </div>

                                             {/* Tél */}
                                             <div className={`d-flex align-items-center flex-wrap`}>
                                                  <span className="fas fa-phone fs-5 w-25px" />
                                                  <a href={`${item.telProfessionnelIndicatif?.phoneCodeWithPlusPrefix} ${item.telProfessionnel}`}>
                                                       {item.telProfessionnelIndicatif?.phoneCodeWithPlusPrefix} {item.telProfessionnel}
                                                  </a>
                                             </div>
                                        </div>
                                        <div className="col text-end">
                                             <div>
                                                  <MyTooltip title={"Discuter via Google chat"}>
                                                       <a
                                                            href={"https://chat.google.com"}
                                                            target={"_blank"}
                                                            className={`btn btn-outline btn-outline-dark btn-sm btn-icon rounded-circle mb-2`}
                                                       >
                                                            <i className="fas fa-comments fs-4" />
                                                       </a>
                                                  </MyTooltip>
                                             </div>
                                             <div>
                                                  <MyTooltip title={"Démarrer une visio instantanée"}>
                                                       <a
                                                            href={`https://meet.google.com/new?calleeId=${item.mailProfessionnel}&amp;authuser=0&amp;hl=fr`}
                                                            target={"_blank"}
                                                            className={`btn btn-outline btn-outline-dark btn-sm btn-icon rounded-circle mb-2`}
                                                       >
                                                            <i className="fas fa-video fs-4" />
                                                       </a>
                                                  </MyTooltip>
                                             </div>
                                        </div>
                                   </div>
                              </>
                         )}
                    </div>
               </>
          </MyLoadingOverlay>
     )
}

const AnnuaireListItem = ({ item, index }: { item: IPersonnelModel; index: number }) => {
     const context = useAnnuaireContext()

     const isItemSelected: boolean = context.itemInPreview !== null && context.itemInPreview.selectedItem.id === item.id
     const isItemLoading: boolean = context.itemInPreview !== null && context.itemInPreview.selectedItem.id === item.id && context.itemInPreview.isSelectedItemLoading

     // Shows item in preview
     function handleClickItemForPreview(e: React.MouseEvent<any>) {
          const clickedElement = e.target as HTMLElement
          // .not-concerned-by-preview elements are not concerned by the click
          if (!clickedElement.classList.contains("not-concerned-by-preview") && context.itemInPreview?.selectedItem.id !== item.id) {
               context.setAreFiltersVisible(false)

               context.setItemInPreview({
                    ...getSelectedItemPlusPreviousAndNextItem(item, index, context.listingQuery.data!.data, context.listingQuery.data!.page, context.listingQuery.data!.totalPages),
                    isSelectedItemLoading: false,
               })
          }
     }

     return <ListItem item={item} handleClickItemForPreview={handleClickItemForPreview} isItemSelected={isItemSelected} isItemLoading={isItemLoading} />
}

interface IListItemPropsModel {
     item: IPersonnelModel
     handleClickItemForPreview: (e: React.MouseEvent<any>) => void
     isItemSelected: boolean
     isItemLoading: boolean
}

export default AnnuaireListItem
