import { useQuery } from "react-query"
import { AxiosError } from "axios"
import MySimpleSpinner from "@common-utils/MySimpleSpinner"
import MyAlert from "@common-utils/MyAlert"
import React, { useState } from "react"
import MyCard from "@common-utils/MyCard"
import GlobalHelper from "@common-helpers/GlobalHelper"
import { IGetConsultantsDeclarationsVoyantsResponseModel } from "./core/_models"
import { getConsultantsDeclarationsVoyants } from "./core/_requests"
import { Link, useNavigate } from "react-router-dom"
import MyDateMonthYearField from "@common-utils/fields/MyDateMonthYearField"
import { toast } from "react-toastify"
import { ROUTING_ABSOLUTE_PATH_GESTION_PRODUCTION_DECLARATIONS_LISTING } from "../routing/routing-endpoints"
import { useAuth } from "../../../AppContext"
import VoyantsMonthVueSiege from "./_VoyantsMonthVueSiege"
import VoyantsMonthVueHorsSiege from "./_VoyantsMonthHorsSiege"

const REACT_QUERY_KEY_GET_DECLARATIONS_SALARIE_VOYANTS = "REACT_QUERY_KEY_GET_DECLARATIONS_VOYANTS"
const REACT_QUERY_KEY_GET_DECLARATIONS_ST_VOYANTS = "REACT_QUERY_KEY_GET_DECLARATIONS_ST_VOYANTS"

const GestionProductionDeclarationsVoyants = () => {
     const navigate = useNavigate()
     const { currentUser } = useAuth()
     const [date, setDate] = useState<string>()
     const [system, setSystem] = useState<"OLD" | "NOUVEAU">("OLD")

     // Request voyants salariés
     const declarationsSalarieVoyantsQuery = useQuery<IGetConsultantsDeclarationsVoyantsResponseModel[], AxiosError>(REACT_QUERY_KEY_GET_DECLARATIONS_SALARIE_VOYANTS, () =>
          getConsultantsDeclarationsVoyants("salarie").then(r => r.data)
     )

     // Request voyants ST
     const declarationsSousTraitantVoyantsQuery = useQuery<IGetConsultantsDeclarationsVoyantsResponseModel[], AxiosError>(REACT_QUERY_KEY_GET_DECLARATIONS_ST_VOYANTS, () =>
          getConsultantsDeclarationsVoyants("st").then(r => r.data)
     )

     // In case results
     return (
          <div className={"d-flex justify-content-center"}>
               <div className={"w-100 w-lg-75 w-xxl-70"}>
                    <div className="d-flex justify-content-center justify-content-md-end mb-4">
                         <button className={`btn btn-sm ${system === "OLD" ? "btn-dark" : "btn-outline btn-outline-dark"} me-2`} onClick={() => setSystem("OLD")}>
                              AUTRES ENTITÉS
                         </button>
                         <button className={`btn btn-sm ${system === "NOUVEAU" ? "btn-dark" : "btn-outline btn-outline-dark"}`} onClick={() => setSystem("NOUVEAU")}>
                              ENTITÉ GS & NEO
                         </button>
                    </div>

                    {system === "OLD" && (
                         <>
                              <MyAlert type={"primary"} classNames={"mb-4"}>
                                   <div>
                                        Le nouveau système est en phase de test mais uniquement pour CTS Neo. Si vous n'êtes pas concerné par cette entité, veuillez utiliser
                                        provisoirement l'ancienne interface jusqu'à fin 2024. Sinon, veuillez cliquer sur l'onglet <b>"ENTITÉ NEO"</b>.
                                        <br />
                                        Merci de votre compréhension.
                                   </div>
                              </MyAlert>
                              <div className="d-flex justify-content-center">
                                   <Link
                                        to={
                                             currentUser?.roleGroup === "ROLE_GROUP_ADMINISTRATIF"
                                                  ? "https://agora.cts-corporate.com/gestion-cra-ndf-ddc/voyants-for-non-admin"
                                                  : "https://agora.cts-corporate.com/gestion-cra-ndf-ddc/voyants-for-admin"
                                        }
                                        className={"btn btn-primary d-flex align-items-center"}
                                        target={"_blank"}
                                   >
                                        ACCEDER A L'ANCIENNE INTERFACE <i className={"fas fa-external-link ms-2"} />
                                   </Link>
                              </div>
                         </>
                    )}

                    {system === "NOUVEAU" && (
                         <>
                              <MyCard>
                                   <MyCard.Header>Déclarations des salariés</MyCard.Header>
                                   <MyCard.Body>
                                        <>
                                             <MyAlert type={"primary"} classNames={"mb-4"}>
                                                  Ce système est en phase de test uniquement pour l'entité GS & Neo
                                             </MyAlert>

                                             {declarationsSalarieVoyantsQuery.isFetching && (
                                                  <div className={"text-center p-5"}>
                                                       <MySimpleSpinner size={"xl"} />
                                                  </div>
                                             )}

                                             {!declarationsSalarieVoyantsQuery.isFetching && declarationsSalarieVoyantsQuery.isError && (
                                                  <MyAlert type={"danger"} classNames={"mb-4"}>
                                                       <>
                                                            <span className={"me-2"}>{declarationsSalarieVoyantsQuery.error?.response?.data?.detail}</span>
                                                            <button className={"btn btn-sm btn-danger"} onClick={() => declarationsSalarieVoyantsQuery.refetch()}>
                                                                 Recharger
                                                            </button>
                                                       </>
                                                  </MyAlert>
                                             )}

                                             {/* Month cards */}
                                             {!declarationsSalarieVoyantsQuery.isFetching && !declarationsSalarieVoyantsQuery.isError && declarationsSalarieVoyantsQuery.data && (
                                                  <>
                                                       <div className={"row"}>
                                                            {declarationsSalarieVoyantsQuery.data?.map((item, key) => (
                                                                 <div className={"col-6 col-sm-3 col-md-3 mb-xl-0 mb-4"} key={key}>
                                                                      <MyCard>
                                                                           <MyCard.Header isCollapseIconShown={false}>
                                                                                <span className={"fas fa-calendar-day me-2 fs-3"}></span>
                                                                                <Link
                                                                                     to={ROUTING_ABSOLUTE_PATH_GESTION_PRODUCTION_DECLARATIONS_LISTING}
                                                                                     state={{ mois: item.mois.parseZone().format("YYYY-MM-DD"), contrat: "salarie" }}
                                                                                     className={"text-dark text-hover-primary"}
                                                                                >
                                                                                     {GlobalHelper.firstLetterUppercase(item.mois.parseZone().format("MMM YYYY"))}
                                                                                </Link>
                                                                           </MyCard.Header>
                                                                           <MyCard.Body>
                                                                                {currentUser?.roleGroup === "ROLE_GROUP_ADMINISTRATIF" ? (
                                                                                     <VoyantsMonthVueSiege item={item.voyants} mois={item.mois} contrat={"salarie"} />
                                                                                ) : (
                                                                                     <VoyantsMonthVueHorsSiege item={item.voyants} mois={item.mois} contrat={"salarie"} />
                                                                                )}
                                                                           </MyCard.Body>
                                                                      </MyCard>
                                                                 </div>
                                                            ))}
                                                       </div>

                                                       <div className="d-flex align-items-center my-5">
                                                            <div className="border-bottom border-secondary mw-50 w-100"></div>
                                                            <span className="fw-bold border-secondary fs-7 mx-2">OU</span>
                                                            <div className="border-bottom border-secondary mw-50 w-100"></div>
                                                       </div>

                                                       {/* Choose month */}
                                                       <div className="d-flex justify-content-center">
                                                            <div className={"w-100 w-sm-50 w-lg-30 d-flex"}>
                                                                 <MyDateMonthYearField name={"date"} value={date} onChange={setDate} placeholder={"Choisissez un mois"} />
                                                                 <button
                                                                      type="submit"
                                                                      className="btn btn-sm btn-outline btn-outline-dark ms-2"
                                                                      onClick={() => {
                                                                           if (!date || date === "") {
                                                                                toast.error("Veuillez saisir un mois")
                                                                           } else {
                                                                                navigate(ROUTING_ABSOLUTE_PATH_GESTION_PRODUCTION_DECLARATIONS_LISTING, {
                                                                                     state: { mois: date + "-01", contrat: "salarie" },
                                                                                })
                                                                           }
                                                                      }}
                                                                 >
                                                                      <i className="fas fa-search text-dark"></i>
                                                                 </button>
                                                            </div>
                                                       </div>
                                                  </>
                                             )}
                                        </>
                                   </MyCard.Body>
                              </MyCard>

                              <div className="separator separator-dashed my-5" />

                              <MyCard>
                                   <MyCard.Header>Déclarations des sous traitants</MyCard.Header>
                                   <MyCard.Body>
                                        <MyAlert type={"primary"} classNames={"mb-4"}>
                                             Ce système est en phase de test uniquement pour l'entité GS & Neo
                                        </MyAlert>

                                        {declarationsSousTraitantVoyantsQuery.isFetching && (
                                             <div className={"text-center p-5"}>
                                                  <MySimpleSpinner size={"xl"} />
                                             </div>
                                        )}

                                        {!declarationsSousTraitantVoyantsQuery.isFetching && declarationsSousTraitantVoyantsQuery.isError && (
                                             <MyAlert type={"danger"} classNames={"mb-4"}>
                                                  <>
                                                       <span className={"me-2"}>{declarationsSousTraitantVoyantsQuery.error?.response?.data?.detail}</span>
                                                       <button className={"btn btn-sm btn-danger"} onClick={() => declarationsSousTraitantVoyantsQuery.refetch()}>
                                                            Recharger
                                                       </button>
                                                  </>
                                             </MyAlert>
                                        )}

                                        {/* Month cards */}
                                        {!declarationsSousTraitantVoyantsQuery.isFetching &&
                                             !declarationsSousTraitantVoyantsQuery.isError &&
                                             declarationsSousTraitantVoyantsQuery.data && (
                                                  <>
                                                       <div className={"row"}>
                                                            {declarationsSousTraitantVoyantsQuery.data?.map((item, key) => (
                                                                 <div className={"col-6 col-sm-3 col-md-3 mb-xl-0 mb-4"} key={key}>
                                                                      <MyCard>
                                                                           <MyCard.Header isCollapseIconShown={false}>
                                                                                <span className={"fas fa-calendar-day me-2 fs-3"}></span>
                                                                                <Link
                                                                                     to={ROUTING_ABSOLUTE_PATH_GESTION_PRODUCTION_DECLARATIONS_LISTING}
                                                                                     state={{ mois: item.mois.parseZone().format("YYYY-MM-DD"), contrat: "st" }}
                                                                                     className={"text-dark text-hover-primary"}
                                                                                >
                                                                                     {GlobalHelper.firstLetterUppercase(item.mois.format("MMM YYYY"))}
                                                                                </Link>
                                                                           </MyCard.Header>
                                                                           <MyCard.Body>
                                                                                {currentUser?.roleGroup === "ROLE_GROUP_ADMINISTRATIF" ? (
                                                                                     <VoyantsMonthVueSiege item={item.voyants} mois={item.mois} contrat={"st"} />
                                                                                ) : (
                                                                                     <VoyantsMonthVueHorsSiege item={item.voyants} mois={item.mois} contrat={"st"} />
                                                                                )}
                                                                           </MyCard.Body>
                                                                      </MyCard>
                                                                 </div>
                                                            ))}
                                                       </div>

                                                       <div className="d-flex align-items-center my-5">
                                                            <div className="border-bottom border-secondary mw-50 w-100"></div>
                                                            <span className="fw-bold border-secondary fs-7 mx-2">OU</span>
                                                            <div className="border-bottom border-secondary mw-50 w-100"></div>
                                                       </div>

                                                       {/* Choose month */}
                                                       <div className="d-flex justify-content-center">
                                                            <div className={"w-100 w-sm-50 w-lg-30 d-flex"}>
                                                                 <MyDateMonthYearField name={"date"} value={date} onChange={setDate} placeholder={"Choisissez un mois"} />
                                                                 <button
                                                                      type="submit"
                                                                      className="btn btn-sm btn-outline btn-outline-dark ms-2"
                                                                      onClick={() => {
                                                                           if (!date || date === "") {
                                                                                toast.error("Veuillez saisir un mois")
                                                                           } else {
                                                                                navigate(ROUTING_ABSOLUTE_PATH_GESTION_PRODUCTION_DECLARATIONS_LISTING, {
                                                                                     state: {
                                                                                          mois: date + "-01",
                                                                                          contrat: "st",
                                                                                     },
                                                                                })
                                                                           }
                                                                      }}
                                                                 >
                                                                      <i className="fas fa-search text-dark"></i>
                                                                 </button>
                                                            </div>
                                                       </div>
                                                  </>
                                             )}
                                   </MyCard.Body>
                              </MyCard>
                         </>
                    )}
               </div>
          </div>
     )
}

export default GestionProductionDeclarationsVoyants
