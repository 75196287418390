import React, { useMemo, useRef, useState } from "react"
import { useQueryClient } from "react-query"
import { useSearchResultsContext } from "../../CandidatsRechercheResults"
import { addStatut, removeStatut, replaceCurriculum } from "../../../../core/_requests"
import { ISearchResultsDataItemResponseModel, ISearchResultsResponseModel } from "../../core/_models"
import MySimpleSpinner from "@common-utils/MySimpleSpinner"
import {
     CONST_CANDIDATSTATUS_CONTACTE_PAR_EMAIL,
     CONST_CANDIDATSTATUS_CONTACTE_PAR_TEL,
     CONST_CANDIDATSTATUS_NON_RETENU_CAUSE_COMPETENCES,
     CONST_CANDIDATSTATUS_NON_RETENU_CAUSE_DISPO,
     CONST_CANDIDATSTATUS_NON_RETENU_CAUSE_MOBILITE,
     CONST_CANDIDATSTATUS_NON_RETENU_CAUSE_REFUS_CANDIDAT,
     CONST_CANDIDATSTATUS_NON_RETENU_CAUSE_REMUNERATION,
     CONST_CANDIDATSUIVI_TYPE_ENTRETIEN_CLIENT,
     CONST_CANDIDATSUIVI_TYPE_ENTRETIEN_CTS,
     CONST_CANDIDATSUIVI_TYPE_ENTRETIEN_TELEPHONIQUE,
     CONST_CANDIDATSUIVI_TYPE_MESSAGE_REPONDEUR,
     CONST_DEFAULT_TOAST_OPTIONS,
} from "@common-constants/*"
import MyTooltip from "@common-utils/MyTooltip"
import { getSelectedCandidatePlusPreviousAndNextCandidate } from "../../core/helpers"
import { toast } from "react-toastify"
import axios, { AxiosError } from "axios"
import { CONST_API_RECRUITMENT_CANDIDATES_GET_DOWNLOAD_CURRICULUM_ENDPOINT } from "../../../../../../../endpoints"
import FileAndDriveHelper from "@common-helpers/FileAndDriveHelper"
import MyModal from "@common-utils/MyModal"
import CandidatAddSuiviEntretienTelephoniqueForm from "../../../../suivi/_CandidatAddSuiviEntretienTel"
import CandidatAddSuiviMessageRepondeurForm from "../../../../suivi/_CandidatAddSuiviMessageRepondeur"
import CandidatAddSuiviEntretienAgenceForm from "../../../../suivi/_CandidatAddSuiviEntretienAgence"
import CandidatAddSuiviEntretienClientForm from "../../../../suivi/_CandidatAddSuiviEntretienClient"
import { useSearchResultsPreviewContext } from "../_CandidatsRechercheResultsPreview"
import { IConsultantDEModel } from "@common-models/*"
import useSwal from "@common-hooks/useSwal"
import { useNavigate } from "react-router-dom"
import { useAuth } from "../../../../../../../AppContext"
import CandidatDemanderInfoPreEmbaucheParMail from "../../../../actions/_CandidatDemanderInfoPreEmbaucheParMail"
import CandidatEntamerDemandeEmbauche from "../../../../actions/_CandidatEntamerDemandeEmbauche"

/* Item navigation: switch from one item to another */
export const ItemNavigationArrows = () => {
     const globalContext = useSearchResultsContext()

     // Handles navigation to previous candidate
     function handleNavigateToPreviousCandidate() {
          if (!globalContext.itemInPreview) return

          if (globalContext.itemInPreview.previousItem) {
               globalContext.setItemInPreview(
                    getSelectedCandidatePlusPreviousAndNextCandidate(
                         globalContext.itemInPreview!.previousItem as ISearchResultsDataItemResponseModel,
                         globalContext.itemInPreview!.selectedItemIndex - 1,
                         globalContext.useListingQuery.data!.data,
                         globalContext.useListingQuery.data!.page,
                         globalContext.useListingQuery.data!.totalPages
                    )
               )

               if (!globalContext.itemInPreview!.previousItem?.connectedUserHasSeenCandidat) {
                    globalContext.checkItemAsSeen(globalContext.itemInPreview!.previousItem.candidat.id as number)
               }
          }

          if (globalContext.itemInPreview.isPreviousItemInThePreviousPage) {
               globalContext.setFilters(prev => ({
                    ...prev,
                    page: prev.page - 1,
               }))
          }
     }

     // Handles navigation to next candidate
     function handleNavigateToNextCandidate() {
          if (!globalContext.itemInPreview) return

          if (globalContext.itemInPreview.nextItem) {
               globalContext.setItemInPreview(
                    getSelectedCandidatePlusPreviousAndNextCandidate(
                         globalContext.itemInPreview!.nextItem,
                         globalContext.itemInPreview!.selectedItemIndex + 1,
                         globalContext.useListingQuery.data!.data,
                         globalContext.useListingQuery.data!.page,
                         globalContext.useListingQuery.data!.totalPages
                    )
               )

               if (!globalContext.itemInPreview!.nextItem?.connectedUserHasSeenCandidat) globalContext.checkItemAsSeen(globalContext.itemInPreview!.nextItem.candidat.id as number)
          }

          if (globalContext.itemInPreview.isNextItemInTheNextPage) {
               globalContext.setFilters(prev => ({
                    ...prev,
                    page: prev.page + 1,
               }))
          }
     }

     return (
          <div ref={globalContext.previewContainerRef}>
               <MyTooltip title={"Candidat précédent"}>
                    <button
                         className="btn btn-sm btn-active-light-primary p-0 py-3 px-4"
                         onClick={e => {
                              e.stopPropagation()
                              handleNavigateToPreviousCandidate()
                         }}
                         disabled={!globalContext.itemInPreview?.previousItem && !globalContext.itemInPreview?.isPreviousItemInThePreviousPage}
                    >
                         <i className="fas fa-angle-left text-dark fs-1"></i>
                    </button>
               </MyTooltip>
               <MyTooltip title={"Candidat suivant"}>
                    <button
                         className="btn btn-sm btn-active-light-primary p-0 py-3 px-4"
                         onClick={e => {
                              e.stopPropagation()
                              handleNavigateToNextCandidate()
                         }}
                         disabled={!globalContext.itemInPreview?.nextItem && !globalContext.itemInPreview?.isNextItemInTheNextPage}
                    >
                         <i className="fas fa-angle-right fs-1 text-dark"></i>
                    </button>
               </MyTooltip>
          </div>
     )
}

/* Statuts */
export const ActionsRapidesButton = () => {
     const [isAddingOrRemovingStatut, setIsAddingOrRemovingStatut] = useState<boolean>(false)
     const queryClient = useQueryClient()
     const globalContext = useSearchResultsContext()
     const selectedItemInPreview = globalContext.itemInPreview!.selectedItem
     const candidateInPreview = globalContext.useListingQuery.data?.data.find(item => item.candidat.id === selectedItemInPreview?.candidat.id)

     function handleAddStatut(statut: string) {
          setIsAddingOrRemovingStatut(true)
          addStatut(candidateInPreview?.candidat.id as number, statut)
               .then(r => {
                    queryClient.setQueryData<ISearchResultsResponseModel | undefined>(
                         [globalContext.REACT_QUERY_KEY_SEARCH_RESULTS, globalContext.filters],
                         (prev: ISearchResultsResponseModel | undefined) => {
                              if (prev?.data) {
                                   const index = prev.data.findIndex(item_ => item_.candidat.id === candidateInPreview?.candidat.id)
                                   prev.data[index] = {
                                        ...prev.data[index],
                                        connectedUserAddedStatusToCandidat: r.data,
                                   }

                                   return prev
                              }

                              return prev
                         }
                    )
               })
               .finally(() => setIsAddingOrRemovingStatut(false))
     }

     function handleRemoveStatut(statut: string) {
          setIsAddingOrRemovingStatut(true)
          removeStatut(candidateInPreview?.candidat.id as number, statut)
               .then(r => {
                    queryClient.setQueryData<ISearchResultsResponseModel | undefined>(
                         [globalContext.REACT_QUERY_KEY_SEARCH_RESULTS, globalContext.filters],
                         (prev: ISearchResultsResponseModel | undefined) => {
                              if (prev?.data) {
                                   const index = prev.data.findIndex(item_ => item_.candidat.id === candidateInPreview?.candidat.id)
                                   prev.data[index] = {
                                        ...prev.data[index],
                                        connectedUserAddedStatusToCandidat: r.data,
                                   }

                                   return prev
                              }

                              return prev
                         }
                    )
               })
               .finally(() => setIsAddingOrRemovingStatut(false))
     }

     function isStatutChecked(statut: string) {
          return candidateInPreview?.connectedUserAddedStatusToCandidat?.find(item => item.contacteOrNotRetenuRaison === statut) !== undefined
     }

     return (
          <div className="dropdown me-6">
               <button className="btn btn-sm btn-dark p-0 px-3 py-2 py-sm-2 px-sm-3" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                    <span className={"d-none d-sm-inline-flex"}>Actions rapides</span> <i className={"fas fa-caret-down fs-4 fs-sm-7"} />
               </button>
               <ul className="dropdown-menu">
                    <li className={"text-center"}>{isAddingOrRemovingStatut && <MySimpleSpinner size={"sm"} />}</li>
                    <li>
                         <h6 className="dropdown-header">Déjà contacté par</h6>
                    </li>
                    <li>
                         <span
                              className="dropdown-item cursor-pointer"
                              onClick={e => {
                                   e.stopPropagation()
                                   isStatutChecked(CONST_CANDIDATSTATUS_CONTACTE_PAR_TEL)
                                        ? handleRemoveStatut(CONST_CANDIDATSTATUS_CONTACTE_PAR_TEL)
                                        : handleAddStatut(CONST_CANDIDATSTATUS_CONTACTE_PAR_TEL)
                              }}
                         >
                              <span className="form-check form-check-custom">
                                   <input
                                        type={"checkbox"}
                                        checked={isStatutChecked(CONST_CANDIDATSTATUS_CONTACTE_PAR_TEL)}
                                        onChange={() => {}}
                                        className={"form-check-input border-secondary cursor-pointer h-15px w-15px rounded-0 me-2"}
                                   />
                                   Téléphone
                              </span>
                         </span>
                    </li>
                    <li>
                         <span
                              className="dropdown-item cursor-pointer"
                              onClick={e => {
                                   e.stopPropagation()
                                   isStatutChecked(CONST_CANDIDATSTATUS_CONTACTE_PAR_EMAIL)
                                        ? handleRemoveStatut(CONST_CANDIDATSTATUS_CONTACTE_PAR_EMAIL)
                                        : handleAddStatut(CONST_CANDIDATSTATUS_CONTACTE_PAR_EMAIL)
                              }}
                         >
                              <span className="form-check form-check-custom">
                                   <input
                                        type={"checkbox"}
                                        checked={isStatutChecked(CONST_CANDIDATSTATUS_CONTACTE_PAR_EMAIL)}
                                        onChange={() => {}}
                                        className={"form-check-input border-secondary cursor-pointer h-15px w-15px rounded-0 me-2"}
                                   />
                                   Email
                              </span>
                         </span>
                    </li>
                    <li>
                         <hr className="separator" />
                    </li>
                    <li>
                         <h6 className="dropdown-header">Non retenu pour cause de</h6>
                    </li>
                    <li>
                         <span
                              className="dropdown-item cursor-pointer"
                              onClick={e => {
                                   e.stopPropagation()
                                   isStatutChecked(CONST_CANDIDATSTATUS_NON_RETENU_CAUSE_MOBILITE)
                                        ? handleRemoveStatut(CONST_CANDIDATSTATUS_NON_RETENU_CAUSE_MOBILITE)
                                        : handleAddStatut(CONST_CANDIDATSTATUS_NON_RETENU_CAUSE_MOBILITE)
                              }}
                         >
                              <span className="form-check form-check-custom">
                                   <input
                                        type={"checkbox"}
                                        checked={isStatutChecked(CONST_CANDIDATSTATUS_NON_RETENU_CAUSE_MOBILITE)}
                                        onChange={() => {}}
                                        className={"form-check-input border-secondary cursor-pointer h-15px w-15px rounded-0 me-2"}
                                   />
                                   Mobilité
                              </span>
                         </span>
                    </li>
                    <li>
                         <span
                              className="dropdown-item cursor-pointer"
                              onClick={e => {
                                   e.stopPropagation()
                                   isStatutChecked(CONST_CANDIDATSTATUS_NON_RETENU_CAUSE_COMPETENCES)
                                        ? handleRemoveStatut(CONST_CANDIDATSTATUS_NON_RETENU_CAUSE_COMPETENCES)
                                        : handleAddStatut(CONST_CANDIDATSTATUS_NON_RETENU_CAUSE_COMPETENCES)
                              }}
                         >
                              <span className="form-check form-check-custom">
                                   <input
                                        type={"checkbox"}
                                        checked={isStatutChecked(CONST_CANDIDATSTATUS_NON_RETENU_CAUSE_COMPETENCES)}
                                        onChange={() => {}}
                                        className={"form-check-input border-secondary cursor-pointer h-15px w-15px rounded-0 me-2"}
                                   />
                                   Compétences
                              </span>
                         </span>
                    </li>
                    <li>
                         <span
                              className="dropdown-item cursor-pointer"
                              onClick={e => {
                                   e.stopPropagation()
                                   isStatutChecked(CONST_CANDIDATSTATUS_NON_RETENU_CAUSE_DISPO)
                                        ? handleRemoveStatut(CONST_CANDIDATSTATUS_NON_RETENU_CAUSE_DISPO)
                                        : handleAddStatut(CONST_CANDIDATSTATUS_NON_RETENU_CAUSE_DISPO)
                              }}
                         >
                              <span className="form-check form-check-custom">
                                   <input
                                        type={"checkbox"}
                                        checked={isStatutChecked(CONST_CANDIDATSTATUS_NON_RETENU_CAUSE_DISPO)}
                                        onChange={() => {}}
                                        className={"form-check-input border-secondary cursor-pointer h-15px w-15px rounded-0 me-2"}
                                   />
                                   Disponibilité
                              </span>
                         </span>
                    </li>
                    <li>
                         <span
                              className="dropdown-item cursor-pointer"
                              onClick={e => {
                                   e.stopPropagation()
                                   isStatutChecked(CONST_CANDIDATSTATUS_NON_RETENU_CAUSE_REMUNERATION)
                                        ? handleRemoveStatut(CONST_CANDIDATSTATUS_NON_RETENU_CAUSE_REMUNERATION)
                                        : handleAddStatut(CONST_CANDIDATSTATUS_NON_RETENU_CAUSE_REMUNERATION)
                              }}
                         >
                              <span className="form-check form-check-custom">
                                   <input
                                        type={"checkbox"}
                                        checked={isStatutChecked(CONST_CANDIDATSTATUS_NON_RETENU_CAUSE_REMUNERATION)}
                                        onChange={() => {}}
                                        className={"form-check-input border-secondary cursor-pointer h-15px w-15px rounded-0 me-2"}
                                   />
                                   Rémunération
                              </span>
                         </span>
                    </li>
                    <li>
                         <span
                              className="dropdown-item cursor-pointer"
                              onClick={e => {
                                   e.stopPropagation()
                                   isStatutChecked(CONST_CANDIDATSTATUS_NON_RETENU_CAUSE_REFUS_CANDIDAT)
                                        ? handleRemoveStatut(CONST_CANDIDATSTATUS_NON_RETENU_CAUSE_REFUS_CANDIDAT)
                                        : handleAddStatut(CONST_CANDIDATSTATUS_NON_RETENU_CAUSE_REFUS_CANDIDAT)
                              }}
                         >
                              <span className="form-check form-check-custom">
                                   <input
                                        type={"checkbox"}
                                        checked={isStatutChecked(CONST_CANDIDATSTATUS_NON_RETENU_CAUSE_REFUS_CANDIDAT)}
                                        onChange={() => {}}
                                        className={"form-check-input border-secondary cursor-pointer h-15px w-15px rounded-0 me-2"}
                                   />
                                   Refus candidat
                              </span>
                         </span>
                    </li>
                    <li className={"text-center mt-5 mt-sm-2 cursor-pointer"}>
                         <button className={"btn btn-sm btn-dark p-0 px-2 p1-2"}>
                              Fermer <i className={"fas fa-close fs-7"} />
                         </button>
                    </li>
               </ul>
          </div>
     )
}

/* Recruter Button */
export const RecruterButton = () => {
     const navigate = useNavigate()
     const { currentUser } = useAuth()
     const queryClient = useQueryClient()
     const { swal } = useSwal()
     const globalContext = useSearchResultsContext()
     const selectedItemInPreview = globalContext.itemInPreview!.selectedItem
     const candidateInPreview = globalContext.useListingQuery.data?.data.find(item => item.candidat.id === selectedItemInPreview?.candidat.id)
     const [isModalDemanderInfoPreEmbaucheShown, setIsModalDemanderInfoPreEmbaucheShown] = useState<boolean>(false)
     const [isModalEntamerDemandeEmbaucheShown, setIsModalEntamerDemandeEmbaucheShown] = useState<boolean>(false)

     function handleSubmitCallBackDemanderInfoPreEmbauche(demandeEmbauche: IConsultantDEModel) {
          queryClient.setQueryData<ISearchResultsResponseModel | undefined>(
               [globalContext.REACT_QUERY_KEY_SEARCH_RESULTS, globalContext.filters],
               (prev: ISearchResultsResponseModel | undefined) => {
                    if (prev?.data) {
                         const index = prev.data.findIndex(item_ => item_.candidat.id === candidateInPreview?.candidat.id)
                         prev.data[index] = {
                              ...prev.data[index],
                              activeDemandeEmbauche: demandeEmbauche,
                         }

                         return prev
                    }

                    return prev
               }
          )
          setIsModalDemanderInfoPreEmbaucheShown(false)
     }

     function handleSubmitCallBackEntamerDemandeEmbauche(demandeEmbauche: IConsultantDEModel) {
          navigate(`/recrutement/demandes-embauche/${demandeEmbauche.consultant!.id}/details`)
     }

     const FormDemandeInformationsPreEmabaucheInModal = useMemo(() => {
          return (
               <MyModal
                    title={<span>Faire remplir les informations par le candidat</span>}
                    show={true}
                    handleClose={() => setIsModalDemanderInfoPreEmbaucheShown(false)}
                    size={"lg"}
               >
                    <CandidatDemanderInfoPreEmbaucheParMail candidat={candidateInPreview!.candidat} callBackOnSubmit={handleSubmitCallBackDemanderInfoPreEmbauche} />
               </MyModal>
          )
     }, [isModalDemanderInfoPreEmbaucheShown])

     const FormEntamerDemandeEmbaucheInModal = useMemo(() => {
          return (
               <MyModal title={<span>Saisir moi-même les informations d'embauche</span>} show={true} handleClose={() => setIsModalEntamerDemandeEmbaucheShown(false)} size={"lg"}>
                    <CandidatEntamerDemandeEmbauche candidat={candidateInPreview!.candidat} callBackOnSubmit={handleSubmitCallBackEntamerDemandeEmbauche} />
               </MyModal>
          )
     }, [isModalEntamerDemandeEmbaucheShown])

     return (
          <>
               <div className="dropup me-6">
                    <button className="btn btn-sm btn-info p-0 px-3 py-2 py-sm-2 px-sm-3" type="button" data-bs-toggle="dropdown" data-bs-auto-close="true" aria-expanded="false">
                         {/*<span className={"d-none d-sm-inline-flex"}>{candidateInPreview?.activeDemandeEmbauche ? "Poursuivre le recrutement" : "Recruter"}</span>{" "}*/}
                         <span className={"d-none d-sm-inline-flex"}>Recruter</span> <i className={"fas fa-door-open fs-4 fs-sm-7"} />
                    </button>
                    <ul className="dropdown-menu" aria-labelledby="defaultDropdown">
                         <MyTooltip
                              title={
                                   "Cela enverra un mail au candidat afin qu'il puisse remplir ses informations personnelles et joindre ses documents d'embauche dans son espace Talent."
                              }
                         >
                              <li>
                                   <span
                                        className="dropdown-item cursor-pointer"
                                        onClick={() => {
                                             setIsModalDemanderInfoPreEmbaucheShown(true)
                                        }}
                                   >
                                        Faire remplir les informations par le candidat
                                   </span>
                              </li>
                         </MyTooltip>

                         <li>
                              <span className="dropdown-item cursor-pointer" onClick={() => setIsModalEntamerDemandeEmbaucheShown(true)}>
                                   Saisir moi-même les informations d'embauche
                              </span>
                         </li>
                    </ul>
               </div>

               {/* Modal demande information pre-embauche & entamer DE forms */}
               {isModalDemanderInfoPreEmbaucheShown && <>{FormDemandeInformationsPreEmabaucheInModal}</>}
               {isModalEntamerDemandeEmbaucheShown && <>{FormEntamerDemandeEmbaucheInModal}</>}
          </>
     )
}

/* Curriculum with utils like download/replace CV, send mail, share profil ... */
export const CurriculumIframeWithUtils = () => {
     const queryClient = useQueryClient()
     const globalContext = useSearchResultsContext()
     const previewContent = useSearchResultsPreviewContext()
     const selectedItemInPreview = globalContext.itemInPreview!.selectedItem
     const candidateInPreview = globalContext.useListingQuery.data?.data.find(item => item.candidat.id === selectedItemInPreview?.candidat.id)
     const refReplaceCurriculumButton = useRef<any>(null)

     // Handles download curriculum
     function handleDownloadCurriculum() {
          toast.info("Téléchargement en cours ...", {
               ...CONST_DEFAULT_TOAST_OPTIONS,
               autoClose: false,
          })
          axios.get(CONST_API_RECRUITMENT_CANDIDATES_GET_DOWNLOAD_CURRICULUM_ENDPOINT(candidateInPreview?.candidat.id as number), {
               responseType: "blob",
          })
               .then(r => {
                    const fileName = `CV_${candidateInPreview?.candidat.prenom}_${candidateInPreview?.candidat.nom}.${
                         candidateInPreview?.googleDriveMimeTypeForCv ? FileAndDriveHelper.getExtensionAccordingToMimetype(candidateInPreview.googleDriveMimeTypeForCv) : ""
                    }`
                    FileAndDriveHelper.downloadFile(r.data, fileName)

                    toast.dismiss()
                    toast.success("Le CV a bien été téléchargé")
               })
               .catch(() => {
                    toast.dismiss()
                    toast.error("Un problème est survenu lors du téléchargement du CV.", { autoClose: false })
               })
     }

     // Handles replace curricum
     function handleReplaceCurriculum(event) {
          const file_binary = event.target.files[0]

          if (file_binary) {
               toast.info("Remplacement en cours ...", {
                    ...CONST_DEFAULT_TOAST_OPTIONS,
                    autoClose: false,
               })
               replaceCurriculum(candidateInPreview?.candidat.id as number, file_binary)
                    .then(r => {
                         queryClient.setQueryData<ISearchResultsResponseModel | undefined>(
                              [globalContext.REACT_QUERY_KEY_SEARCH_RESULTS, globalContext.filters],
                              (prev: ISearchResultsResponseModel | undefined) => {
                                   if (prev?.data) {
                                        const index = prev.data.findIndex(item_ => item_.candidat.id === candidateInPreview?.candidat.id)
                                        prev.data[index] = {
                                             ...prev.data[index],
                                             googleDriveIdForCv: r.data.googleDriveId,
                                             googleDriveMimeTypeForCv: r.data.googleDriveMimeType,
                                        }

                                        return prev
                                   }

                                   return prev
                              }
                         )

                         toast.dismiss()
                         toast.success("Le CV a bien été remplacé.")
                    })
                    .catch((e: AxiosError) => {
                         toast.dismiss()
                         toast.error(e?.response?.data.detail, { autoClose: false })
                    })
          }
     }

     function handleIframeLoad() {
          previewContent.setIsPreviewBodyLoading(false)
          globalContext.setItemInPreview(prev =>
               prev
                    ? {
                           ...prev,
                           isSelectedItemLoading: false,
                      }
                    : null
          )
     }

     return (
          <>
               <iframe
                    onLoad={() => handleIframeLoad()}
                    className={"preview-height"}
                    allowFullScreen={true}
                    src={FileAndDriveHelper.getUrlOfDriveFilePreview(candidateInPreview?.googleDriveIdForCv as string)}
               />
               {/* Curriculum utils: download/replace CV, send mail, vivier, traitement, partage */}
               <div className={"vertical-container-of-button-on-iframe bg-secondary rounded text-center"}>
                    {/* Download CV */}
                    <MyTooltip title={"Télécharger le CV"} placement={"right"}>
                         <button
                              type={"button"}
                              className={"btn btn-outline btn-outline-dark btn-sm btn-icon rounded-circle mb-5 mb-sm-2"}
                              onClick={() => handleDownloadCurriculum()}
                         >
                              <i className={"fas fa-download"} />
                         </button>
                    </MyTooltip>

                    {/* Replace CV */}
                    <MyTooltip title={"Remplacer le CV"} placement={"right"}>
                         <span>
                              <input type="file" className={"d-none"} ref={refReplaceCurriculumButton} onChange={handleReplaceCurriculum} />
                              <button className="btn btn-outline btn-outline-dark btn-sm btn-icon rounded-circle" onClick={() => refReplaceCurriculumButton.current.click()}>
                                   <i className="fas fa-sync" />
                              </button>
                         </span>
                    </MyTooltip>

                    <div className="separator separator-dashed border-white mb-5 mb-sm-2 mt-5 mt-sm-2"></div>

                    {/* Tél. */}
                    {candidateInPreview?.candidat.tel && (
                         <MyTooltip title={"Appeler"} placement={"right"}>
                              <a
                                   href={`tel:${candidateInPreview.candidat.telIndicatif?.phoneCodeWithPlusPrefix} ${candidateInPreview.candidat.tel}`}
                                   className={"btn btn-success p-0 px-3 py-2 mb-5 mb-sm-2"}
                              >
                                   <i className={"fas fa-phone"} />
                              </a>
                         </MyTooltip>
                    )}

                    {/* Mail */}
                    <MyTooltip title={"Envoyer un mail"} placement={"right"}>
                         <a
                              href={`mailto:${candidateInPreview?.candidat.email}`}
                              target={"_blank"}
                              className={"btn btn-outline btn-outline-primary btn-sm btn-icon rounded-circle mb-5 mb-sm-2"}
                         >
                              <i className={"fas fa-envelope"} />
                         </a>
                    </MyTooltip>

                    {/* Vivier */}
                    <MyTooltip title={"Ajouter à un vivier"} placement={"right"}>
                         <button
                              type={"button"}
                              className={"btn btn-outline btn-outline-danger btn-sm btn-icon rounded-circle mb-5 mb-sm-2"}
                              onClick={() => candidateInPreview && globalContext.setAddingCandidateToViviers(candidateInPreview)}
                         >
                              <i className="fas fa-heart fs-6" />
                         </button>
                    </MyTooltip>

                    {/* Liste traitement */}
                    <MyTooltip
                         title={candidateInPreview?.doesConnectedUserAddedCandidatInListeTraitement ? "Supprimer de ma liste de traitement" : "Ajouter à ma liste de traitement"}
                         placement={"right"}
                    >
                         <button
                              type={"button"}
                              className={`btn ${
                                   candidateInPreview?.doesConnectedUserAddedCandidatInListeTraitement ? "btn-warning" : "btn-outline btn-outline-warning"
                              } btn-sm btn-icon rounded-circle mb-5 mb-sm-2`}
                              onClick={() => globalContext.handleAddOrRemoveCandidateToListeTraitement(candidateInPreview as ISearchResultsDataItemResponseModel)}
                              disabled={!!globalContext.addingRemovingCandidateToFromListeTraitement}
                         >
                              {globalContext.addingRemovingCandidateToFromListeTraitement ? (
                                   <MySimpleSpinner />
                              ) : (
                                   <>
                                        {candidateInPreview?.doesConnectedUserAddedCandidatInListeTraitement ? (
                                             <i className="fas fa-minus fs-2" />
                                        ) : (
                                             <i className="fas fa-plus fs-2" />
                                        )}
                                   </>
                              )}
                         </button>
                    </MyTooltip>
                    {/* Partager */}
                    <MyTooltip title={"Partager le profil par mail"} placement={"right"}>
                         <button
                              type={"button"}
                              className={"btn btn-outline btn-outline-dark btn-sm btn-icon rounded-circle"}
                              onClick={() => candidateInPreview && globalContext.setSharingCandidate(candidateInPreview)}
                         >
                              <i className="fas fa-share fs-6" />
                         </button>
                    </MyTooltip>
               </div>
          </>
     )
}

/* Suivis: message répondeur, DMS, entretien en agence, RQ */
export const AjouterUnSuiviButton = () => {
     const globalContext = useSearchResultsContext()
     const selectedItemInPreview = globalContext.itemInPreview!.selectedItem
     const candidateInPreview = globalContext.useListingQuery.data?.data.find(item => item.candidat.id === selectedItemInPreview?.candidat.id)

     return (
          <div className="dropup me-6">
               <button className="btn btn-sm btn-info p-0 px-3 py-2 py-sm-2 px-sm-3" type="button" data-bs-toggle="dropdown" data-bs-auto-close="true" aria-expanded="false">
                    <span className={"d-none d-sm-inline-flex"}>Ajouter un suivi</span> <i className={"fas fa-plus fs-4 fs-sm-7"} />
               </button>
               <ul className="dropdown-menu" aria-labelledby="defaultDropdown">
                    <li>
                         <h6 className="dropdown-header">Suivis</h6>
                    </li>
                    <li>
                         <span
                              className="dropdown-item cursor-pointer"
                              onClick={() =>
                                   globalContext.setAddingSuiviToCandidate({
                                        item: candidateInPreview as ISearchResultsDataItemResponseModel,
                                        suiviType: CONST_CANDIDATSUIVI_TYPE_MESSAGE_REPONDEUR,
                                   })
                              }
                         >
                              Message répondeur
                         </span>
                    </li>
                    <li>
                         <span
                              className="dropdown-item cursor-pointer"
                              onClick={() =>
                                   globalContext.setAddingSuiviToCandidate({
                                        item: candidateInPreview as ISearchResultsDataItemResponseModel,
                                        suiviType: CONST_CANDIDATSUIVI_TYPE_ENTRETIEN_TELEPHONIQUE,
                                   })
                              }
                         >
                              DMS
                         </span>
                    </li>
                    <li>
                         <span
                              className="dropdown-item cursor-pointer"
                              onClick={() =>
                                   globalContext.setAddingSuiviToCandidate({
                                        item: candidateInPreview as ISearchResultsDataItemResponseModel,
                                        suiviType: CONST_CANDIDATSUIVI_TYPE_ENTRETIEN_CTS,
                                   })
                              }
                         >
                              Entretien en agence
                         </span>
                    </li>
                    <li>
                         <span
                              className="dropdown-item cursor-pointer"
                              onClick={() =>
                                   globalContext.setAddingSuiviToCandidate({
                                        item: candidateInPreview as ISearchResultsDataItemResponseModel,
                                        suiviType: CONST_CANDIDATSUIVI_TYPE_ENTRETIEN_CLIENT,
                                   })
                              }
                         >
                              Réunion de qualif. (RQ)
                         </span>
                    </li>
               </ul>
          </div>
     )
}

// Wrapper Suivi
export const SuiviFormModal = () => {
     const queryClient = useQueryClient()
     const globalContext = useSearchResultsContext()
     const item = globalContext.useListingQuery.data?.data.find(
          item_ => item_.candidat.id === globalContext.addingSuiviToCandidate!.item!.candidat.id
     ) as ISearchResultsDataItemResponseModel

     if (globalContext.addingSuiviToCandidate) {
          switch (globalContext.addingSuiviToCandidate.suiviType) {
               case CONST_CANDIDATSUIVI_TYPE_ENTRETIEN_TELEPHONIQUE:
                    return (
                         <MyModal title={<span>DMS de {item.candidat.prenomNom}</span>} show={true} handleClose={() => globalContext.setAddingSuiviToCandidate(null)} fullscreen>
                              <CandidatAddSuiviEntretienTelephoniqueForm
                                   candidat={{ id: item.candidat?.id as number, prenomNom: item.candidat?.prenomNom as string }}
                                   curriculumGoogleDriveUrl={FileAndDriveHelper.getUrlOfDriveFilePreview(item.googleDriveIdForCv as string)}
                                   handleSubmitCallBack={({ suivi, candidat }) => {
                                        queryClient.setQueryData<ISearchResultsResponseModel | undefined>(
                                             [globalContext.REACT_QUERY_KEY_SEARCH_RESULTS, globalContext.filters],
                                             (prev: ISearchResultsResponseModel | undefined) => {
                                                  if (prev?.data) {
                                                       const index = prev.data.findIndex(item_ => item_.candidat.id === item.candidat.id)
                                                       prev.data[index] = {
                                                            ...prev.data[index],
                                                            candidat: { ...prev.data[index].candidat, ...candidat },
                                                            entretienTelOfConnectedUserAndHisTeam: [suivi, ...prev.data[index].entretienTelOfConnectedUserAndHisTeam],
                                                       }

                                                       return prev
                                                  }

                                                  return prev
                                             }
                                        )
                                        globalContext.setAddingSuiviToCandidate(null)
                                   }}
                              />
                         </MyModal>
                    )
               case CONST_CANDIDATSUIVI_TYPE_MESSAGE_REPONDEUR:
                    return (
                         <MyModal
                              title={<span>Message répondeur pour {item.candidat.prenomNom}</span>}
                              show={true}
                              handleClose={() => globalContext.setAddingSuiviToCandidate(null)}
                              fullscreen
                         >
                              <CandidatAddSuiviMessageRepondeurForm
                                   candidat={{ id: item.candidat?.id as number, prenomNom: item.candidat?.prenomNom as string }}
                                   curriculumGoogleDriveUrl={FileAndDriveHelper.getUrlOfDriveFilePreview(item.googleDriveIdForCv as string)}
                                   handleSubmitCallBack={() => globalContext.setAddingSuiviToCandidate(null)}
                              />
                         </MyModal>
                    )
               case CONST_CANDIDATSUIVI_TYPE_ENTRETIEN_CTS:
                    return (
                         <MyModal
                              title={<span>Entretien en agence avec {item.candidat.prenomNom}</span>}
                              show={true}
                              handleClose={() => globalContext.setAddingSuiviToCandidate(null)}
                              fullscreen
                         >
                              <CandidatAddSuiviEntretienAgenceForm
                                   candidat={{ id: item.candidat?.id as number, prenomNom: item.candidat?.prenomNom as string }}
                                   curriculumGoogleDriveUrl={FileAndDriveHelper.getUrlOfDriveFilePreview(item.googleDriveIdForCv as string)}
                                   handleSubmitCallBack={() => globalContext.setAddingSuiviToCandidate(null)}
                              />
                         </MyModal>
                    )
               case CONST_CANDIDATSUIVI_TYPE_ENTRETIEN_CLIENT:
                    return (
                         <MyModal
                              title={<span>Réunion de qualification de {item.candidat.prenomNom}</span>}
                              show={true}
                              handleClose={() => globalContext.setAddingSuiviToCandidate(null)}
                              fullscreen
                         >
                              <CandidatAddSuiviEntretienClientForm
                                   candidat={{ id: item.candidat?.id as number, prenomNom: item.candidat?.prenomNom as string }}
                                   curriculumGoogleDriveUrl={FileAndDriveHelper.getUrlOfDriveFilePreview(item.googleDriveIdForCv as string)}
                                   handleSubmitCallBack={() => globalContext.setAddingSuiviToCandidate(null)}
                              />
                         </MyModal>
                    )
               default:
                    return <span>Pas de suivi possible</span>
          }
     }

     return <span>Pas de suivi possible</span>
}
