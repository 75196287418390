import { CONST_CONSULTANT_STATUT_ST_PORTAGE, CONST_CONSULTANT_STATUT_ST_SOCIETE_SERVICE, CONST_USER_ROLE_CONSULTANT } from "@common-constants/*"
import MyAlert from "@common-utils/MyAlert"
import React from "react"
import { REACT_QUERY_KEY_GET_CONSULTANT_DETAILS, useConsultantDetailsContext } from "../ConsultantDetails"
import { disableEspaceConsultant, enableEspaceConsultant, envoyerInstructionsConnexionEspaceCollabRequest } from "../core/_requests"
import { toast } from "react-toastify"
import { IGetDetailsConsultant } from "../core/_models"
import Swal from "sweetalert2"
import useSwal from "@common-hooks/useSwal"
import { useQueryClient } from "react-query"

const EnableDisableAccount = () => {
     const queryClient = useQueryClient()
     const { swal } = useSwal()

     const { detailsQuery } = useConsultantDetailsContext()

     function handleEnableDisableEspaceConsultant() {
          swal.fire({
               icon: "warning",
               title:
                    detailsQuery.data?.consultant.candidat?.user?.roles?.includes("ROLE_CONSULTANT") && detailsQuery.data?.consultant.candidat?.user.enabled
                         ? "Voulez-vous couper son accès à l'espace Talent?"
                         : "Voulez-vous lui donner accès à l'espace Talent?",
               html: "Aucune notification lui sera envoyée.",
               showCancelButton: true,
               confirmButtonText: "Oui",
               cancelButtonText: "Annuler",
               showLoaderOnConfirm: true,
               preConfirm: async () => {
                    const hasAccess = !!(
                         detailsQuery.data?.consultant.candidat?.user?.roles &&
                         detailsQuery.data.consultant.candidat.user.roles.includes("ROLE_CONSULTANT") &&
                         detailsQuery.data?.consultant.candidat?.user.enabled
                    )
                    const request = hasAccess ? disableEspaceConsultant : enableEspaceConsultant
                    return request(detailsQuery.data?.consultant.id as number)
                         .then(() => {
                              toast.success(
                                   hasAccess ? "Le collaborateur n'aura plus accès à son espace consultant." : "Le collaborateur a désormais accès à son espace consultant."
                              )
                              queryClient.setQueryData<IGetDetailsConsultant | undefined>(REACT_QUERY_KEY_GET_CONSULTANT_DETAILS, (prev: IGetDetailsConsultant | undefined) => {
                                   if (prev) {
                                        prev.consultant = {
                                             ...prev.consultant,
                                             candidat: {
                                                  ...prev.consultant.candidat,
                                                  user: {
                                                       ...prev.consultant.candidat?.user,
                                                       enabled: !prev.consultant.candidat?.user?.roles?.includes("ROLE_CONSULTANT"),
                                                       roles: prev.consultant.candidat?.user?.roles?.includes("ROLE_CONSULTANT")
                                                            ? ["ROLE_CANDIDAT_EN_COURS_EMBAUCHE"]
                                                            : ["ROLE_CONSULTANT"],
                                                  },
                                             },
                                        }
                                   }

                                   return prev
                              })
                         })
                         .catch(e => {
                              toast.error(e?.response?.data?.detail, { autoClose: false })
                         })
               },
               allowOutsideClick: () => !Swal.isLoading(),
          }).then()
     }

     function handleEnvoyerInstructionsConnexion() {
          swal.fire({
               icon: "warning",
               title: "Voulez-vous envoyer les instructions de connexion à l'espace Talent?",
               showCancelButton: true,
               confirmButtonText: "Confirmer",
               cancelButtonText: "Annuler",
               showLoaderOnConfirm: true,
               preConfirm: async () => {
                    return envoyerInstructionsConnexionEspaceCollabRequest(detailsQuery.data?.consultant.id as number)
                         .then(() => {
                              toast.success("Instructions envoyées avec succès.")
                         })
                         .catch(e => {
                              toast.error(e?.response?.data?.detail, { autoClose: false })
                         })
               },
               allowOutsideClick: () => !Swal.isLoading(),
          }).then()
     }

     return (
          <>
               {detailsQuery.data?.consultant.etat !== "ETAT_HORS_EFFECTIF" &&
                    ![CONST_CONSULTANT_STATUT_ST_PORTAGE, CONST_CONSULTANT_STATUT_ST_SOCIETE_SERVICE].includes(detailsQuery.data?.consultant.statut as string) && (
                         <>
                              {!detailsQuery.data?.consultant.candidat?.user?.roles?.includes(CONST_USER_ROLE_CONSULTANT) ||
                              !detailsQuery.data?.consultant.candidat?.user?.enabled ? (
                                   <MyAlert type={"warning"} classNames={"mb-4"}>
                                        <div className={"d-flex align-items-center"}>
                                             L'espace Talent du collaborateur n'est pas actif.{" "}
                                             <button
                                                  className={"btn btn-sm btn-success p-0 px-2 py-1 d-flex align-items-center ms-2"}
                                                  onClick={() => handleEnableDisableEspaceConsultant()}
                                             >
                                                  Activer <span className={"fas fa-power-off ms-1"} />
                                             </button>
                                        </div>
                                   </MyAlert>
                              ) : (
                                   <>
                                        {!detailsQuery.data.consultant.candidat?.user.dateDerniereConnexion && (
                                             <MyAlert type={"info"} classNames={"mb-4"}>
                                                  <div className={"d-flex align-items-center"}>
                                                       <div>
                                                            Envoyer <span className={"text-dark"}>les instructions de connexion</span> à l'espace{" "}
                                                            <span className="text-dark">Talent</span> sur l'adresse mail{" "}
                                                            <span className={"text-dark"}>{detailsQuery.data.consultant.mailPro}</span>.
                                                       </div>
                                                       <button
                                                            className={"btn btn-sm btn-success p-0 px-2 py-1 d-flex align-items-center ms-2"}
                                                            onClick={() => handleEnvoyerInstructionsConnexion()}
                                                       >
                                                            Envoyer <span className={"fas fa-paper-plane ms-1"} />
                                                       </button>
                                                  </div>
                                             </MyAlert>
                                        )}
                                        <MyAlert type={"success"} classNames={"mb-4"}>
                                             <div className={"d-flex align-items-center"}>
                                                  L'espace Talent est actif.
                                                  <button
                                                       className={"btn btn-sm btn-warning p-0 px-2 py-1 d-flex align-items-center ms-2"}
                                                       onClick={() => handleEnableDisableEspaceConsultant()}
                                                  >
                                                       Désactiver <span className={"fas fa-power-off ms-1"} />
                                                  </button>
                                             </div>
                                        </MyAlert>
                                   </>
                              )}
                         </>
                    )}
          </>
     )
}

export default EnableDisableAccount
