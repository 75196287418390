// Etat badge for column état
import { IPersonnelDeclarationMensuelleModel } from "@common-models/*"
import {
     CONST_PERSONNEL_CODE_CELINE_CORLE,
     CONST_PERSONNEL_CODE_DIDIER_BAGUR,
     CONST_PERSONNEL_DECLARATION_SUIVI_TYPE_INTERESSE_DEMANDE_REGUL,
     CONST_PERSONNEL_DECLARATION_SUIVI_TYPE_INTERESSE_FINALISATION,
     CONST_PERSONNEL_DECLARATION_SUIVI_TYPE_INTERESSE_RECTIFICATION_SANS_DEMANDE,
     CONST_PERSONNEL_DECLARATION_SUIVI_TYPE_INTERESSE_SIGNATURE,
     CONST_PERSONNEL_DECLARATION_SUIVI_TYPE_RESP_DEMANDE_RECTIFICATION,
     CONST_PERSONNEL_DECLARATION_SUIVI_TYPE_RESP_REJET_REGUL,
     CONST_PERSONNEL_DECLARATION_SUIVI_TYPE_RESP_VALIDATION,
     CONST_PERSONNEL_DECLARATION_SUIVI_TYPE_RESP_VALIDATION_REGUL,
     CONST_PERSONNEL_DECLARATION_SUIVI_TYPE_SIEGE_DEMANDER_RECTIFICATION,
     CONST_PERSONNEL_DECLARATION_SUIVI_TYPE_SIEGE_VALIDATION,
} from "@common-constants/*"
import React from "react"
import { useStructureDeclarationsListingContext } from "../GestionStructureDeclarationsListing"
import { IGetStructureDeclarationsDataItemResponseModel } from "../core/_models"
import { useAuth } from "../../../../../../AppContext"

// Etat badge for cell column état
export const EtatBadge = ({ declaration }: { declaration: IPersonnelDeclarationMensuelleModel | null }) => {
     const { currentUser } = useAuth()

     if (declaration?.etat) {
          // Badge according to access
          if (
               currentUser?.roleGroup === "ROLE_GROUP_ADMINISTRATIF" &&
               !(
                    [CONST_PERSONNEL_CODE_DIDIER_BAGUR, CONST_PERSONNEL_CODE_CELINE_CORLE].includes(currentUser.code as string) &&
                    currentUser.personnelHierarchyFromUpToDown?.find(personnel => personnel.id === declaration.personnel?.id)
               )
          ) {
               if ([CONST_PERSONNEL_DECLARATION_SUIVI_TYPE_RESP_VALIDATION].includes(declaration.etat?.type as string)) {
                    return <span className={"badge badge-warning not-concerned-by-preview"}>À valider</span>
               }

               if (
                    [CONST_PERSONNEL_DECLARATION_SUIVI_TYPE_SIEGE_DEMANDER_RECTIFICATION, CONST_PERSONNEL_DECLARATION_SUIVI_TYPE_RESP_VALIDATION_REGUL].includes(
                         declaration.etat?.typeDependency as string
                    )
               ) {
                    return <span className={"badge badge-info not-concerned-by-preview"}>En cours de rectification</span>
               }

               if (
                    [
                         CONST_PERSONNEL_DECLARATION_SUIVI_TYPE_INTERESSE_FINALISATION,
                         CONST_PERSONNEL_DECLARATION_SUIVI_TYPE_INTERESSE_SIGNATURE,
                         CONST_PERSONNEL_DECLARATION_SUIVI_TYPE_INTERESSE_RECTIFICATION_SANS_DEMANDE,
                    ].includes(declaration.etat?.type as string)
               ) {
                    return <span className={"badge badge-danger not-concerned-by-preview"}>En attente de réception</span>
               }

               if (
                    [
                         CONST_PERSONNEL_DECLARATION_SUIVI_TYPE_SIEGE_DEMANDER_RECTIFICATION,
                         CONST_PERSONNEL_DECLARATION_SUIVI_TYPE_RESP_DEMANDE_RECTIFICATION,
                         CONST_PERSONNEL_DECLARATION_SUIVI_TYPE_RESP_VALIDATION_REGUL,
                    ].includes(declaration.etat?.type as string)
               ) {
                    return <span className={"badge badge-info not-concerned-by-preview"}>En cours de rectification</span>
               }
               if (
                    [
                         CONST_PERSONNEL_DECLARATION_SUIVI_TYPE_SIEGE_VALIDATION,
                         CONST_PERSONNEL_DECLARATION_SUIVI_TYPE_INTERESSE_DEMANDE_REGUL,
                         CONST_PERSONNEL_DECLARATION_SUIVI_TYPE_RESP_REJET_REGUL,
                    ].includes(declaration.etat?.type as string)
               ) {
                    return <span className={"badge badge-success not-concerned-by-preview"}>Validée par le siège</span>
               }
          } else {
               if ([CONST_PERSONNEL_DECLARATION_SUIVI_TYPE_INTERESSE_SIGNATURE].includes(declaration.etat?.type as string)) {
                    return <span className={"badge badge-warning not-concerned-by-preview"}>À valider</span>
               }

               if (
                    [CONST_PERSONNEL_DECLARATION_SUIVI_TYPE_SIEGE_DEMANDER_RECTIFICATION, CONST_PERSONNEL_DECLARATION_SUIVI_TYPE_INTERESSE_DEMANDE_REGUL].includes(
                         declaration.etat?.type as string
                    )
               ) {
                    return <span className={"badge badge-warning not-concerned-by-preview"}>À contrôler</span>
               }

               if (
                    [
                         CONST_PERSONNEL_DECLARATION_SUIVI_TYPE_RESP_DEMANDE_RECTIFICATION,
                         CONST_PERSONNEL_DECLARATION_SUIVI_TYPE_SIEGE_DEMANDER_RECTIFICATION,
                         CONST_PERSONNEL_DECLARATION_SUIVI_TYPE_RESP_VALIDATION_REGUL,
                    ].includes(declaration.etat?.typeDependency as string)
               ) {
                    return <span className={"badge badge-info not-concerned-by-preview"}>En cours de rectification</span>
               }

               if (
                    [CONST_PERSONNEL_DECLARATION_SUIVI_TYPE_INTERESSE_FINALISATION, CONST_PERSONNEL_DECLARATION_SUIVI_TYPE_INTERESSE_RECTIFICATION_SANS_DEMANDE].includes(
                         declaration.etat?.type as string
                    )
               ) {
                    return <span className={"badge badge-danger not-concerned-by-preview"}>En attente de réception</span>
               }

               if (
                    [CONST_PERSONNEL_DECLARATION_SUIVI_TYPE_RESP_DEMANDE_RECTIFICATION, CONST_PERSONNEL_DECLARATION_SUIVI_TYPE_RESP_VALIDATION_REGUL].includes(
                         declaration.etat?.type as string
                    )
               ) {
                    return <span className={"badge badge-info not-concerned-by-preview"}>En cours de rectification</span>
               }

               if ([CONST_PERSONNEL_DECLARATION_SUIVI_TYPE_RESP_VALIDATION].includes(declaration.etat?.type as string)) {
                    return <span className={"badge badge-success not-concerned-by-preview"}>Envoyée au siège</span>
               }

               if ([CONST_PERSONNEL_DECLARATION_SUIVI_TYPE_SIEGE_VALIDATION, CONST_PERSONNEL_DECLARATION_SUIVI_TYPE_RESP_REJET_REGUL].includes(declaration.etat?.type as string)) {
                    return <span className={"badge badge-success not-concerned-by-preview"}>Validée par le siège</span>
               }
          }
     }

     return <span className={"badge badge-danger not-concerned-by-preview"}>En attente de réception</span>
}

export const IconRelance = ({ item }: { item: IGetStructureDeclarationsDataItemResponseModel }) => {
     const context = useStructureDeclarationsListingContext()

     const ActionIcon = () => <span className={"fas fa-bell fs-1 text-warning text-hover-danger ms-4 not-concerned-by-preview"} onClick={() => context.handleRelance([item])} />

     return context.isRelancable(item) ? <ActionIcon /> : <></>
}
