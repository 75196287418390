import React, { useState } from "react"
import { Link } from "react-router-dom"
import { useConnectedUserDeclarationDetails } from "./ConnectedPersonnelDeclarationDetails"
import MyModal from "@common-utils/MyModal"
import { IPersonnelDdcModel } from "@common-models/*"
import { toast } from "react-toastify"
import { useQueryClient } from "react-query"
import ConnectedPersonnelCreateDdcForm from "../../ddc/create/_ConnectedPersonnelCreateDdcForm"
import PersonnelDdcListingForOne, { REACT_QUERY_KEY_GET_PERSONNEL_DDC_LISTING } from "../../../declaration/ddc/listing-one-personnel/PersonnelDdcListingForOne"
import { useAuth } from "../../../../../AppContext"

export const ConnectedUserDeclarationDetails_ddc = () => {
     const { currentUser } = useAuth()
     const context = useConnectedUserDeclarationDetails()
     const queryClient = useQueryClient()
     const [createInModal, setCreateInModal] = useState<boolean>(false)

     function handleCreateDdc(newItem: IPersonnelDdcModel) {
          queryClient.setQueryData<IPersonnelDdcModel[] | undefined>(REACT_QUERY_KEY_GET_PERSONNEL_DDC_LISTING, (prev: IPersonnelDdcModel[] | undefined) => {
               if (prev) {
                    return [newItem, ...prev]
               }

               return prev
          })
          setCreateInModal(false)
          toast.success("Votre demande de congés a bien été envoyée.")
     }

     const Footer = () => {
          return (
               <>
                    <div className="d-none d-sm-flex justify-content-between">
                         <div>
                              <Link to="/personnel/mes-activites/declarations " className={`btn btn-sm btn-light-primary me-2`}>
                                   <i className={"fas fa-list-dots me-2 align-middle"} /> Retour aux déclarations
                              </Link>
                              <button type="button" className="btn btn-sm btn-light-primary me-3" onClick={() => context.setCurrentStepHeader("arrets")}>
                                   <span className={"fas fa-arrow-left fs-3 me-1 align-middle"} /> Arrêts
                              </button>
                         </div>

                         <button
                              type="button"
                              className="btn btn-sm btn-primary"
                              onClick={() => {
                                   context.setCurrentStepHeader(context.enCoursDeRectificationEnabledDepuisRecapitulatif ? "recap" : "cra")
                                   context.setEnCoursDeRectificationEnabledDepuisRecapitulatif(false)
                              }}
                         >
                              {context.enCoursDeRectificationEnabledDepuisRecapitulatif ? "Récapitulatif" : "CRA"}
                              <span className={"fas fa-arrow-right fs-3 ms-1 align-middle"} />
                         </button>
                    </div>

                    <div className="d-flex d-sm-none flex-column">
                         <div className={"mb-3"}>
                              <button type="button" className="btn btn-sm btn-light-primary me-3" onClick={() => context.setCurrentStepHeader("arrets")}>
                                   <span className={"fas fa-arrow-left fs-3 me-1 align-middle"} /> Arrêts
                              </button>
                              <button
                                   type="button"
                                   className="btn btn-sm btn-primary float-end"
                                   onClick={() => {
                                        context.setCurrentStepHeader(context.enCoursDeRectificationEnabledDepuisRecapitulatif ? "recap" : "cra")
                                        context.setEnCoursDeRectificationEnabledDepuisRecapitulatif(false)
                                   }}
                              >
                                   {context.enCoursDeRectificationEnabledDepuisRecapitulatif ? "Récapitulatif" : "CRA"}
                                   <span className={"fas fa-arrow-right fs-3 ms-1 align-middle"} />
                              </button>
                         </div>

                         <Link to="/personnel/mes-activites/declarations " className={`btn btn-sm btn-light-primary`}>
                              <span className={"fas fa-list-dots fs-1 me-3 align-middle"} /> Retour aux déclarations
                         </Link>
                    </div>
               </>
          )
     }

     return (
          <>
               {context.data.declaration?.modifiable && (
                    <div className="d-flex justify-content-end mb-4">
                         <button type={"button"} className={`btn btn-primary btn-sm d-flex align-items-center`} onClick={() => setCreateInModal(true)}>
                              <span className={`d-md-inline d-none`}>Nouvelle demande</span>
                              <i className={"fas fa-plus-circle ms-md-2 pe-0 fs-3"} />
                         </button>
                    </div>
               )}

               <div>
                    <PersonnelDdcListingForOne
                         personnel_ID={currentUser?.id as number}
                         filtres={{ duOrAuWithThisMonthAndYear: context.data.declaration?.mois?.parseZone().format("YYYY-MM-DD") as string }}
                         editionMode={context.editionModeEnabled}
                    />
               </div>

               <div className="separator separator-dashed my-6" />
               <Footer />

               {createInModal && (
                    <MyModal show={true} handleClose={() => setCreateInModal(false)} title={<>Nouvelle demande</>} size={"lg"}>
                         <ConnectedPersonnelCreateDdcForm
                              duOrAuWithThisMonthAndYear={context.data.declaration?.mois?.parseZone().startOf("month").format("YYYY-MM-DD")}
                              callback={val => handleCreateDdc(val)}
                         />
                    </MyModal>
               )}
          </>
     )
}
