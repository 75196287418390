import axios from "axios"
import { CONST_API_BASE_URL_ENDPOINT } from "../../../endpoints"
import MyAlert from "@common-utils/MyAlert"
import React from "react"
import { useMutation } from "react-query"
import MySimpleSpinner from "@common-utils/MySimpleSpinner"

const SyncGoogleCalendar = () => {
     const mutation = useMutation(
          async () => {
               const response = await axios.post<{ url: string }>(CONST_API_BASE_URL_ENDPOINT + "/init-google-calendar-step-1")
               return response.data
          },
          {
               onSuccess: data => {
                    // Redirect to data.url
                    window.location.href = data.url
               },
               onError: error => {
                    console.error("Erreur lors de la synchronisation :", error)
               },
          }
     )

     return (
          <>
               <>
                    <div className={"d-flex justify-content-center"}>
                         <div className={"w-100 w-lg-75 w-xxl-65"}>
                              <MyAlert title={<>Synchronisation requise</>} type={"primary"} classNames={"mb-5"}>
                                   <>Merci de synchroniser votre calendrier CTS avec Talent afin d’y intégrer automatiquement vos événements enregistrés dans Talent.</>
                              </MyAlert>

                              <div className="d-flex justify-content-center">
                                   <button
                                        type="button"
                                        className="btn btn-sm btn-primary d-flex align-items-center"
                                        onClick={() => mutation.mutate()}
                                        disabled={mutation.isLoading}
                                   >
                                        {mutation.isLoading ? (
                                             <MySimpleSpinner size={"sm"} />
                                        ) : (
                                             <>
                                                  Synchroniser <span className="fas fa-sync ms-1" />
                                             </>
                                        )}
                                   </button>
                              </div>
                         </div>
                    </div>
               </>
          </>
     )
}

export default SyncGoogleCalendar
