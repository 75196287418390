import { toast } from "react-toastify"
import { IFournisseurDocumentModel, IHttpErrorResponseModel } from "@common-models/*"
import { AxiosError } from "axios"
import { useQuery, useQueryClient } from "react-query"
import MySimpleSpinner from "@common-utils/MySimpleSpinner"
import MyAlert from "@common-utils/MyAlert"
import React, { useRef, useState } from "react"
import {
     CONST_CIVILITE_MME,
     CONST_FOURNISSEUR_DOCUMENT_ASSURANCE_RC_PRO,
     CONST_FOURNISSEUR_DOCUMENT_EXTRAIT_KBIS,
     CONST_FOURNISSEUR_DOCUMENT_REGULARITE_FISCALE,
     CONST_FOURNISSEUR_DOCUMENT_RIB,
     CONST_FOURNISSEUR_DOCUMENT_VIGILANCE_URSSAF,
} from "@common-constants/*"
import { addFournisseurDocument, getFournisseurDocuments } from "../core/_requests"
import { deleteFournisseurDocument, replaceFournisseurDocument } from "./core/_requests"
import useSwal from "@common-hooks/useSwal"
import { useAuth } from "../../../AppContext"
import MyModal from "@common-utils/MyModal"
import MyLoadingOverlay from "@common-utils/MyLoadingOverlay"
import FileAndDriveHelper from "@common-helpers/FileAndDriveHelper"

const REACT_QUERY_KEY_GET_FOURNISSEUR_DOCS_QUERY = "REACT_QUERY_KEY_GET_FOURNISSEUR_DOCS_QUERY"
const FournisseurDocuments = ({ fournisseur_ID }: { fournisseur_ID: number }) => {
     const { currentUser } = useAuth()
     const { swal } = useSwal()
     const queryClient = useQueryClient()

     const [activeDriveDocumentInModal, setActiveDriveDocumentInModal] = useState<{
          driveID: string
          driveMimeType: string
          modalTitle: string
          isLoading: boolean
          isShown: boolean
     }>()

     const refsInputRemplacerDocument = {}
     refsInputRemplacerDocument[CONST_FOURNISSEUR_DOCUMENT_EXTRAIT_KBIS] = useRef<HTMLInputElement>(null)
     refsInputRemplacerDocument[CONST_FOURNISSEUR_DOCUMENT_ASSURANCE_RC_PRO] = useRef<HTMLInputElement>(null)
     refsInputRemplacerDocument[CONST_FOURNISSEUR_DOCUMENT_VIGILANCE_URSSAF] = useRef<HTMLInputElement>(null)
     refsInputRemplacerDocument[CONST_FOURNISSEUR_DOCUMENT_REGULARITE_FISCALE] = useRef<HTMLInputElement>(null)
     refsInputRemplacerDocument[CONST_FOURNISSEUR_DOCUMENT_RIB] = useRef<HTMLInputElement>(null)

     // Query
     const documentsQuery = useQuery<IFournisseurDocumentModel[], AxiosError>(REACT_QUERY_KEY_GET_FOURNISSEUR_DOCS_QUERY, () => {
          return getFournisseurDocuments(fournisseur_ID)
               .then(r => {
                    return r.data
               })
               .catch((e: AxiosError) => {
                    const error: IHttpErrorResponseModel = e.response?.data

                    // Toast error
                    toast.error(error?.detail, { autoClose: false })

                    throw e
               })
     })

     function handleAddDocument(e: React.ChangeEvent<HTMLInputElement>, type: string) {
          const fileBinary = e.target.files![0]

          if (fileBinary) {
               e.target.disabled = true
               toast.info(`Envoi du document en cours ...`, { autoClose: false })

               addFournisseurDocument(fournisseur_ID, type, fileBinary)
                    .then(r => {
                         queryClient.setQueryData<IFournisseurDocumentModel[] | undefined>(
                              REACT_QUERY_KEY_GET_FOURNISSEUR_DOCS_QUERY,
                              (prev: IFournisseurDocumentModel[] | undefined) => {
                                   if (prev && prev.length > 0) {
                                        return [...prev, r.data]
                                   }

                                   return [r.data]
                              }
                         )
                         toast.dismiss()
                         toast.success("Le document a bien ajouté.")
                    })
                    .catch((e: AxiosError) => {
                         toast.dismiss()
                         toast.error(e?.response?.data?.detail, { autoClose: false })
                    })
                    .finally(() => {
                         e.target.value = ""
                         e.target.disabled = false
                    })
          }
     }

     function handleSetDocument(e: React.ChangeEvent<HTMLInputElement>, id: number) {
          const fileBinary = e.target.files![0]

          if (fileBinary) {
               e.target.disabled = true
               toast.info("Remplacement du document en cours ...", { autoClose: false })

               replaceFournisseurDocument(id, fileBinary)
                    .then(r => {
                         queryClient.setQueryData<IFournisseurDocumentModel[] | undefined>(
                              REACT_QUERY_KEY_GET_FOURNISSEUR_DOCS_QUERY,
                              (prev: IFournisseurDocumentModel[] | undefined) => {
                                   if (prev) {
                                        const index = prev.findIndex(item => item.id === id) as number
                                        prev[index] = r.data
                                        return prev
                                   }
                                   return prev
                              }
                         )
                         toast.dismiss()
                         toast.success("Le document a bien été remplacé.")
                    })
                    .catch(e => {
                         toast.dismiss()
                         toast.error(e?.response?.data?.detail, { autoClose: false })
                    })
                    .finally(() => {
                         e.target.value = ""
                         e.target.disabled = false
                    })
          }
     }

     function handleRemoveDocument(id: number) {
          swal.fire({
               icon: "warning",
               text: `Êtes-vous sûr${currentUser?.civilite === CONST_CIVILITE_MME ? "e" : ""} de vouloir supprimer le document?`,
               showCancelButton: true,
               confirmButtonText: "Oui",
               cancelButtonText: "Annuler",
               focusConfirm: true,
               showLoaderOnConfirm: true,
               preConfirm: () => {
                    return deleteFournisseurDocument(id)
                         .then(() => {
                              queryClient.setQueryData<IFournisseurDocumentModel[] | undefined>(
                                   REACT_QUERY_KEY_GET_FOURNISSEUR_DOCS_QUERY,
                                   (prev: IFournisseurDocumentModel[] | undefined) => {
                                        if (prev) {
                                             return prev.filter(item => item.id !== id)
                                        }

                                        return prev
                                   }
                              )
                              toast.success("Le document a bien été supprimé.")
                         })
                         .catch(e => {
                              toast.error(e?.response?.data?.detail, { autoClose: false })
                         })
               },
               allowOutsideClick: () => !swal.isLoading(),
          }).then()
     }

     return (
          <>
               {/* loader */}
               {documentsQuery.isFetching && (
                    <div className={"text-center p-5"}>
                         <MySimpleSpinner size={"xl"} />{" "}
                    </div>
               )}

               {/* error */}
               {!documentsQuery.isFetching && documentsQuery.isError && (
                    <MyAlert type={"danger"} classNames={"mb-4"}>
                         <>
                              <span className={"me-2"}>{documentsQuery.error?.response?.data?.detail}</span>
                              <button className={"btn btn-sm btn-danger"} onClick={() => documentsQuery.refetch()}>
                                   Recharger
                              </button>
                         </>
                    </MyAlert>
               )}

               {!documentsQuery.isFetching && !documentsQuery.isError && (
                    <>
                         {/* EXTRAIS KBIS */}
                         <div className={`row mb-6`}>
                              <label className="col-md-4 fw-bold fs-6 d-flex align-items-center">
                                   <span className={"me-2"}>Extrait KBIS</span>
                                   {documentsQuery.data?.find(item => item.type === "TYPE_FOURNISSEUR_EXTRAIT_KBIS") ? (
                                        <i className={"fas fa-check-circle text-success"} />
                                   ) : (
                                        <i className={"fas fa-exclamation-circle text-warning"} />
                                   )}
                              </label>
                              <div className="col-md-8 d-flex form-group">
                                   {documentsQuery.data?.find(item => item.type === "TYPE_FOURNISSEUR_EXTRAIT_KBIS") ? (
                                        <>
                                             <button
                                                  className={"btn btn-sm btn-warning me-4 p-0 px-3 py-2 d-flex align-items-center"}
                                                  onClick={() => refsInputRemplacerDocument[CONST_FOURNISSEUR_DOCUMENT_EXTRAIT_KBIS]?.current?.click()}
                                             >
                                                  Remplacer <i className={"fas fa-sync ms-1"} />
                                             </button>
                                             <input
                                                  type="file"
                                                  className={"d-none"}
                                                  ref={refsInputRemplacerDocument[CONST_FOURNISSEUR_DOCUMENT_EXTRAIT_KBIS]}
                                                  onChange={e =>
                                                       handleSetDocument(e, documentsQuery.data?.find(item => item.type === CONST_FOURNISSEUR_DOCUMENT_EXTRAIT_KBIS)?.id as number)
                                                  }
                                             />
                                             <button
                                                  className={"btn btn-sm btn-primary me-4 p-0 px-3 py-2 d-flex align-items-center"}
                                                  onClick={() =>
                                                       setActiveDriveDocumentInModal({
                                                            isShown: true,
                                                            isLoading: true,
                                                            driveID: documentsQuery.data.find(item => item.type === CONST_FOURNISSEUR_DOCUMENT_EXTRAIT_KBIS)
                                                                 ?.googleDriveId as string,
                                                            modalTitle: documentsQuery.data.find(item => item.type === CONST_FOURNISSEUR_DOCUMENT_EXTRAIT_KBIS)
                                                                 ?.typeLabel as string,
                                                            driveMimeType: documentsQuery.data.find(item => item.type === CONST_FOURNISSEUR_DOCUMENT_EXTRAIT_KBIS)
                                                                 ?.googleDriveMimeType as string as string,
                                                       })
                                                  }
                                             >
                                                  Voir <i className="fas fa-eye ms-1" />
                                             </button>
                                             <button
                                                  className={"btn btn-sm btn-danger d-flex align-items-center p-0 px-3 py-2"}
                                                  onClick={() =>
                                                       handleRemoveDocument(documentsQuery.data.find(item => item.type === CONST_FOURNISSEUR_DOCUMENT_EXTRAIT_KBIS)?.id as number)
                                                  }
                                             >
                                                  Supprimer <i className={"fas fa-trash ms-1"} />
                                             </button>
                                        </>
                                   ) : (
                                        <input type={"file"} className={"form-control"} onChange={e => handleAddDocument(e, CONST_FOURNISSEUR_DOCUMENT_EXTRAIT_KBIS)} />
                                   )}
                              </div>
                         </div>

                         {/* ASSURANCE DE RESPONSABILITE CIVILITE PRO */}
                         <div className={`row mb-6`}>
                              <label className="col-md-4 fw-bold fs-6 d-flex align-items-center">
                                   <span className={"me-2"}>Assurance RC Pro.</span>
                                   {documentsQuery.data?.find(item => item.type === "TYPE_FOURNISSEUR_ASSURANCE_RC_PRO") ? (
                                        <i className={"fas fa-check-circle text-success"} />
                                   ) : (
                                        <i className={"fas fa-exclamation-circle text-warning"} />
                                   )}
                              </label>
                              <div className="col-md-8 d-flex form-group">
                                   {documentsQuery.data?.find(item => item.type === "TYPE_FOURNISSEUR_ASSURANCE_RC_PRO") ? (
                                        <>
                                             <button
                                                  className={"btn btn-sm btn-warning me-4 p-0 px-3 py-2 d-flex align-items-center"}
                                                  onClick={() => refsInputRemplacerDocument[CONST_FOURNISSEUR_DOCUMENT_ASSURANCE_RC_PRO]?.current?.click()}
                                             >
                                                  Remplacer <i className={"fas fa-sync ms-1"} />
                                             </button>
                                             <input
                                                  type="file"
                                                  className={"d-none"}
                                                  ref={refsInputRemplacerDocument[CONST_FOURNISSEUR_DOCUMENT_ASSURANCE_RC_PRO]}
                                                  onChange={e =>
                                                       handleSetDocument(
                                                            e,
                                                            documentsQuery.data?.find(item => item.type === CONST_FOURNISSEUR_DOCUMENT_ASSURANCE_RC_PRO)?.id as number
                                                       )
                                                  }
                                             />
                                             <button
                                                  className={"btn btn-sm btn-primary me-4 p-0 px-3 py-2 d-flex align-items-center"}
                                                  onClick={() =>
                                                       setActiveDriveDocumentInModal({
                                                            isShown: true,
                                                            isLoading: true,
                                                            driveID: documentsQuery.data.find(item => item.type === CONST_FOURNISSEUR_DOCUMENT_ASSURANCE_RC_PRO)
                                                                 ?.googleDriveId as string,
                                                            modalTitle: documentsQuery.data.find(item => item.type === CONST_FOURNISSEUR_DOCUMENT_ASSURANCE_RC_PRO)
                                                                 ?.typeLabel as string,
                                                            driveMimeType: documentsQuery.data.find(item => item.type === CONST_FOURNISSEUR_DOCUMENT_ASSURANCE_RC_PRO)
                                                                 ?.googleDriveMimeType as string as string,
                                                       })
                                                  }
                                             >
                                                  Voir <i className="fas fa-eye ms-1" />
                                             </button>
                                             <button
                                                  className={"btn btn-sm btn-danger d-flex align-items-center p-0 px-3 py-2"}
                                                  onClick={() =>
                                                       handleRemoveDocument(
                                                            documentsQuery.data.find(item => item.type === CONST_FOURNISSEUR_DOCUMENT_ASSURANCE_RC_PRO)?.id as number
                                                       )
                                                  }
                                             >
                                                  Supprimer <i className={"fas fa-trash ms-1"} />
                                             </button>
                                        </>
                                   ) : (
                                        <input type={"file"} className={"form-control"} onChange={e => handleAddDocument(e, CONST_FOURNISSEUR_DOCUMENT_ASSURANCE_RC_PRO)} />
                                   )}
                              </div>
                         </div>

                         {/* Vigilance URSSAF */}
                         <div className={`row mb-6`}>
                              <label className="col-md-4 fw-bold fs-6 d-flex align-items-center">
                                   <span className={"me-2"}>Vigilance URSSAF</span>
                                   {documentsQuery.data?.find(item => item.type === "TYPE_FOURNISSEUR_VIGILANCE_URSSAF") ? (
                                        <i className={"fas fa-check-circle text-success"} />
                                   ) : (
                                        <i className={"fas fa-exclamation-circle text-warning"} />
                                   )}
                              </label>
                              <div className="col-md-8 d-flex form-group">
                                   {documentsQuery.data?.find(item => item.type === "TYPE_FOURNISSEUR_VIGILANCE_URSSAF") ? (
                                        <>
                                             <button
                                                  className={"btn btn-sm btn-warning me-4 p-0 px-3 py-2 d-flex align-items-center"}
                                                  onClick={() => refsInputRemplacerDocument[CONST_FOURNISSEUR_DOCUMENT_VIGILANCE_URSSAF]?.current?.click()}
                                             >
                                                  Remplacer <i className={"fas fa-sync ms-1"} />
                                             </button>
                                             <input
                                                  type="file"
                                                  className={"d-none"}
                                                  ref={refsInputRemplacerDocument[CONST_FOURNISSEUR_DOCUMENT_VIGILANCE_URSSAF]}
                                                  onChange={e =>
                                                       handleSetDocument(
                                                            e,
                                                            documentsQuery.data?.find(item => item.type === CONST_FOURNISSEUR_DOCUMENT_VIGILANCE_URSSAF)?.id as number
                                                       )
                                                  }
                                             />
                                             <button
                                                  className={"btn btn-sm btn-primary me-4 p-0 px-3 py-2 d-flex align-items-center"}
                                                  onClick={() =>
                                                       setActiveDriveDocumentInModal({
                                                            isShown: true,
                                                            isLoading: true,
                                                            driveID: documentsQuery.data.find(item => item.type === CONST_FOURNISSEUR_DOCUMENT_VIGILANCE_URSSAF)
                                                                 ?.googleDriveId as string,
                                                            modalTitle: documentsQuery.data.find(item => item.type === CONST_FOURNISSEUR_DOCUMENT_VIGILANCE_URSSAF)
                                                                 ?.typeLabel as string,
                                                            driveMimeType: documentsQuery.data.find(item => item.type === CONST_FOURNISSEUR_DOCUMENT_VIGILANCE_URSSAF)
                                                                 ?.googleDriveMimeType as string as string,
                                                       })
                                                  }
                                             >
                                                  Voir <i className="fas fa-eye ms-1" />
                                             </button>
                                             <button
                                                  className={"btn btn-sm btn-danger d-flex align-items-center p-0 px-3 py-2"}
                                                  onClick={() =>
                                                       handleRemoveDocument(
                                                            documentsQuery.data.find(item => item.type === CONST_FOURNISSEUR_DOCUMENT_VIGILANCE_URSSAF)?.id as number
                                                       )
                                                  }
                                             >
                                                  Supprimer <i className={"fas fa-trash ms-1"} />
                                             </button>
                                        </>
                                   ) : (
                                        <input type={"file"} className={"form-control"} onChange={e => handleAddDocument(e, CONST_FOURNISSEUR_DOCUMENT_VIGILANCE_URSSAF)} />
                                   )}
                              </div>
                         </div>

                         {/* Régularité fiscale */}
                         <div className={`row mb-6`}>
                              <label className="col-md-4 fw-bold fs-6 d-flex align-items-center">
                                   <span className={"me-2"}>Régularité fiscale</span>
                                   {documentsQuery.data?.find(item => item.type === "TYPE_FOURNISSEUR_REGULARITE_FISCALE") ? (
                                        <i className={"fas fa-check-circle text-success"} />
                                   ) : (
                                        <i className={"fas fa-exclamation-circle text-warning"} />
                                   )}
                              </label>
                              <div className="col-md-8 d-flex form-group">
                                   {documentsQuery.data?.find(item => item.type === "TYPE_FOURNISSEUR_REGULARITE_FISCALE") ? (
                                        <>
                                             <button
                                                  className={"btn btn-sm btn-warning me-4 p-0 px-3 py-2 d-flex align-items-center"}
                                                  onClick={() => refsInputRemplacerDocument[CONST_FOURNISSEUR_DOCUMENT_REGULARITE_FISCALE]?.current?.click()}
                                             >
                                                  Remplacer <i className={"fas fa-sync ms-1"} />
                                             </button>
                                             <input
                                                  type="file"
                                                  className={"d-none"}
                                                  ref={refsInputRemplacerDocument[CONST_FOURNISSEUR_DOCUMENT_REGULARITE_FISCALE]}
                                                  onChange={e =>
                                                       handleSetDocument(
                                                            e,
                                                            documentsQuery.data?.find(item => item.type === CONST_FOURNISSEUR_DOCUMENT_REGULARITE_FISCALE)?.id as number
                                                       )
                                                  }
                                             />
                                             <button
                                                  className={"btn btn-sm btn-primary me-4 p-0 px-3 py-2 d-flex align-items-center"}
                                                  onClick={() =>
                                                       setActiveDriveDocumentInModal({
                                                            isShown: true,
                                                            isLoading: true,
                                                            driveID: documentsQuery.data.find(item => item.type === CONST_FOURNISSEUR_DOCUMENT_REGULARITE_FISCALE)
                                                                 ?.googleDriveId as string,
                                                            modalTitle: documentsQuery.data.find(item => item.type === CONST_FOURNISSEUR_DOCUMENT_REGULARITE_FISCALE)
                                                                 ?.typeLabel as string,
                                                            driveMimeType: documentsQuery.data.find(item => item.type === CONST_FOURNISSEUR_DOCUMENT_REGULARITE_FISCALE)
                                                                 ?.googleDriveMimeType as string as string,
                                                       })
                                                  }
                                             >
                                                  Voir <i className="fas fa-eye ms-1" />
                                             </button>
                                             <button
                                                  className={"btn btn-sm btn-danger d-flex align-items-center p-0 px-3 py-2"}
                                                  onClick={() =>
                                                       handleRemoveDocument(
                                                            documentsQuery.data.find(item => item.type === CONST_FOURNISSEUR_DOCUMENT_REGULARITE_FISCALE)?.id as number
                                                       )
                                                  }
                                             >
                                                  Supprimer <i className={"fas fa-trash ms-1"} />
                                             </button>
                                        </>
                                   ) : (
                                        <input type={"file"} className={"form-control"} onChange={e => handleAddDocument(e, CONST_FOURNISSEUR_DOCUMENT_REGULARITE_FISCALE)} />
                                   )}
                              </div>
                         </div>

                         {/* RIB */}
                         <div className={`row mb-6`}>
                              <label className="col-md-4 fw-bold fs-6 d-flex align-items-center">
                                   <span className={"me-2"}>RIB</span>
                                   {documentsQuery.data?.find(item => item.type === "TYPE_FOURNISSEUR_RIB") ? (
                                        <i className={"fas fa-check-circle text-success"} />
                                   ) : (
                                        <i className={"fas fa-exclamation-circle text-warning"} />
                                   )}
                              </label>
                              <div className="col-md-8 d-flex form-group">
                                   {documentsQuery.data?.find(item => item.type === "TYPE_FOURNISSEUR_RIB") ? (
                                        <>
                                             <button
                                                  className={"btn btn-sm btn-warning me-4 p-0 px-3 py-2 d-flex align-items-center"}
                                                  onClick={() => refsInputRemplacerDocument[CONST_FOURNISSEUR_DOCUMENT_RIB]?.current?.click()}
                                             >
                                                  Remplacer <i className={"fas fa-sync ms-1"} />
                                             </button>
                                             <input
                                                  type="file"
                                                  className={"d-none"}
                                                  ref={refsInputRemplacerDocument[CONST_FOURNISSEUR_DOCUMENT_RIB]}
                                                  onChange={e =>
                                                       handleSetDocument(e, documentsQuery.data?.find(item => item.type === CONST_FOURNISSEUR_DOCUMENT_RIB)?.id as number)
                                                  }
                                             />
                                             <button
                                                  className={"btn btn-sm btn-primary me-4 p-0 px-3 py-2 d-flex align-items-center"}
                                                  onClick={() =>
                                                       setActiveDriveDocumentInModal({
                                                            isShown: true,
                                                            isLoading: true,
                                                            driveID: documentsQuery.data.find(item => item.type === CONST_FOURNISSEUR_DOCUMENT_RIB)?.googleDriveId as string,
                                                            modalTitle: documentsQuery.data.find(item => item.type === CONST_FOURNISSEUR_DOCUMENT_RIB)?.typeLabel as string,
                                                            driveMimeType: documentsQuery.data.find(item => item.type === CONST_FOURNISSEUR_DOCUMENT_RIB)
                                                                 ?.googleDriveMimeType as string as string,
                                                       })
                                                  }
                                             >
                                                  Voir <i className="fas fa-eye ms-1" />
                                             </button>
                                             <button
                                                  className={"btn btn-sm btn-danger d-flex align-items-center p-0 px-3 py-2"}
                                                  onClick={() => handleRemoveDocument(documentsQuery.data.find(item => item.type === CONST_FOURNISSEUR_DOCUMENT_RIB)?.id as number)}
                                             >
                                                  Supprimer <i className={"fas fa-trash ms-1"} />
                                             </button>
                                        </>
                                   ) : (
                                        <input type={"file"} className={"form-control"} onChange={e => handleAddDocument(e, CONST_FOURNISSEUR_DOCUMENT_RIB)} />
                                   )}
                              </div>
                         </div>
                    </>
               )}

               {activeDriveDocumentInModal && (
                    <MyModal
                         title={<span>{activeDriveDocumentInModal.modalTitle}</span>}
                         show={true}
                         handleClose={() => setActiveDriveDocumentInModal(undefined)}
                         noPadding
                         size={"lg"}
                    >
                         <MyLoadingOverlay isActive={activeDriveDocumentInModal.isLoading}>
                              <iframe
                                   src={FileAndDriveHelper.getUrlOfDriveFilePreview(activeDriveDocumentInModal.driveID)}
                                   onLoad={() => setActiveDriveDocumentInModal(prev => (prev ? { ...prev, isLoading: false } : prev))}
                                   style={{ width: "100%", minHeight: "82vh", overflowY: "scroll" }}
                              />
                         </MyLoadingOverlay>
                    </MyModal>
               )}
          </>
     )
}

export default FournisseurDocuments
