import React from "react"
import * as Yup from "yup"
import { ErrorMessage, FieldArray, Form, Formik } from "formik"
import { useQueryClient } from "react-query"
import { toast } from "react-toastify"
import { useConnectedUserNdfDepensesContext } from "../_PersonnelNdfDepenses"
import { ICreateConnectedUserNdfDepenseGroupedRequestModel } from "../../../../mes-activites/ndf/core/_models"
import { IHttpErrorResponseModel, IPersonnelNdfDetailModel } from "@common-models/*"
import MyAlert from "@common-utils/MyAlert"
import MyDateDayMonthYearField from "@common-utils/fields/MyDateDayMonthYearField"
import MySelectField from "@common-utils/fields/MySelectField"
import {
     CONST_HTTP_CUSTOM_CODE_FORM_VALIDATION_ERROR,
     CONST_NDF_DETAILS_CATEGORIES_OPTIONS_FOR_SELECT2,
     CONST_PERSONNEL_NDF_DETAILS_CATEGORIE_REPAS_MIDI,
} from "@common-constants/*"
import MyInputField from "@common-utils/fields/MyInputField"
import { createPersonnelNdfDepenseGroupedRequest } from "../../core/_requests"
import MySelectCreatableField from "@common-utils/fields/MySelectCreatableField"
import MyTextareaField from "@common-utils/fields/MyTextareaField"

const ConnectedUserNdfDepenseCreateGrouped = () => {
     const queryClient = useQueryClient()
     const context = useConnectedUserNdfDepensesContext()

     // Schema
     const shema = Yup.object().shape({
          fichiers: Yup.array().of(
               Yup.mixed()
                    .required("PJ requise")
                    .test("fileSize", "La taille de la PJ doit être inférieure ou égale à 10MB", (value: any) => {
                         return value && value.size <= 10000000
                    })
                    .test("fileFormat", "La PJ doit être au format JPEG, PNG ou PDF", (value: any) => {
                         return value && ["application/pdf", "image/jpeg", "image/png"].includes(value.type)
                    })
          ),
          childs: Yup.array().of(
               Yup.object().shape({
                    date: Yup.string().required("Champ requis"),
                    numeroTicket: Yup.number().required("Champ requis").typeError("Format incorrect."),
                    categorie: Yup.string().required("Champ requis"),
                    montantTTC: Yup.number().required("Champ requis").typeError("Format incorrect."),
                    description: Yup.string().required().label(`"Description"`),
                    repasSeulOuEnGroupe: Yup.string()
                         .label(`"Seul ou en groupe"`)
                         .when("categorie", {
                              is: CONST_PERSONNEL_NDF_DETAILS_CATEGORIE_REPAS_MIDI,
                              then: schema => schema.required(),
                         }),
                    invites: Yup.array()
                         .label(`"Invités"`)
                         .when("repasSeulOuEnGroupe", {
                              is: "REPAS_MIDI_EN_GROUPE",
                              then: schema => schema.required(),
                         }),
               })
          ),
     })

     return (
          <Formik
               initialValues={{
                    fichiers: [new File([new Blob()], "")] as File[],
                    childs: [{ date: undefined, numeroTicket: "", categorie: "", montantTTC: "", description: "", repasSeulOuEnGroupe: undefined, invites: undefined }],
               }}
               onSubmit={(values, { setStatus, setSubmitting, setFieldError }) => {
                    setStatus(null)
                    const childs: ICreateConnectedUserNdfDepenseGroupedRequestModel[] = []
                    values.childs.map(item =>
                         childs.push({
                              numeroTicket: parseInt(item.numeroTicket),
                              categorie: item.categorie,
                              montantTTC: parseFloat(item.montantTTC),
                              date: item.date || undefined,
                              decription: item.description || undefined,
                              repasSeulOuEnGroupe: item.repasSeulOuEnGroupe || undefined,
                              invites: item.invites || undefined,
                         })
                    )
                    createPersonnelNdfDepenseGroupedRequest(context.ndfId, childs, values.fichiers as File[])
                         .then(r => {
                              queryClient.setQueryData([context.REACT_QUERY_KEY_GET_LISTING, context.ndfId], (details: IPersonnelNdfDetailModel[] | undefined) => {
                                   if (details) {
                                        const dataWithNewDepense = [r.data, ...details]
                                        context.computeTotalExpenses(dataWithNewDepense || [])
                                        return dataWithNewDepense
                                   }

                                   return []
                              })

                              toast.success("L'ajout a bien été pris en compte.")
                              setSubmitting(false)
                              context.setIsAddDepenseModalShown(false)
                              context.setTypeAddDepense(null)
                              context.setIsCardDepenseSimpleCollapsed(false)
                         })
                         .catch(e => {
                              const error: IHttpErrorResponseModel = e.response?.data

                              // Set form errors
                              if (error?.code === CONST_HTTP_CUSTOM_CODE_FORM_VALIDATION_ERROR && error?.errors) {
                                   for (const key in error.errors) setFieldError(key, error.errors[key])
                              }

                              // Set form global status and notify user using a toast
                              setStatus(error?.detail)

                              toast.error(error?.detail, { autoClose: false })

                              // Stop submit loader
                              setSubmitting(false)
                         })
               }}
               validationSchema={shema}
          >
               {({ values, setFieldValue, isSubmitting, status, errors }) => {
                    console.log(errors)
                    return (
                         <Form noValidate autoComplete="off">
                              {status && (
                                   <MyAlert type={"danger"} classNames={"mb-4"}>
                                        {status}
                                   </MyAlert>
                              )}
                              <div className={"mb-4"}>
                                   <label className="form-label fw-bolder fs-6">Justificatifs</label>
                                   <FieldArray name={"fichiers"}>
                                        {({ push, remove }) => (
                                             <div className={"row"}>
                                                  {values.fichiers.map((item, index) => {
                                                       return (
                                                            <div className={"col-md-6 col-lg-4 mb-4"} key={index}>
                                                                 <div className="d-flex">
                                                                      <input
                                                                           type={"file"}
                                                                           name={`fichiers[${index}]`}
                                                                           onChange={e =>
                                                                                setFieldValue(
                                                                                     `fichiers[${index}]`,
                                                                                     e.target.files && e.target.files.length > 0 ? e.target.files[0] : null
                                                                                )
                                                                           }
                                                                           className={"form-control me-2"}
                                                                      />
                                                                      {index > 0 && (
                                                                           <button className={"btn btn-light-danger btn-sm"} type={"button"} onClick={() => remove(index)}>
                                                                                <span className={"fas fa-minus-circle"}></span>
                                                                           </button>
                                                                      )}
                                                                 </div>
                                                                 <ErrorMessage name={`fichiers[${index}]`}>{msg => <div className={"text-danger"}>{msg}</div>}</ErrorMessage>
                                                                 <div className={"d-flex justify-content-sm-start justify-content-center mt-4"}>
                                                                      {index === values.fichiers.length - 1 && (
                                                                           <button
                                                                                className={`btn btn-light-primary btn-sm ${index > 0 && "me-2"}`}
                                                                                type={"button"}
                                                                                onClick={() => push({})}
                                                                           >
                                                                                Ajouter une autre justificatif <i className={"fas fa-plus-circle ps-1 pe-0"} />
                                                                           </button>
                                                                      )}
                                                                 </div>
                                                            </div>
                                                       )
                                                  })}
                                             </div>
                                        )}
                                   </FieldArray>
                              </div>

                              <label className="form-label fw-bolder fs-6">Détails des dépenses</label>
                              <FieldArray name={"childs"}>
                                   {({ push, remove }) => (
                                        <>
                                             <div className={"row"}>
                                                  {values.childs
                                                       .slice(0)
                                                       .reverse()
                                                       .map((item, index) => {
                                                            return (
                                                                 <div className={"col-sm-4 mb-4"} key={index}>
                                                                      <div className={"mb-2"}>
                                                                           <MyDateDayMonthYearField
                                                                                onChange={date => setFieldValue(`childs[${index}].date`, date)}
                                                                                value={values.childs[index].date}
                                                                                name={`childs[${index}].date`}
                                                                                placeholder={"Date"}
                                                                           />
                                                                           <ErrorMessage name={`childs[${index}].date`}>
                                                                                {msg => <div className={"text-danger"}>{msg}</div>}
                                                                           </ErrorMessage>
                                                                      </div>
                                                                      <div className={"mb-2"}>
                                                                           <input
                                                                                className={"form-control"}
                                                                                name={`childs[${index}].numeroTicket`}
                                                                                placeholder={"Numéro que vous avez inscrit"}
                                                                                autoComplete={"off"}
                                                                                inputMode={"numeric"}
                                                                                onChange={e => setFieldValue(`childs[${index}].numeroTicket`, e.target.value)}
                                                                                value={values.childs[index].numeroTicket}
                                                                           />
                                                                           <ErrorMessage name={`childs[${index}].numeroTicket`}>
                                                                                {msg => <div className={"text-danger"}>{msg}</div>}
                                                                           </ErrorMessage>
                                                                      </div>
                                                                      <div className={"mb-2"}>
                                                                           <MySelectField
                                                                                options={CONST_NDF_DETAILS_CATEGORIES_OPTIONS_FOR_SELECT2}
                                                                                name={`childs[${index}].categorie`}
                                                                                isSearchable={false}
                                                                                isClearable={false}
                                                                                placeholder={"Catégorie"}
                                                                                value={values.childs[index].categorie}
                                                                                onChange={value => setFieldValue(`childs[${index}].categorie`, value)}
                                                                                isInvalid={false}
                                                                           />
                                                                           <ErrorMessage name={`childs[${index}].categorie`}>
                                                                                {msg => <div className={"text-danger"}>{msg}</div>}
                                                                           </ErrorMessage>
                                                                      </div>
                                                                      <div className={"mb-2"}>
                                                                           <MyInputField
                                                                                name={`childs[${index}].montantTTC`}
                                                                                value={values.childs[index].montantTTC}
                                                                                type={"number"}
                                                                                inputMode={"decimal"}
                                                                                placeholder={"Montant TTC"}
                                                                                isInvalid={false}
                                                                                onChange={val => setFieldValue(`childs[${index}].montantTTC`, val)}
                                                                           />
                                                                           <ErrorMessage name={`childs[${index}].montantTTC`}>
                                                                                {msg => <div className={"text-danger"}>{msg}</div>}
                                                                           </ErrorMessage>
                                                                      </div>
                                                                      {values.childs[index].categorie === CONST_PERSONNEL_NDF_DETAILS_CATEGORIE_REPAS_MIDI && (
                                                                           <>
                                                                                <div className={"col-12 mt-5"}>
                                                                                     <MyAlert type={"primary"} classNames={"mb-2"}>
                                                                                          Veuillez indiquer s'il s'agit d'un repas seul ou en groupe
                                                                                     </MyAlert>
                                                                                     <MySelectField
                                                                                          name={`childs[${index}].repasSeulOuEnGroupe`}
                                                                                          options={[
                                                                                               {
                                                                                                    value: "REPAS_MIDI_SEUL",
                                                                                                    label: "Repas seul",
                                                                                               },
                                                                                               {
                                                                                                    value: "REPAS_MIDI_EN_GROUPE",
                                                                                                    label: "Repas en groupe",
                                                                                               },
                                                                                          ]}
                                                                                          value={values.childs[index].repasSeulOuEnGroupe}
                                                                                          onChange={val => setFieldValue(`childs[${index}].repasSeulOuEnGroupe`, val)}
                                                                                          isInvalid={false}
                                                                                          placeholder={"Choisissez si seul ou en groupe"}
                                                                                     />
                                                                                     <ErrorMessage name={`childs[${index}].repasSeulOuEnGroupe`}>
                                                                                          {msg => <div className={"text-danger"}>{msg}</div>}
                                                                                     </ErrorMessage>
                                                                                </div>

                                                                                {values.childs[index].repasSeulOuEnGroupe === "REPAS_MIDI_EN_GROUPE" && (
                                                                                     <>
                                                                                          <div className={"col-12 mt-2"}>
                                                                                               <MyAlert type={"primary"} classNames={"mb-2"}>
                                                                                                    Veuillez fournir les noms et prénoms de vos invités. S'il s'agit d'un client,
                                                                                                    ajoutez le nom de la société en plus.
                                                                                               </MyAlert>
                                                                                               <MySelectCreatableField
                                                                                                    name={`childs[${index}].invites`}
                                                                                                    options={[]}
                                                                                                    value={values.childs[index].invites}
                                                                                                    onChange={val => setFieldValue(`childs[${index}].invites`, val)}
                                                                                                    isInvalid={false}
                                                                                                    placeholder={"Saisissez les invités"}
                                                                                               />
                                                                                               <ErrorMessage name={`childs[${index}].invites`}>
                                                                                                    {msg => <div className={"text-danger"}>{msg}</div>}
                                                                                               </ErrorMessage>
                                                                                          </div>
                                                                                     </>
                                                                                )}
                                                                           </>
                                                                      )}

                                                                      <div className={"col-12 mt-5 mb-2"}>
                                                                           <MyTextareaField
                                                                                name={`childs[${index}].description`}
                                                                                value={values.childs[index].description}
                                                                                isInvalid={false}
                                                                                rows={2}
                                                                                onChange={val => setFieldValue(`childs[${index}].description`, val)}
                                                                                placeholder={"Decription"}
                                                                           />
                                                                           <ErrorMessage name={`childs[${index}].description`}>
                                                                                {msg => <div className={"text-danger"}>{msg}</div>}
                                                                           </ErrorMessage>
                                                                      </div>

                                                                      <div className={"d-flex justify-content-sm-start justify-content-between"}>
                                                                           {index > 0 && (
                                                                                <button
                                                                                     className={`btn btn-light-danger btn-sm ${index > 0 && "me-2"}`}
                                                                                     type={"button"}
                                                                                     onClick={() => remove(index)}
                                                                                >
                                                                                     Supprimer <i className={"fas fa-minus-circle"} />
                                                                                </button>
                                                                           )}

                                                                           {index === values.childs.length - 1 && (
                                                                                <button
                                                                                     className={`btn btn-light-primary btn-sm`}
                                                                                     type={"button"}
                                                                                     onClick={() =>
                                                                                          push({
                                                                                               date: undefined,
                                                                                               numeroTicket: "",
                                                                                               categorie: "",
                                                                                               montantTTC: "",
                                                                                               description: "",
                                                                                          })
                                                                                     }
                                                                                >
                                                                                     Ajouter un autre détail <i className={"fas fa-plus-circle"} />
                                                                                </button>
                                                                           )}
                                                                      </div>
                                                                 </div>
                                                            )
                                                       })}
                                             </div>
                                        </>
                                   )}
                              </FieldArray>

                              <div className="separator separator-dashed bg-primary my-4"></div>
                              <div className="text-center pt-6 align-items-center">
                                   <button className={"btn btn-sm btn-primary"} type={"submit"} disabled={isSubmitting}>
                                        {!isSubmitting && (
                                             <>
                                                  Finaliser l'ajout <i className={"fas fa-check-circle"} />
                                             </>
                                        )}
                                        {isSubmitting && (
                                             <span className="indicator-progress" style={{ display: "block" }}>
                                                  Ajout en cours
                                                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                             </span>
                                        )}
                                   </button>
                              </div>
                         </Form>
                    )
               }}
          </Formik>
     )
}

export default ConnectedUserNdfDepenseCreateGrouped
