import MyLoadingOverlay from "@common-utils/MyLoadingOverlay"
import FileAndDriveHelper from "@common-helpers/FileAndDriveHelper"
import { toAbsoluteUrl } from "@common-metronic/helpers"
import React, { useState } from "react"
import { useFournisseurListingContext } from "../../FournisseurListing"
import { IItemResponseModel, IResponseModel } from "../../core/_models"
import { useThemeMode } from "@common-metronic/partials"
import { IFournisseurResponsableModel } from "@common-models/*"
import MyModal from "@common-utils/MyModal"
import AddResponsable from "./AddResponsable"
import MyAlert from "@common-utils/MyAlert"
import swal from "sweetalert2"
import Swal from "sweetalert2"
import axios from "axios"
import { CONST_API_FOURNISSEUR_RESPONSABLE_ENVOYER_INSTRUCTIONS_CONNEXION_ESPACE_TALENT_ENDPOINT } from "../../../../../endpoints"
import { toast } from "react-toastify"
import FournisseurResponsableDetails from "../../../responsable/FournisseurResponsableDetails"
import { useQueryClient } from "react-query"

const Responsables = () => {
     const globalContext = useFournisseurListingContext()
     const selectedItemInPreview = globalContext.itemInPreview!.selectedItem
     const itemInPreview = globalContext.listingQuery.data?.data.find(item => item.fournisseur?.id === selectedItemInPreview.fournisseur?.id) as IItemResponseModel
     const [isAddingResponsable, setIsAddingResponsable] = useState(false)

     function handleAddResponsable(resp: IFournisseurResponsableModel) {
          swal.fire({
               icon: "warning",
               title: "Voulez-vous lui envoyer les instructions de connexion ?",
               showCancelButton: true,
               confirmButtonText: "Confirmer",
               cancelButtonText: "Annuler",
               showLoaderOnConfirm: true,
               preConfirm: async () => {
                    return axios
                         .post(CONST_API_FOURNISSEUR_RESPONSABLE_ENVOYER_INSTRUCTIONS_CONNEXION_ESPACE_TALENT_ENDPOINT(resp!.id), {})
                         .then(() => {
                              toast.success("Les instructions de connexion ont bien été envoyés.")
                         })
                         .catch(e => {
                              toast.error(e?.response?.data?.detail, { autoClose: false })
                         })
               },
               allowOutsideClick: () => !Swal.isLoading(),
          })
     }

     return (
          <>
               <div className="d-flex justify-content-between">
                    <h4>Responsables</h4>
                    <span className={"fas fa-plus-circle fs-1 cursor-pointer text-hover-primary"} onClick={() => setIsAddingResponsable(true)}></span>
               </div>

               <div className="separator separator-dashed mt-3 mb-5" />

               {itemInPreview.fournisseur.responsables!.length === 0 && <MyAlert type={"primary"}>Aucun responsable fournisseur de disponible.</MyAlert>}
               {itemInPreview.fournisseur.responsables!.length > 0 && (
                    <div className="d-flex flex-column gap-5">
                         {itemInPreview.fournisseur.responsables!.map((responsable, key) => (
                              <ResponsableItem responsable={responsable} key={key} />
                         ))}
                    </div>
               )}

               {isAddingResponsable && (
                    <MyModal title={<span>Nouveau Responsable</span>} show={isAddingResponsable} handleClose={() => setIsAddingResponsable(false)}>
                         <AddResponsable
                              closeModal={() => setIsAddingResponsable(false)}
                              onSubmitCallback={resp => {
                                   toast.success("Le responsable a bien été ajouté.")
                                   setIsAddingResponsable(false)
                                   handleAddResponsable(resp)
                              }}
                         />
                    </MyModal>
               )}
          </>
     )
}

const ResponsableItem = ({ responsable }: { responsable: IFournisseurResponsableModel }) => {
     const queryClient = useQueryClient()
     const globalContext = useFournisseurListingContext()
     const selectedItemInPreview = globalContext.itemInPreview!.selectedItem
     const itemInPreview = globalContext.listingQuery.data?.data.find(item => item.fournisseur?.id === selectedItemInPreview.fournisseur?.id) as IItemResponseModel

     const { mode } = useThemeMode()
     const [isAvatarLoading, setIsAvatarLoading] = useState<boolean>(!!responsable.user?.avatarGoogleDriveId)
     const [isShowingDetailsInModal, setIsShowingDetailsInModal] = useState(false)

     function handleEditResponsable(resp: IFournisseurResponsableModel) {
          queryClient.setQueryData<IResponseModel | undefined>(globalContext.REACT_QUERY_KEY_GET_LISTING, (prev: IResponseModel | undefined) => {
               if (prev?.data) {
                    prev?.data.map(item => {
                         if (item.fournisseur.id === itemInPreview.fournisseur.id) {
                              item.fournisseur = {
                                   ...item.fournisseur,
                                   responsables: item.fournisseur.responsables?.map(r => (r.id === resp.id ? resp : r)),
                              }

                              return item
                         }
                    })
               }

               return prev
          })
     }

     return (
          <>
               <div className={`p-4 d-flex align-items-center bg-light rounded-2 cursor-pointer border-hover-primary`} onClick={() => setIsShowingDetailsInModal(true)}>
                    {/* Avatar */}
                    <div>
                         <div className={"d-flex justify-content-center"}>
                              <MyLoadingOverlay isActive={isAvatarLoading} styles={{ height: "50px", width: "50px", borderRadius: "50%" }} size={"sm"}>
                                   {/* Avatar */}
                                   <img
                                        src={
                                             responsable.user?.avatarGoogleDriveId
                                                  ? FileAndDriveHelper.getThumbnailDriveUrl(responsable.user.avatarGoogleDriveId)
                                                  : toAbsoluteUrl(`/media/svg/avatars/${mode === "light" ? "blank" : "blank-dark"}.svg`)
                                        }
                                        onLoad={() => setIsAvatarLoading(false)}
                                        style={{ height: "50px", width: "50px", borderRadius: "50%" }}
                                        alt="Avatar"
                                   />
                              </MyLoadingOverlay>
                         </div>
                    </div>
                    <div className={"flex-grow-1 ms-4"}>
                         <div className={"d-flex align-items-center"}>
                              <div>
                                   <span className={"fs-5 fw-bold me-2 d-flex align-items-center"}>
                                        {responsable.user?.prenomNom}{" "}
                                        {responsable.user?.roles?.includes("ROLE_RESPONSABLE_FOURNISSEUR_ADMIN") && <div className={"badge badge-info ms-2"}>Admin</div>}
                                   </span>
                              </div>
                              <div className={"ms-auto"}>
                                   <div className={`badge badge-dark`}>{responsable.poste}</div>
                              </div>
                         </div>
                         <div className={"d-flex align-items-center"}>
                              <a href={`mailto:${responsable.user?.username}}`} target={"_blank"} rel={"noreferrer"}>
                                   {responsable.user?.username}
                              </a>
                              <div className={"ms-auto mt-1"}>
                                   <div className={`badge ${responsable.user?.enabled ? "badge-success" : "badge-danger"}`}>
                                        {responsable.user?.enabled ? (
                                             <span>
                                                  Compte actif <span className={"fas fa-check-circle"} />
                                             </span>
                                        ) : (
                                             <span>
                                                  Compte inactif <span className={"fas fa-times-circle"} />
                                             </span>
                                        )}
                                   </div>
                              </div>
                         </div>
                    </div>
               </div>

               {isShowingDetailsInModal && (
                    <MyModal title={<span>{responsable.user?.prenomNom}</span>} show={true} handleClose={() => setIsShowingDetailsInModal(false)}>
                         <FournisseurResponsableDetails fournisseurId={responsable.id!} onSubmitEditionCallback={handleEditResponsable} isFournisseurFieldVisible={false} />
                    </MyModal>
               )}
          </>
     )
}

export default Responsables
