import React from "react"
import AddResponsableFournisseurForm from "../../../../utils/_AddResponsableFournisseurForm"
import { IFournisseurResponsableModel } from "@common-models/*"
import { IItemResponseModel, IResponseModel } from "../../core/_models"
import { useQueryClient } from "react-query"
import { useFournisseurListingContext } from "../../FournisseurListing"

const AddResponsable = ({ closeModal, onSubmitCallback }: { closeModal: () => void; onSubmitCallback: (resp: IFournisseurResponsableModel) => void }) => {
     const queryClient = useQueryClient()
     const globalContext = useFournisseurListingContext()
     const selectedItemInPreview = globalContext.itemInPreview!.selectedItem
     const itemInPreview = globalContext.listingQuery.data?.data.find(item => item.fournisseur?.id === selectedItemInPreview.fournisseur?.id) as IItemResponseModel

     function handleOnSubmitCreation(resp: IFournisseurResponsableModel) {
          onSubmitCallback(resp)
          queryClient.setQueryData<IResponseModel | undefined>(globalContext.REACT_QUERY_KEY_GET_LISTING, (prev: IResponseModel | undefined) => {
               if (prev?.data) {
                    prev?.data.map(item => {
                         if (item.fournisseur.id === itemInPreview.fournisseur.id) {
                              item.fournisseur = {
                                   ...item.fournisseur,
                                   responsables: item.fournisseur.responsables ? [resp, ...item.fournisseur.responsables] : [resp],
                              }
                              return item
                         }
                    })
               }

               return prev
          })
     }

     return (
          <>
               <AddResponsableFournisseurForm
                    onSubmitCallback={handleOnSubmitCreation}
                    onCancelCallback={closeModal}
                    defaultFournisseurFieldLabel={itemInPreview.fournisseur.raisonSociale}
                    defaultFournisseurFieldValue={itemInPreview.fournisseur.id}
                    isFournisseurFieldReadOnly={true}
               />
          </>
     )
}

export default AddResponsable
