import { useQuery, useQueryClient } from "react-query"
import { AxiosError } from "axios"
import { IHttpErrorResponseModel, IPersonnelDeclarationMensuelleModel, IPersonnelDeclarationMensuelleSuiviModel } from "@common-models/*"
import { toast } from "react-toastify"
import MyAlert from "@common-utils/MyAlert"
import { Link, useNavigate } from "react-router-dom"
import GlobalHelper from "@common-helpers/GlobalHelper"
import globalHelper from "@common-helpers/GlobalHelper"
import moment from "moment"
import MyTooltip from "@common-utils/MyTooltip"
import MyCard from "@common-utils/MyCard"
import React from "react"
import MySimpleSpinner from "@common-utils/MySimpleSpinner"
import {
     CONST_PERSONNEL_DECLARATION_SUIVI_TYPE_INTERESSE_DEMANDE_REGUL,
     CONST_PERSONNEL_DECLARATION_SUIVI_TYPE_INTERESSE_FINALISATION,
     CONST_PERSONNEL_DECLARATION_SUIVI_TYPE_INTERESSE_RECTIFICATION_SANS_DEMANDE,
     CONST_PERSONNEL_DECLARATION_SUIVI_TYPE_INTERESSE_SIGNATURE,
     CONST_PERSONNEL_DECLARATION_SUIVI_TYPE_RESP_DEMANDE_RECTIFICATION,
     CONST_PERSONNEL_DECLARATION_SUIVI_TYPE_RESP_REJET_REGUL,
     CONST_PERSONNEL_DECLARATION_SUIVI_TYPE_RESP_VALIDATION,
     CONST_PERSONNEL_DECLARATION_SUIVI_TYPE_RESP_VALIDATION_REGUL,
     CONST_PERSONNEL_DECLARATION_SUIVI_TYPE_SIEGE_DEMANDER_RECTIFICATION,
     CONST_PERSONNEL_DECLARATION_SUIVI_TYPE_SIEGE_VALIDATION,
} from "@common-constants/*"
import { usePersonnelDeclarationActions } from "../core/_hooks"
import { useAuth } from "../../../../AppContext"
import { getPersonnelDeclarationsRequest } from "../../core/_requests"

const REACT_QUERY_KEY_GET_LISTING = "REACT_QUERY_KEY_GET_LISTING"
const ConnectedPersonnelDeclarationsList = () => {
     const navigate = useNavigate()
     const { currentUser } = useAuth()
     const queryClient = useQueryClient()

     const listingQuery = useQuery<IPersonnelDeclarationMensuelleModel[], AxiosError>(REACT_QUERY_KEY_GET_LISTING, async () => {
          return getPersonnelDeclarationsRequest(currentUser?.id as number, {})
               .then(r => {
                    return r.data
               })
               .catch((e: AxiosError) => {
                    const error: IHttpErrorResponseModel = e.response?.data

                    toast.error(error?.detail, { autoClose: false })

                    throw e
               })
     })

     const { actions } = usePersonnelDeclarationActions()

     function entamerRectificationSansValidationRespOuSiege(declaration: IPersonnelDeclarationMensuelleModel) {
          actions.entamerRectificationSansValidationBmOuSiege(declaration.id as number, () =>
               navigate(`${declaration.id}/details`, {
                    state: {
                         default_step: "recap",
                         breadCrumbPageTitle: GlobalHelper.firstLetterUppercase(moment(declaration.mois).format("MMMM YYYY")),
                    },
               })
          )
     }

     function handleDemandeRegulCallback(declaration_ID: number, suivi: IPersonnelDeclarationMensuelleSuiviModel) {
          queryClient.setQueryData<IPersonnelDeclarationMensuelleModel[] | undefined>(REACT_QUERY_KEY_GET_LISTING, (prev: IPersonnelDeclarationMensuelleModel[] | undefined) => {
               if (prev && prev?.length > 0) {
                    const index = prev.findIndex(item => item?.id === declaration_ID)
                    if (index >= 0) {
                         prev[index] = {
                              ...prev[index],
                              etat: suivi,
                         }
                         return prev
                    }
               }

               return prev
          })
     }

     const BadgeAccordingToEtat = ({ declaration }: { declaration: IPersonnelDeclarationMensuelleModel }) => {
          return (
               <div>
                    {[null, undefined, CONST_PERSONNEL_DECLARATION_SUIVI_TYPE_INTERESSE_RECTIFICATION_SANS_DEMANDE].includes(declaration.etat?.type) && (
                         <span className="badge badge-warning text-black">
                              À COMPLÉTER <i className={"fas fa-exclamation-triangle text-black ms-1"} />
                         </span>
                    )}
                    {[CONST_PERSONNEL_DECLARATION_SUIVI_TYPE_INTERESSE_FINALISATION].includes(declaration.etat?.type as string) && (
                         <span className="badge badge-warning text-black">
                              À SIGNER <i className={"fas fa-exclamation-triangle text-black ms-1"} />
                         </span>
                    )}
                    {[CONST_PERSONNEL_DECLARATION_SUIVI_TYPE_INTERESSE_SIGNATURE].includes(declaration.etat?.type as string) && (
                         <span className="badge badge-light-success">SIGNÉE ET ENVOYÉE</span>
                    )}

                    {[CONST_PERSONNEL_DECLARATION_SUIVI_TYPE_RESP_DEMANDE_RECTIFICATION].includes(declaration.etat?.type as string) && (
                         <span className="badge badge-light-danger">À RÉCTIFIER</span>
                    )}

                    {[CONST_PERSONNEL_DECLARATION_SUIVI_TYPE_RESP_VALIDATION_REGUL].includes(declaration.etat?.type as string) && (
                         <span className="badge badge-light-warning">À RÉGULARISER</span>
                    )}

                    {[
                         CONST_PERSONNEL_DECLARATION_SUIVI_TYPE_RESP_VALIDATION,
                         CONST_PERSONNEL_DECLARATION_SUIVI_TYPE_SIEGE_DEMANDER_RECTIFICATION,
                         CONST_PERSONNEL_DECLARATION_SUIVI_TYPE_SIEGE_VALIDATION,
                         CONST_PERSONNEL_DECLARATION_SUIVI_TYPE_INTERESSE_DEMANDE_REGUL,
                         CONST_PERSONNEL_DECLARATION_SUIVI_TYPE_RESP_REJET_REGUL,
                    ].includes(declaration.etat?.type as string) && <span className="badge badge-light-success">Validée</span>}
               </div>
          )
     }

     return (
          <div className={"d-flex justify-content-center"}>
               <div className={"w-100 w-lg-80"}>
                    <MyCard>
                         <MyCard.Header
                              rightSideContent={
                                   <>
                                        <button type={"button"} className={`btn btn-light-primary btn-sm me-2 d-flex align-items-center`} onClick={() => listingQuery.refetch()}>
                                             <span className={`d-md-inline d-none`}>Recharger la liste</span>
                                             <i className={"fas fa-sync ms-md-2 pe-0 fs-3"} />
                                        </button>
                                        <Link className={`btn btn-sm btn-primary d-flex align-items-center`} to={"create"}>
                                             Nouvelle déclaration <span className={"fas fa-plus-circle fs-3 ms-1"} />
                                        </Link>
                                   </>
                              }
                         >
                              Mes déclarations
                         </MyCard.Header>
                         <MyCard.Body>
                              {listingQuery.isFetching && (
                                   <div className={"text-center"}>
                                        <MySimpleSpinner size={"xl"} />
                                   </div>
                              )}

                              {!listingQuery.isFetching && !listingQuery.isError && listingQuery.data && listingQuery.data.length === 0 && (
                                   <MyAlert type={"primary"}>
                                        <div>Aucune déclaration trouvée</div>
                                   </MyAlert>
                              )}

                              {listingQuery.isError && (
                                   <MyAlert type={"danger"}>
                                        <div className={"d-flex align-items-center"}>
                                             <div className={"me-2"}>{listingQuery.error.response?.data.detail}</div>
                                             <button className={"btn btn-sm btn-danger d-flex align-items-center p-0 px-3 py-2"} onClick={() => listingQuery.refetch()}>
                                                  Recharger
                                             </button>
                                        </div>
                                   </MyAlert>
                              )}

                              {!listingQuery.isFetching && !listingQuery.isError && listingQuery.data && listingQuery.data.length > 0 && (
                                   <>
                                        <div className={"row"} style={{ maxHeight: "55vh", overflow: "auto" }}>
                                             {listingQuery.data.map((item, key) => (
                                                  <div className="col-md-6 col-xl-4 mb-9 card" key={key}>
                                                       <div className="card-header border border-primary bg-primary" style={{ minHeight: 0 }}>
                                                            <div className="card-title">
                                                                 <Link
                                                                      to={`${item.id}/details`}
                                                                      style={{ color: "inherit" }}
                                                                      state={{
                                                                           default_step: "recap",
                                                                           breadCrumbPageTitle: GlobalHelper.firstLetterUppercase(item.mois?.parseZone().format("MMMM YYYY")),
                                                                      }}
                                                                 >
                                                                      {GlobalHelper.firstLetterUppercase(item.mois?.parseZone().format("MMMM YYYY"))}
                                                                 </Link>
                                                            </div>
                                                            <div className="card-toolbar">
                                                                 <BadgeAccordingToEtat declaration={item} />
                                                            </div>
                                                       </div>
                                                       <div className="card-body text-center border-start border-end border-bottom border-primary p-4">
                                                            <div className={"d-flex justify-content-center"}>
                                                                 <MyTooltip title={"Arrêts maladie"}>
                                                                      <Link
                                                                           to={`${item.id}/details`}
                                                                           style={{ verticalAlign: "middle" }}
                                                                           state={{
                                                                                default_step: "arrets",
                                                                                breadCrumbPageTitle: GlobalHelper.firstLetterUppercase(item.mois?.parseZone().format("MMMM YYYY")),
                                                                           }}
                                                                           className={`p-3`}
                                                                      >
                                                                           <span className={"fas fa-file-medical text-dark"} style={{ fontSize: "2rem" }} />
                                                                      </Link>
                                                                 </MyTooltip>

                                                                 <MyTooltip title={"Demandes de congés"}>
                                                                      <Link
                                                                           to={`${item.id}/details`}
                                                                           style={{ verticalAlign: "middle" }}
                                                                           state={{
                                                                                default_step: "ddc",
                                                                                breadCrumbPageTitle: GlobalHelper.firstLetterUppercase(item.mois?.parseZone().format("MMMM YYYY")),
                                                                           }}
                                                                           className={`p-3`}
                                                                      >
                                                                           <span className={"fas fa-umbrella-beach text-dark"} style={{ fontSize: "2rem" }} />
                                                                      </Link>
                                                                 </MyTooltip>

                                                                 <MyTooltip title={"CRA"}>
                                                                      <Link
                                                                           to={`${item.id}/details`}
                                                                           style={{ verticalAlign: "middle" }}
                                                                           state={{
                                                                                default_step: "cra",
                                                                                breadCrumbPageTitle: globalHelper.firstLetterUppercase(item.mois?.parseZone().format("MMMM YYYY")),
                                                                           }}
                                                                           className={`p-3`}
                                                                      >
                                                                           <span className={"fas fa-calendar-days text-dark"} style={{ fontSize: "2rem" }} />
                                                                      </Link>
                                                                 </MyTooltip>

                                                                 <MyTooltip title={"NDF"}>
                                                                      <Link
                                                                           to={`${item.id}/details`}
                                                                           style={{ verticalAlign: "middle" }}
                                                                           state={{
                                                                                default_step: "ndf",
                                                                                breadCrumbPageTitle: GlobalHelper.firstLetterUppercase(item.mois?.parseZone().format("MMMM YYYY")),
                                                                           }}
                                                                           className={`p-3`}
                                                                      >
                                                                           <span className={"fas fa-file-invoice-dollar text-dark"} style={{ fontSize: "2rem" }} />
                                                                      </Link>
                                                                 </MyTooltip>

                                                                 <MyTooltip title={"TR"}>
                                                                      <Link
                                                                           to={`${item.id}/details`}
                                                                           style={{ verticalAlign: "middle" }}
                                                                           state={{
                                                                                default_step: "ticket_resto",
                                                                                breadCrumbPageTitle: GlobalHelper.firstLetterUppercase(item.mois?.parseZone().format("MMMM YYYY")),
                                                                           }}
                                                                           className={`p-3`}
                                                                      >
                                                                           <span className={"fas fa-utensils text-dark"} style={{ fontSize: "2rem" }} />
                                                                      </Link>
                                                                 </MyTooltip>

                                                                 <MyTooltip title={"Récapitulatif"}>
                                                                      <Link
                                                                           to={`${item.id}/details`}
                                                                           style={{ verticalAlign: "middle" }}
                                                                           state={{
                                                                                default_step: "recap",
                                                                                breadCrumbPageTitle: GlobalHelper.firstLetterUppercase(item.mois?.parseZone().format("MMMM YYYY")),
                                                                           }}
                                                                           className={`p-3`}
                                                                      >
                                                                           <span className={"fas fa-list text-dark"} style={{ fontSize: "2rem" }} />
                                                                      </Link>
                                                                 </MyTooltip>
                                                            </div>

                                                            <div className="separator separator-dashed mb-2"></div>

                                                            <div className="d-flex justify-content-center">
                                                                 {[
                                                                      undefined,
                                                                      CONST_PERSONNEL_DECLARATION_SUIVI_TYPE_INTERESSE_RECTIFICATION_SANS_DEMANDE,
                                                                      CONST_PERSONNEL_DECLARATION_SUIVI_TYPE_INTERESSE_FINALISATION,
                                                                 ].includes(item.etat?.type as string) && (
                                                                      <>
                                                                           <MyTooltip title={"Consulter ma déclaration"}>
                                                                                <button
                                                                                     type={"button"}
                                                                                     className={"btn btn-sm btn-primary me-2"}
                                                                                     onClick={() =>
                                                                                          navigate(`${item.id}/details`, {
                                                                                               state: {
                                                                                                    default_step: "recap",
                                                                                                    breadCrumbPageTitle: GlobalHelper.firstLetterUppercase(
                                                                                                         item.mois?.parseZone().format("MMMM YYYY")
                                                                                                    ),
                                                                                               },
                                                                                          })
                                                                                     }
                                                                                >
                                                                                     <span className={"fas fa-eye align-middle fs-4"} />
                                                                                </button>
                                                                           </MyTooltip>
                                                                           <button
                                                                                type={"button"}
                                                                                className={"btn btn-sm btn-success"}
                                                                                onClick={() =>
                                                                                     navigate(`${item.id}/details`, {
                                                                                          state: {
                                                                                               default_step: "recap",
                                                                                               breadCrumbPageTitle: GlobalHelper.firstLetterUppercase(
                                                                                                    item.mois?.parseZone().format("MMMM YYYY")
                                                                                               ),
                                                                                          },
                                                                                     })
                                                                                }
                                                                           >
                                                                                Signer <span className={"fad fa-signature align-middle fs-4"} />
                                                                           </button>
                                                                      </>
                                                                 )}

                                                                 {[CONST_PERSONNEL_DECLARATION_SUIVI_TYPE_INTERESSE_SIGNATURE].includes(item.etat?.type as string) && (
                                                                      <>
                                                                           {item.etat?.typeDependency === null && (
                                                                                <MyTooltip title={"Apporter une modification"}>
                                                                                     <button
                                                                                          type={"button"}
                                                                                          className={"btn btn-sm btn-warning me-2"}
                                                                                          onClick={() => entamerRectificationSansValidationRespOuSiege(item)}
                                                                                     >
                                                                                          <span className={"fas fa-edit align-middle fs-4"} />
                                                                                     </button>
                                                                                </MyTooltip>
                                                                           )}

                                                                           <MyTooltip title={"Consulter ma déclaration"}>
                                                                                <button
                                                                                     type={"button"}
                                                                                     className={"btn btn-sm btn-primary"}
                                                                                     onClick={() =>
                                                                                          navigate(`${item.id}/details`, {
                                                                                               state: {
                                                                                                    default_step: "recap",
                                                                                                    breadCrumbPageTitle: GlobalHelper.firstLetterUppercase(
                                                                                                         item.mois?.parseZone().format("MMMM YYYY")
                                                                                                    ),
                                                                                               },
                                                                                          })
                                                                                     }
                                                                                >
                                                                                     <span className={"fas fa-eye align-middle fs-4"} />
                                                                                </button>
                                                                           </MyTooltip>
                                                                      </>
                                                                 )}

                                                                 {[CONST_PERSONNEL_DECLARATION_SUIVI_TYPE_RESP_DEMANDE_RECTIFICATION].includes(item.etat?.type as string) && (
                                                                      <button
                                                                           type={"button"}
                                                                           className={"btn btn-sm btn-danger"}
                                                                           onClick={() =>
                                                                                navigate(`${item.id}/details`, {
                                                                                     state: {
                                                                                          default_step: "recap",
                                                                                          breadCrumbPageTitle: GlobalHelper.firstLetterUppercase(
                                                                                               item.mois?.parseZone().format("MMMM YYYY")
                                                                                          ),
                                                                                     },
                                                                                })
                                                                           }
                                                                      >
                                                                           Réctifier <i className={"fas fa-edit align-middle fs-4"} />
                                                                      </button>
                                                                 )}

                                                                 {[CONST_PERSONNEL_DECLARATION_SUIVI_TYPE_RESP_VALIDATION_REGUL].includes(item.etat?.type as string) && (
                                                                      <button
                                                                           type={"button"}
                                                                           className={"btn btn-sm btn-warning"}
                                                                           onClick={() =>
                                                                                navigate(`${item.id}/details`, {
                                                                                     state: {
                                                                                          default_step: "recap",
                                                                                          breadCrumbPageTitle: GlobalHelper.firstLetterUppercase(
                                                                                               item.mois?.parseZone().format("MMMM YYYY")
                                                                                          ),
                                                                                     },
                                                                                })
                                                                           }
                                                                      >
                                                                           Régulariser <i className={"fas fa-edit align-middle fs-4"} />
                                                                      </button>
                                                                 )}

                                                                 {[
                                                                      CONST_PERSONNEL_DECLARATION_SUIVI_TYPE_RESP_VALIDATION,
                                                                      CONST_PERSONNEL_DECLARATION_SUIVI_TYPE_INTERESSE_DEMANDE_REGUL,
                                                                      CONST_PERSONNEL_DECLARATION_SUIVI_TYPE_SIEGE_DEMANDER_RECTIFICATION,
                                                                 ].includes(item.etat?.type as string) && (
                                                                      <button
                                                                           type={"button"}
                                                                           className={"btn btn-sm btn-primary"}
                                                                           onClick={() =>
                                                                                navigate(`${item.id}/details`, {
                                                                                     state: {
                                                                                          default_step: "recap",
                                                                                          breadCrumbPageTitle: GlobalHelper.firstLetterUppercase(
                                                                                               item.mois?.parseZone().format("MMMM YYYY")
                                                                                          ),
                                                                                     },
                                                                                })
                                                                           }
                                                                      >
                                                                           Consulter <i className={"fas fa-eye align-middle fs-4"} />
                                                                      </button>
                                                                 )}

                                                                 {[
                                                                      CONST_PERSONNEL_DECLARATION_SUIVI_TYPE_SIEGE_VALIDATION,
                                                                      CONST_PERSONNEL_DECLARATION_SUIVI_TYPE_RESP_REJET_REGUL,
                                                                 ].includes(item.etat?.type as string) && (
                                                                      <>
                                                                           <MyTooltip title={"Consulter ma déclaration"}>
                                                                                <button
                                                                                     type={"button"}
                                                                                     className={"btn btn-sm btn-primary me-2"}
                                                                                     onClick={() =>
                                                                                          navigate(`${item.id}/details`, {
                                                                                               state: {
                                                                                                    default_step: "recap",
                                                                                                    breadCrumbPageTitle: GlobalHelper.firstLetterUppercase(
                                                                                                         item.mois?.parseZone().format("MMMM YYYY")
                                                                                                    ),
                                                                                               },
                                                                                          })
                                                                                     }
                                                                                >
                                                                                     <span className={"fas fa-eye align-middle fs-4"} />
                                                                                </button>
                                                                           </MyTooltip>
                                                                           <MyTooltip title={"Demander une régularisation"}>
                                                                                <button
                                                                                     type={"button"}
                                                                                     className={"btn btn-sm btn-danger me-2"}
                                                                                     onClick={() => actions.demandeRegul(item?.id as number, handleDemandeRegulCallback)}
                                                                                >
                                                                                     <span className={"fas fa-hand align-middle fs-4"} />
                                                                                </button>
                                                                           </MyTooltip>
                                                                      </>
                                                                 )}
                                                            </div>
                                                       </div>
                                                  </div>
                                             ))}
                                        </div>
                                   </>
                              )}
                         </MyCard.Body>
                    </MyCard>
               </div>
          </div>
     )
}

export default ConnectedPersonnelDeclarationsList
