import { usePersonnelTrContext } from "./_PersonnelTr"

const PersonnelTrTableTheadRows = () => {
     const context = usePersonnelTrContext()
     return (
          <>
               <tr className={"px-2"}>
                    <th className="col fw-bold sticky-column"></th>
                    {context.data.personnelCraDetails?.map((item, key) => {
                         return (
                              <th key={item.id} className={`text-center text-primary fw-bold ${key === context.data.personnelCraDetails!.length - 1 && "pe-4"}`}>
                                   {item.date?.parseZone().format("DD")}
                              </th>
                         )
                    })}
               </tr>
          </>
     )
}

export default PersonnelTrTableTheadRows
